export const INITIATE_COMPOSE_MESSAGE = 'communicator/INITIATE_COMPOSE_MESSAGE';
export const END_COMPOSE_MESSAGE = 'communicator/END_COMPOSE_MESSAGE';

export const CREATE_DRAFT_MESSAGE_REQUEST = 'communicator/CREATE_DRAFT_MESSAGE_REQUEST';
export const CREATE_DRAFT_MESSAGE_SUCCESS = 'communicator/CREATE_DRAFT_MESSAGE_SUCCESS';
export const CREATE_DRAFT_MESSAGE_FAILURE = 'communicator/CREATE_DRAFT_MESSAGE_FAILURE';

export const SEND_MESSAGE_REQUEST = 'communicator/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'communicator/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'communicator/SEND_MESSAGE_FAILURE';

export const ADD_RECIPIENT_REQUEST = 'communicator/ADD_RECIPIENT_REQUEST';
export const ADD_RECIPIENT_SUCCESS = 'communicator/ADD_RECIPIENT_SUCCESS';
export const ADD_RECIPIENT_FAILURE = 'communicator/ADD_RECIPIENT_FAILURE';

export const DELETE_RECIPIENT_REQUEST = 'communicator/DELETE_RECIPIENT_REQUEST';
export const DELETE_RECIPIENT_SUCCESS = 'communicator/DELETE_RECIPIENT_SUCCESS';
export const DELETE_RECIPIENT_FAILURE = 'communicator/DELETE_RECIPIENT_FAILURE';

export const GET_MESSAGE_REQUEST = 'communicator/GET_MESSAGE_REQUEST';
export const GET_MESSAGE_SUCCESS = 'communicator/GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_FAILURE = 'communicator/GET_MESSAGE_FAILURE';

export const GET_MESSAGE_RECIPIENTS_REQUEST = 'communicator/GET_MESSAGE_RECIPIENTS_REQUEST';
export const GET_MESSAGE_RECIPIENTS_SUCCESS = 'communicator/GET_MESSAGE_RECIPIENTS_SUCCESS';
export const GET_MESSAGE_RECIPIENTS_FAILURE = 'communicator/GET_MESSAGE_RECIPIENTS_FAILURE';

export const GET_MESSAGE_TEMPLATES_REQUEST = 'communicator/GET_MESSAGE_TEMPLATES_REQUEST';
export const GET_MESSAGE_TEMPLATES_SUCCESS = 'communicator/GET_MESSAGE_TEMPLATES_SUCCESS';
export const GET_MESSAGE_TEMPLATES_FAILURE = 'communicator/GET_MESSAGE_TEMPLATES_FAILURE';