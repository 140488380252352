import MapLayer from '../MapLayer';

export const SITE_DETAILS_SOURCE_ID = 'site-details-source';
export const SITE_DETAILS_LAYER_ID = 'site-details-layer';

export default class SiteDetailsLayer extends MapLayer {

  constructor() {
    super();

    this.data = false;
  }

  getInteractiveLayerIds() {
    return [SITE_DETAILS_LAYER_ID];
  }

  getLayerIds() {
    return [SITE_DETAILS_LAYER_ID];
  }

  createSources() {
    if (this.map) {
      this.map.addSource(SITE_DETAILS_SOURCE_ID, {
        type: 'geojson',
        data: this.data
      });
    }
  }

  createLayers() {
    if (this.map) {
      this.map.addLayer(
        {
          'id': SITE_DETAILS_LAYER_ID,
          'source': SITE_DETAILS_SOURCE_ID,
          'type': 'symbol',
          'layout': {
            'icon-image': [
              'case',
              ["!", ['has', 'pointCount']], 'icon_site_generic',
              ['<=', ['get', 'pointCount'], 9], `cluster_sites_1_digit`,
              ['<=', ['get', 'pointCount'], 99], `cluster_sites_2_digits`,
              ['<=', ['get', 'pointCount'], 999], `cluster_sites_3_digits`,
              'cluster_sites_4_digits'
            ],
            'text-field': ['get', 'pointCountAbbrev'],
            'text-size': 12,
          },
          'paint': {
            'text-color': '#ffffff'
          }
        }
      );
    }
  }

  /**
   * Set the source data for the layer,
   * sets source to false if data is undefined.
   *
   * @param data
   */
  setData(data) {
    if (this.data !== data) {
      this.data = data || false;

      if (this.map) {
        this.map.getSource(SITE_DETAILS_SOURCE_ID)?.setData(this.data);
      }
    }
  }
}
