import {push} from 'connected-react-router';
import {defineMessages, FormattedMessage} from 'react-intl';
import _ from 'lodash'

import * as types from './types';
import {notificationActions} from '../notification';
import {formatUpdatePayloadByConfigType, formatResponseByConfigType, groupResourcesLinksByType} from './utils';
import {ORGANIZATION_CONFIGURATION_TYPES} from '../../../containers/Organizations/OrganizationProfilePage/tabs/OrganizationClientConfiguration/ClientConfiguration.constants';
import LocalizedModal from '../../../components/Common/LocalizedModal/LocalizedModal';

const {CHECKIN_NOTIFICATIONS, CHECKIN_NOTIFICATION_ITEM, CUSTOM_LOGO} = ORGANIZATION_CONFIGURATION_TYPES;

const messages = defineMessages({
  updateFailure: {
    id: 'Redux.organization-configurations.updateFailure',
    defaultMessage: '{orgConfig} failed to update'
  },
  deleteFailure: {
    id: 'Redux.organization-configurations.deleteFailure',
    defaultMessage: '{orgConfig} failed to delete'
  },
  updateSuccess: {
    id: 'Redux.organization-configurations.updateSuccess',
    defaultMessage: '{orgConfig} successfully updated'
  },
  addSuccess: {
    id: 'Redux.organization-configurations.addSuccess',
    defaultMessage: '{orgConfig} successfully added'
  },
  deleteSuccess: {
    id: 'Redux.organization-configurations.deleteSuccess',
    defaultMessage: '{orgConfig} successfully deleted'
  },
  access_to_analyst: {
    id: 'Redux.organization-configurations.access_to_analyst',
    defaultMessage: 'Access to Analyst'
  },
  custom_logo: {
    id: 'Redux.organization-configurations.custom_logo',
    defaultMessage: 'Client Logo'
  },
  on_boarding: {
    id: 'Redux.organization-configurations.on_boarding',
    defaultMessage: 'Onboarding Fields'
  },
  custom_links: {
    id: 'Redux.organization-configurations.custom_links',
    defaultMessage: 'Custom Link'
  },
  accessToAnalyst: {
    id: 'Redux.organization-configurations.accessToAnalyst',
    defaultMessage: 'Access to Analyst'
  },
  data_retention: {
    id: 'Redux.organization-configurations.data_retention',
    defaultMessage: 'Data Retention'
  },
  custom_contacts: {
    id: 'Redux.organization-configurations.custom_contacts',
    defaultMessage: 'Custom Contacts'
  },
  custom_login: {
    id: 'Redux.organization-configurations.custom_login',
    defaultMessage: 'Custom Login'
  },
  custom_from_email: {
    id: 'Redux.organization-configurations.custom_from_email',
    defaultMessage: 'Custom From Email'
  },
  checkin_notifications: {
    id: 'Redux.organization-configurations.checkin_notifications',
    defaultMessage: 'Check-in Notifications'
  },
  checkin_notification_item: {
    id: 'Redux.organization-configurations.checkin_notification_item',
    defaultMessage: 'Check-in Notification'
  },
  mobile: {
    id: 'Redux.organization-configurations.mobile',
    defaultMessage: 'Mobile'
  },
  addSuccessConfiguration: {
    id: 'Redux.organization-configurations.addSuccessConfiguration',
    defaultMessage: 'Successfully saved'
  },
  addConfigurationFailure: {
    id: 'Redux.organization-configurations.addConfigurationFailure',
    defaultMessage: 'Could not save'
  },
  anotherOrgUsedFailure: {
    id: 'Redux.organization-configurations.anotherOrgUsedFailure',
    defaultMessage: 'Email Address is currently used by other Organizations. Customized Email Sent From addresses can only be configured within the same top parent and its hierarchy.'
  },
  'CUSTOM_FROM_EMAIL': {
    id: 'Redux.organization-configurations.CUSTOM_FROM_EMAIL',
    defaultMessage: 'Custom From Email'
  },
  modalOkText: {
    id: 'Redux.organization-configurations.modalOkText',
    defaultMessage: 'Ok'
  },
  modalHeader: {
    id: 'Redux.organization-configurations.modalHeader',
    defaultMessage: 'Email Address does not match validated Emails'
  },
  modalContent: {
    id: 'Redux.organization-configurations.modalContent',
    defaultMessage: 'The Email Address you have entered does not match a validated email address. Please check the spelling to ensure it aligns with the requested email address.'
    + '{newLine} {newLine}'
    + 'If the email address has not yet been validated, you will need to submit a request to ensure the client updates their DNS records to permit Crisis24 to use the email address.'
  },
});

/**
 *
 * @returns {Function}
 */
export const getOrgConfiguration = (orgId, orgConfigType) => {
  return (dispatch, _getState, {organization}) => {
    dispatch({type: types.GET_CONFIGURATION_REQUEST, payload: {configType: orgConfigType}});

    return organization.getConfiguration(orgId, orgConfigType)
      .then((result) => {
        const parsedResultData = formatResponseByConfigType(result.data, orgConfigType);
        return dispatch({
          type: types.GET_CONFIGURATION_SUCCESS,
          payload: {
            configType: orgConfigType,
            data: parsedResultData
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_CONFIGURATION_FAILURE, payload: {configType: orgConfigType}});
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const addOrgConfiguration = (orgId, values, orgConfigType, url) => {
  return (dispatch, _getState, {organization}) => {
    dispatch({type: types.ADD_CONFIGURATION_REQUEST, payload: {configType: orgConfigType}});

    const updatedValues = formatUpdatePayloadByConfigType(values, orgConfigType);

    return organization.addConfiguration(orgId, updatedValues, orgConfigType)
      .then((result) => {
        dispatch({
          type: types.ADD_CONFIGURATION_SUCCESS,
          payload: {
            configType: orgConfigType,
            data: result.data
          }
        });
        dispatch(notificationActions.showSuccessNotification(
          <FormattedMessage {...messages.addSuccessConfiguration} />
        ));

        /* added this because the EditOnboardingFieldsModal doesn't have a returnUrl*/
        if(url) {
          dispatch(push(url));
        }

      })
      .catch((error) => {
        const {data} = _.get(error, ['response']);
        const errorMessage = data.message

        dispatch({type: types.ADD_CONFIGURATION_FAILURE, payload: {configType: orgConfigType, error, errorMessage: errorMessage}});

        if (errorMessage?.includes('is in use by another client')) {
          dispatch(notificationActions.showErrorNotification(
            <FormattedMessage {...messages.anotherOrgUsedFailure} />
          ));
        }
        else if(errorMessage?.includes('is not whitelisted')) {
          LocalizedModal.error({
            closable: true,
            destroyOnClose: true,
            maskClosable: false,
            okType: 'primary',
            okText: <FormattedMessage {...messages.modalOkText} />,
            title: <FormattedMessage {...messages.modalHeader} />,
            content: <FormattedMessage {...messages.modalContent} values={{newLine: <br />}}/>
          });
        }
        else {
          dispatch(notificationActions.showErrorNotification(
            <FormattedMessage {...messages.addConfigurationFailure} />
          ));
        }
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const updateOrgConfiguration = (orgId, values, orgConfigType, url) => {
  return (dispatch, _getState, {organization}) => {

    const updatedValues = formatUpdatePayloadByConfigType(values, orgConfigType);

    dispatch({type: types.UPDATE_CONFIGURATION_REQUEST, payload: {configType: orgConfigType}});

    return organization.updateConfiguration(orgId, updatedValues, orgConfigType)
      .then((result) => {

        dispatch({
          type: types.UPDATE_CONFIGURATION_SUCCESS,
          payload: {
            configType: orgConfigType,
            data: result.data
          }
        });

        dispatch(notificationActions.showSuccessNotification(
          <FormattedMessage {...messages.updateSuccess} values={{orgConfig: <FormattedMessage {...messages[orgConfigType]}/>}}/>
        ));

        /* added this because the EditOnboardingFieldsModal doesn't have a returnUrl*/
        if(url) {
          dispatch(push(url));
        }

      })
      .catch((error) => {
        const {data} = _.get(error, ['response']);
        const errorMessage = data.message

        dispatch({type: types.UPDATE_CONFIGURATION_FAILURE, payload: {configType: orgConfigType, error, errorMessage: errorMessage}});

        if (errorMessage?.includes('is in use by another client')) {
          dispatch(notificationActions.showErrorNotification(
            <FormattedMessage {...messages.anotherOrgUsedFailure} />
          ));
        }
        else if(errorMessage?.includes('is not whitelisted')) {
          LocalizedModal.error({
            closable: true,
            destroyOnClose: true,
            maskClosable: false,
            okType: 'primary',
            okText: <FormattedMessage {...messages.modalOkText} />,
            title: <FormattedMessage {...messages.modalHeader} />,
            content: <FormattedMessage {...messages.modalContent} values={{newLine: <br />}}/>
          });
        }
        else {
          dispatch(notificationActions.showErrorNotification(
            <FormattedMessage {...messages.updateFailure} values={{orgConfig: <FormattedMessage {...messages[orgConfigType]}/>}}/>
          ));
        }
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const deleteOrgConfiguration = (itemId, orgId, orgConfigType, url) => {
  return (dispatch, _getState, {organization}) => {
    dispatch({type: types.DELETE_CONFIGURATION_REQUEST});

    return organization.deleteConfiguration(itemId, orgId, orgConfigType)
      .then(() => {
        dispatch({type: types.DELETE_CONFIGURATION_SUCCESS});
        dispatch(push(url));
      })
      .catch(() => {
        dispatch({type: types.DELETE_CONFIGURATION_FAILURE});

        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.deleteFailure} values={{orgConfig: <FormattedMessage {...messages[orgConfigType]}/>}}/>
        ));
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const getMobileCheckinNotificationConfigs = (orgId) => {
  return (dispatch, _getState, {checkinNotifications}) => {
    dispatch({type: types.GET_MOBILE_CONFIGURATIONS_REQUEST, payload: {configType: CHECKIN_NOTIFICATIONS}});
    return checkinNotifications.getCheckInNotifications(orgId)
      .then((result) => {
        const parsedResultData = formatResponseByConfigType(result.data, CHECKIN_NOTIFICATIONS);
        return dispatch({
          type: types.GET_MOBILE_CONFIGURATIONS_SUCCESS,
          payload: {
            configType: CHECKIN_NOTIFICATIONS,
            data: parsedResultData
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_MOBILE_CONFIGURATIONS_FAILURE, payload: {configType: CHECKIN_NOTIFICATIONS}});
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const getMobileCheckinNotificationItemConfig = (orgId, configurationId) => {
  return (dispatch, _getState, {checkinNotifications}) => {
    dispatch({type: types.GET_CONFIGURATION_ITEM_REQUEST, payload: {configType: CHECKIN_NOTIFICATION_ITEM}});

    return checkinNotifications.getCheckInNotificationItem(orgId, configurationId)
      .then((result) => {
        const parsedResultData = formatResponseByConfigType(result.data, CHECKIN_NOTIFICATION_ITEM);
        return dispatch({
          type: types.GET_CONFIGURATION_ITEM_SUCCESS,
          payload: {
            configType: CHECKIN_NOTIFICATION_ITEM,
            data: parsedResultData
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_CONFIGURATION_ITEM_FAILURE, payload: {configType: CHECKIN_NOTIFICATION_ITEM}});
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const addMobileCheckinNotificationItemConfig = (orgId, values, url) => {
  return (dispatch, _getState, {checkinNotifications}) => {
    dispatch({type: types.ADD_CONFIGURATION_ITEM_REQUEST, payload: {configType: CHECKIN_NOTIFICATION_ITEM}});

    return checkinNotifications.addCheckInNotification(orgId, values)
      .then((result) => {
        dispatch({
          type: types.ADD_CONFIGURATION_ITEM_SUCCESS,
          payload: {
            configType: CHECKIN_NOTIFICATION_ITEM,
            data: result.data
          }
        });
        dispatch(getMobileCheckinNotificationConfigs(orgId));
        dispatch(push(url));
        dispatch(notificationActions.showSuccessNotification(
          <FormattedMessage {...messages.addSuccess} values={{orgConfig: <FormattedMessage {...messages[CHECKIN_NOTIFICATION_ITEM]}/>}}/>
        ));
      })
      .catch((error) => {
        dispatch({type: types.ADD_CONFIGURATION_ITEM_FAILURE, payload: {configType: CHECKIN_NOTIFICATION_ITEM, error}});

        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.updateFailure} values={{orgConfig: <FormattedMessage {...messages[CHECKIN_NOTIFICATION_ITEM]}/>}}/>
        ));
      })
  };
};


export const updateMobileCheckinNotificationItemConfig = (orgId, configurationId, values, url) => {

  return (dispatch, _getState, {checkinNotifications}) => {
    dispatch({type: types.UPDATE_CONFIGURATION_ITEM_REQUEST, payload: {configType: CHECKIN_NOTIFICATION_ITEM}});

    return checkinNotifications.updateCheckInNotification(orgId, configurationId, values)
      .then((result) => {
        dispatch({
          type: types.UPDATE_CONFIGURATION_ITEM_SUCCESS,
          payload: {
            configType: CHECKIN_NOTIFICATION_ITEM,
            data: result.data
          }
        });
        dispatch(push(url));
        dispatch(getMobileCheckinNotificationConfigs(orgId));
        dispatch(notificationActions.showSuccessNotification(
          <FormattedMessage {...messages.updateSuccess} values={{orgConfig: <FormattedMessage {...messages[CHECKIN_NOTIFICATION_ITEM]}/>}}/>
        ));
      })
      .catch((error) => {
        dispatch({type: types.UPDATE_CONFIGURATION_ITEM_FAILURE, payload: {configType: CHECKIN_NOTIFICATION_ITEM, error}});

        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.updateFailure} values={{orgConfig: <FormattedMessage {...messages[CHECKIN_NOTIFICATION_ITEM]}/>}}/>
        ));
      })
  };
};


/**
 *
 * @returns {Function}
 */
export const deleteMobileCheckinNotificationItemConfig = (orgId, configurationId) => {
  return (dispatch, _getState, {checkinNotifications}) => {
    dispatch({type: types.DELETE_CONFIGURATION_ITEM_REQUEST});

    return checkinNotifications.deleteCheckInNotification(orgId, configurationId)
      .then(() => {
        dispatch({type: types.DELETE_CONFIGURATION_ITEM_SUCCESS});
        dispatch(getMobileCheckinNotificationConfigs(orgId));
      })
      .catch(() => {
        dispatch({type: types.DELETE_CONFIGURATION_ITEM_FAILURE});

        dispatch(notificationActions.showErrorNotification(
          <FormattedMessage {...messages.deleteFailure} values={{orgConfig: <FormattedMessage {...messages[CHECKIN_NOTIFICATION_ITEM]}/>}}/>
        ));
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const clearMobileCheckinNotificationItemConfig = () => {
  return (dispatch, _getState) => {
    dispatch({
      type: types.CLEAR_CONFIGURATION_ITEM,
      payload: {
        configType: CHECKIN_NOTIFICATION_ITEM,
        data: {}
      }
    });
  };
};

/**
 *
 * @returns {Function}
 */
export const clearClientLogoItemConfig = () => {
  return (dispatch, _getState) => {
    dispatch({
      type: types.CLEAR_CONFIGURATION_ITEM,
      payload: {
        configType: CUSTOM_LOGO,
        data: {}
      }
    });
  };
};

/**
 *
 * @returns {Function}
 */
export const getOrgResources = (orgId) => {
  return (dispatch, _getState, {resources}) => {
    dispatch({type: types.GET_RESOURCES_REQUEST});

    return resources.getResources(orgId)
      .then((result) => {
        const groupedLinks = groupResourcesLinksByType(result);

        return dispatch({
          type: types.GET_RESOURCES_SUCCESS,
          payload: {
            data: groupedLinks
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_RESOURCES_FAILURE});
      });
  };
};
