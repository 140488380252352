import TagManager from 'react-gtm-module';
import _ from 'lodash';
import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';

import {getDatadogPermitted} from '../utils/usercentrics';

/**
 * Initializes Google Tag Manager and Datadog UX Monitoring
 */
function initialize() {
  if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize(process.env.REACT_APP_GTM_ID);
  }
}

function initializeAnalytics(currentState) {
  if (process.env.REACT_APP_DD_ENV && getDatadogPermitted()) {
    datadogRum.init({
      applicationId: 'e7d126a3-f544-4fa9-bb75-cacf324754ae',
      clientToken: 'pubb7f48bd5674bc228193335d3d585df34',
      site: 'datadoghq.com',
      service: 'horizon-web',
      env: process.env.REACT_APP_DD_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      sampleRate: 100,
      version: (process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION + (process.env.REACT_APP_HASH ? '-' + process.env.REACT_APP_HASH : '') : 'LOCAL'),
      trackInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ["frustration-signals"],
      allowedTracingOrigins: [/https:\/\/.*\.crisis24horizon\.com/]
    });
    datadogRum.addRumGlobalContext('stack', process.env.REACT_APP_STACK);
    datadogLogs.setLoggerGlobalContext({stack: process.env.REACT_APP_STACK});
  }
  this.updateAnalytics(currentState);
}

function updateAnalytics(currentState) {
  this.ddSetUserVars(currentState);
  this.updateGTMDataLayer(currentState);
}

function getCustomVars(currentState) {
  const OrgName = _.get(currentState, 'session.currentPermissions.orgName');
  const OrgId = _.get(currentState, 'session.currentPermissions.orgId');
  const UserId = _.get(currentState, 'session.userId');
  const LanguageId = _.get(currentState, 'localization.locale');
  return {OrgName, OrgId, UserId, LanguageId}
}

/**
 * Identify user sessions in Datadog
 */
function ddSetUserVars(currentState) {
  const {UserId, OrgName, OrgId} = this.getCustomVars(currentState);

  if (UserId) {
    datadogRum.setUser({
      id: UserId,
      orgName: OrgName,
      orgId: OrgId
    });

    datadogRum.startSessionReplayRecording();
  }

}

/**
 * Update GTM data layer
 */
function updateGTMDataLayer(currentState) {
  if (process.env.REACT_APP_GTM_ID) {
    const {OrgName, OrgId, UserId, LanguageId} = this.getCustomVars(currentState);

    const tagManagerArgs = {
      dataLayer: {
        OrgName, OrgId, UserId, LanguageId
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  }
}

export default {
  initialize,
  initializeAnalytics,
  updateAnalytics,
  getCustomVars,
  ddSetUserVars,
  updateGTMDataLayer
};
