import {defineMessages} from "react-intl";

const messages = defineMessages({
  country: {
    id: 'locations.constants.country',
    defaultMessage: 'Country'
  },
  province: {
    id: 'locations.constants.province',
    defaultMessage: 'Province'
  },
  city: {
    id: 'locations.constants.city',
    defaultMessage: 'City'
  }
});

export const LOCATION_TIER_IDS = {
  COUNTRY: 3,
  PROVINCE: 5,
  CITY: 6
};

export const LOCAION_TIER_ENUM_IDS = {
  [LOCATION_TIER_IDS.COUNTRY]: 'Country',
  [LOCATION_TIER_IDS.PROVINCE]: 'Province',
  [LOCATION_TIER_IDS.CITY]: 'City'
};

export const WORLDWIDE_ID = '1';

export const LOCATION_TIER = {
  COUNTRY: {
    id: 'COUNTRY',
    tier: 3,
    labelMessage: messages.country
  },
  PROVINCE: {
    id: 'PROVINCE',
    tier: 5,
    labelMessage: messages.province
  },
  CITY: {
    id: 'CITY',
    tier: 6,
    labelMessage: messages.city
  }
}

