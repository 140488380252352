export const LOGO_TYPE_FIELDS = {
  WEB_APP: 'WEB',
  MOBILE_APP: 'MOBILE',
  NOTIFICATIONS: 'NOTIFICATION'
};

export const CLIENT_LOGOS_FIELD_NAMES = {
  LOGO: 'logo',
  SCOPES: 'scopes',
  LOGOS: 'logos'
};

export const LOGO_SCOPES = {
  ...LOGO_TYPE_FIELDS,
  NONE: 'NONE'
};