import * as types from './types';
import {SET_CURRENT_PERSONA} from '../session/types';

const categoriesReducer = (
  state = {
    isFetchingAlertCategories: false,
    alertCategoriesTree: [],
    alertParentCategoriesTree: [],
    alertCategoriesDict: {},
    error: null,
    isFetchingArticlesCategories: false,
    articlesCategoriesTree: [],
    articlesParentCategoriesTree: [],
    articlesDict: {},
    isFetchingRatingsCategories: false,
    ratingsCategoriesTree: [],
    ratingsDict: {},

    isFetchingIncidentCategories: false,
    incidentCategoriesTree: [],
    incidentParentCategoriesTree: [],
    incidentCategoriesDict: {},

    isFetchingCategories: false,
    topLevelCategories: [],
    categoriesTree: [],
    dict: {},
    locale: undefined
  },
  action
) => {
  switch (action.type) {
    case types.ALERT_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingAlertCategories: true,
        error: null
      };
    case types.ALERT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingAlertCategories: false,
        alertCategoriesTree: action.payload.alertCategoriesTree,
        alertParentCategoriesTree: action.payload.alertParentCategoriesTree,
        alertCategoriesDict: action.payload.dict
      };
    case types.ALERT_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingAlertCategories: false,
        alertCategoriesTree: [],
        alertParentCategoriesTree: [],
        alertCategoriesDict: {},
        error: action.error
      };

    case types.ARTICLES_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingArticlesCategories: true,
        error: null
      };
    case types.ARTICLES_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingArticlesCategories: false,
        articlesCategoriesTree: action.payload.alertCategoriesTree,
        articlesParentCategoriesTree: action.payload.alertParentCategoriesTree,
        articlesDict: action.payload.dict
      };
    case types.ARTICLES_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingArticlesCategories: false,
        articlesCategoriesTree: [],
        articlesParentCategoriesTree: [],
        articlesDict: {},
        error: action.error
      };

    case types.RATINGS_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingRatingsCategories: true,
        ratingsCategoriesTree: [],
        ratingsDict: {}
      };
    case types.RATINGS_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingRatingsCategories: false,
        ratingsCategoriesTree: action.payload.alertCategoriesTree,
        ratingsDict: action.payload.dict
      };
    case types.RATINGS_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingRatingsCategories: false
      };

    case types.CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingCategories: true,
      };
    case types.CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingCategories: false,
        topLevelCategories: action.payload.topLevelCategories,
        categoriesTree: action.payload.categoriesTree,
        dict: action.payload.dict,
        locale: action.payload.locale
      };
    case types.CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingAllCategories: false
      };

    case types.INCIDENT_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingIncidentCategories: true,
        error: null
      };
    case types.INCIDENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingIncidentCategories: false,
        incidentCategoriesTree: action.payload.alertCategoriesTree,
        incidentParentCategoriesTree: action.payload.alertParentCategoriesTree,
        incidentCategoriesDict:  action.payload.dict,
      };
    case types.INCIDENT_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingIncidentCategories: false,
        incidentCategoriesTree: [],
        incidentParentCategoriesTree: [],
        error: action.error
      };

    case SET_CURRENT_PERSONA:
      return {
        ...state,
        alertCategoriesTree: [],
        alertParentCategoriesTree: [],
        ratingsCategoriesTree: [],
        categoriesTree: [],
        dict: {},
        locale: undefined
      };
    default:
      return state;
  }
};

export default categoriesReducer;
