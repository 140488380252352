import {PureComponent} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LocalizedNotification from '../../components/Common/LocalizedNotification/LocalizedNotification';
import {notificationActions} from '../../redux/modules/notification';

export class Notifications extends PureComponent {

  static propTypes = {
    notifications: PropTypes.array,
    removeNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.notifications)) {
      this.updateNotifications();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.notifications !== prevProps.notifications) {
      this.updateNotifications();
    }
  }

  updateNotifications = () => {
    _.forEach(this.props.notifications, this.renderNotification);
  };

  renderNotification = (notification) => {
    const props = {
      onClose: () => this.props.removeNotification(notification.key),
      ...notification
    };

    switch (notification.type) {
      case 'info':
        return LocalizedNotification.info(props);
      case 'warning':
        return LocalizedNotification.warning(props);
      case 'error':
        return LocalizedNotification.error(props);
      case 'success':
        return LocalizedNotification.success(props);

      default:
        return LocalizedNotification.open(props);
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = ({notification, localization}) => ({
  notifications: notification.notifications,
  locale: localization.locale,
});

const mapDispatchToProps = dispatch => ({
  removeNotification: (key) => dispatch(notificationActions.removeNotification(key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
