export const ALERT_CATEGORIES_REQUEST = 'categories/ALERT_CATEGORIES_REQUEST';
export const ALERT_CATEGORIES_SUCCESS = 'categories/ALERT_CATEGORIES_SUCCESS';
export const ALERT_CATEGORIES_ERROR = 'categories/ALERT_CATEGORIES_ERROR';

export const ARTICLES_CATEGORIES_REQUEST = 'categories/ARTICLES_CATEGORIES_REQUEST';
export const ARTICLES_CATEGORIES_SUCCESS = 'categories/ARTICLES_CATEGORIES_SUCCESS';
export const ARTICLES_CATEGORIES_ERROR = 'categories/ARTICLES_CATEGORIES_ERROR';

export const RATINGS_CATEGORIES_REQUEST = 'categories/RATINGS_CATEGORIES_REQUEST';
export const RATINGS_CATEGORIES_SUCCESS = 'categories/RATINGS_CATEGORIES_SUCCESS';
export const RATINGS_CATEGORIES_ERROR = 'categories/RATINGS_CATEGORIES_ERROR';

export const CATEGORIES_REQUEST = 'categories/CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'categories/CATEGORIES_SUCCESS';
export const CATEGORIES_ERROR = 'categories/CATEGORIES_ERROR';

export const INCIDENT_CATEGORIES_REQUEST = 'categories/INCIDENT_CATEGORIES_REQUEST';
export const INCIDENT_CATEGORIES_SUCCESS = 'categories/INCIDENT_CATEGORIES_SUCCESS';
export const INCIDENT_CATEGORIES_ERROR = 'categories/INCIDENT_CATEGORIES_ERROR';
