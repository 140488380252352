import _ from 'lodash';

import authStorage from './auth-storage';
import {Amplify, Auth, Hub} from 'aws-amplify';

import {ROUTE_URLS} from '../../constants/routes';
import {SELF_REGISTRATION_TYPES} from '../../constants/self-registration';
import selfRegistration from './self-registration';

const AMPLIFY_CONFIG = {
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile'
    ],
    redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT,
    redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT,
    responseType: 'code'
  },
  Auth:{
    cookieStorage: {
      domain: process.env.REACT_AMPLIFY_COOKIE_DOMAIN,
      path: '/',
      expires: null,
      sameSite: 'strict',
      secure: process.env.REACT_AMPLIFY_COOKIE_DOMAIN !== 'localhost'
    }
  }
};

const FSSO_ERROR_MESSAGE = 'Already found an entry for username';

/**
 * Applies Amplify.configure and adds Hub listener to watch customOAuthState
 * events on auth channel. Upon event triggers redirectToEncodedUrl, used for
 * forwarding deep linkage
 */
function initialize() {
  Amplify.configure(AMPLIFY_CONFIG);

  const queryString = window.location.search;

  // check for state in query string and add listener to auth channel to redirect if found and signIn event
  if (queryString) {
    const params = new URLSearchParams(queryString);

    const encodedUrl = params.get('state');
    const errorDescription = params.get('error_description');

    // Retry FSSO if specific error case
    if (errorDescription && errorDescription.includes(FSSO_ERROR_MESSAGE)) {
      const emailDomain = _.last(errorDescription?.split('@'))?.trim();
      const isRetryBlocked = authStorage.getFssoRetryBlock();

      if (emailDomain && !isRetryBlocked) {
        authStorage.setFssoRetryBlock(true);

        selfRegistration.selfRegPoliciesExists({type: SELF_REGISTRATION_TYPES.FSSO, emailDomain})
          .then(results => {
            if (results?.data?.providerName) {
              federatedSignIn(results?.data?.providerName, encodedUrl)
                .then(() => null);
            }
          });
        return;
      }
      else {
        authStorage.setFssoRetryBlock(false);
      }
    }

    if (encodedUrl) {
      Hub.listen('auth', ({payload: {event}}) => {
        if (event === 'signIn') {
          authStorage.setFssoRetryBlock(false);
          authStorage.redirectToEncodedUrl(encodedUrl);
        }
      });
    }
  }

  authStorage.setFssoRetryBlock(false);
  Hub.listen('auth', ({payload: {event, data}}) => {
    if (event === 'customOAuthState') {
      authStorage.redirectToEncodedUrl(data);
    }
  });
}

/**
 * Returns Auth.currentSession true if resolves else false
 * @returns {Promise<boolean>}
 */
function hasCurrentSession() {
  return Auth.currentSession()
    .then(() => true)
    .catch(() => false);
}

/**
 * Triggers Auth.signOut
 */
function logout() {
  return Auth.signOut()
    .then(() => null)
    .catch(() => null)
    .finally(() => {
      if (!authStorage.getFssoRetryBlock()) {
        window.location.assign(`${window.location.origin}${ROUTE_URLS.LOGIN}`);
      }
    });
}

/**
 * Clears local session information
 * Directs application to federatedSignIn
 *
 * Adds object with customState property set to encoded window location href
 * to facilitate deep link return upon login return
 */
function redirectToLogin() {
  if (!authStorage.getFssoRetryBlock()) {

    const encodedUrl = authStorage.encodeUrl(window.location.href);

    window.location.assign(`${window.location.origin}${ROUTE_URLS.LOGIN}?redirectUrl=${encodedUrl}`);
  }
}

function signIn(username, password) {
  return Auth.signIn(username, password);
}

function forgotPassword(username, metadata) {
  return Auth.forgotPassword(username, metadata);
}

function forgotPasswordSubmit(username, code, password) {
  return Auth.forgotPasswordSubmit(username, code, password);
}

function completeNewPassword(user, password) {
  return Auth.completeNewPassword(user, password);
}

function federatedSignIn(provider, redirectUrl) {
  return Auth.federatedSignIn({
    customProvider: provider,
    customState: redirectUrl
  });
}

function sendCustomChallengeAnswer(user, challengeResponse) {
  return Auth.sendCustomChallengeAnswer(user, challengeResponse);
}

export default {
  initialize,
  logout,
  redirectToLogin,
  signIn,
  forgotPassword,
  forgotPasswordSubmit,
  completeNewPassword,
  federatedSignIn,
  hasCurrentSession,
  sendCustomChallengeAnswer
};
