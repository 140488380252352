export const ALERT_LAYER_ID_GLOBAL = 'alerts-layer';
export const PERSON_DETAIL_LAYER_ID_GLOBAL = 'person-detail-layer';

export const MAX_PERSONS_IN_LIST_DRAWER = 500;
export const MAX_SITES_IN_LIST_DRAWER = 500;
export const MAX_OCCUPANTS_IN_LIST_DRAWER = 500;

export const MAP_LOCATIONS_FILTER = 'locationsFilter';

export const ALERT_SOURCE_ID = 'alerts-source';
export const ALERT_LAYER_ID = ALERT_LAYER_ID_GLOBAL;
export const ALERT_CLUSTER_LAYER_ID = 'alerts-clustered-layer';