import {SITE_COUNT_AS_VALUES} from '../../../../WorldMap/MapMenuDrawer/MapFilterDrawer/SiteTab/constants';
import MapLayer from '../MapLayer';

export const SITE_SOURCE_ID = 'site-source';
export const SITE_LAYER_ID = 'site-layer';

const FILTER_SITES = 'pointCount';
const FILTER_SITES_ABBREV = 'pointCountAbbrev';

const FILTER_OCCUPANTS = 'occupantCount';
const FILTER_OCCUPANTS_ABBREV = 'occupantCountAbbrev';

export default class SiteLayer extends MapLayer {

  constructor() {
    super();

    this.data = false;
    this.filterProperty = undefined;
    this.filterAbbrevProperty = undefined;
  }

  getInteractiveLayerIds() {
    return [SITE_LAYER_ID];
  }

  getLayerIds() {
    return [SITE_LAYER_ID];
  }

  createSources() {
    if (this.map) {
      this.map.addSource(SITE_SOURCE_ID, {
        type: 'geojson',
        data: this.data
      });
    }
  }

  createLayers() {
    if (this.map) {
      this.map.addLayer(
        {
          'id': SITE_LAYER_ID,
          'source': SITE_SOURCE_ID,
          'type': 'symbol',
          'layout': {
            'text-size': 12,
            'icon-allow-overlap': true
          },
          'paint': {
            'text-color': '#ffffff'
          }
        }
      );
    }
  }

  /**
   * Set the source data for the layer,
   * sets source to false if data is undefined.
   *
   * @param data
   */
  setData(data) {
    if (this.data !== data) {
      this.data = data || false;

      if (this.map) {
        this.map.getSource(SITE_SOURCE_ID)?.setData(this.data);
      }
    }
  }

  updateFilterProperty(currentCountAsValue) {
    if (currentCountAsValue === SITE_COUNT_AS_VALUES.SITES) {
      this.filterProperty = FILTER_SITES;
      this.filterAbbrevProperty = FILTER_SITES_ABBREV;
    } else {
      this.filterProperty = FILTER_OCCUPANTS;
      this.filterAbbrevProperty = FILTER_OCCUPANTS_ABBREV;
    }
    this.map?.setLayoutProperty(SITE_LAYER_ID, 'icon-image', [
      'case',
      ["!", ['has', this.filterProperty]], 'icon_site_generic',
      ['<=', ['get', this.filterProperty], 9], `cluster_sites_1_digit`,
      ['<=', ['get', this.filterProperty], 99], `cluster_sites_2_digits`,
      ['<=', ['get', this.filterProperty], 999], `cluster_sites_3_digits`,
      'cluster_sites_4_digits'
    ]);
    this.map?.setLayoutProperty(SITE_LAYER_ID, 'text-field', ['get', this.filterAbbrevProperty]);
  }
}
