import {useMemo} from 'react';

import communicatorApi from '../../../services/api/communicator';
import useBaseReadServiceHook from '../../services/useBaseReadServiceHook';

export default function useResponseOptions(deliveryId) {

  const config = useMemo(() => {
    if (deliveryId) {
      return  {
        apiCall: communicatorApi.getResponseOptions,
        pathParams: {deliveryId},
      };
    }
  }, [deliveryId]);

  return useBaseReadServiceHook(config);
}
