import PropTypes from 'prop-types';

export const FS_EXCLUDE = 'fs-exclude'
export const DD_PRIVACY_MASK = 'dd-privacy-mask'

export default function FsExcludeWrapper(props = {}) {

  const {
    children,
  } = props;

  return (
    <span className={`${FS_EXCLUDE} ${DD_PRIVACY_MASK}`} >
      {children}
    </span>
  );
}

FsExcludeWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
