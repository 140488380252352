/**
 * Base reading service hook for hitting a service and returning data. Utilizes
 * abort controller so that only the most recent call is stored as the result.
 * This should be wrapped within additional hook and not used directly in a component.
 *
 * ex. or a wrapping hook
 *

 function useEntityDetails(searchData) {
    // useMemo to create/maintain the configuration only updating as above parameter change (searchData)
    const config = useMemo(() => {
      if ( searchData) // logic for when call should be cleared and results set to null
        return {
          apiCall: serviceApi.getEntity,
          pathParams: { data expected to be used in api call path },
          params: { data expected to be used as api query params }
          data: { data expected to be used as api data params (POST) }
        };
      }
    }, [searchData]); //items that effect prevention logic

 return useBaseReadServiceHook(config);
 }


 *
 * hook access points
 *
 * loading boolean to know if it is waiting for service call to complete
 * results current data results
 * sync function to force retrigger even when config has not changed, with slight delay 100ms. ex update data after another service saves
 * cancel function to cancel current call and set results to null
 */

import {useCallback, useEffect, useState} from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

/**
 *
 * @param {Object} config
 * @param {function} config.apiCall
 * @param {Object} config.params
 * @param {Object} config.pathParams URL path params object
 * @param {Object} config.params query params object
 * @param {Object} config.data api data params object
 *
 */
function useBaseReadServiceHook(config = {}) {
  const {
    apiCall,
    pathParams,
    params,
    data,
    onSuccess,
    onError
  } = config || {};

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(undefined);
  const [abortController, setAbortController] = useState(null);

  const refreshResults = useCallback(() => {
    if (!_.isEmpty(config)) {
      abortController?.abort?.();

      setLoading(true);

      const _abortController = new AbortController();
      setAbortController(_abortController);

      // setup config data to be sent to api request
      const configData = {
        signal: _abortController.signal,
        pathParams,
        params,
        data
      };

      apiCall(configData)
        .then((response) => {
          const data = _.get(response, ['data']);
          setResults(data);
          setError(undefined);
          setLoading(false);
          onSuccess?.();
        })
        .catch((err) => {
          if (err?.message === 'canceled') {
            return;
          }
          console.error(err);
          setError(err);
          setResults(null);
          setLoading(false);
          onError?.(err);
        })
    } else {
      cancel();
      setResults(null);
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  // cancel functionality
  const cancel = useCallback(() => {
    abortController?.abort?.();
    setLoading(false);
  }, [abortController]);

  // auto trigger refreshResults upon update
  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshResults]);

  return {
    loading,
    results,
    error,
    cancel,
    // Calls to refresh data with slight delay, because services could not keep up with direct calls after saving
    // if this delay need to be larger adjust it in the wrapping hook
    // sync: () => setTimeout(() => refreshResults(), 100)
    sync: useCallback(() => setTimeout(() => refreshResults(), 100), [refreshResults])
  };
}

useBaseReadServiceHook.propTypes = {
  apiCall: PropTypes.func.isRequired,
  pathParams: PropTypes.object,
  params: PropTypes.object,
  data: PropTypes.object,
  error: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

export default useBaseReadServiceHook;
