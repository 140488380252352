import waRequest from './wa-request';

export const apiBase = '/checkin/v2';

/**
 * Initiate authenticated POST ${apiBase}/checkins/searches
 *
 * @returns {Promise}
 */
function getCheckIns({personId, organizationId, checkInReason, checkInReasons}, {page = 0, size = 25, sort}, locale) {
  const url = `${apiBase}/checkins/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      page,
      size,
      sort,
      locale
    },
    data: {
      checkInReasons,
      personId,
      checkInReason
    }
  });
}

function getCheckInsConfigPattern(
  {
    params: {
      organizationId,
      page = 0,
      size = 25,
      sort,
      locale
    },
    data: {
      personId,
      checkInReason,
      checkInReasons,
      dateStart,
      dateEnd
    }
  }
) {

  const url = `${apiBase}/checkins/searches`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      page,
      size,
      sort,
      locale
    },
    data: {
      checkInReasons,
      personId,
      checkInReason,
      dateStart,
      dateEnd
    }
  });


}

export default {
  getCheckIns,
  getCheckInsConfigPattern
};

