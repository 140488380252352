import {LAYERS} from '../components/Common/Mapbox/layers/layerIds';
import {threatzone_detail_layers} from '../constants/threat-zones-filters';

export function getThreatZoneLayerIdFromLocationTier(locationTier) {
  if (locationTier === 'COUNTRY') {
    return LAYERS.THREAT_ZONE_COUNTRY_LAYER_ID;
  }
  else if (locationTier === 'ADMINISTRATIVE_DIVISION') {
    return LAYERS.THREAT_ZONE_PROVINCE_LAYER_ID;
  }
  else if (locationTier === 'CITY') {
    return LAYERS.THREAT_ZONE_CITY_LAYER_ID;
  }
  return  '';
}

export function getThreatZoneDetailLayerIdFromLocationTier(locationTier) {
  if (locationTier === 'COUNTRY') {
    return LAYERS.THREAT_ZONE_COUNTRY_DETAIL_LAYER_ID;
  }
  else if (locationTier === 'ADMINISTRATIVE_DIVISION') {
    return LAYERS.THREAT_ZONE_PROVINCE_DETAIL_LAYER_ID;
  }
  else if (locationTier === 'CITY') {
    return LAYERS.THREAT_ZONE_CITY_DETAIL_LAYER_ID;
  }
  return  '';
}

export function isThreatZoneDetailLayerActive(detailFeature, selectedFeatures) {
  return (
    threatzone_detail_layers.includes(detailFeature?.layer?.id)
    ||
    !!selectedFeatures?.find(feat => threatzone_detail_layers.includes(feat.layer.id))
  );
}

export function getThreatZoneDetailActiveLayerId(detailFeature, selectedFeatures) {
  if (selectedFeatures?.length > 0) {
    const feat = selectedFeatures.find(feat => threatzone_detail_layers.includes(feat.layer.id));
    return feat?.layer?.id;
  } else {
    return detailFeature?.layer?.id;
  }
}