import {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {NavLink, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Col, PageHeader, Row} from 'antd';

import InfoPopoverIcon from '../../Common/InfoPopoverIcon/InfoPopoverIcon';
import {ExtendedRoutingContext} from '../../ExtendedRouting/ExtendedRoutingProvider';
import BasePageHeaderSubtitle from './BasePageHeaderSubtitle/BasePageHeaderSubtitle';

function checkIfShorten(list, currentIndex) {
  let shorten = false;
  for (let i = currentIndex + 1; i < list.length; i++) {
    if (list[i].breadcrumbShortenAncestors === true) {
      shorten = true;
    }
  }
  return shorten;
}

function BasePageHeader(props) {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const extendedRoutingContext = useContext(ExtendedRoutingContext);

  const {
    title,
    subtitle,
    subtitleExtra,
    info,
    heading,
    defaultTitle,
    extra,
    tabs,
    content,
    popoverPlacement,
    maxWidthPopover
  } = props;

  function breadcrumbRenderer(route, _params, routes) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last
      ? <span>{route.name}</span>
      : <NavLink to={route.path}>{route.name}</NavLink>;
  }

  function GetBreadcrumbParams() {
    if (breadcrumbs && breadcrumbs.length > 0) {
      return {
        routes: breadcrumbs.map((item, currentIndex) => {
          const shorten = checkIfShorten(breadcrumbs, currentIndex);
          if (typeof item.breadcrumb === 'object' && item.breadcrumb !== null) {
            return {
              name: shorten && item.shortBreadcrumb ?
                <FormattedMessage {...item.shortBreadcrumb} /> :
                <FormattedMessage {...item.breadcrumb} />,
              path: item.url
            };
          }
          return {
            name: item.breadcrumb,
            path: item.url
          };
        }),
        itemRender: breadcrumbRenderer
      };
    }
    else {
      return heading
        ? {routes: [{name: heading, path: 'empty'}], itemRender: breadcrumbRenderer}
        : undefined;
    }
  }

  useEffect(() => {
    setBreadcrumbs([...extendedRoutingContext]);
  }, [extendedRoutingContext, setBreadcrumbs, location]);

  const hasSubtitle = !!subtitle;
  const hasSubtitleExtra = !!subtitleExtra;

  return (
    <PageHeader
      className={classNames({'has-tabs': !!tabs})}
      title={
        <Row>
          <Col className={classNames({'has-subtitle': hasSubtitle})}>
            {title || defaultTitle}
          </Col>
          {hasSubtitle ? <Col><BasePageHeaderSubtitle subtitle={subtitle}/></Col> : null}
          {hasSubtitleExtra ? <Col>{subtitleExtra}</Col> : null}
        </Row>
      }
      subTitle={<>
        {info && <InfoPopoverIcon maxWidth={maxWidthPopover} content={info} placement={popoverPlacement}/>}
      </>}
      extra={extra}
      breadcrumb={GetBreadcrumbParams()}
      footer={tabs && tabs}
    >
      {content}
    </PageHeader>
  );
}

BasePageHeader.propTypes = {
  title: PropTypes.node,
  info: PropTypes.node,
  subtitle: PropTypes.node,
  subtitleExtra: PropTypes.node,
  heading: PropTypes.node,
  defaultTitle: PropTypes.node,
  extra: PropTypes.node,
  tabs: PropTypes.node,
  content: PropTypes.node,
  popoverPlacement: PropTypes.string,
  maxWidthPopover: PropTypes.string
};

export default BasePageHeader;
