import * as types from './types';

/**
 * Request alsat.getPersonLocationTypes and dispatch to store values
 *
 * @returns {function(*, *, {locations: *}): Promise<T | never>}
 */
export function requestPeopleLocationTypes(locale) {
  return (dispatch, getState, {alsat}) => {

    dispatch({type: types.PEOPLE_LOCATION_TYPES_REQUEST});

    return alsat.getPersonLocationTypes(locale)
      .then(results => dispatch({
        type: types.PEOPLE_LOCATION_TYPES_SUCCESS,
        payload: {
          data: results.data,
          locale
        }
      }))
      .catch(error => dispatch({
        type: types.PEOPLE_LOCATION_TYPES_FAILURE,
        error
      }));
  };
}
