import MapMBTileOrgLayer from '../MapMBTileOrgLayer';

import {BASE_API_URL} from '../../../../../services/api/base-utils';
import {getRatingKey} from '../../../../../utils/rating-filters';
import {RATINGS_COUNTRY_LAYER_ID} from './CountryRatingsLayer';
import {getLevelsFilter, getLocationsFilter, isNotWorldwideLocation} from '../+shared/utils';

import styles from '../../../../../containers/App/colors.module.css';

export const RATINGS_PROVINCE_SOURCE_ID = 'ratings-province-source';
export const RATINGS_PROVINCE_LAYER_ID = 'ratings-province-layer';
export const RATINGS_PROVINCE_LINE_LAYER_ID = 'ratings-province-line-layer';

const MIN_ZOOM = 1;
const MAX_ZOOM = 14;

function addLayer(map, layerDef) {
  const oldLayers = map.getStyle().layers;
  const layerIndex = oldLayers.findIndex(l => l.id === RATINGS_COUNTRY_LAYER_ID);
  const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;

  map.addLayer(layerDef, before);
}

export default class ProvinceRatingsLayer extends MapMBTileOrgLayer {
  ratingId;
  levels;
  mapType;
  locationsFilter;

  getInteractiveLayerIds() {
    return [RATINGS_PROVINCE_LAYER_ID];
  }

  getLayerIds() {
    return [RATINGS_PROVINCE_LAYER_ID, RATINGS_PROVINCE_LINE_LAYER_ID];
  }

  getTilesUrl() {
    return `${BASE_API_URL}/mbtiles/v1/mbtiles/rating/{z}/{x}/{y}?tileType=Boundary&locationTier=Province&organizationId=${this.organizationId}`;
  }

  setTiles() {
    this.map.getSource(RATINGS_PROVINCE_SOURCE_ID).setTiles([this.getTilesUrl()]);
  }

  createSources() {
    if (this.map) {
      this.map.addSource(RATINGS_PROVINCE_SOURCE_ID, {
        'type': 'vector',
        'tiles': [this.getTilesUrl()],
        minzoom: 1,
        maxzoom: 16
      });
    }
  }

  createLayers() {
    if (this.map) {
      const opacityProvince = [
        'step',
        ['zoom'],
        // 1 if zoom is less then MIN_ZOOM
        1, MIN_ZOOM,
        // 1 if zoom is less then 8
        1, 8,
        // 0.7 if zoom is less then MAX_ZOOM
        0.7, MAX_ZOOM,
        // 0 if zoom is MAX_ZOOM (or greater)
        0
      ];

      addLayer(this.map, {
        'id': RATINGS_PROVINCE_LINE_LAYER_ID,
        'source': RATINGS_PROVINCE_SOURCE_ID,
        "source-layer": 'province_boundary_tiles',
        'type': 'line',
        'paint': {
          'line-opacity': opacityProvince
        },
        minzoom: MIN_ZOOM,
        maxzoom: MAX_ZOOM
      });

      addLayer(this.map, {
        'id': RATINGS_PROVINCE_LAYER_ID,
        'source': RATINGS_PROVINCE_SOURCE_ID,
        "source-layer": 'province_boundary_tiles',
        'type': 'fill',
        'paint': {
          'fill-opacity': opacityProvince,
          'fill-outline-color': 'black'
        },
        minzoom: MIN_ZOOM,
        maxzoom: MAX_ZOOM
      });

      this.updateStyling();
    }
  }

  setRatingFilters(ratingId, levels, mapType, locationsFilter) {
    if (this.map && levels && levels.length > 0) {
      this.ratingId = ratingId;
      this.levels = levels;
      this.mapType = mapType;
      this.locationsFilter = locationsFilter;

      this.updateStyling();
    }
  }

  updateStyling() {
    if (this.map && this.organizationId && this.levels && this.levels.length > 0) {

      const currentRatingProperty = ['get', getRatingKey(this.ratingId)];

      const levelsFilter = getLevelsFilter(currentRatingProperty, this.levels);

      const filterNullRatings = [
        'any',
        ['!=', currentRatingProperty, null]
      ];

      const filters = [
        levelsFilter,
        filterNullRatings
      ];

      if (this.locationsFilter?.length > 0 && isNotWorldwideLocation(this.locationsFilter)) {
        filters.push(getLocationsFilter(this.locationsFilter));
      }

      // filters out items that have a rating that is outside the levels wanted
      this.map.setFilter(RATINGS_PROVINCE_LAYER_ID, ['all', ...filters]);

      this.map.setFilter(RATINGS_PROVINCE_LINE_LAYER_ID, ['all', ...filters]);

      this.map.setPaintProperty(RATINGS_PROVINCE_LAYER_ID,
        'fill-color', [
          'step',
          ['to-number', currentRatingProperty, 0],
          // ratingUnrated if less then 1
          styles.transparent, 1,
          // ratingMinimal if less then 1.5
          styles.ratingMinimal, 1.5,
          // ratingLow if less then 2.5
          styles.ratingLow, 2.5,
          // ratingModerate if less then 3.5
          styles.ratingModerate, 3.5,
          // ratingHigh if less then 4.5
          styles.ratingHigh, 4.5,
          // ratingExtreme if more than 4.5
          styles.ratingExtreme
        ]);
    }
  }
}
