import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {defineMessages, FormattedMessage} from 'react-intl';

import {Col, Row, Skeleton, Alert, Card, Typography, Button} from 'antd';

import useResponseOptions from '../../hooks/services/communicator/useResponseOptions';

import HeaderBar from '../App/HeaderBar/HeaderBar';
import BasePage from '../App/BasePage/BasePage';
import BasePageHeader from '../App/BasePage/BasePageHeader';
import SurveyResponseForm from './SurveyResponseForm';
import SurveyResponseCard from './SurveyResponseCard/SurveyResponseCard';
import LayoutSpace from '../Common/LayoutSpace/LayoutSpace';

import './SurveyResponsePage.css'

const messages = defineMessages({
  pageHeading: {
    id: 'SurveyResponsePage.pageHeading',
    defaultMessage: 'Communication'
  },
  pageTitle: {
    id: 'SurveyResponsePage.pageTitle',
    defaultMessage: 'Survey Response'
  },
  noSurveys: {
    id: 'SurveyResponsePage.noSurveys',
    defaultMessage: 'There are no response options available for this message.'
  },
  statusConfirmation: {
    id: 'SurveyResponsePage.statusConfirmation',
    defaultMessage: 'Status Confirmation'
  },
  responsesSentTitle: {
    id: 'SurveyResponsePage.responsesSentTitle',
    defaultMessage: 'Survey responses sent.'
  },
  responsesSentDescription: {
    id: 'SurveyResponsePage.responsesSentDescription',
    defaultMessage: 'Thank you for submitting your responses.'
  },
  noResponseProvided: {
    id: 'SurveyResponsePage.noResponseProvided',
    defaultMessage: 'No response provided'
  },
  clickHere: {
    id: 'SurveyResponsePage.clickHere',
    defaultMessage: 'Click here'
  },
  updateResponse: {
    id: 'SurveyResponsePage.updateResponse',
    defaultMessage: '{link} to change or update your responses.'
  }
});

function createCardsFromCombinedResponseWithTransformedData(response, transformedData) {
  const {statusConfirmationResponseOptions, surveyQuestions} = response;
  const {statusConfirmationResponse} = transformedData;

  const statusConfirmationResponseCard = statusConfirmationResponseOptions?.length ?
    {
      key: 'statusConfirmationResponse',
      title: <FormattedMessage {...messages.statusConfirmation} />,
      responseOptionText: statusConfirmationResponseOptions.find(option => option.number === statusConfirmationResponse?.confirmedStatus)?.text,
      comments: statusConfirmationResponse?.comments
    } : 
    null

  const surveyResponseCards = surveyQuestions ?
    surveyQuestions.map((question, index) => {
      const surveyResponse = transformedData?.surveyResponses?.find(response => response.questionNumber === question.number);
      const responseOption = question?.responseOptions?.find(option => option.number === surveyResponse?.responseOptionNumber)
      const responseOptionText = (!responseOption && !surveyResponse?.comments) ?
      // show "no response" if no response option and no comments
        (
          <span className='no-response-provided'>
            <Typography.Text italic>
              <FormattedMessage {...messages.noResponseProvided} />
            </Typography.Text>
          </span>
        ) :
        responseOption?.text;
    
      return {
        key: `${question.number}-${index}`,
        title: question?.text,
        responseOptionText: responseOptionText,
        comments: surveyResponse?.comments
      };
    }) :
    [];

  return [statusConfirmationResponseCard, ...surveyResponseCards];  
}

export default function SurveyResponsePage () {
  const {params} = useRouteMatch();
  const {deliveryInstanceId}  = params;
  
  const [cardData, setCardData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [previousFormData, setPreviousFormData] = useState({});
  const {results, loading} = useResponseOptions(deliveryInstanceId);

  const hasStatusConfirmationOptions = _.size(results?.statusConfirmationResponseOptions) > 0;
  const hasSurveys = _.size(results?.surveyQuestions) > 0;
  
  useEffect(() => {
    if (formSubmitted && transformedData) {
      const res = createCardsFromCombinedResponseWithTransformedData(results, transformedData);
      setCardData(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitted, transformedData]);
  
  function handleFormSubmitted(modifiedVals, formVals) {
    setTransformedData(modifiedVals);
    setFormSubmitted(true);
    setPreviousFormData(formVals);
  }

  function handleUpdateResponses() {
    setFormSubmitted(false);
  }

  const updateResponsesLink = (
    <div>
      <p className='update-response-link'>  
        <FormattedMessage
          {...messages.updateResponse}
          values={{link:
            <Button
              type="link"
              onClick={handleUpdateResponses}
            >
              <FormattedMessage {...messages.clickHere} />
            </Button>
          }}
        />
      </p>
    </div>
  );

  function renderResponseReadOnlyCards() {
    return cardData.map(card => card && (
      <SurveyResponseCard
        title={card?.title}
        responseOptionText={card?.responseOptionText}
        comments={card?.comments}
        key={card.key}
      />
    ));
  }

  const header = (
    <BasePageHeader heading={<FormattedMessage {...messages.pageHeading} />} title={<FormattedMessage {...messages.pageTitle} />} />
  );

  const confirmationAlert = (
    <Alert
      message={<FormattedMessage {...messages.responsesSentTitle} />}
      description={<FormattedMessage {...messages.responsesSentDescription} />}
      type="success"
      showIcon={true}
      closable={true}
    />
  );

  function renderBody() {
    if (formSubmitted) {
      return (
        <LayoutSpace>
          {confirmationAlert}
          {updateResponsesLink}
          {renderResponseReadOnlyCards()}
        </LayoutSpace>
      );
    } else if (hasSurveys || hasStatusConfirmationOptions) {
      return (
        <SurveyResponseForm
          results={results}
          handleFormSubmitted={handleFormSubmitted}
          deliveryInstanceId={deliveryInstanceId}
          previousFormData={previousFormData}
        />
      );
    } else {
      return (
        <Card>
          <FormattedMessage {...messages.noSurveys} />
        </Card>
      );
    }
  }
  

  return (
    <div className='SurveyResponsePage'>
      <HeaderBar />
      <BasePage header={header} pageTitleIntl={messages.pageTitle}>
        <Skeleton loading={loading}>
          <Row gutter={16}>
            <Col sm={24} md={18} lg={12}>
              {renderBody()}
            </Col>
          </Row>
        </Skeleton>
      </BasePage>
    </div>
  );  
}

