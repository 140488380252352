const MAP_URL = /https:\/\/maps\.(.*)\.com/i;
const LOCAL_APP_URL = 'http://localhost:3000/$1';

/**
 * When running locally we get cors issues for calls to the maps URL endpoints. We utilize this function to
 * convert the URL from the external endpoint to be against the localized http://localhost:3000/ endpoint.
 * This end point will be later be proxied to circumvent CORS, within local dev environment.
 *
 * https://maps.worldaware.com/ETC will be converted to http://localhost:3000/worldaware/ETC
 * and again the project proxy which is used within during local development will be able to
 * retrieve this information without CORS issues.
 *
 * @param geoJsonUrl
 * @returns {*}
 */
export default (geoJsonUrl) =>  {
  return process.env.REACT_APP_ENVIRONMENT.includes('LOCAL')
    ? geoJsonUrl.replace(MAP_URL, LOCAL_APP_URL)
    : geoJsonUrl;
}
