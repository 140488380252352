import {Component} from 'react';

import analyticsService from '../../services/analytics';
import scrollingService from '../../services/scrolling';
import authenticationService from '../../services/api/authentication';

import progressBar from '../../components/App/ProgressBar/ProgressBar';

import ContextProvider from '../../components/App/ContextProvider/ContextProvider';
import {ExtendedRoutingProvider} from '../../components/ExtendedRouting/ExtendedRoutingProvider';
import PublicRouter from '../../components/App/PublicRouter/PublicRouter';

import 'antd/dist/antd.css';
import './colors.module.css';
import './fonts.module.css';
import './spacing.css';
import './responsive.css';
import './global.css';
import './metaData.css';
import './role-style.css';

export default class App extends Component {

  constructor(props) {
    super(props);
    progressBar.initialize();
    analyticsService.initialize();
    scrollingService.listenForPageChanges();
    authenticationService.initialize();
  }

  render() {
    return (
      <ContextProvider>
        <ExtendedRoutingProvider>
          <PublicRouter/>
        </ExtendedRoutingProvider>
      </ContextProvider>
    );
  }
}
