import {lazy} from 'react';

const SESSION_STORAGE_KEY = 'import-error-refreshed';

export default function lazyWithRetry(componentImport) {
  return lazy(() => {
    const hasImportErrorAlready = JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEY) || 'false');

    return componentImport()
      .then(results => {
        if (hasImportErrorAlready) {
          // after success clear error
          window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
        }
        return results;
      })
      .catch(error => {
        if (!hasImportErrorAlready) {
          // Assume page just needs to refresh for correct chunk to be retrieved
          window.sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
          return window.location.reload();
        }

        // The page has already been reloaded, allow for app to crash out
        throw error;
      });
  });
}
