export function toggleAccessibeDisplay(hideAccessibe) {
  const shadowHost = document.querySelectorAll('.notranslate');
  for (let i = 0; i < shadowHost.length; i++) {
    const shadowRoot = shadowHost[i]?.shadowRoot;
    if (shadowRoot) {
      const accessibe = shadowRoot.querySelector('.position-x-right');
      if (accessibe) {
        if (hideAccessibe) {
          accessibe.style.display = 'none';
        } else {
          accessibe.style.display = 'block';
        }
      }
    }
  }
}