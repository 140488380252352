import _ from 'lodash';

/**
 * Returns an array of all the nodes in the tree flattened out.
 * Can accept a single tree object or a collection
 *
 * @param tree
 * @returns {*}
 */
export function reduceTree(tree) {

  const recurse = (reducer, node) => {
    if (node) {
      reducer.push(node);

      if (node.children && node.children.length) {
        _.reduce(node.children, recurse, reducer);
      }
    }

    return reducer;
  };

  return _.reduce(_.castArray(tree), recurse, []);
}