import _ from 'lodash';

import {
  VIEW_CITY_RATINGS,
  VIEW_COUNTRY_RATINGS,
  VIEW_PROVINCE_RATINGS
} from '../../../../constants/permissions';
import {
  MAP_RATINGS_ORGS,
  LOCATION_TIERS_ID,
  MAP_RATINGS_CATEGORY,
  WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE
} from '../../../../constants/rating-filters';
import {ROOT_RATINGS_CATEGORY_IDS} from '../../../../constants/categories';
import {SORT_DIRECTION} from '../../../../constants/sorting';
import {getRatingKey} from '../../../../utils/rating-filters';
import {getCategoryName} from '../../categories/utils';
import {LAYERS} from '../../../../components/Common/Mapbox/layers/layerIds';

const ROOT_RATINGS_CATEGORY_VALUES = Object.values(ROOT_RATINGS_CATEGORY_IDS);

export const RATING_TYPES_VALUES = {
  COUNTRIES: 'COUNTRIES',
  PROVINCES: 'PROVINCES',
  CITIES: 'CITIES'
};

export const RATING_TYPES_MAPPING = [
  {
    id: 'countries',
    permissions: [VIEW_COUNTRY_RATINGS],
    value: RATING_TYPES_VALUES.COUNTRIES
  },
  {
    id: 'provinces',
    permissions: [VIEW_PROVINCE_RATINGS],
    value: RATING_TYPES_VALUES.PROVINCES
  },
  {
    id: 'cities',
    permissions: [VIEW_CITY_RATINGS],
    value: RATING_TYPES_VALUES.CITIES
  }
];

export function getRatingCurrentSelectedOrgPermissions(state) {
  const ratingOrg = _.get(state, ['worldMap', WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS_ORGS]);
  const fullPermissions = _.get(state, ['session', 'fullPermissions'], {});
  const permissions = fullPermissions[ratingOrg]?.permissions;
  return _.filter(_.keys(permissions), key => permissions[key] === true);
}

/**
 * From getState check the permissionIds and if it includes VIEW_PROVINCE_RATINGS
 * @param getState
 * @returns {boolean}
 */
export function checkForProvinceRatingPermission(getState) {
  const permissionIds = getRatingCurrentSelectedOrgPermissions(getState());
  return permissionIds.includes(VIEW_PROVINCE_RATINGS);
}

export function formRatingItemsIntoFeatures(ratingItems = [], store) {
  const currentCategoryId = _.get(store,
    ['worldMap', WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS_CATEGORY]);
  const ratingsDict = _.get(store, 'categories.dict', {});
  const ratingKey = getRatingKey(currentCategoryId);

  const features = ratingItems
    // this filter is needed only to be able to work fine in DEV/QA env
    // because some data maybe added to test and this data sometimes doesn't have geoJson
    .filter((ratingItem) => {
      return ratingItem?.geoJson;
    })
    .map((ratingItem) => {
      const feature = {
        'type': 'Feature',
        'properties': formRatingItemIntoFeatureProperties(ratingItem),
        'geometry': JSON.parse(ratingItem?.geoJson)
      };

      feature.properties.currentCategoryRating = _.get(feature, ['properties', ratingKey]);
      feature.properties.currentCategoryName = getCategoryName(ratingsDict, currentCategoryId);

      return feature;
    });

  return {'type': 'FeatureCollection', 'features': features};
}

export function formRatingItemIntoFeatureProperties(ratingsItem, locale) {
  const localeKey = locale ? `_${locale}` : '';
  const allRatings = ratingsItem.ratings.reduce((accumulator, categoryRating) => {
    accumulator[getRatingKey(categoryRating.categoryId)] = categoryRating.rating ? categoryRating.rating : 0;
    return accumulator;
  }, {});
  return {
    [`locationName${localeKey}`]: ratingsItem.location?.name,
    [`locationTier${localeKey}`]: ratingsItem.locationTier?.name,
    [`parentLocationName${localeKey}`]: ratingsItem.parentLocation?.name,
    countryId: ratingsItem.country?.id,
    locationId: ratingsItem.location?.id,
    ...allRatings
  };
}

/**
 * Take feature object properties parse out the rating properties startsWith("rating_")
 * and parses those key via removing "rating_" then maps their values to rating objects
 *
 * {
 * rating: value,
 * categoryId: key,
 * name: getCategoryName(ratingsCategoriesDict, key)
 * }
 *
 * @param feature
 * @param ratingsCategoriesDict
 * @returns {*}
 */
export function formRatingObjectFromFeatureRatings(feature, ratingsCategoriesDict) {
  const ranks =
    _.chain(feature)
      .get('properties', {})
      .pickBy((value, key) => key.startsWith('rank_'))
      .value();
  return _.chain(feature)
    .get('properties', {})
    // pick ratings items and remove 'rating_' from keys
    .pickBy((value, key) => key.startsWith('rating_'))
    .mapKeys((value, key) => key.replace('rating_', ''))
    // map value and keys to expected ratings object
    .mapValues((value, categoryId) => {
      return {
        rank: ranks['rank_' + categoryId],
        rating: value,
        category: {
          id: categoryId,
          name: getCategoryName(ratingsCategoriesDict, categoryId)
        },
        parent: ROOT_RATINGS_CATEGORY_VALUES.includes(categoryId)
      };
    })
    .values()
    .value();
}

export function parseRatingsPolygonFeatureResult(response, store, locale) {
  const ratingsData = response?.data?.content || [];
  const currentCategoryId = _.get(store, ['worldMap', WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE, MAP_RATINGS_CATEGORY]);
  const ratingsDict = _.get(store, 'categories.dict', {});

  const ratingLevels = _.get(store, ['worldMap', 'ratingsFilter', 'mapRatingsLevels']);

  const resCountryRatings = ratingsData
    .filter(feature => {
      const currentRatingCategory = feature?.ratings?.find(ratingItem => ratingItem.categoryId === currentCategoryId);
      const currentCategoryRating =  currentRatingCategory?.rating || 0;
      return currentCategoryRating >= ratingLevels[0] && currentCategoryRating <= ratingLevels[1];
    })
    .map(feature => {
      const locationTierId = feature?.locationTier?.id;
      let layerId;
      if (locationTierId === LOCATION_TIERS_ID.COUNTRY) {
        layerId = LAYERS.RATINGS_COUNTRY_LAYER_ID;
      }
      else if (locationTierId === LOCATION_TIERS_ID.PROVINCE) {
        layerId = LAYERS.RATINGS_PROVINCE_LAYER_ID;
      }
      else {
        layerId = LAYERS.RATINGS_CITY_LAYER_BOUNDARY_ID;
      }

      const currentRatingCategory = feature.ratings.find(ratingItem => ratingItem.categoryId === currentCategoryId);
      const properties = formRatingItemIntoFeatureProperties(feature, locale);

      return {
        currentCategoryId,
        properties,
        layer: {id: layerId},
        currentCategoryRating: currentRatingCategory?.rating || 0,
        currentCategoryName: getCategoryName(ratingsDict, currentCategoryId),
        detailsRatings: formRatingObjectFromFeatureRatings({properties}, ratingsDict)
      };

    });

  return _.orderBy(resCountryRatings, ['locationTier.id', 'currentCategoryRating', 'location.name'],
    [SORT_DIRECTION.ASC, SORT_DIRECTION.DESC, SORT_DIRECTION.ASC]);
}

export function filterRatingTypesViaPermissions(hasPermission = {}, ratingTypes) {
  return RATING_TYPES_MAPPING.reduce((accumulator, item) => {
    const acceptedPermissions = item.permissions
      .filter((permission) => {
        return hasPermission[permission];
      });
    if (ratingTypes?.includes?.(item.value) && acceptedPermissions.length === item.permissions.length) {
      accumulator.push(item.value);
    }
    return accumulator;
  }, []);
}

/**
 * Returns true if RATINGS_PROVINCE_LAYER_ID matches the feature layer.id
 *
 * @param feature
 * @returns {*}
 */
export function isProvinceRatingFeature(feature) {
  const layerId = _.get(feature, 'layer.id');
  return layerId === LAYERS.RATINGS_PROVINCE_LAYER_ID;
}

/**
 * Returns true if RATINGS_COUNTRY_LAYER_ID or RATINGS_PROVINCE_LAYER_ID matches the feature layer.id
 *
 * @param feature
 * @returns {*}
 */
export function isRatingFeature(feature) {
  const layerId = _.get(feature, 'layer.id');
  return layerId === LAYERS.RATINGS_COUNTRY_LAYER_ID ||
    layerId === LAYERS.RATINGS_PROVINCE_LAYER_ID ||
    layerId === LAYERS.RATINGS_CITY_LAYER_BOUNDARY_ID ||
    layerId === LAYERS.RATINGS_CITY_LAYER_POINT_ID;
}
