import * as types from './types';
import _ from 'lodash';

import {parseApiResult} from './utils';
import {groupResourcesLinksByType} from '../organization-configurations/utils';

/**
 * Dispatches a requestLocations action only if the current store
 * locations.list is empty.
 *
 * @returns {Function}
 */
export const requestLocationsIfNecessary = () => {
  return (dispatch, getState) => {

    const currentLocations = _.get(getState(), 'locations.list');
    const needToRequest = _.isEmpty(currentLocations);

    if (needToRequest) {
      return dispatch(requestLocations());
    }
  };
};

/**
 * Request locations.getLocations and parse the results into
 * tree, dict, list, and cclist (cityCountry) store values
 *
 * @returns {function(*, *, {locations: *}): Promise<T | never>}
 */
export const requestLocations = () => {
  return (dispatch, getState, {geoLocations}) => {
    const locale = _.get(getState(), 'localization.locale', '');

    dispatch({type: types.LOCATIONS_REQUEST});

    return geoLocations.getLocations(locale)
      .then(results => dispatch({
        type: types.LOCATIONS_SUCCESS,
        payload: {
          ...parseApiResult(results),
          locale
        }
      }))
      .catch(error => dispatch({
        type: types.LOCATIONS_ERROR,
        error
      }));
  };
};

const syncLocations = (force = false) => {
  return (dispatch, getState, {geoLocations}) => {
    const locale = getState()?.localization?.locale;

    if (force || getState()?.locations?.locale !== locale || _.isEmpty(getState()?.locations?.dict)) {
      dispatch({type: types.LOCATIONS_REQUEST});

      return geoLocations.getLocations(locale)
        .then(results => dispatch({
          type: types.LOCATIONS_SUCCESS,
          payload: {
            ...parseApiResult(results),
            locale
          }
        }))
        .catch(error => dispatch({
          type: types.LOCATIONS_ERROR,
          error
        }));
    }
  };
};

const _throttledSyncLocations = _.throttle((dispatch, force) => dispatch(syncLocations(force)), 10000);

export const getLocations = (force) => {
  return (dispatch) => {
    return _throttledSyncLocations(dispatch, force);
  };
};

/**
 *
 * @returns {Function}
 */
export const getLocationResources = (orgId, locationId) => {
  return (dispatch, _getState, {resources}) => {
    dispatch({type: types.GET_LOCATION_RESOURCES_REQUEST});

    return resources.getLocationResources(orgId, locationId)
      .then((result) => {
        const groupedLinks = groupResourcesLinksByType(result);
        return dispatch({
          type: types.GET_LOCATION_RESOURCES_SUCCESS,
          payload: {
            data: groupedLinks
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_LOCATION_RESOURCES_FAILURE});
      });
  };
};
