import {applyMiddleware, compose, createStore} from 'redux';
import {responsiveStoreEnhancer} from 'redux-responsive';
import {routerMiddleware} from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';

import history from '../services/history';
import api from '../services/api';
import rootReducer from './reducer';

const enhancers = [
  responsiveStoreEnhancer
];

const middleware = [
  thunkMiddleware.withExtraArgument(api),
  routerMiddleware(history)
];

// Add devtools and redux logging in development
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension({
      serialize: {
        options: {
          undefined: true,
          function: function(fn) { return fn.toString() }
        }
      }
    }));
  }

  const {createLogger} = require(`redux-logger`);
  middleware.push(createLogger({collapsed: true}));
}

let store;
// Return a function for better control during tests
const configureStore = initialState => {
  store = createStore(
    rootReducer(),
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    if (!store.asyncReducers[key]) {
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(rootReducer(store.asyncReducers));
    }
  };

  return store;
};

export default configureStore;

export function injectReducer(key, asyncReducer) {
  store.injectReducer(key, asyncReducer);
}
