import waHttp from './wa-http';

export const baseApi = '/branding/v1';

/**
 * Initiate UN-authenticated GET ${baseApi}/branding/${clientUrlString}/logo
 *
 * @param clientUrlString
 * @returns {Promise<*>}
 */
function getBrandingLogo(clientUrlString) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/branding/${clientUrlString}/logo`;

  return waHttp.httpRequest({
    baseURL,
    url: url
  });
}

/**
 * Initiate UN-authenticated 
 *  GET ${baseApi}/branding/${clientUrlString}/logo
 *
 * @param clientUrlString
 * @param signal
 * @returns {Promise}
 */
function getBrandingLogoConfigPattern({pathParams: {clientUrlString}, signal}) {  
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/branding/${clientUrlString}/logo`;

  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'get',
  });
}

/**
 * Initiate UN-authenticated GET ${baseApi}/branding/${clientUrlString}/custom-contacts
 *
 * @param clientUrlString
 * @returns {Promise<*>}
 */
function getBrandingCustomContacts(clientUrlString) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/branding/${clientUrlString}/custom-contacts`;

  return waHttp.httpRequest({
    baseURL,
    url: url
  });
}

/**
 * Initiate UN-authenticated 
 *  GET ${baseApi}/branding/${clientUrlString}/custom-contacts
 *
 * @param clientUrlString
 * @param signal
 * @returns {Promise}
 */
function getBrandingCustomContactsConfigPattern({pathParams: {clientUrlString}, signal}) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/branding/${clientUrlString}/custom-contacts`;

  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'get',
  });
}

/**
 * Initiate UN-authenticated GET ${baseApi}/branding/${clientUrlString}/custom-login
 *
 * @param clientUrlString
 * @returns {Promise<*>}
 */
function getBrandingCustomLogin(clientUrlString) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/branding/${clientUrlString}/custom-login`;

  return waHttp.httpRequest({
    baseURL,
    url: url
  });
}

/**
 * Initiate UN-authenticated 
 *  GET ${baseApi}/branding/${clientUrlString}/custom-login
 *
 * @param clientUrlString
 * @param signal
 * @returns {Promise}
 */
function getBrandingCustomLoginConfigPattern({pathParams: {clientUrlString}, signal}) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/branding/${clientUrlString}/custom-login`;
  
  return waHttp.httpRequest({
    baseURL,
    signal,
    url: url,
    method: 'get',
  });
}

export default {
  getBrandingLogo,
  getBrandingCustomLogin,
  getBrandingCustomContacts,
  
  // config pattern
  getBrandingLogoConfigPattern,
  getBrandingCustomContactsConfigPattern,
  getBrandingCustomLoginConfigPattern
};
