import {getHorizonStoragePermitted} from './usercentrics';

/**
 * A `localStorage` wrapper which only executes methods if the user has consented to our local storage in usercentrics
 */
export const horizonLocalStorage = {
  getItem: function(keyName) {
    if (getHorizonStoragePermitted()) {
      return localStorage.getItem(keyName);
    }
    else {
      //For backwards compatibility, localStorage.getItem() should return null (instead of undefined) in cases where the localStorage is not accessible.
      return null;
    }
  },
  setItem: function(keyName, keyValue) {
    if (getHorizonStoragePermitted()) {
      localStorage.setItem(keyName, keyValue);
    }
  },
  removeItem: function(keyName) {
    if (getHorizonStoragePermitted()) {
      localStorage.removeItem(keyName);
    }
  },
  clear: function() {
    if (getHorizonStoragePermitted()) {
      localStorage.clear();
    }
  }
};
