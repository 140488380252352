import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useMemo} from 'react';

import {Input} from 'antd';

import validators from './FormItemValidators';
import {FS_EXCLUDE} from '../FsExcludeWrapper/FsExcludeWrapper';

import BaseFormItem from '../BaseFormItem/BaseFormItem';

import './TextAreaInput.css';

function TextAreaInput(props) {
  const {
    className,
    bottomOverlay,
    minLength = 0,
    maxLength = 1000,
    rules = [],
    whitespace = true,
    lengthValidationTrigger = ['onChange'],
    rows = 4,
    autoSize,
    fsExclude,
    disabled,
    placeholder,
    maxLengthCustomValidationMessage,
    showCount
  } = props;

  const textAreaProps = {
    className: classNames({[FS_EXCLUDE]: fsExclude}, {'text-area-with-overlay': bottomOverlay}),
    placeholder,
    disabled,
    rows,
    autoSize,
    showCount,
    maxLength: showCount ? maxLength : undefined
  };

  const fullRules = useMemo(() => {
    const _fullRules = [...rules, validators.minLength(minLength, lengthValidationTrigger)];

    // if max length property exist add to rules list it
    maxLength > 0 && _fullRules.push(validators.maxLength(maxLength, lengthValidationTrigger, maxLengthCustomValidationMessage));

    // if whitespace add to rules
    whitespace && _fullRules.push(validators.whiteSpace());

    return _fullRules;
  }, [whitespace, maxLength, rules, minLength, lengthValidationTrigger, maxLengthCustomValidationMessage]);

  return (
    <>
      <BaseFormItem
        {...props}
        className={classNames('TextAreaInput', className)}
        rules={fullRules}
      >
        <Input.TextArea  {...textAreaProps} />
      </BaseFormItem>
      {bottomOverlay && <div style={{position: 'relative', top: '-3.5em', left: '-1.2em'}}>{bottomOverlay}</div>}
    </>
  );
}

TextAreaInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  label: PropTypes.node,
  info: PropTypes.node,
  placeholder: PropTypes.node,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  bottomOverlay: PropTypes.node,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  lengthValidationTrigger: PropTypes.array,
  rules: PropTypes.array,
  whitespace: PropTypes.bool,
  fsExclude: PropTypes.bool,
  rows: PropTypes.number,
  autoSize: PropTypes.object,
  extra: PropTypes.node,
  maxLengthCustomValidationMessage: PropTypes.object,
  showCount: PropTypes.bool
};

export default TextAreaInput;
