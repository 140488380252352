export const USERCENTRICS_SETTINGS_KEY = 'uc_settings';

//These are the template IDs from the usercentrics admin interface for each DPS
export const USERCENTRICS_DPS = {
  HORIZON_STORAGE: {id: 'zr9ybZmpOdzgxq', name: 'Crisis24 Horizon Functional Storage'},
  DATADOG: {id: 'P7XVa18qr', name: 'Datadog'},
  ZENDESK: {id: '2Yb3MFSPtdsKWE', name: 'Zendesk Widget'},
};

//The names of all the services which require refreshing the page when their consent has changed
export const SERVICES_REQUIRING_REFRESH = [USERCENTRICS_DPS.DATADOG.name, USERCENTRICS_DPS.ZENDESK.name];
