import _ from 'lodash';

/**
 * Base class for MapboxMap layers and sources
 */
export default class MapLayer {

  constructor() {
    this.layers = [];
    this.visibleLayers = new Set();

    this.setVisibility(true);
  }

  /**
   * Should be called after mapbox style.load event.
   *
   * This function sets the this.map reference and calls
   * createSource, createLayers, and initializeVisibility
   *
   * @param map
   */
  onAdd(map) {
    this.map = map;

    this.createSources();
    this.loadImages();
    this.createLayers();
    this.initializeLayers();
  }

  /**
   * Should be called to clean up the map reference if being removed
   */
  onRemove() {
    this.map = null;
  }

  /**
   * Resets the visibility of each layer based on the internal
   * visibleLayers set. Useful for when a MapLayer that has state is
   * re-added to a map when its style changes.
   */
  initializeLayers() {
    _.forEach(this.getLayerIds(), id => {
      this.setLayerVisibility(id, this.visibleLayers.has(id));
    });

    _.forEach(this.getInteractiveLayerIds(), id => {
      this.setPointerMouseEventHandlers(id);
    });
  }

  /**
   * Sets mouseenter and mouseleave event handlers to change the cursor
   * style to a pointer when over interactive layers
   *
   * @param layerId
   */
  setPointerMouseEventHandlers(layerId) {
    if (this.map) {
      this.map.on('mouseenter', layerId, () => {
        if (this.map.getCanvas().style.cursor !== 'zoom-in') {
          this.map.getCanvas().style.cursor = 'pointer';
        }
      });

      this.map.on('mouseleave', layerId, () => {
        if (this.map.getCanvas().style.cursor === 'pointer') {
          this.map.getCanvas().style.cursor = '';
        }
      });
    }
  }

  /**
   * Set the visibility of all layers at once
   *
   * @param isVisible
   */
  setVisibility(isVisible) {
    if (isVisible) {
      _.forEach(this.getLayerIds(), id => this.visibleLayers.add(id));
    }
    else {
      this.visibleLayers.clear();
    }

    this.initializeLayers();
  }

  /**
   * Returns the id property of the layer 2 indexes past background.
   * Typically raster layers (like satellite) are background + 1, so this
   * puts us at a reliable layer above the background.
   *
   * @returns {*}
   */
  getBaseLayerId() {
    if (this.map) {
      const layers = this.map.getStyle().layers;
      const backgroundIndex = _.findIndex(layers, d => d.id === 'background');

      return _.get(layers, [backgroundIndex + 2, 'id']);
    }
  }

  /**
   * Returns true if the map reference exists and map.getLayer returns truthy
   *
   * @param layerId
   * @returns {*|?Object}
   */
  doesLayerExist(layerId) {
    return !!this.map && !!this.map.getLayer(layerId);
  }

  /**
   * Returns true if this layer is currently visible in the map style
   *
   * @param layerId
   * @returns {boolean}
   */
  isMapLayerVisible(layerId) {
    if (this.doesLayerExist(layerId)) {
      return this.map.getLayoutProperty(layerId, 'visibility') !== 'none';
    }

    return false;
  }

  /**
   * Sets the layer visible in the internal visibleLayers set as
   * well as setting the layout property in the map for this layer
   *
   * @param layerId
   * @param isVisible
   */
  setLayerVisibility(layerId, isVisible) {
    // set to visibleLayers
    if (isVisible) {
      this.visibleLayers.add(layerId);
    }
    else {
      this.visibleLayers.delete(layerId);
    }

    const visibilityChanged = this.isMapLayerVisible(layerId) !== isVisible;
    const layerExists = this.doesLayerExist(layerId);

    // set map layer visibility
    if (layerExists && visibilityChanged) {
      this.map.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none');
    }
  }

  /**
   *
   * Returns an array of layerIds that this class supports click interaction.
   * Defaults to getLayerIds if not overridden
   *
   * @returns {string[]}
   */
  getInteractiveLayerIds() {
    return this.getLayerIds();
  }

  /**
   * Check to see if map has image. If image is not present returns a created
   * promise for loading image in to map and adding it to map
   *
   * @param imageId
   * @param imageUrl
   * @returns {Promise<*|Promise<any>|Promise|Promise|Promise|undefined>}
   */
  async loadImage(imageId, imageUrl) {
    const map = this.map;
    if (map && !map.hasImage(imageId)) {
      return new Promise((resolve, reject) => {
        map.loadImage(imageUrl, function(error, image) {
          if (error) {
            reject(error);
          }
          else {
            map.addImage(imageId, image);
            resolve();
          }
        });
      });
    }
  }

  /**
   * This method must be overridden for MapLayer to function appropriately.
   * Should return an array of all layerIds that this class supports
   *
   * @returns {string[]}
   */
  getLayerIds() {
    // empty method for class extension
  }

  /**
   * Should be overridden if additional layers is required to be created
   * and should add layers via this.map.addLayer({id: LAYER_ID... where
   * LAYER_ID is one of the values returned by getLayerIds
   *
   * It is possible to use this class without overriding this method,
   * for use cases where the layer exists in the style and you just want
   * a MapLayer class to manipulate that existing layer.
   */
  createLayers() {
    // empty method for class extension
  }

  /**
   * Should be overridden if a source is required for your layers,
   * and should add a source to this.map.addSource.
   *
   * If you need to update the source after, customize your extension
   * with a updateData type method.
   */
  createSources() {
    // empty method for class extension
  }

  /**
   * Should be overridden if a images are required to be loaded for
   * your layers.
   *
   * this.loadImage(imageId, imageUrl) should be utilized to load images to map.
   *
   */
  loadImages() {
    // empty method for class extension
  }
}
