import waRequest from './wa-request';
import waHttp from './wa-http';

export const baseApi = '/mass-notification/v1';

function createDraftMessage({organizationId, filter, personId, alertId, sitesPersonFilter, personGroupPersonFilter}) {
  const url = `${baseApi}/messages/draft`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      organizationId,
      initiationContext: {
        personFilter: filter,
        sitesPersonFilter,
        personId,
        alertId,
        personGroupPersonFilter,
      }
    }
  });

}

function updateMessage({params: {formats, conferenceBridge, statusConfirmationOptions, survey}}) {
  const url = `${baseApi}/messages/draft`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data: {
      messageConfig: {
        formats,
        conferenceBridge,
        statusConfirmationOptions,
        survey
      }
    }
  });
}

function updateRecipientStatus(messageId, recipientId, confirmedStatus, comments, params) {
  const url = `${baseApi}/messages/${messageId}/recipients/${recipientId}/status`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    params,
    data: {
      confirmedStatus,
      comments,
    }
  });
}

function getRecipientDetails({pathParams: {messageId, recipientId}, params}) {
  const url = `${baseApi}/messages/${messageId}/recipients/${recipientId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params
  });
}

function deactivateMessage(messageId) {
  const url = `${baseApi}/messages/${messageId}/deactivate`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
  });
}

function sendMessage({data}) {
  const url = `${baseApi}/messages/draft/send`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data
  });
}

function addRecipient(personId) {
  const url = `${baseApi}/messages/draft/recipients`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      personId
    }
  });
}

function deleteRecipient(personId) {
  const url = `${baseApi}/messages/draft/recipients/${personId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function getMessage(messageId) {
  const url = `${baseApi}/messages/${messageId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getMessageDetails({pathParams: {messageId}}) {
  return getMessage(messageId);
}

function getMessageSummary({pathParams: {messageId}, params}) {
  const url = `${baseApi}/messages/${messageId}/summary`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params
  });
}

/**
 * @deprecated please use getCrisis24StandardMessageTemplates instead
 * @param locale
 * @returns {Promise}
 */
function getMessageTemplates(locale = 'en') {
  const url = `/templates/v1/templates?categories=COMMUNICATOR_STANDARD&language=${locale}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getCrisis24StandardMessageTemplates({pathParams: {locale = 'en'}}) {
  const url = `/templates/v1/templates?categories=COMMUNICATOR_STANDARD&language=${locale}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getMessageRecipients(messageId, page, size) {
  const url = `${baseApi}/messages/${messageId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      page,
      size
    }
  });
}

function getRecipients({
  pathParams: messageId,
  params: {
    locale,
    page,
    size,
    sort,
    includeDetails
  },
  signal
}) {
  const url = `${baseApi}/messages/${messageId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      languageCode: locale,
      page,
      size,
      sort,
      includeDetails
    },
    signal
  });
}

function getSurveyQuestionResponses({
  pathParams: {
    messageId,
    questionNumber
  },
  params: {
    page,
    size,
    sort
  },
  signal
}) {
  const url = `${baseApi}/messages/${messageId}/surveys/questions/${questionNumber}/responses`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      page,
      size,
      sort
    }
  });
}

function getListOfCommunications({
  params: urlParams,
  data: filter,
  signal
}) {
  const url = `${baseApi}/messages/history`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    signal
  });
}

function getCommunicationsByMessageType({
  params: {
    organizationId,
    correlationId,
    messageType,
    locale,
    page,
    size,
    sort
  },
  signal
}) {
  const url = `${baseApi}/messages/history`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      messageType,
      organizationId,
      correlationId,
      languageCode: locale,
      page,
      size,
      sort
    },
    signal
  });
}

function getResponseOptions({pathParams}) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/messages/deliveries/${pathParams.deliveryId}/response-options`;
  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'get'
  });
}

function submitResponse({
  pathParams: deliveryInstanceId,
  params: urlParams,
  data: filter,
  signal
}) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const url = `${baseApi}/messages/deliveries/${deliveryInstanceId}/responses`;
  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    signal
  });
}

function initiateMessagesExport(configData) {

  const {pathParams: {locale, format, sort}, data} = configData;

  const url = `${baseApi}/messages/history/export`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      languageCode: locale,
      format,
      sort
    },
    data
  });
}

function getMessagesExportStatus({id, successCallback}) {
  const url = `${baseApi}/exports/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback
  );
}

function initiateMessagesDetailsExport(configData) {

  const {pathParams: {messageId, locale, format, sort}, data} = configData;

  const url = `${baseApi}/messages/${messageId}/details/export`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      languageCode: locale,
      format,
      sort
    },
    data
  });

}

function extendMessage({pathParams, data}) {
  const {messageId} = pathParams
  const {hours: extendByHours} = data

  const url = `${baseApi}/messages/${messageId}/extend`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data: {
      extendByHours
    }
  });
}

function resendMessage({pathParams}) {
  const {messageId} = pathParams;
  const url = `${baseApi}/messages/${messageId}/resend`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
  });
}

//Message Templates
function createMessageTemplate({pathParams: {organizationId}, params: {templateName, formats, conferenceBridge, statusConfirmationOptions, survey}}) {
  const url = `${baseApi}/organizations/${organizationId}/messages/templates`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      templateName,
      messageConfig: {
        formats,
        conferenceBridge,
        statusConfirmationOptions,
        survey
      }
    }
  });
}

//Custom Message Templates
function getCustomMessageTemplates({pathParams: {organizationId}, params: {templateName, sort, disabled}}) {
  const url = `${baseApi}/organizations/${organizationId}/messages/templates`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      templateName,
      sort,
      disabled
    }
  });
}

function getCustomMessageTemplate({pathParams: {organizationId, templateId}}) {
  const url = `${baseApi}/organizations/${organizationId}/messages/templates/${templateId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function updateCustomMessageTemplate({pathParams: {organizationId, templateId}, data: messageTemplate}) {
  const url = `${baseApi}/organizations/${organizationId}/messages/templates/${templateId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: messageTemplate
  });
}

function deleteCustomMessageTemplate({pathParams: {organizationId, templateId}}) {
  const url = `${baseApi}/organizations/${organizationId}/messages/templates/${templateId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function toggleTemplateDisabledStatus({pathParams: {orgId, templateId}, params: {disabledStatus}}) {
  const url = `${baseApi}/organizations/${orgId}/messages/templates/${templateId}/toggle`

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data: {
      disabled: disabledStatus
    }
  });
}

function getStandardTemplatesConfig({pathParams: {organizationId}}) {
  const url = `${baseApi}/organizations/${organizationId}/configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function createStandardTemplatesConfig({pathParams: {organizationId}, data: templateConfigurations}) {
  const url = `${baseApi}/organizations/${organizationId}/configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: templateConfigurations
  });
}

function updateStandardTemplatesConfig({pathParams: {organizationId}, data: templateConfigurations}) {
  const url = `${baseApi}/organizations/${organizationId}/configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: templateConfigurations
  });
}

function getUsedLanguage({data: {text}}) {
  const url = `${baseApi}/detect-language`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {text}
  });
}

export default {
  createDraftMessage,
  updateMessage,
  sendMessage,
  addRecipient,
  deleteRecipient,
  getMessage,
  getMessageSummary,
  getMessageRecipients,
  getMessageTemplates,
  getCrisis24StandardMessageTemplates,
  getListOfCommunications,
  getCommunicationsByMessageType,
  getResponseOptions,
  submitResponse,
  getMessageDetails,
  getRecipients,
  getSurveyQuestionResponses,
  initiateMessagesExport,
  getMessagesExportStatus,
  updateRecipientStatus,
  initiateMessagesDetailsExport,
  deactivateMessage,
  getRecipientDetails,
  extendMessage,
  resendMessage,
  createMessageTemplate,
  getCustomMessageTemplates,
  getCustomMessageTemplate,
  updateCustomMessageTemplate,
  toggleTemplateDisabledStatus,
  deleteCustomMessageTemplate,
  getStandardTemplatesConfig,
  createStandardTemplatesConfig,
  updateStandardTemplatesConfig,
  getUsedLanguage
};
