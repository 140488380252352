import {useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {injectIntl} from 'react-intl';

import Layout from 'antd/lib/layout';
import {Helmet} from 'react-helmet';

import Notifications from '../../../containers/Notifications/Notifications';
import LayoutSpace from '../../Common/LayoutSpace/LayoutSpace';

import './BasePage.css';

const {Content, Footer} = Layout;

const APPLICATION_TITLE = 'Crisis24 Horizon';

function BasePage(props) {
  const {
    pageTitleIntl,
    intl,
    pageTitleText,
    header,
    footer,
    className,
    hasUrlTabs,
    noPadding,
    children
  } = props;

  const isMainHeaderMenuHidden = useSelector(state => state?.session?.hideHeader);

  const hasHeader = header !== undefined;
  const hasFooter = footer !== undefined;

  const combinedClassName = classNames(
    'BasePage',
    className,
    {
      'with-footer': hasFooter,
      'with-url-tabs': hasUrlTabs,
      'main-header-menu-hidden': isMainHeaderMenuHidden
    }
  );

  const translatedTitleText = useMemo(() => {
    return intl && pageTitleIntl && intl.formatMessage(pageTitleIntl);
  }, [intl, pageTitleIntl]);

  const title = useMemo(() => {

    if (translatedTitleText && pageTitleText) {
      return `${pageTitleText} ${translatedTitleText} | ${APPLICATION_TITLE}`;
    }
    else if (translatedTitleText) {
      return `${translatedTitleText} | ${APPLICATION_TITLE}`;
    }
    else if (pageTitleText) {
      return `${pageTitleText} | ${APPLICATION_TITLE}`;
    }

    return APPLICATION_TITLE;
  }, [translatedTitleText, pageTitleText]);

  return (
    <Layout className={combinedClassName}>

      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Content className="page-wrapper">

        <Notifications/>

        <div className="page-content">

          {hasHeader && (
            <div className="page-header">
              {header}
            </div>
          )}

          <div className={classNames('page-body', {'no-padding': noPadding})}>
            <LayoutSpace size="medium">
              {children}
            </LayoutSpace>
          </div>

        </div>

      </Content>

      {hasFooter && (
        <Footer className="page-footer">
          {footer}
        </Footer>
      )}

    </Layout>
  );
}

BasePage.propTypes = {
  header: PropTypes.element,
  footer: PropTypes.element,
  className: PropTypes.string,
  pageTitleText: PropTypes.string,
  pageTitleIntl: PropTypes.object,
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  hasUrlTabs: PropTypes.bool,
  noPadding: PropTypes.bool
};

export default injectIntl(BasePage);
