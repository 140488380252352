import waRequest from './wa-request';
import waHttp from './wa-http';

export const baseApi = '/organizations/v1';

/**
 * @typedef {('EMAIL'|'PHONE'|'WEBSITE'|'MOBILE_APP'|'DOCUMENT')} RESOURCE_TYPE
 * @typedef {{
  "name": string,
  "description": string,
  "locations": number[],
  "usefulLinks": boolean,
  "resourceData": object
  }} RESOURCE_DETAILS
 */

/**
 * Initiate authenticated POST /organizations/v1/organizations/{organizationId}/resources/{resourceType}
 * to create a new resource
 *
 * @param organizationId
 * @param {RESOURCE_TYPE} resourceType
 * @param {RESOURCE_DETAILS} resourceDetails
 * @returns {Promise}
 */
function createResource(organizationId, resourceType, resourceDetails) {
  const url = `${baseApi}/organizations/${organizationId}/resources/${resourceType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: resourceDetails
  });
}

/**
 * Initiate authenticated PUT /organizations/v1/organizations/{organizationId}/resources/{resourceId}
 * to update a resource
 *
 * @param organizationId
 * @param resourceId
 * @param {RESOURCE_DETAILS} resourceDetails
 * @returns {Promise}
 */
function updateResource(organizationId, resourceId, resourceDetails) {
  const url = `${baseApi}/organizations/${organizationId}/resources/${resourceId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: resourceDetails
  });
}

/**
 * Initiate authenticated DELETE /organizations/v1/organizations/{organizationId}/resources/{resourceId}
 * to delete a resource
 *
 * @param organizationId
 * @param resourceId
 * @returns {Promise}
 */
function deleteResource(organizationId, resourceId) {
  const url = `${baseApi}/organizations/${organizationId}/resources/${resourceId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/{organizationId}/resources/{resourceId}
 * to get a resource
 *
 * @param organizationId
 * @param resourceId
 * @returns {Promise}
 */
function getResource(organizationId, resourceId) {
  const url = `${baseApi}/organizations/${organizationId}/resources/${resourceId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated GET /organizations/v1/organizations/{organizationId}/resources
 * to get all resources
 *
 * @param organizationId
 * @param {{locationId?: number, resourceType?: RESOURCE_TYPE}} [filter]
 * @returns {Promise}
 */
function getResources(organizationId) {
  const url = `${baseApi}/organizations/${organizationId}/resources`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getResourcesConfigPattern({pathParams: {organizationId}}) {
  const url = `${baseApi}/organizations/${organizationId}/resources`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated GET /organizations/{organizationId}/resources/?locationId={locationId)
 * to get all resources
 *
 * @param organizationId
 * @param {{locationId?: number, resourceType?: RESOURCE_TYPE}} [filter]
 * @returns {Promise}
 */
function getLocationResources(organizationId, locationId) {
  const url = `${baseApi}/organizations/${organizationId}/resources/?locationId=${locationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated GET /organizations/{organizationId}/resources/?siteId={siteId)
 * to get all resources
 *
 * @param organizationId
 * @param {{siteId?: number, resourceType?: RESOURCE_TYPE}} [filter]
 * @returns {Promise}
 */
function getSiteResources(organizationId, siteId) {
  const url = `${baseApi}/organizations/${organizationId}/resources/?siteId=${siteId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated PUT /organizations/v1/organizations/{organizationId}/resources/{resourceId}/disable
 * to disable a resource
 */
function disableResource(organizationId, resourceId) {
  const url = `${baseApi}/organizations/${organizationId}/resources/${resourceId}/disable`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put'
  });
}

/**
 * Initiate authenticated PUT /organizations/v1/organizations/{organizationId}/resources/{resourceId}/enable
 * to enable a resource
 */
function enableResource(organizationId, resourceId) {
  const url = `${baseApi}/organizations/${organizationId}/resources/${resourceId}/enable`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put'
  });
}

/**
 * Initiate authenticated PUT /organizations/v1/organizations/{organizationId}/resources/upload-url
 * Get a presigned url to upload to S3
 */
function getDocumentKey(organizationId, length) {
  const url = `${baseApi}/organizations/${organizationId}/resources/upload-url`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {
      length
    }
  });
}

/**
 * Initiate authenticated PUT Crisis S3 resource URI
 * Upload file to S3
 */
function uploadDocument(file, url) {
  return waHttp.httpRequest({
    url: url,
    headers: {
      'Content-Type': 'application/octet-stream',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'PUT'
    },
    method: 'put',
    data: file
  });
}

/**
 * Initiate authenticated PUT /organizations/v1/organizations/{organizationId}/resources/download-url
 * download file from S3
 */
function downloadDocument(organizationId, key) {
  const url = `${baseApi}/organizations/${organizationId}/resources/download-url`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {
      key
    }
  });
}

export default {
  createResource,
  updateResource,
  deleteResource,
  getResource,
  getResources,
  getLocationResources,
  getSiteResources,
  disableResource,
  enableResource,
  getDocumentKey,
  uploadDocument,
  downloadDocument,
  getResourcesConfigPattern
};
