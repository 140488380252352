import {PureComponent} from 'react';

import './Loading.css';
import {Spin} from 'antd';

export default class Loading extends PureComponent {
  render() {
    return (
      <div className="Loading">
        <Spin size="large" className="spinner"/>
      </div>
    );
  }
}
