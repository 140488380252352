import waRequest from './wa-request';

export const apiBase = '/travel-approval/v1';

function getDecisionMakerConfigs({pathParams: {organizationId}}) {
  const url = `${apiBase}/organizations/${organizationId}/decision-maker-configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getTmcNotificationConfigs({pathParams: {organizationId}}) {
  const url = `${apiBase}/organizations/${organizationId}/tmc-notification-configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function createDecisionMakerConfig({pathParams: {organizationId}, data}) {
  const url = `${apiBase}/organizations/${organizationId}/decision-maker-configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: data
  });

}
function getItineraries({
  params,
  data
}) {
  const url = `${apiBase}/itineraries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params,
    data: data
  });
}

function getItineraryEvents({
  params,
  pathParams: {itineraryId}
}) {
  const url = `${apiBase}/itineraries/${itineraryId}/events`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params
  });
}

function getItineraryEventDetails({
  pathParams: {itineraryId, eventId}
}) {
  const url = `${apiBase}/itineraries/${itineraryId}/events/${eventId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function updateItineraryStatus({
  data,
  pathParams: {itineraryId}
}) {
  const url = `${apiBase}/itineraries/${itineraryId}/status`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data: data
  });
}

function overrideDecisionMakerConfig({pathParams: {organizationId}}) {
  const url = `${apiBase}/organizations/${organizationId}/decision-maker-configs/override`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

function updateDecisionMakerConfig({pathParams: {organizationId, configId}, data}) {
  const url = `${apiBase}/organizations/${organizationId}/decision-maker-configs/${configId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: data
  });
}


function deleteDecisionMakerConfig({pathParams: {organizationId, configId}}) {
  const url = `${apiBase}/organizations/${organizationId}/decision-maker-configs/${configId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function getDecisionMakerConfigById({pathParams: {organizationId, configId}, params: {locale}, signal}) {
  const url = `${apiBase}/organizations/${organizationId}/decision-maker-configs/${configId}`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get',
    signal,
    params: {
      locale
    },
  })
}


export default {
  getDecisionMakerConfigs,
  getTmcNotificationConfigs,
  createDecisionMakerConfig,
  getItineraries,
  deleteDecisionMakerConfig,
  updateDecisionMakerConfig,
  getDecisionMakerConfigById,
  updateItineraryStatus,
  overrideDecisionMakerConfig,
  getItineraryEvents,
  getItineraryEventDetails
};