import {CLASSNAMES} from '../constants/rating-filters';

export function getRatingKey(categoryId) {
  return `rating_${categoryId}`;
}

export function ratingToInteger(rating) {
  return Math.floor(rating);
}

export function getRiskClassName(riskValue) {
  if (riskValue < 1) {
    return CLASSNAMES.UNRATED;
  }
  if (riskValue < 1.5) {
    return CLASSNAMES.MINIMAL;
  }
  if (riskValue < 2.5) {
    return CLASSNAMES.LOW;
  }
  if (riskValue < 3.5) {
    return CLASSNAMES.MODERATE;
  }
  if (riskValue < 4.5) {
    return CLASSNAMES.HIGH;
  }
  if (riskValue <= 5) {
    return CLASSNAMES.EXTREME;
  }
  return CLASSNAMES.UNRATED;
}

export function getAssessmentClassName(assessmentId) {
  switch (assessmentId) {
    case 0:
    case 1:
      return CLASSNAMES.MINIMAL;
    case 2:
    case 3:
      return CLASSNAMES.LOW;
    case 4:
    case 5:
      return CLASSNAMES.MODERATE;
    case 6:
      return CLASSNAMES.HIGH;
    case 7:
    case 8:
      return CLASSNAMES.EXTREME;
    default:
      return CLASSNAMES.UNRATED;

  }
}
