import waRequest from './wa-request';

export const apiBase = '/geo-threat-zones/v2';

/**
 * Initiate authenticated GET ${apiBase}/organizations/${organizationId}/hide-threat-zones-configs
 *
 * @returns {Promise}
 */
function getHideThreatZonesConfiguration(organizationId) {
  const url = `${apiBase}/organizations/${organizationId}/hide-threat-zones-configs`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated PUT ${apiBase}/organizations/${organizationId}/hide-threat-zones-configs
 *
 * @returns {Promise}
 */
function updateHideThreatZonesConfiguration(organizationId, payload){
  const url = `${apiBase}/organizations/${organizationId}/hide-threat-zones-configs`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: payload
  });
}

/**
 * Initiate authenticated DELETE ${apiBase}/organizations/${organizationId}/hide-threat-zones-configs
 *
 * @returns {Promise}
 */
function deleteHideThreatZonesConfiguration(organizationId){
  const url = `${apiBase}/organizations/${organizationId}/hide-threat-zones-configs`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 * Initiate authenticated HEAD ${apiBase}/organizations/${organizationId}/hide-threat-zones-configs
 *
 * @returns {Promise}
 */
function checkHideThreatZonesConfigurationExistence(organizationId) {
  const url = `${apiBase}/organizations/${organizationId}/hide-threat-zones-configs`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'head'
  });
}

/**
 * Initiate authenticated GET ${baseApi}/published-threat-zone-profiles/:locationId
 *
 * @param {number} locationId
 * @param {uuid} organizationId used to find and apply any threat zone related business rules that an organization may have configured
 * @returns {Promise}
 */
function getThreatZonesForLocation(locationId, organizationId) {
  const url = `${apiBase}/published-threat-zone-profiles/${locationId}`

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      organizationId
    }
  });
}

function getAllThreatZonesDataForLocation(locationId, organizationId, locale, locationTier) {
  const url = `${apiBase}/published-threat-zones/searches`

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId: organizationId,
      includeGeoJson: true,
      locales: [locale]
    },
    data: {
      locationIds: [locationId],
      locationTiers: [locationTier]
    }
  });
}

function getTheatZoneDetails(threatZoneId, organizationId, locale) {
  const url = `${apiBase}/published-threat-zones/${threatZoneId}`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      organizationId,
      locales: [locale]
    }
  });
}

const getThreatZonesWithPolygon = (organizationId, geoJson, locale, data) => {
  const url = `${apiBase}/published-threat-zones/searches`

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId: organizationId,
      includeGeoJson: true,
      locales: [locale]
    },
    data: {
      inGeometry: geoJson,
      locationTiers: data.locationTiers || [],
      ratingRange: data.ratingRange || {from: 1, to: 5},
      locationIds: data.locationIds || []
    }
  });
}

export default {
  getHideThreatZonesConfiguration,
  updateHideThreatZonesConfiguration,
  deleteHideThreatZonesConfiguration,
  checkHideThreatZonesConfigurationExistence,
  getThreatZonesForLocation,
  getThreatZonesWithPolygon,
  getAllThreatZonesDataForLocation,
  getTheatZoneDetails
};

