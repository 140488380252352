import {useEffect} from 'react';

import {Card, Col, Row} from 'antd';
import {defineMessages, FormattedMessage} from 'react-intl';

import {toggleAccessibeDisplay} from '../../../../utils/accessibe';

import './CourseCompletion.css';

const COMPLETION_SVG = `${process.env.REACT_APP_CDN_URL}/images/course-completion/completion.svg`;

const messages = defineMessages({
  completedCourse: {
    id: 'CourseCompletion.completedCourse',
    defaultMessage: 'Course completed'
  },
  restOfMessage: {
    id: 'CourseCompletion.restOfMessage',
    defaultMessage: 'You will receive an email confirmation. You may now navigate away from this page.'
  }
});

export default function CourseCompletion() {
  useEffect(() => {
    toggleAccessibeDisplay(true);
    return () => {
      toggleAccessibeDisplay(false);
    }
  }, []);

  return (
    <div className="CourseCompletion">
      <div className="wrapper">
        <Card className="content">
          <Row>
            <Col span={3} lg={2} offset={6}>
              <img src={COMPLETION_SVG} alt="course completion" height="44" width="44"/>
            </Col>
            <Col span={9} lg={10}>
              <strong><FormattedMessage {...messages.completedCourse}/>!</strong> <FormattedMessage {...messages.restOfMessage}/>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
}
