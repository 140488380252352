import * as types from './types';
import _ from 'lodash';

import localization from '../../../services/localization';
import enMessages from '../../../translations/en.json'

import {localeOptions} from '../../../constants/locale';
import analyticsService from '../../../services/analytics';

import {featureToggle} from '../../../components/Common/FeatureToggle/FeatureToggleUtils/FeatureToggleUtils';
import {FEATURES} from '../../../constants/features';

export const initialize = () => {
  return (dispatch, getState) => {
    const systemLanguage = localization.getSystemLanguage();
    const storageLanguage = localization.getLocalStorageLanguage();
    const reduxLanguage = _.get(getState(), ['localization', 'locale']);

    const language = storageLanguage || reduxLanguage || systemLanguage;

    return dispatch(setLanguage(language));
  };
};

/**
 *
 * @param {LOCALES} language
 * @returns {Function}
 */
export const setLanguage = (language) => {
  return (dispatch, getState) => {
    return localization.updateLocaleDataAsync(language)
      .then(localData => {

        localization.setLocalStorageLanguage(language);
        localData.languageName = _.find(localeOptions, ['value', language]).label;
        if (featureToggle(FEATURES.MAPI_223)) {
          document.documentElement.dir = language === "he" ? "rtl" : "ltr";
        }

        dispatch({
          type: types.LOCALE_SET,
          payload: localData
        });

        dispatch({
          type: types.INTEL_LOCALE_RESET
        });

        analyticsService.updateAnalytics(getState());
      });
  };
};

export const setIntelLocaleEnglish = () => {
  return (dispatch) => {

    dispatch({
      type: types.INTEL_LOCALE_SET,
      payload: {intelLocale: 'en', intelMessages: enMessages}
    });
  };
};

export const resetIntelLocale = () => {
  return (dispatch) => {

    dispatch({
      type: types.INTEL_LOCALE_RESET
    });
  };
};
