import {LOCALES} from '../../../constants/locale';

/**
 * The locales used by mapbox are sometimes named differently from the ones we typically use.
 * For example:
 *
 *  Our Chinese Simplified 'zh-hans' corresponds to 'zh-Hans' in mapbox
 *  Our Chinese Traditional 'zh-hant' corresponds to 'zh-Hant' in mapbox
 *
 *  the rest have the same name
 *
 * @param {(LOCALES | string)} locale
 * @return {string} the locale expected by the mapbox library
 */
export function getMapboxLocale(locale) {
  if (locale === LOCALES.ZH_HANS) {
    return 'zh-Hans';
  }
  else if (locale === LOCALES.ZH_HANT) {
    return 'zh-Hant';
  }
  else {
    return locale;
  }
}

/**
 * The locales used by moment are sometimes named differently from the ones we typically use.
 * For example:
 *  Our Portuguese 'pt' corresponds to 'pt-br' in moment,
 *  Our Chinese Simplified 'zh-hans' corresponds to 'zh-cn' in moment
 *  Our Chinese Traditional 'zh-hant' corresponds to 'zh-tw' in moment
 *
 *  the rest have the same name
 *
 * @param {(LOCALES | string)} locale
 * @return {string} the locale expected by the moment library
 */
export function getMomentLocale(locale) {
  if (locale === LOCALES.PT) {
    return 'pt-br';
  }
  else if (locale === LOCALES.ZH_HANS) {
    return 'zh-cn';
  }
  else if (locale === LOCALES.ZH_HANT) {
    return 'zh-tw';
  }
  else {
    return locale;
  }
}
