import {combineReducers} from 'redux';
import {responsiveStateReducer} from 'redux-responsive';
import {connectRouter} from 'connected-react-router';

import history from '../services/history';

import sessionReducer from './modules/session';
import localizationReducer from './modules/localization';
import notificationReducer from './modules/notification';
import locationsReducer from './modules/locations';
import categoriesReducer from './modules/categories';
import sitesReducer from './modules/sites';
import loginReducer from './modules/login'
import drawersReducer from './modules/drawers';
import peopleLocationTypesReducer from './modules/peopleLocationTypes';
import personTypesReducer from './modules/personTypes';
import communicatorReducer from './modules/communicator';
import personMatchingPatternsReducer from './modules/personMatchingPatterns';

// TODO Preferences are here due to auth component/page needing to be officially loaded is this still the case?
import preferencesReducer from './modules/preferences';
import reportsReducer from './modules/reports';
import preferencesFilterReducer from './modules/preferences-filter';

export const staticReducers = {
  browser: responsiveStateReducer,
  router: connectRouter(history),
  session: sessionReducer,
  localization: localizationReducer,
  notification: notificationReducer,
  locations: locationsReducer,
  categories: categoriesReducer,
  sites: sitesReducer,
  login: loginReducer,
  drawers: drawersReducer,
  preferences: preferencesReducer,
  reports: reportsReducer,
  peopleLocationTypes: peopleLocationTypesReducer,
  personTypes: personTypesReducer,
  communicator: communicatorReducer,
  preferencesFilter: preferencesFilterReducer,
  personMatchingPatterns: personMatchingPatternsReducer,
};

export default function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers
  });
}

