import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';

import {Prompt} from 'react-router-dom';

import CloseConfirmationModal from '../CloseConfirmationModal/CloseConfirmationModal';

export function NavigationBlocker({when, message, title, navigate, onNavigation}) {
  const [isNavConfirmed, setIsNavConfirmed] = useState(false);

  const useFuncWhen = (typeof when === 'function');

  useEffect(() => {
    if (isNavConfirmed) {
      navigate?.(isNavConfirmed);
      onNavigation && onNavigation();
      setIsNavConfirmed(false);
    }
  }, [isNavConfirmed, navigate, onNavigation]);

  const executeNavigation = (nextLocation) => {
    setIsNavConfirmed(nextLocation);
  };

  /**
   * Return false to allow for blocking modal to be displayed
   *
   * @param nextLocation
   * @returns {boolean}
   */
  const handleBlockedNavigation = (nextLocation) => {
    if (isNavConfirmed) {
      return true;
    }

    if (useFuncWhen && !when()) {
      return true;
    }

    CloseConfirmationModal({
      title,
      content: message,
      className: 'NavigationBlockerModal'
    }, () => executeNavigation(nextLocation));

    return false;
  };

  return (
    <Prompt
      when={useFuncWhen ? true : when}
      message={handleBlockedNavigation}
    />
  );
}

NavigationBlocker.propTypes = {
  when: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool
  ]),
  message: PropTypes.node,
  title: PropTypes.node,
  onNavigation: PropTypes.func,
  navigate: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  navigate: (nextLocation) => dispatch(push(nextLocation))
});

export default connect(null, mapDispatchToProps)(NavigationBlocker);
