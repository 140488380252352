import * as types from './types';

const reducer = (
  state = {
    isFetching: false,
    list: [],
    error: null,
    locale: null
  },
  action
) => {
  switch (action.type) {
    case types.PERSON_TYPES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case types.PERSON_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        locale: action.payload.locale
      };
    case types.PERSON_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        list: [],
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
