import waRequest from './wa-request';
import waHttp from './wa-http';
import updateGeoJsonURL from '../../utils/update-geoJson-URL';

export const apiBase = '/intel/v3';

/**
 * Initiate authenticated GET ${apiBase}/alerts/searches
 *
 * @param filter
 * @param locale
 * @param page
 * @param size
 * @param sort
 * @returns {Promise}
 */
function getAlerts(filter, locale = 'en', page = 0, size = 25, sort = ['publishedDate,desc']) {
  const url = `${apiBase}/alerts/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale,
      page,
      size,
      sort
    },
    data: filter
  });
}

function getAllAlerts(filter = {'status': 'Active'}, locale = 'en', sort = ['publishedDate,desc']) {
  const url = `${apiBase}/alerts/searches`;

  return waRequest.requestAllPages({
    url: url,
    method: 'post',
    params: {
      locale,
      size: 1000,
      sort
    },
    data: filter
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

/**
 * Initiate authenticated GET ${apiBase}/alerts/${alertId}
 *
 * @param alertId
 * @param locale
 * @param returnMapImage
 * @returns {Promise}
 */
function getAlertById(alertId, locale = 'en', returnMapImage = false) {
  const url = `${apiBase}/alerts/${alertId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      returnMapImage
    }
  });
}

function getAlertByIdConfigPattern({pathParams: {alertId, returnMapImage = false}, params: {locale} = {}, signal}) {
  return getAlertById(alertId, locale, returnMapImage)
}

/**
 * Initiate authenticated POST ${apiBase}/alerts/reports
 *
 * @param body
 * @param urlParams
 * @returns {Promise}
 */
function exportAlerts(body, urlParams) {

  const url = `${apiBase}/alerts/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: body,
    params: urlParams,
    responseType: 'blob'
  });
}

function getAlertGeoJson(geoJsonUrl) {
  return waHttp.httpRequest({
    baseURL: '',
    url: updateGeoJsonURL(geoJsonUrl),
    method: 'get'
  });

}

function getKeywords({params: {locale, isDisabled}}) {
  const url = `${apiBase}/keywords`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      isDisabled,
      size: 500
    },
  });
}

export default {
  getAlerts,
  getAlertById,
  exportAlerts,
  getAllAlerts,
  getAlertGeoJson,
  getAlertByIdConfigPattern,
  getKeywords
};

