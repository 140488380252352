import waRequest from './wa-request';

export const apiBase = '/pnr/v1';
export const expatBasePath = `${apiBase}`;


function getOrgMailboxes({
  params: {
    organizationId,
    includeChildren = false
  },
  signal
}) {
  return waRequest.requestAuthenticated({
    url: `${expatBasePath}/organizations/${organizationId}/mailboxes`,
    signal,
    method: 'get',
    params: {
      includeChildren
    },
  });
}

const getPNRRecords = ({filterString, page, size, statuses, startDate, endDate, organizationIds, feedIds, sourceIds, tmc, client, countryLocationInternalIds}) => {
  const url = `${apiBase}/records`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      filterString,
      page,
      size,
      statuses,
      startDate,
      endDate,
      organizationIds,
      feedIds,
      sourceIds,
      tmc,
      client,
      countryLocationInternalIds
    }
  });
}

const getPNRFeeds = ({size, excludeAudit}) => {
  const url = `${apiBase}/feeds`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      size,
      excludeAudit,
    }
  });
}

const getPNRSources = ({size, excludeAudit}) => {
  const url = `${apiBase}/sources`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      size,
      excludeAudit,
    }
  });
}

const getPNRRecordById = ({pathParams: {pnrId}}) => {
  const url = `${apiBase}/records/${pnrId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
  });
}

const initiatePNRExport = ({
  data: {
    startDate,
    endDate,
    feedIds,
    filterString,
    ids,
    organizationIds,
    sourceIds,
    statuses
  }
}) => {
  const url = `${apiBase}/recordExports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      startDate,
      endDate,
      feedIds,
      filterString,
      ids,
      organizationIds,
      sourceIds,
      statuses
    }
  });
}

function getPNRExportStatus({params:{id}}) {
  const url = `${apiBase}/recordExports/${id}`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get'
  })
}

function downloadReport({params:{id}}) {
  const url = `${apiBase}/recordExports/${id}/results`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get',
    responseType: 'blob'
  })
}

function downloadEmail(vendorEmailId) {
  const url = `${apiBase}/traxo/emails/download`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get',
    responseType: 'blob',
    params: {vendorEmailId},
  });
}

/**
 * Get sources using config pattern (for hook).
 * All params properties are optional
 *
 * @param {Object=} config
 * @param {Object=} config.params
 * @param {string==} config.params.feed
 * @param {string=} config.params.code
 * @param {string=} config.params.name
 * @param {string=} config.params.organization
 * @param {string=} config.params.organizationId
 * @param {boolean=} config.params.includeSubOrganizations
 * @param {string=} config.params.filterString
 * @param {boolean=} config.params.excludeAudit
 * @param {string=} config.params.tmc
 * @param {string=} config.params.client
 * @param {Array.<number>=} config.params.countryLocationInternalIds
 * @param {number=} config.params.page
 * @param {number=} config.params.size
 * @param {Array.<string>=} config.params.sort [property.(asc|desc)] Default sort order is ascending.
 * @param {AbortSignal} config.signal AbortController signal
 *
 * @returns {Promise} PageSearchSourceResponse
 * */
const getPNRSourcesConfigPattern = (config) => {

  const {
    params: {
      feed,
      code,
      name,
      organization,
      organizationId,
      includeSubOrganizations,
      filterString,
      excludeAudit,
      tmc,
      client,
      countryLocationInternalIds,
      page,
      size,
      sort
    },
    signal
  } = config;

  const url = `${apiBase}/sources`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      feed,
      code,
      name,
      organization,
      organizationId,
      includeSubOrganizations,
      filterString,
      excludeAudit,
      tmc,
      client,
      countryLocationInternalIds,
      page,
      size,
      sort
    },
    signal
  });
}

function getBulkUploadsList(
  {
    params: {size = 25, page = 0, sort='createdAt,desc'},
    signal
  }) {
  const url = `${apiBase}/bulkUploads`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      size,
      page,
      sort
    }
  });
}

function getBulkUploadsTemplates(params) {
  const url = `${apiBase}/bulkUploads/example`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob',
    params
  });
}

function uploadFile({params, data}) {
  const url = `${apiBase}/bulkUploads`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params,
    data
  });
}

function getFileDetails(id, successCallback) {
  const url = `${apiBase}/bulkUploads/${id}`;

  return waRequest.requestWithRetryUntilSuccess(
    {
      url: url,
      method: 'get'
    },
    successCallback,
    5000
  );
}

function getFileErrors({pathParams: {fileId}}) {
  const url = `${apiBase}/bulkUploads/${fileId}/errors`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });
}

export default {
  getOrgMailboxes,
  getPNRRecords,
  getPNRFeeds,
  getPNRSources,
  getPNRRecordById,
  initiatePNRExport,
  getPNRExportStatus,
  downloadReport,
  downloadEmail,
  getPNRSourcesConfigPattern,
  getBulkUploadsList,
  getBulkUploadsTemplates,
  uploadFile,
  getFileDetails,
  getFileErrors
};

