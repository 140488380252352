export const MAP_INITIALIZED = 'worldMap/MAP_INITIALIZED';
export const SET_MAP_TYPE = 'worldMap/SET_MAP_TYPE';
export const SET_MAP_POLYGON = 'worldMap/SET_MAP_POLYGON';
export const SET_MAP_SELECTED_POLYGON = 'worldMap/SET_MAP_SELECTED_POLYGON';
export const SET_MAP_POSITION = 'worldMap/SET_MAP_POSITION';
export const SET_MAP_EXTENDED_BOUNDS = 'worldMap/SET_MAP_EXTENDED_BOUNDS';
export const SET_LAYER_VISIBILITY = 'worldMap/SET_LAYER_VISIBILITY';
export const SET_MAP_SELECTED_FEATURES = 'worldMap/SET_MAP_SELECTED_FEATURES';
export const SET_MAP_PROVINCE_FEATURE = 'worldMap/SET_MAP_PROVINCE_FEATURE';
export const SET_MAP_DETAIL_FEATURE = 'worldMap/SET_MAP_DETAIL_FEATURE';
export const SET_MAP_FLY_TO_FEATURE = 'worldMap/SET_MAP_FLY_TO_FEATURE';

export const ALERTS_REQUEST = 'worldMap/ALERTS_REQUEST';
export const ALERTS_SUCCESS = 'worldMap/ALERTS_SUCCESS';
export const ALERTS_ERROR = 'worldMap/ALERTS_ERROR';

export const POLYGON_SEARCH_REQUEST = 'worldMap/POLYGON_SEARCH_REQUEST';
export const POLYGON_SEARCH_SUCCESS = 'worldMap/POLYGON_SEARCH_SUCCESS';
export const POLYGON_SEARCH_ERROR = 'worldMap/POLYGON_SEARCH_ERROR';

export const SET_DATE_RANGE_FILTER = 'worldMap/SET_DATE_RANGE_FILTER';
export const SET_LOCATIONS_FILTER = 'worldMap/SET_LOCATIONS_FILTER';
export const SET_DEFAULT_DATE_RANGE = 'worldMap/SET_DEFAULT_DATE_RANGE';

export const SET_ALERTS_FILTER = 'worldMap/SET_ALERTS_FILTER';
export const RESET_ALERTS_FILTER = 'worldMap/RESET_ALERTS_FILTER';
export const SET_INCLUDE_EXPIRED_INTELLIGENCE_FILTER = 'worldMap/SET_INCLUDE_EXPIRED_INTELLIGENCE_FILTER';
export const SET_ALERT_SEVERITY_FILTER = 'worldMap/SET_ALERT_SEVERITY_FILTER';
export const SET_ALERT_CATEGORY_FILTER = 'worldMap/SET_ALERT_CATEGORY_FILTER';

// Ratings
export const SET_RATINGS_FILTER = 'worldMap/SET_RATINGS_FILTER';

// threat zone
export const SET_THREAT_ZONES_FILTER = 'worldMap/SET_THREAT_ZONES_FILTER';

// Person
export const PERSON_GEODATA_REQUEST = 'worldMap/PERSON_GEODATA_REQUEST';
export const PERSON_GEODATA_SUCCESS = 'worldMap/PERSON_GEODATA_SUCCESS';
export const PERSON_GEODATA_ERROR = 'worldMap/PERSON_GEODATA_ERROR';
export const SET_PERSON_FILTER = 'worldMap/SET_PERSON_FILTER';
export const PERSONS_LIST_SELECTION_REQUEST = 'worldMap/PERSONS_LIST_SELECTION_REQUEST';
export const PERSONS_LIST_SELECTION_SUCCESS = 'worldMap/PERSONS_LIST_SELECTION_SUCCESS';
export const PERSONS_LIST_SELECTION_ERROR = 'worldMap/PERSONS_LIST_SELECTION_ERROR';

export const LEAVING_MAP_PAGE = 'worldMap/LEAVING_MAP_PAGE';
