import _ from 'lodash';

/**
 *
 * @param results
 * @returns {{pageNumber: undefined, numberOfElements: undefined, pageSize: undefined, totalElements: undefined}}
 */
export default function parsePagedResults(results) {
  return parsePagedData(_.get(results, ['data']));
}

/**
 *
 * @param results
 * @returns {{pageNumber: undefined, numberOfElements: undefined, pageSize: undefined, totalElements: undefined}}
 */
export function parsePagedData(results) {
  const totalElements = _.get(results, ['totalElements'], _.get(results, [ 'page', 'totalElements']));
  const numberOfElements = _.get(results, ['numberOfElements'], _.get(results, ['page', 'numberOfElements']));
  const pageNumber = _.get(results, [ 'pageable', 'pageNumber'], _.get(results, [ 'page', 'number']));
  const pageSize = _.get(results, ['pageable', 'pageSize'], _.get(results, [ 'page', 'size']));

  return {
    pageNumber,
    pageSize,
    totalElements,
    numberOfElements
  };
}
