import {defineMessages} from "react-intl";

import {CHINESE_SIMPLIFIED, CHINESE_TRADITIONAL, DUTCH, ENGLISH, FRENCH, GERMAN, HINDI, ITALIAN, JAPANESE, POLISH, PORTUGUESE, RUSSIAN, SPANISH, HEBREW} from "./permissions";
import {featureToggle} from "../components/Common/FeatureToggle/FeatureToggleUtils/FeatureToggleUtils";
import {FEATURES} from "./features";

const BASE_LOCALES = {
  EN: 'en',
  FR: 'fr',
  ES: 'es',
  DE: 'de',
  IT: 'it',
  JA: 'ja',
  PT: 'pt',
  ZH_HANS: 'zh-hans',
  ZH_HANT: 'zh-hant',
  NL: 'nl',
  PL: 'pl',
  HI: 'hi',
  RU: 'ru'
};

export const LOCALES = featureToggle(FEATURES.MAPI_207) ?
  {
    ...BASE_LOCALES,
    ...{HE: 'he'}
  } : BASE_LOCALES;

const baseLocaleOptions = [
  {
    value: LOCALES.EN,
    label: 'English',
    permission: ENGLISH
  }, {
    value: LOCALES.FR,
    label: 'Français',
    permission: FRENCH
  }, {
    value: LOCALES.ES,
    label: 'Español',
    permission: SPANISH
  }, {
    value: LOCALES.DE,
    label: 'Deutsch',
    permission: GERMAN
  }, {
    value: LOCALES.IT,
    label: 'Italiano',
    permission: ITALIAN
  }, {
    value: LOCALES.JA,
    label: '日本語',
    permission: JAPANESE
  }, {
    value: LOCALES.PT,
    label: 'Português',
    permission: PORTUGUESE
  }, {
    value: LOCALES.ZH_HANS,
    label: '简体中文',
    permission: CHINESE_SIMPLIFIED
  }, {
    value: LOCALES.ZH_HANT,
    label: '繁體中文',
    permission: CHINESE_TRADITIONAL
  }, {
    value: LOCALES.NL,
    label: 'Nederlands',
    permission: DUTCH
  }, {
    value: LOCALES.PL,
    label: 'Polski',
    permission: POLISH
  }, {
    value: LOCALES.HI,
    label: 'हिंदी',
    permission: HINDI
  }, {
    value: LOCALES.RU,
    label: 'Русский',
    permission: RUSSIAN
  }
];

export const localeOptions = featureToggle(FEATURES.MAPI_207) ?
  [
    ...baseLocaleOptions,
    {
      value: LOCALES.HE,
      label: 'עִברִית',
      permission: HEBREW
    }
  ] : baseLocaleOptions;


const baseMessageLocales = defineMessages({
  en: {
    id: 'locale.en',
    defaultMessage: 'English'
  },
  fr: {
    id: 'locale.fr',
    defaultMessage: 'French'
  },
  es: {
    id: 'locale.es',
    defaultMessage: 'Spanish'
  },
  de: {
    id: 'locale.de',
    defaultMessage: 'German'
  },
  it: {
    id: 'locale.it',
    defaultMessage: 'Italian'
  },
  ja: {
    id: 'locale.ja',
    defaultMessage: 'Japanese'
  },
  pt: {
    id: 'locale.pt',
    defaultMessage: 'Portuguese'
  },
  "zh-hans": {
    id: 'locale.zh_hans',
    defaultMessage: 'Chinese Simplified'
  },
  "zh-hant": {
    id: 'locale.zh_hant',
    defaultMessage: 'Chinese Traditional'
  },
  nl: {
    id: 'locale.nl',
    defaultMessage: 'Dutch'
  },
  pl: {
    id: 'locale.pl',
    defaultMessage: 'Polish'
  },
  hi: {
    id: 'locale.hi',
    defaultMessage: 'Hindi'
  },
  ru: {
    id: 'locale.ru',
    defaultMessage: 'Russian'
  }
});


export const messageLocales = featureToggle(FEATURES.MAPI_207) ?
  {
    ...baseMessageLocales,
    ...{he: {
      id: 'locale.he',
      defaultMessage: 'Hebrew'
    }}
  } :
  baseMessageLocales;

const BASE_LANGUAGE_DISPLAY = {
  ENGLISH: 'English - default',
  CHINESE_TRADITIONAL: '繁體中文 - Chinese Traditional',
  HINDI: 'हिंदी - Hindi',
  PORTUGUESE: 'Português - Portuguese',
  FRENCH: 'Français - French',
  DUTCH: 'Nederlands - Dutch',
  RUSSIAN: 'Русский - Russian',
  POLISH: 'Polski - Polish',
  CHINESE_SIMPLIFIED: '简体中文 - Chinese Simplified',
  ITALIAN: 'Italiano - Italian',
  SPANISH: 'Español - Spanish',
  JAPANESE: '日本語 - Japanese',
  GERMAN: 'Deutsch - German'
};

export const LANGUAGE_DISPLAY = featureToggle(FEATURES.MAPI_207) ?
  {
    ...BASE_LANGUAGE_DISPLAY,
    ...{HEBREW: 'עִברִית - Hebrew'}
  } :
  BASE_LANGUAGE_DISPLAY;
  
const baseLocaleMessageOptions = [
  {
    value: LOCALES.EN,
    label: LANGUAGE_DISPLAY.ENGLISH,
  }, {
    value: LOCALES.FR,
    label: LANGUAGE_DISPLAY.FRENCH,
  }, {
    value: LOCALES.ES,
    label: LANGUAGE_DISPLAY.SPANISH,
  }, {
    value: LOCALES.DE,
    label: LANGUAGE_DISPLAY.GERMAN,
  }, {
    value: LOCALES.IT,
    label: LANGUAGE_DISPLAY.ITALIAN,
  }, {
    value: LOCALES.JA,
    label: LANGUAGE_DISPLAY.JAPANESE,
  }, {
    value: LOCALES.PT,
    label: LANGUAGE_DISPLAY.PORTUGUESE,
  }, {
    value: LOCALES.ZH_HANS,
    label: LANGUAGE_DISPLAY.CHINESE_SIMPLIFIED,
  }, {
    value: LOCALES.ZH_HANT,
    label: LANGUAGE_DISPLAY.CHINESE_TRADITIONAL,
  }, {
    value: LOCALES.NL,
    label: LANGUAGE_DISPLAY.DUTCH,
  }, {
    value: LOCALES.PL,
    label: LANGUAGE_DISPLAY.POLISH,
  }, {
    value: LOCALES.HI,
    label: LANGUAGE_DISPLAY.HINDI,
  }, {
    value: LOCALES.RU,
    label: LANGUAGE_DISPLAY.RUSSIAN,
  }
];

export const localeMessageOptions = featureToggle(FEATURES.MAPI_207) ?
  [
    ...baseLocaleMessageOptions,
    {
      value: LOCALES.HE,
      label: LANGUAGE_DISPLAY.HEBREW,
    }
  ] :
  baseLocaleMessageOptions;

const baseLocaleMap = {
  [LOCALES.EN]: 'English',
  [LOCALES.FR]: 'Français',
  [LOCALES.ES]: 'Español',
  [LOCALES.DE]: 'Deutsch',
  [LOCALES.IT]: 'Italiano',
  [LOCALES.JA]: '日本語',
  [LOCALES.PT]: 'Português',
  [LOCALES.ZH_HANS]: '简体中文',
  [LOCALES.ZH_HANT]: '繁體中文',
  [LOCALES.NL]: 'Nederlands',
  [LOCALES.PL]: 'Polski',
  [LOCALES.HI]: 'हिंदी',
  [LOCALES.RU]: 'Русский'
};

export const localeMap = featureToggle(FEATURES.MAPI_207) ?
  {
    ...baseLocaleMap,
    ...{[LOCALES.HE]: 'עִברִית'}
  } :
  baseLocaleMap;