import _ from 'lodash';

import {getThreatZoneLayerIdFromLocationTier} from '../../../../utils/threat-zone-utils';
import {convertTheatZoneDetailToFeatureProperties} from '../../ratings-details/utils';
import {VIEW_THREAT_ZONES} from '../../../../constants/permissions';
import {getBoundsFromFeature} from '../../../../components/Common/Mapbox/utils/mapbox-utils';
import {LAYERS} from '../../../../components/Common/Mapbox/layers/layerIds';

export const THREAT_ZONE_TYPES_VALUES = {
  COUNTRIES: 'THREAT_ZONE_COUNTRIES',
  PROVINCES: 'THREAT_ZONE_PROVINCES',
  CITIES: 'THREAT_ZONE_CITIES'
};

export const THREAT_ZONE_TYPES_MAPPING = [
  {
    id: 'threat_zone_countries',
    permissions: [VIEW_THREAT_ZONES],
    value: THREAT_ZONE_TYPES_VALUES.COUNTRIES
  },
  {
    id: 'threat_zone_provinces',
    permissions: [VIEW_THREAT_ZONES],
    value: THREAT_ZONE_TYPES_VALUES.PROVINCES
  },
  {
    id: 'threat_zone_cities',
    permissions: [VIEW_THREAT_ZONES],
    value: THREAT_ZONE_TYPES_VALUES.CITIES
  }
];

export function parseThreatZonePolygonFeatureResult(polygonFeatures, locale) {
  const items = polygonFeatures?.data?.content;
  return items.map(item => {
    const updatedItem = {
      ...item,
      layer: {
        id: getThreatZoneLayerIdFromLocationTier(item.location?.locationTier)
      },
      properties: convertTheatZoneDetailToFeatureProperties(item, locale)
    };
    updatedItem.geometry = JSON.parse(item.geoJson);
    updatedItem.geoJson = undefined;
    updatedItem.bounds = getBoundsFromFeature(updatedItem);
    return updatedItem;
  });
}

export function filterThreatZoneTypesViaPermissions(hasPermission = {}, values) {
  return THREAT_ZONE_TYPES_MAPPING.reduce((accumulator, item) => {
    const acceptedPermissions = item.permissions
      .filter((permission) => {
        return hasPermission[permission];
      });
    if (values?.includes?.(item.value) && acceptedPermissions.length === item.permissions.length) {
      accumulator.push(item.value);
    }
    return accumulator;
  }, []);
}

export function isThreatZoneFeature(feature) {
  const layerId = _.get(feature, 'layer.id');
  return (
    layerId === LAYERS.THREAT_ZONE_COUNTRY_LAYER_ID ||
    layerId === LAYERS.THREAT_ZONE_PROVINCE_LAYER_ID ||
    layerId === LAYERS.THREAT_ZONE_CITY_LAYER_ID ||
    layerId === LAYERS.THREAT_ZONE_COUNTRY_DETAIL_LAYER_ID ||
    layerId === LAYERS.THREAT_ZONE_PROVINCE_DETAIL_LAYER_ID ||
    layerId === LAYERS.THREAT_ZONE_CITY_DETAIL_LAYER_ID
  );
}
