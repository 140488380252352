import {defineMessages, FormattedMessage} from 'react-intl';

import {VIEW_E_LEARNING, VIEW_EXPATRIATIONS, VIEW_IN_APP_MESSAGE_NOTIFICATIONS} from './permissions';
import {FEATURES} from './features';

import {featureToggle} from '../components/Common/FeatureToggle/FeatureToggleUtils/FeatureToggleUtils';

export const messages = defineMessages({
  // message view types
  intelligence: {
    id: 'constants.communications.intelligence',
    defaultMessage: 'Intelligence'
  },
  account: {
    id: 'constants.communications.account',
    defaultMessage: 'Account'
  },
  mobileCheckIn: {
    id: 'constants.communications.mobileCheckIn',
    defaultMessage: 'Mobile Check-in Notifications'
  },
  trips: {
    id: 'constants.communications.trips',
    defaultMessage: 'Trips'
  },
  travelAlert: {
    id: 'constants.communications.travelAlert',
    defaultMessage: 'Travel Alerts'
  },
  active: {
    id: 'constants.communications.active',
    defaultMessage: 'Active Messages'
  },
  inactive: {
    id: 'constants.communications.inactive',
    defaultMessage: 'Inactive Messages'
  },
  expatriations: {
    id: 'constants.communications.expatriations',
    defaultMessage: 'Expatriations'
  },
  expatriationAlerts: {
    id: 'constants.communications.expatriationAlerts',
    defaultMessage: 'Expatriation Alerts'
  },
  eLearning: {
    id: 'constants.communications.eLearning',
    defaultMessage: 'E-Learning'
  },
  inAppMessageNotifications:{
    id: 'constants.communications.inAppMessageNotifications',
    defaultMessage: 'In-App Message Notifications'

  }
});

export const COMMUNICATION_KEYS = {
  sentDate: 'sentDate',
  subject: 'subject',
  recipient: 'recipient',
  sender: 'sender',
  channels: 'channels',
  type: 'type',
  view: 'view',
  name: 'name',
  direction: 'direction',
  intel: 'intel',
  entityProps: 'entityProps',
  severity: 'severity',
  impact: 'impact',
  senderName: 'senderName',
  messageTitle: 'messageTitle',
  messageSentAt: 'messageSentAt',
  sentAt: 'sentAt',
  authorFirstName: 'authorFirstName',
  authorLastName: 'authorLastName',
  recipientCount: 'recipientCount',
  lastResponseReceivedAt: 'lastResponseReceivedAt',
  responseOption: 'responseOption',
  surveyResponse: 'surveyResponse',
  responseCounts: 'responseCounts',
  negativeResponseCount: 'negativeResponseCount',
  noResponseCount: 'noResponseCount',
  positiveResponseCount: 'positiveResponseCount'
};

export const COMMUNICATION_INTEL = {
  gist: 'gist',
  id: 'id',
  severity: 'severity'
};

export const COMMUNICATION_TYPES = {
  ACCOUNT_ACTIVATION: 'ACCOUNT_ACTIVATION',
  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
  EMAIL_USERNAME_CHANGE: 'EMAIL_USERNAME_CHANGE',
  ALERT_SUBSCRIPTION_EXPIRED: 'ALERT_SUBSCRIPTION_EXPIRED',
  ALERT: 'ALERT',
  DIB: 'DIB',
  RATING_NOTIFICATION: 'RATING_NOTIFICATION',
  ANALYSIS: 'ANALYSIS',
  MOBILE_CHECK_IN: 'MOBILE_CHECK_IN',
  THREAT_ZONE: 'THREAT_ZONE',
  INCIDENT: 'INCIDENT',
  EXPATRIATION_ALERT: 'EXPATRIATION_ALERT',
  E_LEARNING: 'E_LEARNING',
  IN_APP_MESSAGE_NOTIFICATION: 'IN_APP_MESSAGE_NOTIFICATION',
  PRE_TRAVEL_NOTIFICATION: 'PRE_TRAVEL_NOTIFICATION'
};

export const MESSAGE_TYPES = {
  ALERT: 'INTELLIGENCE',
  ACCOUNT: 'ACCOUNT',
  MOBILE_CHECK_IN: 'MOBILE_CHECK_IN',
  TRIP: 'TRIP',
  TRAVEL_ALERT: 'TRAVEL_ALERT',
  ACTIVE: 'ACTIVE',
  EXPATRIATION: 'EXPATRIATION',
  EXPATRIATION_SCHEDULED_NOTIFICATION: 'EXPATRIATION_SCHEDULED_NOTIFICATION',
  PRE_EXPATRIATION_NOTIFICATION: 'PRE_EXPATRIATION_NOTIFICATION',
  EXPATRIATION_COMPLETED_NOTIFICATION: 'EXPATRIATION_COMPLETED_NOTIFICATION',
  EXPATRIATION_ALERT: 'EXPATRIATION_ALERT',
  E_LEARNING: 'E_LEARNING',
  ASC_TRAVELER: 'ASC_TRAVELER',
};

export const COMMUNICATION_CATEGORIES = {
  INTELLIGENCE: {
    id: 'INTELLIGENCE',
    label: <FormattedMessage {...messages.intelligence}/>
  },
  ACCOUNT: {
    id: 'ACCOUNT',
    label: <FormattedMessage {...messages.account}/>
  },
  MOBILE_CHECK_IN: {
    id: 'MOBILE_CHECK_IN',
    label: <FormattedMessage {...messages.mobileCheckIn}/>
  },
  TRIP: {
    id: 'TRIP',
    label: <FormattedMessage {...messages.trips}/>
  },
  TRAVEL_ALERT: {
    id: 'TRAVEL_ALERT',
    label: <FormattedMessage {...messages.travelAlert}/>
  },
  EXPATRIATION: {
    id: 'EXPATRIATION',
    label: <FormattedMessage {...messages.expatriations} />,
    dependentPermission: VIEW_EXPATRIATIONS
  },
  EXPATRIATION_ALERT: {
    id: COMMUNICATION_TYPES.EXPATRIATION_ALERT,
    label: <FormattedMessage {...messages.expatriationAlerts} />,
    dependentPermission: VIEW_EXPATRIATIONS
  },
  ...(
    featureToggle(FEATURES.ELEARN_34)
      ? {
        E_LEARNING: {
          id: COMMUNICATION_TYPES.E_LEARNING,
          label: <FormattedMessage {...messages.eLearning} />,
          dependentPermission: VIEW_E_LEARNING
        }
      }
      : {}
  ),
  ...(
    featureToggle(FEATURES.MOBILE_410)
      ? {
        IN_APP_MESSAGE_NOTIFICATION: {
          id: COMMUNICATION_TYPES.IN_APP_MESSAGE_NOTIFICATION,
          label: <FormattedMessage {...messages.inAppMessageNotifications} />,
          dependentPermission: VIEW_IN_APP_MESSAGE_NOTIFICATIONS
        }
      }
      : {}
  )
};

export const MASS_NOTIFICATION_COMMUNICATION_CATEGORIES = {
  ACTIVE: {
    id: 'ACTIVE',
    label: <FormattedMessage {...messages.active}/>
  },
  INACTIVE: {
    id: 'INACTIVE',
    label: <FormattedMessage {...messages.inactive}/>
  }
};

export const COMMUNICATIONS_MODALITIES = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH_NOTIFICATION',
  SMS: 'SMS',
  TTS: 'TTS',
  TWO_WAY_PUSH: 'PUSH'
};

export const COMMUNICATIONS_FORM_FIELDS = {
  subjectLike: 'subjectLike',
  dateRange: 'dateRange',
  recipientLike: 'recipientLike',
  senderLike: 'senderLike',
  recipientUserId: 'recipientUserId',
  view: 'view',
  types: 'types',
  channels: 'channels',
  personId: 'personId',
  type: 'type',
  messageContent: 'messageContent',
  channelTypes: 'channelTypes',
  authorPersonIds: 'authorPersonIds',
  recipientPersonIds: 'recipientPersonIds',
  clientId: 'clientId',
  correlationId: 'correlationId',
  messageTypes: 'messageTypes',
  messageType: 'messageType'
};

export const AUTO_CHECKIN_RESPONSE_TYPES = {
  NEGATIVE: 'NEGATIVE',
  POSITIVE: 'POSITIVE',
  NO_RESPONSE: 'NO_RESPONSE'
}

export const MESSAGE_DETAILS_KEYS = {
  sentTo: 'sentTo',
  status: 'status',
  name: 'name',
  contactIssues: 'contactIssues',
  person: 'person'
};

export const MESSAGE_HISTORY_TYPES = {
  MASS_NOTIFICATION: 'massNotification',
  ACCOUNT_NOTIFICATIONS: 'accountNotifications'
};

export const COMMUNICATION_MESSAGE_TYPES = {
  MANUAL: 'MANUAL',
  CRITICAL_OUTREACH: 'CRITICAL_OUTREACH',
  ASC_TRAVELER: 'ASC_TRAVELER'
};
