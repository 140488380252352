import _ from 'lodash';

export const LOCATION_TYPES = {
  COUNTRY: 'country'
};

/**
 * Parses the data object of a geo-locations.getLocations API request.
 *
 * The result objects is updated with value and title properties. As this
 * tree is being updated, the node references are also returned in a
 * flattened list and a dictionary to reference by id.
 *
 * @param getLocationsApiResult
 * @returns {{tree: *, list: *, dict}}
 */
export function parseApiResult(getLocationsApiResult) {
  const dict = {};
  const list = [];

  function setLocationsFields(location) {
    const currentLocationId = location.id.internal.toString();
    dict[currentLocationId] = location;
    list.push(location);

    location.value = location.id.internal;
    location.title = location.name;

    // Temp sorting until can be discussed with services/product regarding languages and their sorting
    location.children?.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
      .forEach(location => {
        location.parentId = currentLocationId;
        setLocationsFields(location);
      });
  }

  const tree = _.chain(getLocationsApiResult)
    .get(['data', 'locations'])
    .forEach(location => {
      setLocationsFields(location);
    })
    .value();

  return {
    tree: tree,
    dict: dict,
    list: list
  };
}

/**
 * Given the list of locations, return an alphabetically sorted list of countries only.
 *
 * @param {Array} locationsList
 * @param {boolean} convertLocationIdsToIntegers - The locationIds are strings by default (e.g. "92"). If true, we convert these IDs to integers
 * @returns {Array}
 */
export function getSortedListOfCountries(locationsList, convertLocationIdsToIntegers) {
  let sortedListOfCountries = locationsList
    .filter(location => location.type === LOCATION_TYPES.COUNTRY)
    .sort((a, b) => a?.title.localeCompare(b?.title));

  if (convertLocationIdsToIntegers) {
    sortedListOfCountries = sortedListOfCountries.map(country => ({...country, value: parseInt(country.value, 10)}));
  }

  return sortedListOfCountries;
}

/**
 * Returns the string name (title) corresponding to the
 * location found in the dictionary with the provided id.
 *
 * @param locationDict
 * @param locationId
 * @returns {*}
 */
export function getLocationName(locationDict, locationId) {
  return _.get(locationDict, [locationId, 'title']);
}

/**
 * Returns the string names (titles) corresponding to the
 * locations found in the dictionary for the provided locationIds.
 *
 * @param locationDict
 * @param locationIds
 * @returns {*}
 */
export function getLocationNames(locationDict, locationIds) {
  return locationIds.map(locationId => getLocationName(locationDict, locationId));
}

