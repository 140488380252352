import * as types from './types';
import {LOCATION_CHANGE} from 'connected-react-router';
import {SET_CURRENT_PERSONA} from '../session/types';

export const INITIAL_STATE = {
  messageHistory: false,
  alertsIncidents: false,
  mapSelection: false,
  mapDetail: false,
  mapProvince: false,
  mapPolygon: false,
  mapMenu: false,
  mapPersonsList: false,
  mapSitesList: false,
  mapOccupantsList: false,
  composeMessage: false
};

const drawersReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {

    case types.CLOSE_DRAWER:
      return {
        ...state,
        [action.payload.drawer]: false
      };

    case types.OPEN_DRAWER:
      return {
        ...state,
        [action.payload.drawer]: true
      };

    case types.TOGGLE_DRAWER:
      return {
        ...state,
        [action.payload.drawer]: !state[action.payload.drawer]
      };

    case types.OPEN_EXCLUSIVE:
      return {
        ...state,
        messageHistory: false,
        alertsIncidents: false,
        mapSelection: false,
        mapDetail: false,
        mapMenu: false,
        mapPolygon: false,
        mapProvince: false,
        mapPersonsList: false,
        mapSitesList: false,
        mapOccupantsList: false,
        composeMessage: false,
        [action.payload.drawer]: true
      };

    case types.TOGGLE_EXCLUSIVE:
      return {
        ...state,
        messageHistory: false,
        alertsIncidents: false,
        mapSelection: false,
        mapDetail: false,
        mapProvince: false,
        mapMenu: false,
        mapPolygon: false,
        mapPersonsList: false,
        mapSitesList: false,
        mapOccupantsList: false,
        composeMessage: false,
        [action.payload.drawer]: !state[action.payload.drawer]
      };

    case types.OPEN_MAP_DETAIL_EXCLUSIVE:
      return {
        ...state,
        messageHistory: false,
        alertsIncidents: false,
        mapDetail: false,
        mapMenu: false,
        mapProvince: false,
        [action.payload.drawer]: true
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        messageHistory: false,
        alertsIncidents: false,
        mapMenu: false,
        mapPolygon: false,
        mapProvince: false,
        mapPersonsList: false,
        mapSitesList: false,
        mapOccupantsList: false,

        // temporary - commenting out because drawers close when toast messages are displayed (MessageRecipientsDrawer)
        // composeMessage: false
      };

    case SET_CURRENT_PERSONA:
    case types.CLOSE_ALL:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default drawersReducer;
