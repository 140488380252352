export const INITIALIZE = 'login/INITIALIZE';

export const SIGN_IN_PASSWORD_REQUEST = 'login/SIGN_IN_PASSWORD_REQUEST';
export const SIGN_IN_PASSWORD_SUCCESS = 'login/SIGN_IN_PASSWORD_SUCCESS';
export const SIGN_IN_PASSWORD_FAILURE = 'login/SIGN_IN_PASSWORD_FAILURE';

// users for forgotten password temporary code
export const START_PASSWORD_RESET_REQUEST = 'login/START_PASSWORD_RESET_REQUEST';
export const START_PASSWORD_RESET_SUCCESS = 'login/START_PASSWORD_RESET_SUCCESS';
export const START_PASSWORD_RESET_FAILURE = 'login/START_PASSWORD_RESET_FAILURE';
export const COMPLETE_PASSWORD_RESET_REQUEST = 'login/COMPLETE_PASSWORD_RESET_REQUEST';
export const COMPLETE_PASSWORD_RESET_SUCCESS = 'login/COMPLETE_PASSWORD_RESET_SUCCESS';
export const COMPLETE_PASSWORD_RESET_FAILURE = 'login/COMPLETE_PASSWORD_RESET_FAILURE';

// USED for admin password reset temporary password
export const SUBMIT_NEW_PASSWORD_REQUEST = 'login/SUBMIT_NEW_PASSWORD_REQUEST';
export const SUBMIT_NEW_PASSWORD_SUCCESS = 'login/SUBMIT_NEW_PASSWORD_SUCCESS';
export const SUBMIT_NEW_PASSWORD_FAILURE = 'login/SUBMIT_NEW_PASSWORD_FAILURE';

export const FEDERATED_SIGN_IN_REQUEST = 'login/FEDERATED_SIGN_IN_REQUEST';
export const FEDERATED_SIGN_IN_SUCCESS = 'login/FEDERATED_SIGN_IN_SUCCESS';
export const FEDERATED_SIGN_IN_FAILURE = 'login/FEDERATED_SIGN_IN_FAILURE';

export const START_REGISTRATION = 'login/START_REGISTRATION';
export const START_MEMBER_ID_REGISTRATION = 'login/START_MEMBER_ID_REGISTRATION';
export const START_NON_MEMBER_ID_REGISTRATION = 'login/START_NON_MEMBER_ID_REGISTRATION';
export const COMPLETE_REGISTRATION_REQUEST = 'login/COMPLETE_REGISTRATION_REQUEST';
export const COMPLETE_REGISTRATION_SUCCESS = 'login/COMPLETE_REGISTRATION_SUCCESS';
export const COMPLETE_REGISTRATION_DELAYED = 'login/COMPLETE_REGISTRATION_DELAYED';
export const COMPLETE_REGISTRATION_FAILURE = 'login/COMPLETE_REGISTRATION_FAILURE';

export const NEW_PASSWORD_REQUIRED = 'login/NEW_PASSWORD_REQUIRED';
export const LOGIN_COMPLETION = 'login/LOGIN_COMPLETION';
export const START_PASSWORD_RESET = 'login/START_PASSWORD_RESET';
export const SUBMIT_USERNAME = 'login/SUBMIT_USERNAME';
export const SET_STEP = 'login/SET_STEP';

export const GET_COBRANDING_INFO_REQUEST = 'login/GET_COBRANDING_INFO_REQUEST';
export const GET_COBRANDING_INFO_SUCCESS = 'login/GET_COBRANDING_INFO_SUCCESS';
export const GET_COBRANDING_INFO_FAILURE = 'login/GET_COBRANDING_INFO_FAILURE';

export const AUTO_SIGN_IN_FAILURE = 'login/AUTO_SIGN_IN_FAILURE';

export const RESEND_TEMPORARY_CREDENTIALS_REQUEST = 'login/RESEND_TEMPORARY_CREDENTIALS_REQUEST';
export const RESEND_TEMPORARY_CREDENTIALS_SUCCESS = 'login/RESEND_TEMPORARY_CREDENTIALS_SUCCESS';
export const RESEND_TEMPORARY_CREDENTIALS_FAILURE = 'login/RESEND_TEMPORARY_CREDENTIALS_FAILURE';

export const LOADING = 'login/LOADING';

export const REGISTRATION_LINK_REQUEST = 'login/REGISTRATION_LINK_REQUEST';
export const REGISTRATION_LINK_SUCCESS = 'login/REGISTRATION_LINK_SUCCESS';
export const REGISTRATION_LINK_FAILURE = 'login/REGISTRATION_LINK_FAILURE';

export const FSSO_LOGIN = 'login/FSSO_LOGIN';

