import MapLayer from '../MapLayer';

export const THREAT_ZONE_COUNTRY_DETAIL_SOURCE_BOUNDARY_ID = 'threat-zone-country-detail-source-boundary';
export const THREAT_ZONE_PROVINCE_DETAIL_SOURCE_BOUNDARY_ID = 'threat-zone-province-detail-source-boundary';
export const THREAT_ZONE_CITY_DETAIL_SOURCE_BOUNDARY_ID = 'threat-zone-city-detail-source-boundary';
export const THREAT_ZONE_COUNTRY_DETAIL_LAYER_ID = 'threat-zone-country-detail-layer-boundary';
export const THREAT_ZONE_PROVINCE_DETAIL_LAYER_ID = 'threat-zone-province-detail-layer-boundary';
export const THREAT_ZONE_CITY_DETAIL_LAYER_ID = 'threat-zone-city-detail-layer-boundary';

function addLayer(map, layerDef) {
  map.addLayer(layerDef, 'regional_zones');
}

export default class ThreatZoneDetailLayer extends MapLayer {
  ratingId;
  levels;
  mapType;
  organizationId;
  layerId;
  data;

  constructor(organizationId, layerId, sourceId) {
    super();
    this.data = false;
    this.organizationId = organizationId;
    this.layerId = layerId;
    this.sourceId = sourceId;
  }

  getInteractiveLayerIds() {
    return [];
  }

  getLayerIds() {
    return [this.layerId];
  }

  createSources() {
    if (this.map) {
      this.map.addSource(this.sourceId, {
        type: 'geojson',
        data: {'type': 'FeatureCollection', 'features': []}
      });
    }
  }

  createLayers() {
    if (this.map) {
      addLayer(this.map, {
        'id': this.layerId, // Layer ID
        'type': 'fill',
        'source': this.sourceId, // ID of the tile source created above
        'paint': {
          'fill-opacity':
          [
            'step',
            ['zoom'],
            1, 16,
            0
          ]
        }
      });

      this.updateStyling();
    }
  }

  setRatingFilters(ratingId, levels, mapType) {
    if (this.ratingId !== ratingId || this.levels !== levels || this.mapType !== mapType) {
      this.ratingId = ratingId;
      this.levels = levels;
      this.mapType = mapType;

      this.updateStyling();
    }
  }

  updateStyling() {
    if (this.map && this.levels && this.levels.length > 0) {

      const currentRatingProperty = ['get', 'rating'];

      this.map.setPaintProperty(this.layerId, 'fill-pattern', [
        'step',
        ['to-number', currentRatingProperty, 0],
        '',
        1, 'diag_minimal_greenblue_v1',
        2, 'diag_low_green_v1',
        3, 'diag_moderate_yellow_v1',
        4, 'diag_high_orange_v1',
        5, 'diag_extreme_red_v1'
      ]);
    }
  }

  setData(data) {
    if (this.data !== data) {
      this.data = data || false;

      if (this.map) {
        this.map.getSource(this.sourceId)?.setData({'type': 'FeatureCollection', 'features': this.data});
      }
    }
  }
}
