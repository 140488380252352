import waRequest from './wa-request';

export const baseApi = '/zendesk/v1';

function getZendeskWidgetToken() {
  const url = `${baseApi}/get-widget-token`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getZendeskHelpUrl() {
  const url = `${baseApi}/get-url`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

export default {
  getZendeskWidgetToken,
  getZendeskHelpUrl
};
