import PropTypes from 'prop-types';
import classNames from 'classnames';

import FieldLabel from '../FieldLabel/FieldLabel';
import InfoPopoverIcon from '../InfoPopoverIcon/InfoPopoverIcon';

import './InfoLabel.css';

function InfoLabel(props) {

  const {
    formItemClass = true,
    className,
    labelBold = true,
    label,
    textPostLabel,
    info,
    maxWidth,
    placement,
    secondary,
    clearfix = true,
    disabled = false
  } = props;

  return (
    <span className={classNames("InfoLabel",  {'ant-form-item': formItemClass})}>
      <FieldLabel
        label={label}
        labelBold={labelBold}
        textPostLabel={textPostLabel}
        className={className}
        disabled={disabled}
      />

      {info && (
        <InfoPopoverIcon
          content={info}
          maxWidth={maxWidth}
          placement={placement}
        />
      )}

      <span className="secondary">
        {secondary}
      </span>

      {clearfix && <span className="clearfix"/>}
    </span>
  )
}

InfoLabel.propTypes = {
  label: PropTypes.node.isRequired,
  textPostLabel: PropTypes.node,
  info: PropTypes.node,
  secondary: PropTypes.element,
  labelBold: PropTypes.bool,
  clearfix: PropTypes.bool,
  placement: PropTypes.string,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  formItemClass: PropTypes.bool
}

export default InfoLabel;