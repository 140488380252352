import waRequest from './wa-request';

const searchRequestSingleton = waRequest.createRequestSingleton();

/**
 * Initiate authenticated GET /locations/v1/locations
 *
 * @returns {Promise}
 */
function getLocations() {
  const url = '/locations/v1/locations';

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      fields: 'id,parentId,name,type',
      size: 3000
    }
  });
}

/**
 * Initiate authenticated GET /locations/v1/locations/{locationId}
 *
 * @param locationId
 * @returns {Promise}
 */
function getLocation(locationId) {
  const url = `/locations/v1/locations/${locationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 *  Initiate authenticated GET /locations/v1/locations/search/${searchString}
 *
 * @param searchString
 * @returns {Promise<any>}
 */
function locationSearch(searchString) {
  const url = `/locations/v1/locations/search/${searchString}`;

  return searchRequestSingleton({
    url: url,
    method: 'get'
  });
}

export default {
  getLocation,
  getLocations,
  locationSearch
};

