import {
  ALERT_DETAIL_POINTS_LAYER_ID,
  ALERT_DETAIL_LINES_LAYER_ID,
  ALERT_DETAIL_POLYGONS_LAYER_ID,
  NON_MAP_AFFECTED_PEOPLE_LAYER_ID,
  NON_MAP_AFFECTED_PEOPLE_AT_SITES_LAYER_ID,
  NON_MAP_AFFECTED_SITES_LAYER_ID
} from './alert/AlertDetailLayer';

import {RATINGS_COUNTRY_LAYER_ID} from './rating/CountryRatingsLayer';
import {RATINGS_PROVINCE_LAYER_ID} from './rating/ProvinceRatingsLayer';
import {RATINGS_CITY_LAYER_POINT_ID, RATINGS_CITY_LAYER_BOUNDARY_ID} from './rating/CityRatingsLayer';

import {PERSON_LAYER_ID} from './person/PersonLayer';
import {PERSON_DETAIL_LAYER_ID} from './person/PersonDetailLayer';

import {THREAT_ZONE_LAYER_ID} from './threat-zone/ThreatZoneLayer';
import {
  THREAT_ZONE_COUNTRY_DETAIL_LAYER_ID,
  THREAT_ZONE_PROVINCE_DETAIL_LAYER_ID,
  THREAT_ZONE_CITY_DETAIL_LAYER_ID
} from './threat-zone/ThreatZoneDetailLayer';
import {THREAT_ZONE_COUNTRY_LAYER_ID} from './threat-zone/CountryThreatZoneLayer';
import {THREAT_ZONE_PROVINCE_LAYER_ID} from './threat-zone/ProvinceThreatZoneLayer';
import {THREAT_ZONE_CITY_LAYER_ID} from './threat-zone/CityThreatZoneLayer';

import {SITE_LAYER_ID} from './site/SiteLayer';
import {SITE_DETAILS_LAYER_ID} from './site/SiteDetailsLayer';

import {
  POLYGON_SELECTION_LAYER,
  POLYGON_BOUNDS_LAYER
} from '../controls/PolygonManager';
import {ALERT_CLUSTER_LAYER_ID, ALERT_LAYER_ID} from '../../../../constants/map';
import {CRISIS_SIGNAL_LAYER_ID} from './person/CrisisSignalLayer/CrisisSignalLayer';


export const POLYGON_LAYERS = [
  POLYGON_SELECTION_LAYER,
  POLYGON_BOUNDS_LAYER
];

export const ALERT_LAYERS = [
  ALERT_LAYER_ID,
  ALERT_CLUSTER_LAYER_ID
];

export const GEODATA_LIST_LAYERS = [
  POLYGON_SELECTION_LAYER,
  POLYGON_BOUNDS_LAYER
];

export const LAYERS = {
  ALERT_LAYER_ID,
  ALERT_CLUSTER_LAYER_ID,
  ALERT_DETAIL_POINTS_LAYER_ID,
  ALERT_DETAIL_LINES_LAYER_ID,
  ALERT_DETAIL_POLYGONS_LAYER_ID,
  POLYGON_SELECTION_LAYER,
  POLYGON_BOUNDS_LAYER,
  RATINGS_COUNTRY_LAYER_ID,
  RATINGS_PROVINCE_LAYER_ID,
  RATINGS_CITY_LAYER_POINT_ID,
  RATINGS_CITY_LAYER_BOUNDARY_ID,
  PERSON_LAYER_ID,
  PERSON_DETAIL_LAYER_ID,
  CRISIS_SIGNAL_LAYER_ID,
  THREAT_ZONE_LAYER_ID,
  THREAT_ZONE_COUNTRY_LAYER_ID,
  THREAT_ZONE_PROVINCE_LAYER_ID,
  THREAT_ZONE_CITY_LAYER_ID,
  THREAT_ZONE_COUNTRY_DETAIL_LAYER_ID,
  THREAT_ZONE_PROVINCE_DETAIL_LAYER_ID,
  THREAT_ZONE_CITY_DETAIL_LAYER_ID,
  NON_MAP_AFFECTED_PEOPLE_LAYER_ID,
  NON_MAP_AFFECTED_PEOPLE_AT_SITES_LAYER_ID,
  NON_MAP_AFFECTED_SITES_LAYER_ID,
  SITE_LAYER_ID,
  SITE_DETAILS_LAYER_ID
};

export default LAYERS;
