import * as types from './types';

export const INITIAL_STATE = {
  loadingReports: false,
  dashboardList: []
}
const reportsReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case types.GET_REPORTS_REQUEST:
      return {
        ...state,
        loadingReports: true,
        dashboardList: []
      }
    case types.GET_REPORTS_SUCCESS:
      return {
        ...state,
        loadingReports: false,
        dashboardList: action.payload
      }
    case types.GET_REPORTS_FAILURE:
      return {
        ...state,
        loadingReports: false,
        dashboardList: []
      }
    default:
      return state;
  }
}

export default reportsReducer;
