import {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';

import {ConfigProvider} from 'antd';

import {localizationActions} from '../../redux/modules/localization';

import {featureToggle} from '../../components/Common/FeatureToggle/FeatureToggleUtils/FeatureToggleUtils';
import {FEATURES} from '../../constants/features';
import {LOCALES} from '../../constants/locale';

export class LocalizationProvider extends Component {

  static propTypes = {
    locale: PropTypes.string,
    antd: PropTypes.object,
    messages: PropTypes.object,
    initialize: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };

  componentDidMount() {
    this.props.initialize();
  }

  render() {
    return (
      <IntlProvider locale={this.props.locale} messages={this.props.messages} textComponent="span">
        <ConfigProvider locale={this.props.antd} direction={(this.props.locale === LOCALES.HE && featureToggle(FEATURES.MAPI_223))? "rtl" : "ltr"}>
          {this.props.children}
        </ConfigProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({localization}) => ({
  locale: localization.locale,
  messages: localization.messages,
  antd: localization.antd
});

const mapDispatchToProps = dispatch => ({
  initialize: () => dispatch(localizationActions.initialize())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalizationProvider);
