import waRequest from './wa-request';

/**
 * Initiate authenticated GET /xsrf
 *
 * @returns {Promise}
 */
function requestCsrfToken() {
  const url = `/xsrf`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

/**
 * Initiate authenticated GET /xsrf/ping
 * Simple service for testing csrf token validation
 * @returns {Promise}
 */
function getCsrfPing() {
  const url = `/xsrf/ping`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
  });
}

export default {
  requestCsrfToken,
  getCsrfPing
};
