import {defineMessages, FormattedMessage} from 'react-intl';

export const PEOPLE = 'people';
export const WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE = 'personFilter';

export const EXPOSURE_TIME_PERIOD = {
  NOW: 'now',
  NOW_THROUGH_END: 'nowThroughEnd',
  ORIGINAL_THROUGH_END: 'originalThroughEnd',
  CUSTOM_DURATION: 'customDuration'
};

export const EXPOSURE_TYPE = {
  ORIGINAL_NOW_THROUGH_END: 'ORIGINAL_NOW_THROUGH_END',
  NOW: 'NOW',
  NOW_THROUGH_END: 'NOW_THROUGH_END'
};

const messages = defineMessages({
  now: {
    id: 'person-filters.now',
    defaultMessage: 'Now'
  },
  throughEnd: {
    id: 'person-filters.throughEnd',
    defaultMessage: 'Now Through End'
  },
  occupants: {
    id: 'person-filters.occupants',
    defaultMessage: 'Occupants'
  }
});

export const EXPOSURE_RANGE = {
  NOW: {
    value: EXPOSURE_TIME_PERIOD.NOW,
    label: <FormattedMessage {...messages.now}/>,
    serviceEnum: EXPOSURE_TYPE.NOW
  },
  THROUGH_END: {
    value: EXPOSURE_TIME_PERIOD.NOW_THROUGH_END,
    label: <FormattedMessage {...messages.throughEnd}/>,
    serviceEnum: EXPOSURE_TYPE.NOW_THROUGH_END
  },
  OCCUPANTS: {
    value: EXPOSURE_TIME_PERIOD.NOW_THROUGH_END,
    label: <FormattedMessage {...messages.occupants}/>,
    serviceEnum: EXPOSURE_TYPE.NOW_THROUGH_END
  }
};
