import {
  VIEW_CITY_INTEL,
  VIEW_CITY_RATINGS,
  VIEW_COUNTRY_INTEL,
  VIEW_COUNTRY_RATINGS,
  VIEW_PROVINCE_INTEL,
  VIEW_PROVINCE_RATINGS
} from './permissions';

export const WORLD_MAP_RATINGS_FILTER_STORAGE_KEY_BASE = 'ratingsFilter';

export const MAP_RATINGS = 'mapRatings';
export const MAP_RATINGS_CATEGORY = 'mapRatingsCategory';
export const MAP_RATINGS_LEVELS = 'mapRatingsLevels';
export const MAP_RATINGS_ORGS = 'mapRatingsOrgs';

export const RATINGS_DEFAULT_RANGE = [1, 5];

export const LOCATION_TIERS = {
  PROVINCE: 'Province',
  COUNTRY: 'Country',
  CITY: 'City'
};
export const LOCATION_TIERS_ID = {
  PROVINCE: 5,
  COUNTRY: 3,
  CITY: 6
};
export const LOCATION_TIERS_VIEW_INTEL_PERMISSIONS_MAP_IDS = {
  [LOCATION_TIERS_ID.COUNTRY]: VIEW_COUNTRY_INTEL,
  [LOCATION_TIERS_ID.PROVINCE]: VIEW_PROVINCE_INTEL,
  [LOCATION_TIERS_ID.CITY]: VIEW_CITY_INTEL
};
export const LOCATION_TIERS_PERMISSIONS_MAP = {
  [LOCATION_TIERS.COUNTRY]: VIEW_COUNTRY_RATINGS,
  [LOCATION_TIERS.PROVINCE]: VIEW_PROVINCE_RATINGS,
  [LOCATION_TIERS.CITY]: VIEW_CITY_RATINGS
};
export const constants = {
  RATINGS: 'ratings',
  LOCATION: 'location',
  LOCATION_NAME: 'locationName',
  LOCATION_TIER: 'locationTier',
  PARENT_LOCATION: 'parentLocation',
  PARENT_LOCATION_NAME: 'parentLocationName',
  NAME: 'name',
  ID_PROP: 'id',
  ASSESSMENT: 'assessment',
  ASSESSMENT_NAME: 'assessmentName',
  ALL: 'ALL',
  SECURITY: 'SECURITY',
  HEALTH: 'HEALTH',
  CITY_PLUS_COUNTRY: 'CITY_PLUS_COUNTRY',
  PROVINCE_PLUS_COUNTRY: 'PROVINCE_PLUS_COUNTRY',
};

export const CLASSNAMES = {
  MINIMAL: 'minimal',
  LOW: 'low',
  MODERATE: 'moderate',
  HIGH: 'high',
  EXTREME: 'extreme',
  UNRATED: 'unrated'
};
