import * as types from './types';

const organizationConfigurationsReducer = (
  state = {
    configurationsData: {},
    resourcesLoading: false,
    resources: [],
    addConfigurationError: null
  },
  action
) => {
  switch (action.type) {
    case types.ADD_CONFIGURATION_REQUEST:
    case types.ADD_CONFIGURATION_ITEM_REQUEST:
    case types.UPDATE_CONFIGURATION_REQUEST:
    case types.UPDATE_CONFIGURATION_ITEM_REQUEST:
    case types.GET_CONFIGURATION_REQUEST:
    case types.GET_CONFIGURATION_ITEM_REQUEST:
      return {
        ...state,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            data: {},
            loading: true
          }
        }
      };
    case types.GET_MOBILE_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            data: [],
            loading: true
          }
        }
      };
    case types.GET_MOBILE_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            loading: false
          }
        }
      };
    
    case types.UPDATE_CONFIGURATION_ITEM_SUCCESS:
    case types.GET_CONFIGURATION_SUCCESS:
    case types.GET_CONFIGURATION_ITEM_SUCCESS:
    case types.GET_MOBILE_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            data: action.payload.data,
            loading: false
          }
        }
      };
    case types.ADD_CONFIGURATION_SUCCESS:
    case types.UPDATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        addConfigurationError: null,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            data: action.payload.data,
            loading: false
          }
        }
      };
    case types.ADD_CONFIGURATION_FAILURE:
      return {
        ...state,
        addConfigurationError: action.payload.errorMessage,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            loading: false
          }
        }
      };

    case types.UPDATE_CONFIGURATION_FAILURE:
      return {
        ...state,
        addConfigurationError: action.payload.errorMessage,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            data: {},
            loading: false
          }
        }
      };
    case types.ADD_CONFIGURATION_ITEM_FAILURE:
      return {
        ...state,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            loading: false
          }
        }
      };
  
    case types.GET_CONFIGURATION_FAILURE:
    case types.GET_CONFIGURATION_ITEM_FAILURE:
    case types.UPDATE_CONFIGURATION_ITEM_FAILURE:
    case types.DELETE_CONFIGURATION_FAILURE:
    case types.DELETE_CONFIGURATION_ITEM_FAILURE:
    case types.CLEAR_CONFIGURATION_ITEM:
      return {
        ...state,
        configurationsData: {
          ...state.configurationsData,
          [action.payload.configType]: {
            data: {},
            loading: false
          }
        }
      };

    case types.GET_RESOURCES_REQUEST:
      return {
        ...state,
        resourcesLoading: true
      }

    case types.GET_RESOURCES_SUCCESS:
      return {
        ...state,
        resourcesLoading: false,
        resources: action.payload.data
      }

    case types.GET_RESOURCES_FAILURE:
      return {
        ...state,
        resourcesLoading: false
      }

    default:
      return state;
  }
};

export default organizationConfigurationsReducer;
