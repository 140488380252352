import history from './history';

/**
 * Configures listener to resetScroll on history changes
 */
function listenForPageChanges() {
  history.listen(() => {
    resetPageScroll();
  });
}

/**
 * Resets the scroll of window and .page-wrapper elements to 0
 */
function resetPageScroll() {
  try {
    window.scrollTo(0, 0);
    window.document.querySelector('.page-wrapper').scrollTop = 0;
  }
  catch (error) {
    //do nothing
  }
}

export default {
  listenForPageChanges,
  resetPageScroll
};
