import {useMemo} from 'react';

import selfRegistrationApi from '../../../../services/api/self-registration';
import useBaseReadServiceHook from '../../useBaseReadServiceHook';

/**
 *
 * @param filter
 * @returns {{cancel: ((function(): void)|*), loading: boolean, results: unknown, sync: (function(): number)}}
 */
export default function useSelfRegistrationPoliciesExists(filter) {
  const config = useMemo(() => {
    if (filter) {
      return {
        apiCall: selfRegistrationApi.getSelfRegistrationPoliciesExists,
        params: filter
      };
    }
  }, [filter]);

  return useBaseReadServiceHook(config);
}