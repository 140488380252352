import {INTEL_FORM_FIELDS} from "./intelligence";

export const ORG_RESOURCES_FORM_FIELDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  RESOURCE_DATA: 'resourceData',
  EMAIL: 'email',
  NUMBER: 'number',
  USEFUL_LINKS: 'usefulLinks',
  LOCATION_DETAILS: 'locationDetails',
  LOCATIONS: 'locations',
  LINK: 'link',
  APP_TYPE: 'appType',
  APP_URL: 'appURL',
  APP_ID: 'appId',
  SITES_CHECKBOX: 'sitesCheckbox',
  ORGS: 'orgs',
  SITES: 'sites'
};

export const MOBILE_APP_TYPES = {
  ANDROID: 'ANDROID',
  IOS: 'IOS'
}

export const ORG_RESOURCES_API_FIELDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  EMAIL: 'email',
  USEFUL_LINKS: 'usefulLinks',
  LANGUAGE_VERSION: 'languageVersions',
  SITES: 'sites',
  RESOURCE_DATA: 'resourceData',
  NUMBER: 'number',
  PHONE: 'number',
  APP_URL: 'appURL',
  LINK: 'link',
  WEBSITE: 'link',
  MOBILE_APP: 'appURL',
  LOCATIONS: 'locations',
  AUDIT: 'audit',
  LAST_UPDATED_AT: 'lastUpdatedAt',
  ID: 'id'
};

export const NOTICES_API_FIELDS = {
  NAME: 'name',
  RATING_CUT_OFF:'ratingCutoff',
  LOCALIZED_STATUS:'localizedStatus',
  MODIFIED_DATE: 'modifiedDate',
  MODIFIED_BY: 'modifiedBy',
  LOCATIONS: 'locations',
  CATEGORIES: 'categories',
  ID: 'id',
  DATE_RANGE: 'dateRange',
  DESCRIPTION: 'description',
  NOTICE_STATUS: 'noticeStatus',
  CONTENT: 'content',
  ORGANIZATION_ID: 'organizationId',
  LOCATION_IDS: INTEL_FORM_FIELDS.LOCATION_IDS,
  CATEGORY_IDS: INTEL_FORM_FIELDS.CATEGORY_IDS,
  BEGIN_DATE: 'beginDate',
  END_DATE: 'endDate',
  LANGUAGE_CODE: 'code',
  PERSON_TYPE: 'personTypes'
};

export const NOTICE_STATUS_TYPES = {
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  DEACTIVATED: 'Deactivated'
}

export const RESOURCE_TYPE = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  WEBSITE: 'WEBSITE',
  MOBILE_APP: 'MOBILE_APP',
  DOCUMENT: 'DOCUMENT',
  NOTICE: 'NOTICE'
};
