import {isValidElement} from 'react';
import _ from 'lodash';

import Notification from 'antd/lib/notification';
import {Button} from 'antd';

import localization from '../../../services/localization';
import ContextProvider from '../../App/ContextProvider/ContextProvider';

const TRANSLATED_FIELDS = ['message', 'description', 'buttonLabel'];

Notification.config({
  placement: 'bottomRight'
});

/**
 * If a value is an intl message (with id and defaultMessage values)
 * it is translated into a string using localization.formatMessage.
 *
 * If a value is a React element it is wrapped in a ContextProvider.
 *
 * Otherwise the value is passed through and returned.
 *
 * @param value
 * @returns {string|*}
 */
const translateIfNeeded = (value) => {
  if (localization.isIntlMessageObject(value)) {
    return localization.formatMessage(value);
  }
  else if (isValidElement(value)) {
    return <ContextProvider>{value}</ContextProvider>;
  }

  return value;
};

/**
 * Maps the values of notification props and passes the configured
 * TRANSLATED_FIELDS values to translateIfNeeded
 *
 * @param props
 * @returns {{}}
 */
const translateNotificationProps = (props) => {
  return _.mapValues(props, (value, field) => {

    if (_.includes(TRANSLATED_FIELDS, field)) {
      return translateIfNeeded(value);
    }

    return value;
  });
};

/**
 * Abstraction of AntD Notification.warning that translates intl content
 * using the localization service and wraps in ContextProvider.
 *
 * @param props
 */
const warning = (props) => {
  Notification.warning(translateNotificationProps(props));
};

/**
 * Abstraction of AntD Notification.error that translates intl content
 * using the localization service and wraps in ContextProvider.
 *
 * @param props
 */
const error = (props) => {
  Notification.error(translateNotificationProps(props));
};

/**
 * Abstraction of AntD Notification.info that translates intl content
 * using the localization service and wraps in ContextProvider.
 *
 * @param props
 */
const info = (props) => {
  Notification.info(translateNotificationProps(props));
};

/**
 * Abstraction of AntD Notification.success that translates intl content
 * using the localization service and wraps in ContextProvider.
 *
 * @param props
 */
const success = (props) => {
  const translatedNotification = translateNotificationProps(props);
  if (props.buttonLabel && props.downloadCallback) {
    translatedNotification.btn = (
      <Button type="primary" size="small" onClick={() => {
        props.downloadCallback();
        Notification.close(props.key);
      }
      }>{translatedNotification.buttonLabel}</Button>
    );
  }
  Notification.success(translatedNotification);
};

/**
 * Abstraction of AntD Notification.open that translates intl content
 * using the localization service and wraps in ContextProvider.
 *
 * @param props
 */
const open = (props) => {
  Notification.open(translateNotificationProps(props));
};

export default {
  translateIfNeeded,
  open,
  info,
  error,
  warning,
  success
};
