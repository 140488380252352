import * as types from './types';
import localization from '../../../services/localization';

const localizationReducer = (
  state = {
    locale: localization.locale,
    language: localization.language,
    languageName: undefined,
    messages: localization.messages,
    antd: localization.antd,
    intl: localization.intl,
    intelLocale: undefined,
    intelMessages: undefined
  },
  action
) => {
  switch (action.type) {

    case types.LOCALE_SET:
      return {
        ...state,
        locale: action.payload.locale,
        language: action.payload.language,
        languageName: action.payload.languageName,
        messages: action.payload.messages,
        antd: action.payload.antd,
        intl: action.payload.intl
      };

    case types.INTEL_LOCALE_SET:
      return {
        ...state,
        intelLocale: action.payload.intelLocale,
        intelMessages: action.payload.intelMessages
      };

    case types.INTEL_LOCALE_RESET:
      return {
        ...state,
        intelLocale: undefined,
        intelMessages: undefined
      };      

    default:
      return state;
  }
};

export default localizationReducer;
