/************************************************************************
 *
 * WorldAware Request Utilities
 *
 * A collection of request related methods used to make request
 * against WorldAware authenticated endpoints and manage some common
 * scenarios around pagination, retry, and race conditions.
 *
 ************************************************************************/

import retry from 'retry-as-promised';
import _ from 'lodash';

import waHttp from './wa-http';
import authStorage from './auth-storage';
import authentication from './authentication';

/**
 * Handles adding the WA Authorization token header
 * and also changes the way array params are serialized
 * to conform with WA APIs
 *
 * @param requestConfig
 * @param authRetry
 * @returns {*}
 */
function requestAuthenticated(requestConfig, authRetry = true) {
  return addAuthToConfig(requestConfig)
    .then(config => {
      return waHttp.httpRequest(config)
        .catch((error) => {
          const errorCode = _.get(error, 'response.status');

          // Logout on authentication errors
          if (errorCode === 401) {
            if (authRetry) {
              return requestAuthenticated(requestConfig, false);
            }
            authentication.logout();
          }
          throw(error);
        });
    });
}

/**
 * Creates a function that wraps requestAuthenticated that cancels
 * any outstanding requests made with this function (regardless of config).
 * This helps address slow request race conditions to ensure the last request made
 * is the one we ultimately respond to.
 *
 * If a call is canceled, it resolves with prop canceled = true
 *
 * usage:
 *
 * const requestSingleton = createRequestSingleton();
 *
 * requestSingleton({url:'someConfig'})
 *  .then(result => {
 *    if(result.canceled){
 *      // call was canceled
 *    }
 *    else {
 *      // call resolved
 *    }
 *  })
 *
 * @returns {function(*): Promise<any>}
 */
function createRequestSingleton() {
  let cancel;

  return (requestConfig) => {

    // Cancel outstanding request if exists
    if (cancel) {
      cancel();
      cancel = null;
    }

    return new Promise((resolve, reject) => {

      const configWithCancel = {
        ...requestConfig,
        cancelToken: waHttp.getRequestCancelToken((c) => cancel = c)
      };

      // Make new request with cancelTokenIncluded
      requestAuthenticated(configWithCancel)
        .then((result) => {
          cancel = null;
          resolve(result);
        })
        .catch((error) => {
          if (error.constructor.name === 'Cancel' || error.__CANCEL__ === true) {
            resolve({canceled: true});
          }
          else {
            reject(error);
          }
        });
    });
  };
}

/**
 * Creates a request function that wraps requestAuthenticated and a cancel
 * function that can cancel any outstanding requests made with the request function (regardless of config).
 * This helps address slow request race conditions to ensure the last request made
 * is the one we ultimately respond to.
 *
 * If a call is canceled, it resolves with prop canceled = true
 *
 * usage:
 *
 * const cancelableSingleton = createCancelableRequestSingleton();
 *
 * cancelableSingleton.requestAuthenticated({url:'someConfig'})
 *  .then(result => {
 *    if(result.canceled){
 *      // call was canceled
 *    }
 *    else {
 *      // call resolved
 *    }
 *  })
 *
 *  cancelableSingleton.cancel()
 *
 * @returns {{cancel: function(), requestAuthenticated: Promise<any>}}
 */
function createCancelableSingleton() {
  let abortController;

  function cancel() {
    abortController?.abort();
  }

  function request(requestConfig) {

    // Cancel outstanding request if exists
    if (abortController) {
      cancel();
    }

    abortController = new AbortController();

    const configWithSignal = {
      ...requestConfig,
      signal: abortController.signal
    };

    return requestAuthenticated(configWithSignal);

  }

  return {cancel, requestAuthenticated: request};
}

/**
 * Makes a repeated request until one of the following is true:
 *
 * 1. the request returns a non-error response
 * 2. the max number of errors is reached (rejects with last error)
 * 3. the timeout millis value is reached (rejects with timeout)
 *
 * @param requestConfig - requestAuthenticated config
 * @param max - max number of requests with errors before failure
 * @param timeout - max amount of time retrying before failure
 * @param interval - millis to wait between retries
 * @returns {Promise}
 */
async function requestWithRetry(requestConfig, max = 3, timeout = 60000, interval = 1000) {
  return retry(() => {
    return requestAuthenticated(requestConfig);
  }, {max, timeout, backoffBase: interval, backoffExponent: 1});
}

/**
 * Makes a repeated request until one of the following is true:
 *
 * 1. the successCallback returns true
 * 2. the max number of retries is reached (rejects last response)
 * 3. the timeout millis value is reached (rejects with timeout)
 *
 * The request is made repeatedly every interval number of millis
 * and the successCallback is called with the results. It is up to the
 * implementor to return true when the response is complete and should not
 * be continued.
 *
 * successCallback(response)
 *
 * @param requestConfig - requestAuthenticated config
 * @param successCallback - function that is called after each non error response, should return true if success
 * @param interval - millis to wait between retries
 * @param max - max number of requests with errors before failure
 * @param timeout - max amount of time retrying before failure
 * @returns {Promise}
 */
async function requestWithRetryUntilSuccess(
  requestConfig, successCallback, interval = 1000, max = 60, timeout = 60000) {
  const NO_SUCCESS = 'Response was not ready';
  const noSuccessError = new Error(NO_SUCCESS);

  return retry(() => {
    return new Promise((resolve, reject) => {
      requestAuthenticated(requestConfig)
        .then(result => successCallback(result) ? resolve(result) : reject(noSuccessError))
        .catch(reject);
    });
  }, {
    max: max,
    timeout: timeout,
    backoffBase: interval,
    backoffExponent: 1,
    match: [NO_SUCCESS]
  });
}

/**
 * Wrapper around the requestWithRetry method with some logging for use in multi-page request chains
 *
 * Clones the config, sets the page number, attaches the authorization token and calls request with config
 *
 * The request can handle failure and will retry up to 3 times before throwing an error.
 *
 * The resulting page information and data are returned in a wrapped model like below
 *
 * ex. {
 *   size: 50,
 *   totalElements: 210,
 *   totalPages: 5,
 *   number: 0,
 *   data: {response.data}
 *  }
 *
 * @param requestConfig
 * @param page
 * @returns {Promise<{size, totalElements, totalPages, number, summary, data}>}
 */
async function requestSinglePage(requestConfig, page = 0) {

  const config = setConfigPage(requestConfig, page);

  const response = await requestWithRetry(config);

  return wrapPage(response.data);
}

/**
 * For requests that return pages results, this method will call the endpoint with
 * the provided config for the first page, then based on the page metadata returned from
 * the first request, it will continue to request each page until all pages are fetched.
 *
 * Each request is added to a pages collection that will need to be parsed and combined
 *
 * ex. {
 *   size: 50,
 *   totalElements: 210,
 *   totalPages: 5,
 *   number: 0,
 *   data: {response.data}
 *  }
 *
 * @param requestConfig
 * @param onProgress
 * @param firstPageIndex
 * @returns {Promise<{size, totalElements, totalPages, number, summary, data}[]>}
 */
async function requestAllPages(requestConfig, onProgress, firstPageIndex = 0) {

  // Request first page of results and seed output page collection
  const firstPage = await requestSinglePage(requestConfig, firstPageIndex);
  const output = [firstPage];

  // These account for firstPageIndex other than 0
  const indexAdjustment = 1 - firstPageIndex;
  const secondPageIndex = firstPageIndex + 1;
  const adjustedPageTotal = firstPage.totalPages + firstPageIndex;

  // Call onProgress for first page
  if (onProgress) {
    onProgress(firstPageIndex + indexAdjustment, firstPage.totalPages);
  }

  // If more pages to request, get each remaining page
  if (firstPage.totalPages > 1) {
    for (let i = secondPageIndex; i < adjustedPageTotal; i++) {

      output.push(await requestSinglePage(requestConfig, i));

      if (onProgress) {
        onProgress(i + indexAdjustment, firstPage.totalPages);
      }
    }
  }

  return output;
}

/**
 * Wrap a page result in an object that contains the page identifiers as
 * well as the result set to 'data' property.
 *
 * These objects are returned from the getAllPages service, and should be parsed
 * and combined to create a combined collection of results.
 *
 * ex. {
 *   size: 50,
 *   totalElements: 210,
 *   totalPages: 5,
 *   number: 0,
 *   summary: '1/5',
 *   data: {data}
 *  }
 *
 * @param data
 * @returns {*}
 */
function wrapPage(data) {
  const size = getPageValue(data, 'size');
  const totalPages = getPageValue(data, 'totalPages');
  const totalElements = getPageValue(data, 'totalElements');
  const number = getPageValue(data, 'number');

  const displayPage = Math.min(totalPages, number + 1);
  const summary = `${displayPage}/${totalPages}`;

  return {
    size,
    totalElements,
    totalPages,
    number,
    summary,
    data
  };
}

/**
 * Function that fetches page information from result.
 * Looks first for property an inner 'page' object,
 * falls back to looking on object directly if not found in page object,
 * and lastly returns 0 if neither of those exist.
 *
 * ex. if getPageValue(item, 'prop')
 * => returns item.page.prop if exists
 * => else returns item.prop if exists
 * => else returns 0
 *
 * @param item
 * @param property
 * @returns {*}
 */
function getPageValue(item, property) {
  return _.get(item, ['page', property], _.get(item, property, 0));
}

/**
 * Given the results from a requestAllPages request, extract all page values
 * and combine them into one array.
 *
 * Assumes each page result collection is contained in embeddedPropPath ex 'data._embedded.values'
 * and combined results are returned in the same structure for consistency
 *
 * The results can be filtered for uniqueness using the
 * uniqueBy arg (which passes directly to lodash).
 *
 * @param pagedResults
 * @param embeddedPath
 * @param uniqueBy
 * @returns {{data: *}}
 */
function extractAllPagesResult(pagedResults, embeddedPath, uniqueBy) {
  if (!embeddedPath) {
    return pagedResults;
  }

  const data = _.chain(pagedResults)
    .map(embeddedPath)
    .flatten()
    .compact()
    .uniqBy(uniqueBy)
    .value();

  return _.set({}, embeddedPath, data);
}

/**
 *
 * @param requestConfig
 * @param page
 * @returns {*}
 */
function setConfigPage(requestConfig, page = 0) {
  const config = _.cloneDeep(requestConfig);

  _.set(config, 'params.page', page);

  return config;
}

/**
 * Check to see if url starts wth one of the REACT_XSRF_TOKEN_API_PATHS returns true if it does
 *
 * @param url
 * @returns {boolean}
 */

const XSRF_TOKEN_API_PATHS = process.env.REACT_APP_XSRF_API_PATHS?.split(',') || [];

function checkForCsrfServicePath(url) {
  return _.some(XSRF_TOKEN_API_PATHS, path => url.startsWith(path));
}

/**
 *
 * @param requestConfig
 * @returns {{headers: {"Content-Type": string, Authorization: string}}}
 */
function addAuthToConfig(requestConfig) {
  const baseURL = process.env.REACT_APP_GATEWAY_URL;
  const apiKey = process.env.REACT_APP_AUTH_API_KEY;
  const isCsrfServicePath = checkForCsrfServicePath(requestConfig.url);

  return authStorage.getToken()
    .then(authToken => {
      return {
        baseURL: baseURL,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
          'x-api-key': apiKey,
          ...(isCsrfServicePath ? {'x-horizon-csrf-token': authStorage.getCsrfToken()} : {})
        },
        ...(isCsrfServicePath ? {withCredentials: true} : {}),
        paramsSerializer: (params) => {
          // Serialize arrays without brackets
          return waHttp.encodeQueryString(params);
        },
        ...requestConfig
      };
    });
}

/**
 * Creates an array of sort parameters for the request in the form:
 * sortField,asc from antd table sort values.
 *
 * @param sortField
 * @param tableSortDirection
 * @returns {*}
 */
export function createSortParams(sortField, tableSortDirection) {
  const sortDir = tableSortDirection === 'descend' ? 'desc' : 'asc';
  let sortParams = [`${sortField},${sortDir}`];

  if (sortField === 'lastName') {
    sortParams.push(`firstName,${sortDir}`); // adds firstName to sort to allow full name sorting
  }
  else if (sortField === 'createdBy.lastName') {
    sortParams.push(`createdBy.firstName,${sortDir}`); // adds firstName to sort to allow full name sorting
  }

  return sortField && tableSortDirection ? sortParams : undefined;
}

// Public API
export default {
  requestAuthenticated,
  requestWithRetry,
  requestWithRetryUntilSuccess,
  requestSinglePage,
  requestAllPages,
  createRequestSingleton,
  addAuthToConfig,
  extractAllPagesResult,
  wrapPage,
  getPageValue,
  setConfigPage,
  createSortParams,
  createCancelableSingleton
};
