import * as types from './types';
import _ from 'lodash';

const syncPersonMatchingPatterns = (force = false) => {
  return (dispatch, getState, {persons}) => {
    const locale = getState()?.localization?.locale;

    if (force || getState()?.personMatchingPatterns?.locale !== locale || _.isEmpty(getState()?.personMatchingPatterns?.list)) {
      dispatch({type: types.PERSON_MATCHING_PATTERNS_REQUEST});

      return persons.getPersonMatchingPatterns({params: locale})
        .then(results => dispatch({
          type: types.PERSON_MATCHING_PATTERNS_SUCCESS,
          payload: {
            data: results?.data,
            locale
          }
        }))
        .catch(error => dispatch({
          type: types.PERSON_MATCHING_PATTERNS_FAILURE,
          error
        }));
    }
  };
};

const _throttledSyncPersonMatchingPatterns = _.throttle((dispatch, force) => dispatch(syncPersonMatchingPatterns(force)), 10000);

export const getPersonMatchingPatterns = (force) => {
  return (dispatch) => {
    return _throttledSyncPersonMatchingPatterns(dispatch, force);
  };
};
