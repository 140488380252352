/************************************************************************
 *
 * WorldAware Http Utilities
 *
 * A collection of request related methods used to make request
 * against WorldAware authenticated endpoints.
 *
 ************************************************************************/

import axios from 'axios';
import queryString from 'query-string';

/**
 * Abstraction of HTTP Request (currently using axios library).
 * Abstracting this can help with testing / mocking
 *
 * For more info on request config options see:
 * https://github.com/axios/axios#request-config
 *
 * @param config - http config
 * @returns {Promise<*>}
 */
async function httpRequest(config) {
  return axios.request(config);
}

/**
 * Encode data object for http request as query string
 *
 * Uses default array notation foo: ['1','2','3'] => 'foo=1&foo=2&foo=3'
 *
 * ex.
 * encodeQueryString({arg1:2, arg2:'foo'})
 * => 'arg1=2&arg2=foo'
 *
 * @param model - object with properties to be encoded
 * @param options - query-string stringify options
 * @returns {*}
 */
function encodeQueryString(model, options) {
  return queryString.stringify(model, options);
}

/**
 * Parse a query string into an object.
 *
 * Leading ? or # are ignored, so you can pass location.search or location.hash directly.
 *
 * Uses default array notation 'foo=1&foo=2&foo=3' => foo: ['1','2','3']
 *
 * ex.
 * parseQueryString('arg1=2&arg2=foo')
 * => {arg1:2, arg2:'foo'}
 *
 * @param query - query string
 * @param options - query-string parse options
 * @returns {*}
 */
function parseQueryString(query, options) {
  return queryString.parse(query, options);
}

/**
 * Encode a string (or char array) in base64
 *
 *  ex.
 *  encodeBase64('test')
 *  => 'dGVzdA=='
 *
 * @param input - string to be encoded
 * @returns {string}
 */
function encodeBase64(input) {
  return Buffer.from(input).toString('base64');
}

/**
 * Creates a request cancel token (from axios).
 * The canceler argument is a function that when called
 * triggers the cancel of the associated request.
 *
 *
 * @param canceler
 * @returns {CancelToken}
 */
function getRequestCancelToken(canceler) {
  return new axios.CancelToken(canceler);
}

// Public API
export default {
  httpRequest,
  getRequestCancelToken,
  encodeQueryString,
  parseQueryString,
  encodeBase64
};
