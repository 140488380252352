export const WORLD_MAP_FILTER_SITES_STORAGE_KEY_BASE = 'siteFilter';
export const SITE_FILTER = 'site-root-checkbox';
export const COUNT_AS_FILTER = 'site-count-as';
export const SITE_TYPES_FILTER = 'site-types';

const imageCDN = `${process.env.REACT_APP_CDN_URL}/images/world-map`;

export const SITE_IMAGE_SRC = {
  SITE: `${imageCDN}/icon_site_generic.svg`,
  OCCUPANT: `${imageCDN}/icon_occupant.svg`
};