import _ from 'lodash';
import {featureCollection} from '@turf/turf';

import {getBoundsFromFeature} from '../../../components/Common/Mapbox/utils/mapbox-utils';

import {ALERT_DETAILS_LOCAL_STORAGE_KEY, EXPOSURES_FILTER_STORAGE_KEY_BASE} from '../../../constants/alert-filters';
import {EXPOSURE_TIME_PERIOD} from '../../../constants/person-filters';
import {horizonLocalStorage} from '../../../utils/local-storage';

export function parseAlertDetailsResponse(response, intelApi) {
  const alertDetails = _.get(response, 'data');

  return getMapDataSourceBounds(alertDetails, intelApi)
    .then(({bounds, dataSource}) => {
      dataSource.features?.forEach(feature => {
        feature.bounds = getBoundsFromFeature(feature);
      });

      alertDetails.parsedMapData = {bounds, dataSource};
      return {data: {...response, alertDetails}};
    }
    )
    .catch(() => ({data: {...response, alertDetails}}));
}

export function formExposureBoundAndDataSource(alertDetails) {
  const {
    id,
    fullTitle,
    gist,
    severity: {
      key: severityKey
    } = {},
    mapData: {
      exposureGeoJson
    } = {}
  } = alertDetails;

  let featureGeometry;
  try {
    featureGeometry = JSON.parse(exposureGeoJson);
  }
  catch (e) {
    console.error('unable to parse alert details geometry', exposureGeoJson);
  }

  const dataSource = featureCollection([{
    'properties': {
      'feature-impact': 'Extreme',
      'feature-id': id,
      'feature-title': fullTitle,
      'feature-description': gist,
      'severity': severityKey
    },
    'geometry': featureGeometry
  }]);

  const bounds = featureGeometry ? getBoundsFromFeature(dataSource) : [[0, 0], [0, 0]];

  return {bounds, dataSource};
}

export function getMapDataSourceBounds(alertDetails = {}, intelApi) {
  const {
    mapData: {
      geoJsonUrl,
      exposureGeoJson
    } = {}
  } = alertDetails;

  if (geoJsonUrl) {
    return intelApi.getAlertGeoJson(geoJsonUrl)
      .then(({data: dataSource}) => {
        dataSource?.features.forEach?.(feature => {
          feature.properties.severity = alertDetails.severity?.key;
        });
        const bounds = getBoundsFromFeature(dataSource);
        return {bounds, dataSource};
      })
      .catch(() => {
        if (exposureGeoJson) {
          return Promise.resolve(formExposureBoundAndDataSource(alertDetails));
        }
        return Promise.reject();
      });
  }
  else if (exposureGeoJson) {
    return Promise.resolve(formExposureBoundAndDataSource(alertDetails));
  }
  else {
    return Promise.reject();
  }
}

/**
 * @param alertId
 * @param {object?} customDateRange
 * @param {string?} customDateRange.startDate - ISO DateTime string
 * @param {string?} customDateRange.endDate - ISO DateTime string
 */
export function setAlertDetailsCustomTimeRangeLocalStorage(alertId, customDateRange) {
  // check if any custom ranges exist
  const alertLocalStorage = getAlertDetailsCustomTimeRangeLocalStorage();
  const alertDetailsCustomTimeRange = {[alertId]: customDateRange};

  const combinedAlertDetailsCustomTime = {
    ...alertLocalStorage,
    ...alertDetailsCustomTimeRange
  };
  horizonLocalStorage.setItem(
    ALERT_DETAILS_LOCAL_STORAGE_KEY,
    JSON.stringify(combinedAlertDetailsCustomTime)
  );
}

export function getExposuresFilterByUserId(userId) {
  const storedValue = JSON.parse(horizonLocalStorage.getItem(EXPOSURES_FILTER_STORAGE_KEY_BASE + userId));
  // processed value updates the storedValue to include default for added features default values
  const processedValue = {
    includeSubOrganizations: true, // default which will get overridden if exists in storedValue
    ...storedValue
  }
  return processedValue;
}

export function setExposuresFilterByUserId(userId, filter) {
  horizonLocalStorage.setItem(`${EXPOSURES_FILTER_STORAGE_KEY_BASE}${userId}`, JSON.stringify(filter));
}

export function getAlertDetailsCustomTimeRangeLocalStorage() {
  return JSON.parse(horizonLocalStorage.getItem(ALERT_DETAILS_LOCAL_STORAGE_KEY));
}

export function clearAlertDetailsCustomTimeRangeLocalStorage() {
  horizonLocalStorage.removeItem(ALERT_DETAILS_LOCAL_STORAGE_KEY);
}

//This will always return the session org if Crisis24 Horizon Functional Storage is disabled
export function getCurrentOrgContext(state) {
  const currentUserId = state?.session.userId;
  const currentOrgId = state?.session?.currentPermissions?.orgId;
  const currentOrgContext = _.get(getExposuresFilterByUserId(currentUserId), 'filterAlertOrganization', []);

  return _.map((_.isEmpty(currentOrgContext) ? [{value: currentOrgId}] : currentOrgContext), 'value');
}

export function buildSiteFilter({polygons, distanceFromPolygon, applyVulnerabilityRadius, organizationIds, includeSubOrganizations}){

  const siteFilter = {
    applyVulnerabilityRadius,
    polygons,
    distanceFromPolygon,
    organizationIds,
    includeSubOrganizations
  };

  return siteFilter;
}

export function generateTimePeriodMap(exposureSummary, customDateRange) {
  return {
    [EXPOSURE_TIME_PERIOD.NOW]: {
      dateStart: exposureSummary?.exposures?.now?.beginDate,
      dateEnd: exposureSummary?.exposures?.now?.endDate
    },
    [EXPOSURE_TIME_PERIOD.NOW_THROUGH_END]: {
      dateStart: exposureSummary?.exposures?.nowThroughEnd?.beginDate,
      dateEnd: exposureSummary?.exposures?.nowThroughEnd?.endDate
    },
    [EXPOSURE_TIME_PERIOD.CUSTOM_DURATION]: {
      dateStart: customDateRange?.beginDate,
      dateEnd: customDateRange?.endDate
    },
    [EXPOSURE_TIME_PERIOD.ORIGINAL_THROUGH_END]: {
      dateStart: exposureSummary?.exposures?.originalThroughEnd?.beginDate,
      dateEnd: exposureSummary?.exposures?.originalThroughEnd?.endDate
    }
  };
}