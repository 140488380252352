import preferencesReducer from './reducer';
import * as preferencesTypes from './types';
import * as preferencesActions from './actions';
import * as preferencesUtils from './utils';

export {
  preferencesTypes, 
  preferencesActions, 
  preferencesUtils
};

export default preferencesReducer;
