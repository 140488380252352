import * as types from './types';

const sitesReducer = (
  state = {
    isFetching: false,
    list: [],
    locale: undefined,
    isFetchingSiteResources: false,
    siteResources: {}
  },
  action
) => {
  switch (action.type) {
    case types.SITE_TYPES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SITE_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.list,
        locale: action.payload.locale
      };
    case types.SITE_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case types.GET_SITE_RESOURCES_REQUEST:
      return {
        ...state,
        isFetchingSiteResources: true,
        siteResources: {}
      };
    case types.GET_SITE_RESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingSiteResources: false,
        siteResources: action.payload.data
      };
    case types.GET_SITE_RESOURCES_FAILURE:
      return {
        ...state,
        isFetchingSitenResources: false
      };
    default:
      return state;
  }
};

export default sitesReducer;
