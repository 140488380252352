import PropTypes from 'prop-types';

/**
 * CustomPropTypes
 *
 * Reusable PropType definitions common within the application.
 *
 * This should not include DTOs, as they should be added to service models
 */

const definedMessageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired
});

export default {
  definedMessage: definedMessageShape
};
