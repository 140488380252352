import * as types from './types';

const INITIAL_STATE = {
  composeMessageInProgress: false,
  isSendingDraft: false,
  isCreatingDraft: false,
  personIds: [],
  numPersons: 0,
  allPersonsSelected: false,
  messageId: null,
  recipientIds: [],
  fetchingRecipients: false,
  recipients: [],
  organizationIds: null,
  pageable: {
    pageNumber: 0,
    pageSize: 25,
    totalElements: 0
  },
  deletingRecipient: false,
  addingRecipient: false,
  messageTemplates: [],
  fromEmail: undefined
}

const communicatorReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case types.INITIATE_COMPOSE_MESSAGE:
      return {
        ...state,
        composeMessageInProgress: true,
        personIds: action.payload.personIds,
        numPersons: action.payload.numPersons,
        allPersonsSelected: action.payload.allPersonsSelected
      };
    case types.END_COMPOSE_MESSAGE:
      return {
        ...state,
        composeMessageInProgress: false
      };

    case types.CREATE_DRAFT_MESSAGE_REQUEST:
      return {
        ...state,
        isCreatingDraft: true,
        recipientIds: [],
        organizationIds: null,
        fromEmail: undefined
      }
    case types.CREATE_DRAFT_MESSAGE_SUCCESS:
      return {
        ...state,
        author: action.payload.author,
        messageId: action.payload.id,
        recipientIds: action.payload.initiationContext?.personFilter?.personIds,
        initiationContext: action.payload.initiationContext,
        isCreatingDraft: false,
        organizationIds: action.payload.organizationIds,
        includeSubOrganizations: action.payload.includeSubOrganizations,
        fromEmail: action.payload.fromEmail
      }
    case types.CREATE_DRAFT_MESSAGE_FAILURE:
      return {
        ...state,
        isCreatingDraft: false
      }
    case types.GET_MESSAGE_RECIPIENTS_REQUEST:
      return {
        ...state,
        fetchingRecipients: true,
        recipients: []
      }
    case types.GET_MESSAGE_RECIPIENTS_SUCCESS:
      return {
        ...state,
        recipients: action.payload.content,
        pageable: action.payload.pageable,
        fetchingRecipients: false
      }
    case types.GET_MESSAGE_RECIPIENTS_FAILURE:
      return {
        ...state,
        fetchingRecipients: false
      }

    case types.SEND_MESSAGE_REQUEST:
      return {
        ...state,
        isSendingDraft: true
      }
    case types.SEND_MESSAGE_SUCCESS:
    case types.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        isSendingDraft: false
      }
    case types.DELETE_RECIPIENT_REQUEST:
      return {
        ...state,
        deletingRecipient: true
      }
    case types.DELETE_RECIPIENT_SUCCESS:
    case types.DELETE_RECIPIENT_FAILURE:
      return {
        ...state,
        deletingRecipient: false
      }
    case types.ADD_RECIPIENT_REQUEST:
      return {
        ...state,
        addingRecipient: true
      }
    case types.ADD_RECIPIENT_SUCCESS:
    case types.ADD_RECIPIENT_FAILURE:
      return {
        ...state,
        addingRecipient: false
      }
    case types.GET_MESSAGE_TEMPLATES_SUCCESS:
      return {
        ...state,
        messageTemplates: action.payload.messageTemplates
      }
    case types.GET_MESSAGE_TEMPLATES_REQUEST:
    case types.GET_MESSAGE_TEMPLATES_FAILURE:
      return {
        ...state,
        messageTemplates: []
      }
    default:
      return state;
  }
}

export default communicatorReducer;
