import _ from 'lodash';

import {ACCESS_ANALYST_FIELDS} from '../../../components/Organizations/constants';
import {ORGANIZATION_CONFIGURATION_TYPES, DATA_RETENTION_FIELDS, MOBILE_CHECK_IN_CONFIG_FIELDS, IN_CONTACT_DOMAIN} from '../../../containers/Organizations/OrganizationProfilePage/tabs/OrganizationClientConfiguration/ClientConfiguration.constants';
import {DATA_MANAGEMENT_TYPES} from '../../../containers/Organizations/OrganizationProfilePage/tabs/DataManagement/DataManagement.constants';
import {LOCALES} from '../../../constants/locale';
import {formFullName} from '../../../utils/users/users';
import {ORG_RESOURCES_API_FIELDS} from '../../../constants/resources';
import {EMAIL_TEMPLATE_TYPES} from '../../../containers/Organizations/OrganizationProfilePage/tabs/OrganizationClientConfiguration/Emails/ClientConfigEmailsConstants';

const {CUSTOM_LINKS, ACCESS_TO_ANALYST, CUSTOM_LOGIN, CHECKIN_NOTIFICATION_ITEM} = ORGANIZATION_CONFIGURATION_TYPES;
const {DATA_RETENTION} = DATA_MANAGEMENT_TYPES;

export const updateOrgConfigValues = (values) => {
  const {
    FREQUENCY_LIMIT_PERIOD,
    FREQUENCY_LIMIT
  } = ACCESS_ANALYST_FIELDS;
  return {
    ...values,
    [FREQUENCY_LIMIT_PERIOD]: undefined,
    [FREQUENCY_LIMIT]: {
      number: values[FREQUENCY_LIMIT],
      period: values[FREQUENCY_LIMIT_PERIOD]
    }
  };
}

export const groupLinksByType = (response) => {
  const responseCopy = {...response};
  const {links} = response;
  const sortedLinks = _.orderBy(links, ['audit.createdAt'], ['desc'])
  responseCopy.links = sortedLinks.reduce((acc, link) => {

    if (!acc[link.linkType]) {
      acc[link.linkType] = [];
    }
    acc[link.linkType].push(link);
    return acc;
  }, {});
  return responseCopy;
}

export const groupPoliciesByType = (response) => {
  const responseCopy = {...response};
  const {policies} = response;
  const sortedPolicies = _.orderBy(policies, ['audit.createdAt'], ['desc'])

  responseCopy.policies = sortedPolicies.reduce((acc, policy) => {
    policy.enabled = true;
    acc[policy.type] = policy;
    return acc;
  }, {});
  return responseCopy;
}

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}


// add remaining config types
export const formatUpdatePayloadByConfigType = (payload, configType) => {
  switch (configType) {
    case DATA_RETENTION:
      return formatPoliciesPayload(payload);
    case ACCESS_TO_ANALYST:
      return updateOrgConfigValues(payload);
    case EMAIL_TEMPLATE_TYPES.CUSTOM_FROM_EMAIL:
      return formatCustomEmailSentFromPayload(payload);
    case CUSTOM_LOGIN:
      payload.menuLinks = payload.menuLinks ?? [];
      // removing home and contact links because they're default and cannot be edited
      return _.omit(payload, ["home", "contact"])
    default:
      return payload;
  }
}

const formatCustomEmailSentFromPayload = (payload) => {
  const appliedToValues = []
  if (payload.accountActivation) {
    appliedToValues.push('ACCOUNT_ACTIVATION')
  }
  if (payload.preTripNotification) {
    appliedToValues.push('PRE_TRIP_NOTIFICATION')
  }
  if (payload.preExpatriationNotification) {
    appliedToValues.push('PRE_EXPATRIATION_NOTIFICATION')
  }
  if (payload.alerts) {
    appliedToValues.push('ALERTS')
  }
  if (payload.incidents) {
    appliedToValues.push('INCIDENTS')
  }
  if (payload.tripsAffectedByAlertNotification) {
    appliedToValues.push('TRIPS_AFFECTED_ALERT_NOTIFICATION')
  }
  if (payload.alertsAffectedExpatriations) {
    appliedToValues.push('EXPATS_AFFECTED_ALERT_NOTIFICATION')
  }
  if (payload.massNotifications) {
    appliedToValues.push('MASS_NOTIFICATION');
  }

  let updatedPayload = {
    fromName: payload.fromName,
    alias: payload.alias,
    domain: payload.domain,
    appliedTo: appliedToValues
  }

  return updatedPayload
}

export const formatResponseByConfigType = (responseData, configType) => {
  switch (configType) {
    case CUSTOM_LINKS:
      return groupLinksByType(responseData);
    case DATA_RETENTION:
      return groupPoliciesByType(responseData);
    case CHECKIN_NOTIFICATION_ITEM:
      return {
        ...responseData,
        [MOBILE_CHECK_IN_CONFIG_FIELDS.ORGANIZATIONS]: responseData[MOBILE_CHECK_IN_CONFIG_FIELDS.ORGANIZATIONS].map(org => ({...org, label: org.name, value: org.id})),
        [MOBILE_CHECK_IN_CONFIG_FIELDS.RECIPIENTS]: responseData?.[MOBILE_CHECK_IN_CONFIG_FIELDS.RECIPIENTS]?.map(recipient => ({label: formFullName(recipient, true), value: recipient.id})),
        [MOBILE_CHECK_IN_CONFIG_FIELDS.CLIENT_IN_CONTACT_EMAIL]: responseData?.[MOBILE_CHECK_IN_CONFIG_FIELDS.CLIENT_IN_CONTACT_EMAIL] ? responseData?.clientInContactEmail.split(IN_CONTACT_DOMAIN)[0] : null,
        [MOBILE_CHECK_IN_CONFIG_FIELDS.INCLUDE_SUB_ORGANIZATIONS]: responseData?.[MOBILE_CHECK_IN_CONFIG_FIELDS.INCLUDE_SUB_ORGANIZATIONS] !== false
      };
    case CUSTOM_LOGIN:
      if (responseData.welcomeMessages) {
        responseData.welcomeMessages = _.sortBy(responseData.welcomeMessages, ({locale}) => locale === LOCALES.EN ? 0 : 1)
      }
      return responseData;
    default:
      return responseData;
  }
}


export const formatPoliciesPayload = (vals) => {
  const policies = [];
  Object.keys(DATA_RETENTION_FIELDS).forEach(type => {
    const isEnabled = vals[`${type}-enabled`];
    if (isEnabled) {
      policies.push({
        type,
        purge: {
          duration: vals[type].duration,
          period: vals[type].period
        }
      });
    }
  })
  return {policies};
}

export const groupResourcesLinksByType = ({data}) => {
  const responseCopy = {links: null};
  const sortedLinks = _.orderBy(data, ['audit.createdAt'], ['desc']);

  responseCopy.links = _.groupBy(sortedLinks, (link) => link.type);
  return responseCopy;
}

export function checkIfAllLinksAreQuarantined(groupedLinks){
  return _.chain(groupedLinks)
    .map((res) => res?.[ORG_RESOURCES_API_FIELDS?.RESOURCE_DATA]?.[ORG_RESOURCES_API_FIELDS?.LANGUAGE_VERSION])
    .flatten()
    .every(doc => !!doc.quarantined)
    .value();
}