import LAYERS from "../components/Common/Mapbox/layers/layerIds";

export const THREAT_ZONES = 'threatZones';
export const THREAT_ZONE_LEVELS = 'threatZoneLevels';
export const THREAT_ZONE_ORGS = 'threatZoneOrgs';
export const WORLD_MAP_FILTER_TZ_STORAGE_KEY_BASE = 'threatZoneFilter';

export const threatzone_detail_layers = [
  LAYERS.THREAT_ZONE_COUNTRY_DETAIL_LAYER_ID,
  LAYERS.THREAT_ZONE_PROVINCE_DETAIL_LAYER_ID,
  LAYERS.THREAT_ZONE_CITY_DETAIL_LAYER_ID
];