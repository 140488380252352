import {USERCENTRICS_DPS, USERCENTRICS_SETTINGS_KEY} from '../constants/usercentrics';
import _ from 'lodash';

/**
 * Returns true if the user has consented to Crisis24 Horizon Functional Storage in Usercentrics
 */
export function getHorizonStoragePermitted() {
  return getServiceStatus(USERCENTRICS_DPS.HORIZON_STORAGE.id);
}

/**
 * Returns true if the user has consented to using the Datadog service in Usercentrics
 * Datadog is not protected automatically by Usercentric's Smart Data Protector
 * We disable it manually. We use this function to check the consent status.
 */
export function getDatadogPermitted() {
  return getServiceStatus(USERCENTRICS_DPS.DATADOG.id);
}

/**
 * Returns true if the user has consented to using Zendesk Cookies in Usercentrics.
 * These cookies are necessary for getting access to the Help Center Search
 */
export function getZendeskPermitted() {
  return getServiceStatus(USERCENTRICS_DPS.ZENDESK.id);
}

/**
 * Gets usercentrics service consent information from local storage
 */
function getConsentDetails() {
  let consentDetails;
  try {
    //Essential localStorage - Do not replace with horizonLocalStorage. We need to be able to read Usercentrics consent.
    consentDetails = JSON.parse(localStorage.getItem(USERCENTRICS_SETTINGS_KEY));
  }
  catch (e) {
    console.error('Could not parse usercentrics consent object');
  }

  return consentDetails;
}

/**
 * Returns true if the user has consented to using the service with id "serviceId" within Usercentrics, falsy otherwise
 * @param serviceId - The service ID from the usercentrics admin interface
 */
function getServiceStatus(serviceId) {
  return getConsentDetails()?.services
    ?.find(service => service.id === serviceId)
    ?.status;
}

/**
 * Makes a call to the Usercentrics script within index.html.
 * This opens a Modal allowing the user to manage their cookie preferences
 */
export function showManageCookiesModal() {
  if (!_.isNil(window.UC_UI)) {
    window.UC_UI.showSecondLayer();
  }
  else {
    console.error('Usercentrics did not load successfully, so we could not open the preference modal');
  }
}
