import {SORT_DIRECTION} from './sorting';

export const USER_MODEL_FIELDS = {
  PERSON: 'person',
  PERSON_FIRSTNAME: 'firstName',
  PERSON_MIDDLENAME: 'middleName',
  PERSON_LASTNAME: 'lastName',
  USERNAME: 'username',
  ORGANIZATIONS: 'organizations',
  ROLES: 'roles',
  USER_STATUS: 'userStatus',
  EMAIL: 'email'
};

export const USER_LIST_FIELDS = {
  FILTER_STRING: 'filterString',
  ORGANIZATION_IDS: 'organizationIds',
  ROLE_DEFINITION_ID: 'roleDefinitionId',
  STATUSES: 'statuses'
}

export const DEFAULT_USER_SORT = {
  sortField: USER_MODEL_FIELDS.PERSON_LASTNAME,
  sortDirection: SORT_DIRECTION.ASCEND
};

export const DEFAULT_USER_PAGINATION = {
  page: 0,
  size: 25
}

//These constants would need to be updated in the unlikely event the english role names change
export const ORG_ADMIN_ROLE_NAME = 'Organization Administrator';
export const RISK_MANAGER_ROLE_NAME = 'Risk Manager';

export const INTEL_KEYWORD_IDS = 'INTEL_KEYWORD_IDS';
export const INCIDENT_IMPACT_LEVELS_KEY = 'INCIDENT_IMPACT_LEVELS'