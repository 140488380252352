import {createContext, useContext, useRef, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {PropTypes} from 'prop-types';

// https://codesandbox.io/s/react-router-dynamic-breadcrumb-working-forked-p01k5?file=/src/Breadcrumb.js
const useHasChanged = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const ExtendedRoutingContext = createContext([]);

export const ExtendedRoutingProvider = ({children}) => {
  const extendedRoutingContext = useContext(ExtendedRoutingContext);
  const location = useLocation();
  const changedLocation = useHasChanged(location);
  if (changedLocation) {
    extendedRoutingContext.splice(0, extendedRoutingContext.length);
  }

  return (
    <ExtendedRoutingContext.Provider value={extendedRoutingContext}>
      {children}
    </ExtendedRoutingContext.Provider>
  );
};

ExtendedRoutingProvider.propTypes = {
  children: PropTypes.node
}
