import MapLayer from '../MapLayer';
import {PERSON_DETAIL_LAYER_ID_GLOBAL} from '../../../../../constants/map';

export const PERSON_DETAIL_SOURCE_ID = 'person-detail-source';
export const PERSON_DETAIL_LAYER_ID = PERSON_DETAIL_LAYER_ID_GLOBAL;

export default class PersonDetailLayer extends MapLayer {

  constructor() {
    super();

    this.data = false;
  }

  getInteractiveLayerIds() {
    return [];
  }

  createSources() {
    if (this.map) {
      this.map.addSource(PERSON_DETAIL_SOURCE_ID, {
        type: 'geojson',
        data: this.data
      });
    }
  }

  createLayers() {
    if (this.map) {
      this.map.addLayer(
        {
          'id': PERSON_DETAIL_LAYER_ID,
          'source': PERSON_DETAIL_SOURCE_ID,
          'type': 'symbol',
          'layout': {
            'icon-image': [
              'case',
              ['==', ['get', 'locType'], 1], `marker_person`,
              ['==', ['get', 'locType'], 2], `crisissignal_crisis`,
              ['==', ['get', 'locType'], 3], `marker_checkin`,
              ['==', ['get', 'locType'], 6], `icon_pin_address_white`,
              ['==', ['get', 'locType'], 9], `expatriation`,
              'cluster_1 digit'
            ],
            'text-size': 10,
            'text-offset': [0, -.2]
          }
        }
      );
    }
  }

  /**
   * Set the source data for the csar country layer,
   * sets source to false if data is undefined.
   *
   * @param data
   */
  setData(data) {
    if (this.data !== data) {
      this.data = data || false;

      if (this.map) {
        this.map.getSource(PERSON_DETAIL_SOURCE_ID)?.setData?.(this.data);
      }
    }
  }
}
