import {
  VIEW_CAUTION_ALERTS,
  VIEW_CRITICAL_ALERTS,
  VIEW_INCIDENTS,
  VIEW_INFORMATIONAL_ALERTS,
  VIEW_WARNING_ALERTS
} from "./permissions";

export const ALERT_SEVERITIES = 'severities';
export const INCIDENT_IMPACT_LEVELS = 'incidentImpactLevels';
export const ALERT_CATEGORIES = 'categories';
export const ALERT_REQUIRE_EXPOSURE = 'requirePersonExposures';
export const SITE_REQUIRE_EXPOSURE = 'requireSiteExposures';

export const WORLD_MAP_FILTER_STORAGE_KEY_BASE = 'alertsFilter';

export const ALERT_DETAILS_LOCAL_STORAGE_KEY = 'alert-details';

export const EXPOSURES_FILTER_STORAGE_KEY_BASE = 'exposures-alert-filters';

export const ALERT_INTEL_TYPE = 'ALERT'
export const FLASH_ALERT_INTEL_TYPE = 'FLASH_ALERT'
export const INCIDENT_INTEL_TYPE = 'INCIDENT'

export const EXPOSURES_ALERT_FEED_FILTER_FIELDS = {
  ALERT_SEVERITIES: ALERT_SEVERITIES,
  ALERT_CATEGORIES: ALERT_CATEGORIES,
  FILTER_ALERT_CATEGORY: 'filterAlertCategory',
  FILTER_ALERT_LOCATION: 'filterAlertLocation',
  FILTER_ALERT_SEVERITY: 'filterAlertSeverity',
  FILTER_ALERT_ORGANIZATION: 'filterAlertOrganization',
  FILTER_INCLUDE_EXPIRED_ALERTS: 'filterIncludeExpiredAlerts',
  REQUIRE_PERSON_EXPOSURES: ALERT_REQUIRE_EXPOSURE,
  REQUIRE_SITE_EXPOSURES: 'requireSiteExposures',
  INTEL_TYPES: 'intelTypes',
  INCIDENT_IMPACT_LEVELS: 'incidentImpactLevels'
};

export const PERSISTED_FIELDS = [
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.FILTER_ALERT_CATEGORY,
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.FILTER_ALERT_LOCATION,
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.FILTER_ALERT_SEVERITY,
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.FILTER_ALERT_ORGANIZATION,
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.REQUIRE_PERSON_EXPOSURES,
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.REQUIRE_SITE_EXPOSURES,
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.INTEL_TYPES,
  EXPOSURES_ALERT_FEED_FILTER_FIELDS.INCIDENT_IMPACT_LEVELS
];

/// api https://bitbucket.org/crisis24/wc4-api-docs/src/639e15678e8ad23e9c9f4a8e9c40e987640916a7/src/docs/private/exposures/wc4-exposures-ms.yaml#lines-1219
export const SEARCH_EXPOSURES_REQUEST = {
  ALERT_SEVERITIES: 'alertSeverities',
  INCIDENT_IMPACT_LEVELS: 'incidentImpactLevels'
};

export const VIEW_ALERT_SEVERITIES_PERMISSION_LIST = [
  VIEW_CRITICAL_ALERTS,
  VIEW_WARNING_ALERTS,
  VIEW_CAUTION_ALERTS,
  VIEW_INFORMATIONAL_ALERTS,
];

export const VIEW_ALERT_PERMISSION_LIST = [
  ...VIEW_ALERT_SEVERITIES_PERMISSION_LIST,
  VIEW_INCIDENTS
];

export const INTEL_TYPE_ID_KEY = {
  ALERT: 'alertId',
  INCIDENT: 'incidentId'
};

export const INTEL_ID_MAP = {
  [ALERT_INTEL_TYPE]: INTEL_TYPE_ID_KEY.ALERT,
  [INCIDENT_INTEL_TYPE]: INTEL_TYPE_ID_KEY.INCIDENT
};