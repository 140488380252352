export const ROOT_RATINGS_CATEGORY_IDS = {
  OVERALL: 'f985b1c4-cc5e-40c7-9a97-6a55ac60b52d',
  SECURITY: 'dd47eeed-c6c6-43a9-ba44-a98ddf5dbc19',
  ENVIRONMENT: 'efdef561-da34-4b83-83bb-57fb16610f0a',
  INFRASTRUCTURE: '1ef36ae8-9ae6-4a35-9b42-512c53ebc174',
  HEALTH: 'dd001ee5-60a3-4790-80fd-d89463123142',
  POLITICAL: '445cc960-57d1-4e71-a176-caaaa8ac718f',
  LGBTQ: 'ce8e3e87-05e2-4371-ada8-d2ee8e6e400b',
};

export const CATEGORY_IDS = {
  OVERALL: ROOT_RATINGS_CATEGORY_IDS.OVERALL,
  SECURITY: ROOT_RATINGS_CATEGORY_IDS.SECURITY,
  ENVIRONMENT: ROOT_RATINGS_CATEGORY_IDS.ENVIRONMENT,
  INFRASTRUCTURE: ROOT_RATINGS_CATEGORY_IDS.INFRASTRUCTURE,
  HEALTH: ROOT_RATINGS_CATEGORY_IDS.HEALTH,
  LGBTQ: ROOT_RATINGS_CATEGORY_IDS.LGBTQ,
  POLITICAL: '445cc960-57d1-4e71-a176-caaaa8ac718f',
  LEGAL: '450a36cd-7c31-424a-9611-d7888f8aa733',
  ENTRYEXIT: '5f833f3e-62a2-4691-9aaf-83bdf9e109ec',
  CULTURE: 'e5de8b00-3fa6-4f4f-833e-1c2e6b6219cc',
  FINANCIAL: '1925a489-86e6-4c98-95b2-36792a04198b'
};

export const ALL_CATEGORIES_GUID = '00000000-0000-0000-0000-000000000000';

export const CATEGORY_TYPES = {
  ALERT: 'ALERTS',
  ARTICLES: 'ARTICLES',
  RATINGS: 'RATINGS',
  INCIDENT_REPORTS: 'INCIDENT_REPORTS',
  LOCATION_INTEL: 'LOCATION_INTEL'
};
