import waRequest from './wa-request';

export const apiBase = '/articles/v1';

/**
 * Initiate authenticated GET ${apiBase}/articles/${articleId}
 *
 * @param articleId
 * @param locale
 * @returns {Promise}
 */
function getAdviceById(articleId, locale = 'en') {
  const url = `${apiBase}/articles/${articleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

/**
 * Get a paginated list of available published articles.
 *
 * @param {Object} config
 * @param {Object} config.params
 * @param {Array<String>} config.params.articleTypes One or more ArticleTypes to filter the results on.
 * @param {Array<String>} [config.params.categoryIds] List of category Ids in which the articles are searched. If provided, only articles with matching category are included in search result. If it is left empty, then articles will be searched for all categories
 * @param {boolean} [config.params.searchInChildCategories] Flag indicating whether or not to search the articles within the child categories of the supplied
 * @param {Array<Number>} [config.params.locationIds] List of Location Ids with which to filter articles. If provided, only articles with matching Location are included in search result. If it is left empty, then articles will be searched for all Locations.
 * @param {boolean} [config.params.searchInChildLocations] Flag indicating whether or not to search the articles within the child locations of the supplied
 * @param {string} config.params.locale Locale in ISO 639-1 format in conjunction with ISO 15924, for example zh-Hans.
 * @param {string} config.params.keyword Keyword to filter articles on, this is not case sensitive. Only articles with this keyword in the title OR gist will be included. The filter must not be empty or blank (i.e. only whitespace).
 * @param {Array<Number>} config.params.intelKeywordIds List of Keyword Ids with which to filter articles. If provided, only articles with matching Keyword Ids are included in search result. If it is left empty, then articles will be searched regardless of Keyword Ids.
 * @param {string} config.params.publishedAfter ISO date/time string. Filter articles based on their latest published date being after the provided date-time.
 * @param {string} config.params.publishedBefore ISO date/time string. Filter articles based on their latest published date being before the provided date-time.
 * @param {string} config.params.originalPublishedAfter ISO date/time string. Filter articles based on their original published date being after the provided date-time.
 * @param {string} config.params.originalPublishedBefore ISO date/time string. Filter articles based on their original published date being before the provided date-time.
 * @param {AbortSignal} config.signal use to cancel request
 * @returns {Promise} 200 Ok
 * */
function getArticles(config) {

  const {
    params: {
      articleTypes,
      categoryIds,
      searchInChildCategories = true,
      locationIds,
      searchInChildLocations = true,
      locale = 'en',
      keyword,
      intelKeywordIds,
      publishedAfter,
      publishedBefore,
      originalPublishedAfter,
      originalPublishedBefore,
      page,
      size,
      sort,
    },
    signal
  } = config;

  const url = `${apiBase}/articles`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      articleTypes,
      categoryIds,
      locationIds,
      keyword,
      intelKeywordIds,
      publishedAfter,
      publishedBefore,
      originalPublishedAfter,
      originalPublishedBefore,
      searchInChildCategories,
      searchInChildLocations,
      page,
      size,
      sort
    },
    signal
  });

}

function getArticle(articleId, locale = 'en') {
  const url = `${apiBase}/articles/${articleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

function getArticleConfigPattern({pathParams: {articleId}, params: {locale = 'en'} = {}, signal}) {
  const url = `${apiBase}/articles/${articleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    },
    signal
  });
}

function downloadArticleConfigPattern(config) {

  const {
    pathParams: {
      articleId
    },
    params: {
      locale = 'en'
    }
  } = config;

  const url = `${apiBase}/articles/${articleId}/download`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: {
      locale
    }
  });
}

function downloadArticle(articleId, locale = 'en') {
  return downloadArticleConfigPattern({pathParams: {articleId}, params: {locale}})
}


export default {
  getAdviceById,
  getArticles,
  getArticle,
  downloadArticle,
  getArticleConfigPattern,
  downloadArticleConfigPattern
};
