import _ from 'lodash';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage} from 'react-intl';

import {Form, Divider} from 'antd';

import useManageSurveyResponse from '../../hooks/services/communicator/useManageSurveyResponse';
import useCreateNotification from '../../hooks/notifications/useCreateNotification.js'

import SurveyResponseFormCard from './SurveyResponseCard/SurveyResponseFormCard';
import FormFooter from '../Common/FormFooter/FormFooter';
import LayoutSpace from '../Common/LayoutSpace/LayoutSpace';

const messages = defineMessages({
  pageHeading: {
    id: 'SurveyResponseForm.pageHeading',
    defaultMessage: 'Communication'
  },
  pageTitle: {
    id: 'SurveyResponseForm.pageTitle',
    defaultMessage: 'Survey Response'
  },
  submitText: {
    id: 'SurveyResponseForm.submitText',
    defaultMessage: 'Submit Response'
  },
  surveyResponseErrorTitle: {
    id: 'SurveyResponseForm.surveyResponseErrorTitle',
    defaultMessage: 'Survey response failed'
  },
  statusConfirmation: {
    id: 'SurveyResponseForm.statusConfirmation',
    defaultMessage: 'Status Confirmation'
  },
  wellnessCheckInfo: {
    id: 'SurveyResponseForm.wellnessCheckInfo',
    defaultMessage: 'Use this field to confirm your status. This field is required. You may submit a short, ' +
      'open-text comment with your status. Your response and comment will be visible to an administrator.'
  },
  noResponseProvided: {
    id: 'SurveyResponseForm.noResponseProvided',
    defaultMessage: 'At least one response must be provided.'
  },
});

export default function SurveyResponseForm(props = {}) {
  
  const {handleFormSubmitted, results, deliveryInstanceId, previousFormData} = props;
  const {create, loading} = useManageSurveyResponse(deliveryInstanceId);
  const {showErrorNotification} = useCreateNotification();

  const [form] = Form.useForm();
  
  useEffect(() => {
    if (!_.isEmpty(previousFormData)) {      
      form.setFieldsValue({
        statusConfirmationResponse: previousFormData?.statusConfirmationResponse,
        surveyResponses: previousFormData?.surveyResponses
      }); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousFormData]);
  
  function handleSubmit () {
    form.validateFields()
      .then(vals => {
        const surveyResponseVals = vals?.surveyResponses;
        const surveyResponses = surveyResponseVals && surveyResponseVals
          .map((response, index) => {
            const res = response?.responseOptionNumber || response?.comments ? 
              {
                ...response,
                questionNumber: index + 1,
                responseOptionNumber: response.responseOptionNumber
              } :
              null;
            return res;
          })
          .filter(response => !_.isEmpty(response));
        if (_.isEmpty(vals.statusConfirmationResponse) && _.isEmpty(surveyResponses))  {
          return showErrorNotification(
            <FormattedMessage {...messages.noResponseProvided} />
          );
        }
        const transformedData = {
          statusConfirmationResponse: vals.statusConfirmationResponse,
          ...(surveyResponses?.length > 0 && {surveyResponses})
        };
        create(transformedData)
          .then(() => {
            handleFormSubmitted(transformedData, vals);
          })
          .catch(err => console.error('err:', err));

      })
      .catch(err => console.error('err:', err));
  }

  return (      
    <Form
      form={form}
      layout="vertical"
    >
      <LayoutSpace>
        {results?.statusConfirmationResponseOptions && (
          <SurveyResponseFormCard
            info={<FormattedMessage {...messages.wellnessCheckInfo} />}
            required={true}
            survey={{
              number: 0,
              text: <FormattedMessage {...messages.statusConfirmation} />,
              responseOptions: results?.statusConfirmationResponseOptions
            }}
            field={'statusConfirmationResponse'}
          />
        )}

        {results?.surveyQuestions?.map((question) => {
          return (
            <SurveyResponseFormCard
              key={`${question.number}`}
              survey={question}
              field={'surveyResponses'}
            />
          );
        })}
      </LayoutSpace>
      <Divider />
      <FormFooter
        inline={true}
        form={form}
        submitText={<FormattedMessage {...messages.submitText}/>}
        onSubmit={handleSubmit}
        loading={loading}
        cancelText={null}
      />
    </Form>
  );

}


SurveyResponseForm.propTypes = {
  handleFormSubmitted: PropTypes.func,
  results: PropTypes.object,
  deliveryInstanceId: PropTypes.string,
  previousFormData: PropTypes.object
};