import NProgress from 'nprogress';
import axios from 'axios';
import _ from 'lodash';

import './ProgressBar.css';

/**
 * This is to prevent quick stop/start animations during lots of requests
 */
const throttled = _.throttle(callback => callback(), 600, {leading: true, trailing: true});

/**
 * Adds axios interceptors to show / hide the standard nprogress bar
 * based on pending requests.
 *
 * @param config - nprogress config
 * @param instance - axios instance
 */
const initialize = (config, instance = axios) => {

  let requestsCounter = 0;

  const incrementRequests = () => {
    requestsCounter++;
    throttled(NProgress.start);
  };

  const decrementRequests = () => {
    if ((--requestsCounter) <= 0) {
      throttled(NProgress.done);
    }
  };

  const onRequest = config => {
    incrementRequests();
    return config;
  };

  const onResponse = response => {
    decrementRequests();
    return response;
  };

  const onError = error => {
    decrementRequests();
    return Promise.reject(error);
  };

  NProgress.configure(config);
  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(onResponse, onError);
};

export default {
  initialize
};
