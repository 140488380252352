import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from 'antd/lib/typography/Text';

/**
 * This is the Label component used by BaseFormItem > InfoLabel.
 * It should be used wherever the intent is to duplicate that UX
 *
 * */
function FieldLabel(props) {

  const {
    disabled = false,
    label,
    labelBold = true,
    textPostLabel,
    className
  } = props;

  function isDisabled() {
    return disabled ? 'disabled' : '';
  }

  return (
    <span className={classNames('label', className, isDisabled())}>
      <Text strong={labelBold}>
        {label}
      </Text>
      {textPostLabel && (
        <Text>
          {textPostLabel}
        </Text>
      )}
    </span>
  )
}

FieldLabel.propTypes = {
  label: PropTypes.node.isRequired,
  textPostLabel: PropTypes.node,
  labelBold: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default FieldLabel;