export const AIRLINE_TABLE = {
  AIRLINE_NAME: 'name',
  AIRLINE_CODE: 'iataCode',
  COUNTRY: 'country',
  RATING: 'rating',
  STATUS: 'operationalStatus',
  LAST_UPDATED: 'lastUpdatedDate',
  DESCRIPTION: 'description',
}

export const AIRLINE_RATINGS = {
  HIGHLY_PREFERRED: 'HIGHLY_PREFERRED',
  MODERATELY_PREFERRED: 'MODERATELY_PREFERRED',
  NOT_PREFERRED: 'NOT_PREFERRED',
  NOT_RATED: 'NOT_RATED'
}

export const AIRLINE_STATUS = {
  OPERATIONAL: 'OPERATIONAL',
  SUSPENDED: 'SUSPENDED',
  CEASED: 'CEASED',
}

export const AIRLINE_ADVANCE_SEARCH_ITEMS = {
  COUNTRY_ID: 'countryId',
  RATING: 'rating',
  STATUS: 'operationalStatus',
}
