import * as types from './types';
import * as drawers from './drawers';

export const openMapSelection = () => {
  return openDrawerExclusive(drawers.MAP_SELECTION);
};

export const openMapPolygon = () => {
  return openDrawerExclusive(drawers.MAP_POLYGON);
};

export const closeMapPolygon = () => {
  return closeDrawer(drawers.MAP_POLYGON);
};

export const closeMapSelection = () => {
  return closeDrawer(drawers.MAP_SELECTION);
};

export const openMapDetail = () => {
  return openMapDetailDrawerExclusive(drawers.MAP_DETAIL);
};

export const closeMapDetail = () => {
  return closeDrawer(drawers.MAP_DETAIL);
};

export const openMapProvince = () => {
  return openDrawer(drawers.MAP_PROVINCE);
};

export const closeMapProvince = () => {
  return closeDrawer(drawers.MAP_PROVINCE);
};

export const openMapPersonsList = () => {
  return openDrawer(drawers.MAP_PERSONS_LIST);
};

export const closeMapPersonsList= () => {
  return closeDrawer(drawers.MAP_PERSONS_LIST);
};

export const openMapSitesList = () => {
  return openDrawer(drawers.MAP_SITES_LIST);
};

export const closeMapSitesList = () => {
  return closeDrawer(drawers.MAP_SITES_LIST);
};

export const openMapOccupantsList = () => {
  return openDrawer(drawers.MAP_OCCUPANTS_LIST);
};

export const closeMapOccupantsList = () => {
  return closeDrawer(drawers.MAP_OCCUPANTS_LIST);
};


export const openMapMenu = () => {
  return openDrawerExclusive(drawers.MAP_MENU);
};

export const closeMapMenu = () => {
  return closeDrawer(drawers.MAP_MENU);
};

export const openComposeMessage = () => {
  return openDrawerExclusive(drawers.COMPOSE_MESSAGE);
};

export const closeComposeMessage = () => {
  return closeDrawer(drawers.COMPOSE_MESSAGE);
};

export const openComposeNonExclusively = () => {
  return openDrawer(drawers.COMPOSE_MESSAGE);
};

export const openDrawer = (drawer) => ({
  type: types.OPEN_DRAWER,
  payload: {drawer}
});

export const closeDrawer = (drawer) => ({
  type: types.CLOSE_DRAWER,
  payload: {drawer}
});

export const toggleDrawer = (drawer) => ({
  type: types.TOGGLE_DRAWER,
  payload: {drawer}
});

export const openDrawerExclusive = (drawer) => ({
  type: types.OPEN_EXCLUSIVE,
  payload: {drawer}
});

export const openMapDetailDrawerExclusive = (drawer) => ({
  type: types.OPEN_MAP_DETAIL_EXCLUSIVE,
  payload: {drawer}
});

export const toggleDrawerExclusive = (drawer) => ({
  type: types.TOGGLE_EXCLUSIVE,
  payload: {drawer}
});

export const closeAllDrawers = () => ({
  type: types.CLOSE_ALL
});
