import {isValidElement} from 'react';
import _ from 'lodash';

import {Modal} from 'antd';

import localization from '../../../services/localization';
import {RawIntlProvider} from 'react-intl';

const TRANSLATED_FIELDS = ['content', 'title', 'okText', 'cancelText'];

const defaultProps = {
  centered: true
};

/**
 * If a value is an intl message (with id and defaultMessage values)
 * it is translated into a string using localization.formatMessage.
 *
 * If a value is a React element it is wrapped in a RawIntlProvider
 * using the localization.intl value.
 *
 * Otherwise the value is passed through and returned.
 *
 * @param value
 * @returns {string|*}
 */
const translateIfNeeded = (value) => {
  if (localization.isIntlMessageObject(value)) {
    return localization.formatMessage(value);
  }
  else if (isValidElement(value)) {
    return <RawIntlProvider value={localization.intl}>{value}</RawIntlProvider>;
  }

  return value;
};

/**
 * Maps the values of modal props and passes the configured
 * TRANSLATED_FIELDS values to translateIfNeeded
 *
 * @param props
 * @returns {{}}
 */
const translateModalProps = (props) => {
  props = {...defaultProps, ...props};
  return _.mapValues(props, (value, field) => {

    if (_.includes(TRANSLATED_FIELDS, field)) {
      return translateIfNeeded(value);
    }

    return value;
  });
};

/**
 * Abstraction of AntD Modal.confirm that translates intl content
 * using the localization service.
 *
 * @param props
 */
const confirm = (props) => {
  Modal.confirm(translateModalProps(props));
};

/**
 * Abstraction of AntD Modal.warning that translates intl content
 * using the localization service.
 *
 * @param props
 */
const warning = (props) => {
  Modal.warning(translateModalProps(props));
};

/**
 * Abstraction of AntD Modal.error that translates intl content
 * using the localization service.
 *
 * @param props
 */
const error = (props) => {
  Modal.error(translateModalProps(props));
};

/**
 * Abstraction of AntD Modal.info that translates intl content
 * using the localization service.
 *
 * @param props
 */
const info = (props) => {
  Modal.info(translateModalProps(props));
};

export default {
  translateIfNeeded,
  translateModalProps,
  confirm,
  warning,
  info,
  error
};
