const bottomLimits = {
  1: 1,
  2: 1.5,
  3: 2.5,
  4: 3.5,
  5: 4.5
};

const topLimits = {
  1: 1.49,
  2: 2.49,
  3: 3.49,
  4: 4.49,
  5: 5
};

function getLimits(levels) {

  return {
    bottomLimit: bottomLimits[levels[0]],
    topLimit: topLimits[levels[1]]
  };
}

export function getLevelsFilter(currentRatingProperty, levels) {
  const {bottomLimit, topLimit} = getLimits(levels);

  return [
    'any',
    ['==', currentRatingProperty, null],
    [
      'all',
      ['>=', currentRatingProperty, bottomLimit],
      ['<=', currentRatingProperty, topLimit]
    ]
  ];
}

export function getLevelsThreatZoneFilter(currentRatingProperty, levels) {
  const bottomLimit = levels[0];
  // we add 0.99 to compute the interval limit
  const topLimit = levels[1] + 0.99;

  return [
    'any',
    ['==', currentRatingProperty, null],
    [
      'all',
      ['>=', currentRatingProperty, bottomLimit],
      ['<=', currentRatingProperty, topLimit]
    ]
  ];
}

export function getLocationsFilter(locationsFilter) {
  const locationsFilterInteger = locationsFilter.map(f => parseInt(f));
  return [
    'any',
    ['match', ['get', 'regionId'], locationsFilterInteger, true, false],
    ['match', ['get', 'countryId'], locationsFilterInteger, true, false],
    ['match', ['get', 'provinceId'], locationsFilterInteger, true, false],
    ['match', ['get', 'id'], locationsFilterInteger, true, false]
  ];
}

export function isNotWorldwideLocation(locationsFilter) {
  return !locationsFilter?.includes('1');
}
