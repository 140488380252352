export const EMAIL_MESSAGE_FIELDS = {
  subject: 'subject',
  templateName: 'templateName',
  messageBody: 'messageBody',
  customFromName: 'customFromName',
  messageTemplate: 'messageTemplate',
  messageModalities: 'messageModalities',
  formats: 'formats',
  survey: 'survey',
  combined: 'combined',
  okResponse: 'okResponse',
  needHelpResponse: 'needHelpResponse'
};

export const EMAIL_SURVEY_FIELDS = {
  QUESTIONS: 'questions',
  RESPONSE_OPTIONS: 'responseOptions',
  NUMBER: 'number',
  TEXT: 'text'
}

export const MESSAGE_MODALITIES = {
  EMAIL_FORMAT: 'emailFormat',
  PUSH_FORMAT: 'pushFormat',
  SMS_FORMAT: 'smsFormat',
  TTS_FORMAT: 'ttsFormat'
};

export const MAX_CHAR_COUNT = {
  [MESSAGE_MODALITIES.EMAIL_FORMAT]: 8000000,
  [MESSAGE_MODALITIES.PUSH_FORMAT]: 3500,
  [MESSAGE_MODALITIES.SMS_FORMAT]: 277,
  [MESSAGE_MODALITIES.TTS_FORMAT]: 2500,
}

//maximum number of characters in one SMS message
export const MAX_SMS_LENGTH = 160;

export const REQUIRE_STATUS_CONFIRMATION = 'statusConfirmationOptions';
export const REQUIRE_STATUS_CONFIRMATION_CHECKED = 'statusConfirmationOptionsChecked';
export const RESPONSE_OPTIONS = 'responseOptions';
export const RESEND_FREQUENCY = 'resendFrequency';
export const RESPONSE_OPTIONS_VALUE = [
  {
    number: 1,
    text: 'OK'
  },
  {
    number: 2,
    text: 'Need Help'
  }
];

export const FREQUENCY_TIME_RANGE = {H: {min: 1, max: 24}, M: {min: 30, max: 60}};

export const CONFERENCE_BRIDGE = 'conferenceBridge';
export const CONFERENCE_BRIDGE_CHECKED = 'conferenceBridgeChecked';
export const PHONE_NUMBER = 'phoneNumber';
export const ACCESS_CODE = 'accessCode';

export const STATUS_CONFIRMATION_TYPE = {
  OK: 'okCount',
  NEED_HELP: 'needHelpCount',
  NO_RESPONSE: 'noResponseCount',
  UNDELIVERABLE: 'undeliverableCount'
};

export const MESSAGE_TEMPLATE_FIELDS = {
  TEMPLATE: 'template',
  NAME: 'name',
  AUDIT_INFO: 'auditInfo',
  UPDATED_AT: 'updatedAt',
  UPDATED_BY: 'updatedBy',
  USERNAME: 'username',
  INHERITED: 'inherited',
}