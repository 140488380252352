import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import useCreateNotification from '../notifications/useCreateNotification';
import CustomPropTypes from "../../constants/CustomPropTypes";
import {LOADING_IN_PROGRESS_ERROR} from './constants';

function useBaseManageHook(props) {
  const {
    createApi,
    updateApi,
    removeApi,
    messages
  } = props || {};

  const {showSuccessNotification, showErrorNotification} = useCreateNotification();
  const [loading, setLoading] = useState(false);

  const create = useCallback(async (configData) => {
    if (!loading) {
      setLoading(true);
      return createApi?.(configData)
        .then((result) => {
          messages?.successAdded && showSuccessNotification(messages.successAdded);
          return result;
        })
        .catch(err => {
          messages?.failureAdded && showErrorNotification(messages.failureAdded);
          console.error(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      return Promise.reject(LOADING_IN_PROGRESS_ERROR);
    }
  }, [createApi, messages, loading, showErrorNotification, showSuccessNotification]);

  const update = useCallback(async (configData) => {
    if (!loading) {
      setLoading(true);
      return updateApi?.(configData)
        .then((result) => {
          messages?.successUpdated && showSuccessNotification(messages.successUpdated);
          return result;
        })
        .catch(err => {
          messages?.failureUpdated && showErrorNotification(messages.failureUpdated);
          console.error(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      return Promise.reject(LOADING_IN_PROGRESS_ERROR);
    }
  }, [updateApi, messages, loading, showErrorNotification, showSuccessNotification]);

  const remove = useCallback(async (configData) => {
    if (!loading) {
      setLoading(true);
      return removeApi?.(configData)
        .then((result) => {
          messages?.successRemove && showSuccessNotification(messages.successRemove);
          return result;
        })
        .catch(err => {
          messages?.failureRemove && showErrorNotification(messages.failureRemove);
          console.error(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      return Promise.reject(LOADING_IN_PROGRESS_ERROR);
    }
  }, [removeApi, messages, loading, showErrorNotification, showSuccessNotification]);

  return {create, update, remove, loading};
}

useBaseManageHook.propTypes = {
  createApi: PropTypes.func,
  updateApi: PropTypes.func,
  removeApi: PropTypes.func,
  messages: PropTypes.shape({
    successAdded: CustomPropTypes.definedMessage,
    successUpdated: CustomPropTypes.definedMessage,
    successRemove: CustomPropTypes.definedMessage,
    failureAdded: CustomPropTypes.definedMessage,
    failureUpdated: CustomPropTypes.definedMessage,
    failureRemove: CustomPropTypes.definedMessage
  })
};

export default useBaseManageHook;
