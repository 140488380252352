import * as types from './types';
import {organizationConfigurationsTypes} from '../organization-configurations';

export const INITIAL_STATE = {
  isFetching: false,
  userId: null,
  personId: null,
  username: '',
  profile: {},
  userDetails: {},
  idleTimeout: undefined,
  roleAssignments: undefined,
  permissions: undefined,
  fullPermissions: {},
  currentPermissions: {},
  aggregatedPermissionIds: [],
  orgClientLogoUrl: undefined,
  postLoginEnabled: undefined,
  onboardingFieldsRequired: undefined,
  organizationHierarchy: undefined,
  organizationsDictionary: {},
  organizationsList: [],
  hideHeader: false,
  attributeRestrictions: {}
};

const authReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {

    case types.USER_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case types.USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profile: action.payload.profile,
        userDetails: action.payload.userDetails,
        userId: action.payload.userId,
        personId: action.payload.personId,
        username: action.payload.username,
        roleAssignments: action.payload.roleAssignments,
        permissions: action.payload.permissions,
        fullPermissions: action.payload.fullPermissions,
        currentPermissions: action.payload.currentPermissions,
        aggregatedPermissionIds: action.payload.aggregatedPermissionIds,
        attributeRestrictions: action.payload.attributeRestrictions
      };

    case types.USER_ERROR:
      return {
        ...state,
        isFetching: false,
        profile: {}
      };

    case types.LOGOUT_REQUEST:
      return {
        ...INITIAL_STATE
      };

    case types.IDLE_TIMEOUT_SUCCESS:
    case types.IDLE_TIMEOUT_FAILURE:
      return {
        ...state,
        idleTimeout: action.payload.idleTimeout
      };

    case types.SET_CURRENT_PERSONA:
      return {
        ...state,
        currentPermissions: state.fullPermissions[action.payload.id],
        postLoginEnabled: action.payload.postLoginEnabled
      };

    case types.GET_ORG_CLIENT_LOGO_REQUEST:
      return {
        ...state,
        orgClientLogoUrl: undefined
      };

    case types.GET_ORG_CLIENT_LOGO_SUCCESS:
      return {
        ...state,
        orgClientLogoUrl: action.payload.logoUrl
      };

    case types.GET_ORG_CLIENT_POST_LOGIN_CONFIG_REQUEST:
      return {
        ...state,
        postLoginEnabled: undefined
      };

    case types.GET_ORG_CLIENT_POST_LOGIN_CONFIG_SUCCESS:
      return {
        ...state,
        postLoginEnabled: action.payload.postLoginEnabled
      };

    case types.GET_ORG_CLIENT_POST_LOGIN_CONFIG_FAILURE:
      return {
        ...state,
        postLoginEnabled: false
      };

    case types.SET_ONBOARDING_FIELDS:
      return {
        ...state,
        onboardingFieldsRequired: action.payload
      };

    case types.SAVE_ONBOARDING_FIELDS_SUCCESS:
    case types.SAVE_ONBOARDING_FIELDS_FAILURE:
      return {
        ...state,
        onboardingFieldsRequired: false
      };

    case organizationConfigurationsTypes.UPDATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        postLoginEnabled: action.payload.data.postLoginEnabled ?? state.postLoginEnabled
      };

    case types.USER_ORG_TREE_SUCCESS:
      return {
        ...state,
        organizationHierarchy: action.payload.parsedOrgHierarchy,
        organizationsDictionary: action.payload.organizationsDictionary,
        organizationsList: action.payload.organizationsList
      };

    case types.MOBILE_HIDE_MAIN_HEADER:
      return {
        ...state,
        hideHeader: true
      };

    default:
      return state;
  }
};

export default authReducer;
