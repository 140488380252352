import {Component} from 'react';
import PropTypes from 'prop-types';

import {Popover} from 'antd';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';

import './InfoPopoverIcon.css';

export default class InfoPopoverIcon extends Component {

  static propTypes = {
    content: PropTypes.node.isRequired,
    title: PropTypes.string,
    placement: PropTypes.string,
    maxWidth: PropTypes.string
  };

  render() {
    return (
      <Popover
        content={this.props.content}
        title={this.props.title}
        trigger={['click']}
        placement={this.props.placement}
        overlayClassName={'InfoPopoverIconOverlay'}
        onClick={e => e.stopPropagation()}
        overlayStyle={{maxWidth: this.props.maxWidth || '400px'}}
      >
        <FontAwesomeIcon
          tabIndex={0}
          icon={faQuestionCircle}
          aria-hidden={false}
          aria-label={"Info icon"}
          focusable={true}
          className="InfoPopoverIcon icon"/>
      </Popover>
    );
  }
}
