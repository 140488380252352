import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LayoutSpace.css'

function LayoutSpace({children, className, size = 'large'}){
  return (
    <div className={classNames('LayoutSpace', size, className)}>
      {children}
    </div>
  )
}

LayoutSpace.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium']),
};

export default LayoutSpace
