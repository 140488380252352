import MapLayer from '../MapLayer';

import {BASE_API_URL} from '../../../../../services/api/base-utils';
import {LOCAION_TIER_ENUM_IDS, LOCATION_TIER_IDS} from '../../../../../constants/locations';

const THREAT_ZONE_SOURCE_BOUNDARY_ID = 'threat-zone-source-boundary';
export const THREAT_ZONE_LAYER_ID = 'threat-zone-layer-boundary';

function addLayer(map, layerDef) {
  map.addLayer(layerDef, 'regional_zones');
}

export default class ThreatZoneLayer extends MapLayer {
  organizationId;
  locationId;
  locationTierId;

  constructor(organizationId, locationTierId, locationId) {
    super();
    this.organizationId = organizationId;
    this.locationId = locationId;
    this.locationTierId = locationTierId;
  }

  getInteractiveLayerIds() {
    return this.getLayerIds();
  }

  getLayerIds() {
    return [THREAT_ZONE_LAYER_ID];
  }

  createSources() {
    if (this.map) {
      const locationTierEnumId = LOCAION_TIER_ENUM_IDS[this.locationTierId];
      this.map.addSource(THREAT_ZONE_SOURCE_BOUNDARY_ID, {
        'type': 'vector',
        'tiles': [
          `${BASE_API_URL}/mbtiles/v1/mbtiles/threatzone/{z}/{x}/{y}?locationTier=${locationTierEnumId}&organizationId=${this.organizationId}`
        ],
        minzoom: 1,
        maxzoom: 16
      });
    }
  }

  createLayers() {
    if (this.map) {
      let sourceLayer;
      const locationTierIdNumber = parseInt(this.locationTierId);
      if (locationTierIdNumber === LOCATION_TIER_IDS.COUNTRY) {
        sourceLayer = 'country_threat_zone_tiles';
      } else if (locationTierIdNumber === LOCATION_TIER_IDS.PROVINCE) {
        sourceLayer = 'province_threat_zone_tiles';
      } else if (locationTierIdNumber === LOCATION_TIER_IDS.CITY) {
        sourceLayer = 'city_threat_zone_tiles';
      }
      addLayer(this.map, {
        'id': THREAT_ZONE_LAYER_ID, // Layer ID
        'type': 'fill',
        'source': THREAT_ZONE_SOURCE_BOUNDARY_ID, // ID of the tile source created above
        "source-layer": sourceLayer,
        'paint': {
          'fill-opacity':
          [
            'step',
            ['zoom'],
            0.5, 16,
            0
          ]
        }
      });

      this.updateStyling();
    }
  }

  setRatingFilters() {
    this.updateStyling();
  }

  updateStyling() {
    if (this.map) {

      const currentRatingProperty = ['get', 'rating'];

      if (this.locationId) {
        const locationIdFilter = ['==', 'locationId', this.locationId]
        this.map.setFilter(THREAT_ZONE_LAYER_ID, locationIdFilter);
      }

      this.map.setPaintProperty(THREAT_ZONE_LAYER_ID, 'fill-pattern', [
        'step',
        ['to-number', currentRatingProperty, 0],
        '', 
        1, 'diag_minimal_greenblue_v1', 
        2, 'diag_low_green_v1', 
        3, 'diag_moderate_yellow_v1', 
        4, 'diag_high_orange_v1', 
        5, 'diag_extreme_red_v1'
      ]);
    }
  }
}
