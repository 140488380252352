import waRequest from './wa-request';

export const baseApi = '/collinson-auth/v1';

/**
 * Initiate authenticated GET ${baseApi}/token
 *
 * @returns {Promise}
 */
function getToken() {
  const url = `${baseApi}/token`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

export default {
  getToken
};
