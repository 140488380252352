import _ from 'lodash';

export function createCategoriesTree(categoryResults) {

  const nodes = {};
  return _.chain(categoryResults)
    .get('data')
    .orderBy('rank')
    .map(category => {

      return {
        parentId: category.parentId,
        title: category.name,
        tags: category.tags,
        value: category.id,
        key: category.id
      };
    })
    .forEach(category => {

      nodes[category.key] = _.defaults(category, nodes[category.key], {children: []});
      nodes[category.parentId] = _.defaults(nodes[category.parentId], {children: []});
      nodes[category.parentId]['children'].push(category);
    })
    .filter(category => !category.parentId)
    .value();
}

export function parseApiResult(categoriesResult) {

  const categoriesTree = createCategoriesTree(categoriesResult);
  const parentCategories = categoriesResult?.data?.filter(category => category?.parentId === undefined)
  const topLevelCategories = createCategoriesTree({data: parentCategories});

  const categoryDict = _.chain(categoriesResult)
    .get('data')
    .map(data => {
      data.value = data.id;
      return data;
    })
    .keyBy('id')
    .value();

  return {
    categoriesTree,
    topLevelCategories,
    dict: categoryDict
  };
}

/**
 * Returns the string name (title) corresponding to the
 * category found in the dictionary with the provided id.
 *
 * @param categoryDict
 * @param categoryId
 * @returns {*}
 */
export function getCategoryName(categoryDict, categoryId) {
  return _.get(categoryDict, [categoryId, 'name']);
}
