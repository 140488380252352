/**
 *
 *
 *
 *   EDITING THIS FILE REQUIRES EDITS TO EMAIL TEMPLATE PROJECT REPO FILE AS WELL TO KEEP THEM IN SYNC
 *
 *
 *
 *
 */


import {LOCALES} from '../../../../../../../constants/locale';

export const PREEXPAT_TEMPLATE_DEFAULTS = {
  [LOCALES.EN]: {
    subject: 'Pre Expatriation Notification',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Pre Expatriation Notification'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>You are scheduled to begin an expatriation to the below destination. Please see the latest advice for this destination along with any active alerts.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'You are receiving this notification because your organization has opted you into receiving pre expatriation notifications. For further questions about this notification please contact your organization’s expatriate risk management team or submit request for information to <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.FR]: {
    subject: "Notification préalable à l'expatriation",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "Notification préalable à l'expatriation"
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Il est prévu que vous commenciez une expatriation vers la destination ci-dessous. Consultez les derniers conseils pour cette destination ainsi que les alertes actives.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'Vous recevez cette notification parce que votre organisation a choisi de vous envoyer des notifications de pré-expatriation. Pour toute autre question concernant cette notification, veuillez contacter l\'équipe de gestion des risques liés aux expatriés de votre organisation ou envoyez une demande d\'informations à <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.ES]: {
    subject: 'Notificación previa a la expatriación',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Notificación previa a la expatriación'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Tiene previsto viajar a los siguientes destinos. A continuación encontrará los consejos de viaje más recientes para estos destinos junto con las alertas activas.</div><div><br></div><div>Está recibiendo esta notificación porque su organización ha optado por que reciba notificaciones previas al viaje. Si tienes más preguntas sobre esta notificación, ponte en contacto con el equipo de gestión de riesgos de viaje de tu organización o envía una solicitud de información a <a href="mailto:support@crisis24.com" rel="noopener noreferrer" target="_blank">support@crisis24.com</a>.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'Recibe esta notificación porque su organización ha optado por recibir notificaciones previas a la expatriación. Si tiene más preguntas sobre esta notificación, póngase en contacto con el equipo de gestión de riesgos expatriado de su organización o envíe una solicitud de información a <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.DE]: {
    subject: 'Benachrichtigung vor dem Auslandsaufenthalt',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Benachrichtigung vor dem Auslandsaufenthalt'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Es ist geplant, eine Expatriierung an das unten angegebene Ziel zu beginnen. Bitte beachten Sie die neuesten Hinweise für dieses Reiseziel sowie alle aktiven Benachrichtigungen.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'Sie erhalten diese Benachrichtigung, weil Ihre Organisation Sie für den Empfang von Benachrichtigungen vor dem Auslandsaufenthalt ausgewählt hat. Bei weiteren Fragen zu dieser Benachrichtigung wenden Sie sich bitte an das Risikomanagementteam Ihrer Organisation für Expats oder senden Sie eine Informationsanfrage an <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.IT]: {
    subject: 'Notifica pre-espatrio',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Notifica pre-espatrio'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>È previsto che inizi l\'espatrio verso la destinazione indicata di seguito. Consulta i consigli più recenti per questa destinazione insieme a eventuali avvisi attivi.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'Stai ricevendo questa notifica perché la tua organizzazione ha scelto di ricevere notifiche prima dell\'espatrio. Per ulteriori domande su questa notifica, contatta il team di gestione del rischio per gli espatriati della tua organizzazione o invia una richiesta di informazioni a <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.JA]: {
    subject: '海外赴任前通知',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': '海外赴任前通知'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>下記の目的地への駐在を開始する予定です。この宛先に関する最新のアドバイスと、アクティブなアラートを確認してください。</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'この通知を受け取っているのは、所属する組織が駐在前通知の受信を選択したためです。この通知についてさらに質問がある場合は、組織の駐在員リスク管理チームに連絡するか、<a href="mailto:support@crisis24.com">support@crisis24.com</a> に情報リクエストを送信してください。'
    }]
  },
  [LOCALES.PT]: {
    subject: 'Notificação de pré-expatriação',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Notificação de pré-expatriação'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Você está programado para iniciar uma expatriação para o destino abaixo. Consulte os conselhos mais recentes para este destino junto com todos os alertas ativos.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'Você está recebendo essa notificação porque sua organização optou por receber notificações de pré-expatriação. Para mais perguntas sobre essa notificação, entre em contato com a equipe de gerenciamento de riscos de expatriados da sua organização ou envie uma solicitação de informações para <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.ZH_HANS]: {
    subject: "出国前通知",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "出国前通知"
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>您计划开始移居到以下目的地。请查看有关该目的地的最新建议以及任何有效的警报。</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': '您之所以收到此通知，是因为您的组织已选择您接收离国前的通知。有关此通知的更多问题，请联系您所在组织的外籍人士风险管理团队或向 <a href="mailto:support@crisis24.com">support@crisis24.com</a> 提交信息请求。'
    }]
  },
  [LOCALES.ZH_HANT]: {
    subject: '外籍前通知',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': '外籍前通知'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>您預定開始外籍人士前往以下目的地。請查看有關此目的地的最新建議以及任何有效的警報。</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': '您收到此通知是因為您的組織已選擇您接收外籍前通知。如需有關此通知的進一步問題，請聯絡貴組織的外籍風險管理團隊，或將資訊提交至 <a href="mailto:support@crisis24.com">support@crisis24.com</a>。'
    }]
  },
  [LOCALES.NL]: {
    subject: 'Melding vóór vertrek',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Melding vóór vertrek'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Het is de bedoeling dat u begint te emigreren naar de onderstaande bestemming. Raadpleeg het meest recente advies voor deze bestemming, samen met eventuele actieve meldingen.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'U ontvangt deze melding omdat uw organisatie ervoor heeft gekozen om meldingen vóór uw vertrek te ontvangen. Voor verdere vragen over deze melding kunt u contact opnemen met het risicomanagementteam voor expats van uw organisatie of een verzoek om informatie indienen bij <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.HI]: {
    subject: 'प्री एक्सपैट्रिएशन नोटिफिकेशन',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'प्री एक्सपैट्रिएशन नोटिफिकेशन'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>आप नीचे दिए गए गंतव्य के लिए एक प्रवासी शुरू करने वाले हैं। कृपया किसी भी सक्रिय अलर्ट के साथ इस गंतव्य के लिए नवीनतम सलाह देखें।</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'आपको यह सूचना इसलिए मिल रही है क्योंकि आपके संगठन ने आपको प्रवास-पूर्व सूचनाएं प्राप्त करने का विकल्प चुना है। इस सूचना के बारे में और प्रश्नों के लिए कृपया अपने संगठन की प्रवासी जोखिम प्रबंधन टीम से संपर्क करें या <a href="mailto:support@crisis24.com">support@crisis24.com</a> पर जानकारी के लिए अनुरोध सबमिट करें।'
    }]
  },
  [LOCALES.RU]: {
    subject: 'Уведомление перед поездкой',
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': 'Уведомление перед поездкой'
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Вы планируете отправиться в указанные ниже пункты назначения. Ниже вы найдете последние рекомендации по путешествиям по этим направлениям, а также все активные уведомления.</div><div><br></div><div>Планируется, что вы начнете экспатриацию в указанное ниже место назначения. Пожалуйста, ознакомьтесь с последними рекомендациями по этому направлению, а также с любыми активными оповещениями.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'Вы получили это уведомление, потому что ваша организация разрешила вам получать уведомления до экспатриации. Если у вас возникнут дополнительные вопросы по этому уведомлению, обратитесь в отдел управления рисками, связанными с экспатриантами в вашей организации, или отправьте запрос на получение информации по адресу <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.PL]:{
    subject: "Powiadomienie przed emigracją",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "Powiadomienie przed emigracją"
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;First_Name&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;Last_Name&gt;</span></span>,</div><div><br></div><div>Planujesz rozpocząć emigrację do poniższego miejsca docelowego. Zapoznaj się z najnowszymi poradami dotyczącymi tego miejsca docelowego wraz z aktywnymi alertami.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'Otrzymujesz to powiadomienie, ponieważ Twoja organizacja zdecydowała się na otrzymywanie powiadomień przed ekspatriacją. W przypadku dalszych pytań dotyczących tego powiadomienia należy skontaktować się z zespołem ds. zarządzania ryzykiem za granicą lub przesłać prośbę o udzielenie informacji na adres <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  },
  [LOCALES.HE]:{
    subject: "הודעת טרום הגירה",
    components: [{
      'order': 1,
      'type': 'TITLE',
      'html': "הודעת טרום הגירה"
    }, {
      'order': 2,
      'type': 'DIVIDER'
    }, {
      'order': 3,
      'type': 'HTML',
      'html': '<div><span class="customEmbed" data-key="First_Name" translate="no"><span contenteditable="false">&lt;שם פרטי&gt;</span></span>&nbsp;<span class="customEmbed" data-key="Last_Name" translate="no"><span contenteditable="false">&lt;שם משפחה&gt;</span></span>,</div><div><br></div><div>אתה אמור להתחיל בגלות ליעד למטה. אנא עיין בעצות העדכניות ביותר עבור יעד זה יחד עם כל התראות פעילות.</div>'
    }, {
      'order': 4,
      'type': 'EXPAT_ASSIGNMENT_DETAILS',
      'includeExpatriationName': true,
      'includeExpatriationStartDate': true,
      'includeExpatriationEndDate': true
    }, {
      'order': 5,
      'html': 'Destinations: city, country',
      'includeLocationIntelligenceLinks': true,
      'includeLocationCountryBriefLink': true,
      'includeLocationCityBriefLink': true,
      'type': 'DESTINATIONS'
    }, {
      'order': 6,
      'type': 'DIVIDER'
    }, {
      'order': 7,
      'type': 'HTML',
      'html': 'אתה מקבל הודעה זו מכיוון שהארגון שלך בחר לך לקבל הודעות לפני הגירה. לשאלות נוספות בנוגע להודעה זו אנא צרו קשר עם צוות ניהול הסיכונים הגולים בארגון שלכם או הגשו בקשה לקבלת מידע אל <a href="mailto:support@crisis24.com">support@crisis24.com</a>.'
    }]
  }
}