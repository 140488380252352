import {useCallback} from "react";

import useBaseManageServiceHook from "../useBaseManageServiceHook";
import communicatorApi from "../../../services/api/communicator";


const config =  {
  createApi: communicatorApi.submitResponse
};

export default function useManageSurveyResponse(deliveryInstanceId) {

  const {create, loading} = useBaseManageServiceHook(config);

  const submitResponse = useCallback((payload) => {
    const configData = {
      pathParams: deliveryInstanceId,
      data: payload
    };
    return create(configData);
  }, [create, deliveryInstanceId]);

  return {
    create: submitResponse,
    loading
  };
}
