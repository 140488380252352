import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import {render} from 'react-dom';

import ReactWarningTracker from './react-warning-tracker';
import App from './containers/App/App';

ReactWarningTracker();

render(
  <App/>,
  document.getElementById('root')
);
