import MapMBTileOrgLayer from '../MapMBTileOrgLayer';

import {BASE_API_URL} from '../../../../../services/api/base-utils';
import {getRatingKey} from '../../../../../utils/rating-filters';
import {getLevelsFilter, getLocationsFilter, isNotWorldwideLocation} from '../+shared/utils';

import styles from '../../../../../containers/App/colors.module.css';

export const RATINGS_COUNTRY_SOURCE_ID = 'ratings-country-source';
export const RATINGS_COUNTRY_LAYER_ID = 'ratings-country-layer';

function replaceLayerById(map, layerId, sourceId) {
  const oldLayers = map.getStyle().layers;
  const layerIndex = oldLayers.findIndex(l => l.id === layerId);
  const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
  map.removeLayer(layerId);
  map.addLayer({
    'id': layerId, // Layer ID
    'type': 'fill',
    'source': sourceId, // ID of the tile source created above
    'source-layer': 'country_boundary_tiles',
    'paint': {
      'fill-opacity':
        [
          'step',
          ['zoom'],
          // 1 if zoom is less then 8
          1, 8,
          // 0.5 if zoom is less then 12
          0.5, 12,
          // 0 if zoom is 12 (or greater)
          0
        ]
    }
  }, before);
}

export default class CountryRatingsLayer extends MapMBTileOrgLayer {
  ratingId;
  levels;
  mapType;
  locationsFilter;

  getLayerIds() {
    return [RATINGS_COUNTRY_LAYER_ID];
  }

  getTilesUrl() {
    return `${BASE_API_URL}/mbtiles/v1/mbtiles/rating/{z}/{x}/{y}?tileType=Boundary&locationTier=Country&organizationId=${this.organizationId}`;
  }

  setTiles() {
    this.map.getSource(RATINGS_COUNTRY_SOURCE_ID).setTiles([this.getTilesUrl()]);
  }

  createSources() {
    if (this.map) {
      this.map.addSource(RATINGS_COUNTRY_SOURCE_ID, {
        'type': 'vector',
        'tiles': [this.getTilesUrl()],
        minzoom: 1,
        maxzoom: 16
      });
    }
  }

  createLayers() {
    if (this.map) {
      replaceLayerById(this.map, RATINGS_COUNTRY_LAYER_ID, RATINGS_COUNTRY_SOURCE_ID);

      this.updateStyling();
    }
  }

  setRatingFilters(ratingId, levels, mapType, locationsFilter) {
    if (this.ratingId !== ratingId || this.levels !== levels || this.mapType !== mapType || this.locationsFilter !== locationsFilter) {
      this.ratingId = ratingId;
      this.levels = levels;
      this.mapType = mapType;
      this.locationsFilter = locationsFilter;

      this.updateStyling();
    }
  }

  updateStyling() {
    if (this.map && this.organizationId && this.levels && this.levels.length > 0) {

      const currentRatingProperty = ['get', getRatingKey(this.ratingId)];

      const levelsFilter = getLevelsFilter(currentRatingProperty, this.levels);

      if (this.selectedCountry) {
        // filters out items that have a rating that is outside the levels wanted
        this.map.setFilter(RATINGS_COUNTRY_LAYER_ID,
          ['all',
            ['==', ['get', 'id'], parseInt(this.selectedCountry)],
            levelsFilter
          ]);
      }
      else {
        const filters = [levelsFilter];
        if (this.locationsFilter?.length > 0 && isNotWorldwideLocation(this.locationsFilter)) {
          filters.push(getLocationsFilter(this.locationsFilter));
        }
        this.map.setFilter(RATINGS_COUNTRY_LAYER_ID, ['all', ...filters]);
      }

      this.map.setPaintProperty(RATINGS_COUNTRY_LAYER_ID,
        'fill-color', [
          'step',
          ['to-number', currentRatingProperty, 0],
          // ratingUnrated if less then 1
          styles.ratingUnrated, 1,
          // ratingMinimal if less then 1.5
          styles.ratingMinimal, 1.5,
          // ratingLow if less then 2.5
          styles.ratingLow, 2.5,
          // ratingModerate if less then 3.5
          styles.ratingModerate, 3.5,
          // ratingHigh if less then 4.5
          styles.ratingHigh, 4.5,
          // ratingExtreme if more than 4.5
          styles.ratingExtreme
        ]);
    }
  }
}
