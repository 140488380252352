import _ from 'lodash';

import parsePagedResults from '../../utils/parsePagedResults';
import {SORT_DIRECTION} from '../../../constants/sorting';
import {USER_MODEL_FIELDS} from '../../../constants/users';

/**
 *
 * @param {string} sortDirection one of constants.ASCEND or constants.DESCEND
 * @return {string}
 */
export function getAbbreviatedSortDirection(sortDirection) {
  return sortDirection === SORT_DIRECTION.ASCEND ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
}

/**
 * Generate an array of strings which represents the HTTP Request parameter called "sort" that the backend expects for endpoint persons-ms/users/list.
 * Given the following parameters:
 * @param sortField {string} One of constants.PERSON_LASTNAME or constants.USER_STATUS - Sort by name or userStatus
 * @param tableSortDirection {string} One of constants.ASCEND or constants.DESCEND - increasing or decreasing sort order
 * @returns {Array<string>} One of:
  ["person.lastName,asc", "person.firstName,asc", "person.middleName,asc"],
  ["person.lastName,desc", "person.firstName,desc", "person.middleName,desc"],
  ["userStatus,asc"],
  ["userStatus,desc"]
 */
export function createSortParamsForAdminUserList(sortField, tableSortDirection) {
  const sortDir = tableSortDirection === SORT_DIRECTION.DESCEND ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
  let sortParams = [`${sortField},${sortDir}`];

  if (sortField === USER_MODEL_FIELDS.PERSON_LASTNAME) {
    sortParams.push(`${USER_MODEL_FIELDS.PERSON_FIRSTNAME},${sortDir}`); // adds firstName to sort to allow full name sorting
    sortParams.push(`${USER_MODEL_FIELDS.PERSON_MIDDLENAME},${sortDir}`); // adds middleName to sort to allow full name sorting
  }

  return sortField && tableSortDirection ? sortParams : undefined;
}

/**
 * Parses the data object of a organization.getOrganizationChildren request.
 *
 * The result objects include id / parentId combinations that are used to
 * create a tree structure with children nested under parents. Once this
 * tree has been created, the node references are also returned in a
 * flattened list and a dictionary to reference by id.
 *
 * @param userListResults
 * @returns {{usersList: *, pageable: {pageNumber: *, pageSize: *, sort: null, totalElements: *, direction: string}}}
 */
export function parseUserListResults(userListResults) {
  const pageable = parsePagedResults(userListResults);
  const userList = _.get(userListResults, ['data', 'content'], []);

  return {
    pageable,
    userList
  };
}

