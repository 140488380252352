import {bbox, featureCollection, intersect} from '@turf/turf';

import _ from 'lodash';

const MAP_STYLE_URL_BASE = 'mapbox://styles/prescienttraveler';

export const MAP_STYLES = {
  SATELLITE: {
    id: 'satellite',
    url: `${MAP_STYLE_URL_BASE}/${process.env.REACT_APP_SATELLITE_STYLE}`
  },
  STREET: {
    id: 'street',
    url: `${MAP_STYLE_URL_BASE}/${process.env.REACT_APP_STREET_STYLE}`
  },
  DARK: {
    id: 'dark',
    url: `${MAP_STYLE_URL_BASE}/${process.env.REACT_APP_DARK_STYLE}`
  },
  LIGHT: {
    id: 'light',
    url: `${MAP_STYLE_URL_BASE}/${process.env.REACT_APP_LIGHT_STYLE}`
  }
};

export const mapboxSupportedLanguages = ['ar', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'mul', 'pt', 'ru', 'zh-Hans', 'zh-Hant'];

function doesMapboxSupportLanguage(language) {
  return mapboxSupportedLanguages.includes(language);
}
/**
 * Return a list of strings corresponding to the list of styles available
 */
function getMapStyles(){
  return Object.values(MAP_STYLES).map(style => style.id);
}

/**
 * Async calls map.queryRenderedFeatures for the point, filters the results
 * to only include mapLayers if provided, and calls expandClusterFeatures
 * for each feature returned.
 *
 * Async Promise.all allows us to expand all clusters in parallel and
 * return only when all are complete.
 *
 * @param map
 * @param point
 * @param mapLayers
 * @returns {Promise}
 */
async function getFeaturesUnderPoint(map, point, mapLayers) {
  const features = map.queryRenderedFeatures(point);
  const layerFilter = createLayerFilter(mapLayers);

  const featurePromises = _.chain(features)
    .filter(layerFilter)
    .map(f => expandClusterFeatures(map, f))
    .value();

  return Promise.all(featurePromises);
}

/**
 * Async calls map.queryRenderedFeatures for the bounds of the polygon feature,
 * filters the results to only include mapLayers if provided,
 * further filters to include only features that intersect the polygon,
 * and calls expandClusterFeatures for each feature returned.
 *
 * Async Promise.all allows us to expand all clusters in parallel and
 * return only when all are complete.
 *
 * @param map
 * @param polygon
 * @param mapLayers
 * @returns {Promise}
 */
async function getFeaturesInPolygon(map, polygon, mapLayers) {
  const bounds = getBoundsFromFeature(polygon);
  const southWestPoint = map.project(bounds[0]);
  const northEastPoint = map.project(bounds[1]);

  const features = map.queryRenderedFeatures([southWestPoint, northEastPoint]);
  const layerFilter = createLayerFilter(mapLayers);

  const featurePromises = _.chain(features)
    .filter(layerFilter)
    .filter(f => intersect(f, polygon.geometry))
    .map(f => expandClusterFeatures(map, f))
    .value();

  return Promise.all(featurePromises);
}

/**
 * Returns a filter function that accepts a map feature and returns
 * true if that map is part of the mapLayersOfInterest collection
 * or true if the mapLayersOfInterest is empty;
 *
 * @param mapLayersOfInterest
 * @returns {function(*): (boolean|*)}
 */
function createLayerFilter(mapLayersOfInterest) {

  const layerIds = _.reduce(mapLayersOfInterest, (reducer, layer) => {
    return _.concat(layer?.getInteractiveLayerIds(), reducer);
  }, []);

  return (feature) => {
    const noFilter = _.isEmpty(mapLayersOfInterest);
    const layerId = _.get(feature, 'layer.id');
    const passesFilter = _.includes(layerIds, layerId);

    return noFilter || passesFilter;
  };
}

/**
 * If a feature is a cluster (properties.cluster = true) wait for
 * getClusterChildrenAsync to fetch its children and then set the bounds
 * for the feature using getBoundsFromFeature.
 *
 * Mutates feature, the feature will have bounds and children props added
 * if appropriate.
 *
 * @param map
 * @param feature
 * @returns {Promise<*>}
 */
async function expandClusterFeatures(map, feature) {
  const isCluster = _.get(feature, 'properties.cluster');

  if (isCluster) {
    const propChildren = _.get(feature, 'properties.children');
    const customCluster = _.get(feature, 'properties.customCluster');

    if (propChildren) {
      try {
        const children = JSON.parse(propChildren);
        _.forEach(children, c => _.set(c, 'bounds', getBoundsFromFeature(c)));
        _.set(feature, 'children', children);
      }
      catch (e) {
        // Do nothing
      }

    }
    else if (customCluster) {
      return feature;
    }
    else {
      const clusterId = _.get(feature, 'properties.cluster_id');
      const source = _.get(feature, 'layer.source');

      const children = await getClusterChildrenAsync(map, source, clusterId);

      _.forEach(children, c => _.set(c, 'bounds', getBoundsFromFeature(c)));
      _.set(feature, 'children', children);
    }
  }

  _.set(feature, 'bounds', getBoundsFromFeature(feature));

  return feature;
}

/**
 * Fetch its children of custom cluster layers leaves for given
 * feature and then set the bounds
 * for the feature using getBoundsFromFeature.
 *
 * Mutates feature, the feature will have bounds and children props added.
 *
 * @param feature
 * @param clusterLayer
 */
function expandCustomClusterFeature(feature, clusterLayer) {
  if (feature && clusterLayer) {
    const clusterId = _.get(feature, 'properties.cluster_id');
    const children = clusterLayer.getClusterChildren(clusterId);
    _.forEach(children, c => _.set(c, 'bounds', getBoundsFromFeature(c)));
    _.set(feature, 'children', children);
    _.set(feature, 'bounds', getBoundsFromFeature(feature));
  }
}

/**
 * Returns the cluster children asynchronously from
 * map.getSource(source).getClusterLeaves(clusterId, max, offset)
 *
 * @param map
 * @param source
 * @param clusterId
 * @param max
 * @param offset
 * @returns {Promise<any>}
 */
function getClusterChildrenAsync(map, source, clusterId, max = 10000, offset = 0) {
  return new Promise((resolve, reject) => {
    try {
      const clusterSource = map.getSource(source);

      clusterSource.getClusterLeaves(clusterId, max, offset, (error, childFeatures) => {
        if (error) {
          reject(error);
        }
        else {
          resolve(childFeatures);
        }
      });
    }
    catch (error) {
      reject(error);
    }
  });
}

/**
 * Returns the zoom level needed to show entire cluster in frame
 * from map.getSource(source).getClusterExpansionZoom(clusterId)
 *
 * @param map
 * @param source
 * @param clusterId
 * @returns {Promise<any>}
 */
function getClusterExpansionZoomAsync(map, source, clusterId) {
  return new Promise((resolve, reject) => {
    try {
      const clusterSource = map.getSource(source);

      clusterSource.getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error) {
          reject(error);
        }
        else {
          resolve(zoom);
        }
      });
    }
    catch (error) {
      reject(error);
    }
  });
}

/**
 * Returns the getBoundingBox value of the
 * @param feature
 */
export function getBoundsFromFeature(feature) {
  if (feature.children) {
    return getBoundingBox(featureCollection(feature.children));
  }
  else if(feature?.type === 'FeatureCollection'){
    return getBoundingBox(feature);
  }
  else {
    return getBoundingBox(feature.geometry);
  }
}

/**
 * Returns a turf.bbox from geometry [x1,y1,x2,y2]
 * converted to mapbox [[x1,y1],[x2,y2]] format
 *
 * @param geometry
 * @returns {*[][]}
 */
function getBoundingBox(geometry) {
  if (_.get(geometry, 'type') === 'Point') {
    return [
      geometry.coordinates,
      geometry.coordinates
    ];
  }

  const bounds = bbox(geometry);

  if ((Math.abs(bounds[2] - bounds[0])) > 179) {
    bounds[2] -= 360;
  }

  return [
    [bounds[0], bounds[1]],
    [bounds[2], bounds[3]]
  ];
}

export const BOUNDS_OPTIONS = {padding: 20, linear: true};

export default {
  getMapStyles,
  doesMapboxSupportLanguage,
  getFeaturesUnderPoint,
  getFeaturesInPolygon,
  expandClusterFeatures,
  expandCustomClusterFeature,
  getClusterChildrenAsync,
  getClusterExpansionZoomAsync,
  getBoundsFromFeature,
  getBoundingBox,
  createLayerFilter
};
