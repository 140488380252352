export const AUTO_STATUS_CONFIRM = {
  NAME: 'name',
  DURATION: 'duration',
  NOTIFICATION_CONFIG: 'notificationConfig',
  POC_NOTIFICATION_CONFIG: 'pocNotificationConfig',
  MODALITIES: 'modalities',
  TYPE: 'type'
};

export const AUTO_STATUS_CONFIRM_TYPE = {
  TRAVELER: 'TRAVELER'
}

export const AUTO_STATUS_CONFIRM_MODALITIES = {
  EMAIL: 'EMAIL',
  PUSH: 'PUSH',
  SMS: 'SMS',
  TTS: 'TTS'
}

export const TRAVELER_NOTIFICATION_CONFIG = {
  DESTINATION_TYPES: 'destinationTypes',
  LOCATION_IDS: 'locationIds',
  RISK_RATING: 'countryRiskRatingThresholdRange',
  DELIVERY_FREQUENCY: 'deliveryFrequency',
  RESEND_DURATION: 'resendDuration',
  RESEND_FREQUENCY: 'resendFrequency',
  SEND_AT: 'sendAt',
  TYPE: 'type'
};
export const POC_NOTIFICATION_CONFIG = {
  USER_IDS: 'userIds',
  RESPONSE_SUMMARY_NOTIFICATION_TIMER: 'responseSummaryNotificationTimer',
  NO_RESPONSE_NOTIFICATION_TIMER: 'noResponseNotificationTimer',
  NEED_HELP_NOTIFICATION_ENABLED: 'needHelpNotificationEnabled'
}

export const DURATION_KEYS = {
  START_AT: 'startAt',
  END_AT: 'endAt'
}

export const RISK_RATING_KEYS = {
  FROM: 'from',
  TO: 'to'
}

export const DESTINATION_TYPES = {
  INTERNATIONAL: 'INTERNATIONAL',
  DOMESTIC: 'DOMESTIC'
};

export const DELIVERY_FREQUENCY = {
  DAILY: 'DAILY',
  HOURLY: 'HOURLY',
}

export const TIME_UNIT = {
  HOURS: 'hours',
  MINUTES: 'minutes'
}

export const DEFAULT_RESEND_DURATION = 'PT1H';