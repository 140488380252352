import waRequest from './wa-request';

export const apiBase = '/incidents/v1';

/**
 * Initiate authenticated GET ${apiBase}/alerts/${alertId}
 *
 * @param alertId
 * @param locale
 * @param returnMapImage
 * @returns {Promise}
 */

function getIncidentById({pathParams: {incidentId}, params: {locale} = {}, signal}) {
  const url = `${apiBase}/incident-reports/${incidentId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      locale
    }
  });
}


export default {
  getIncidentById
};

