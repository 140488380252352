import MapMBTileOrgLayer from '../MapMBTileOrgLayer';

import {BASE_API_URL} from '../../../../../services/api/base-utils';
import {RATINGS_CITY_LAYER_POINT_ID} from '../rating/CityRatingsLayer';
import {getLevelsThreatZoneFilter, getLocationsFilter, isNotWorldwideLocation} from '../+shared/utils';

import styles from '../../../../../containers/App/colors.module.css';

const THREAT_ZONE_COUNTRY_SOURCE_BOUNDARY_ID = 'threat-zone-country-source-boundary';
export const THREAT_ZONE_COUNTRY_LAYER_ID = 'threat-zone-country-layer-boundary';
export const THREAT_ZONE_COUNTRY_LINE_LAYER_ID = 'threat-zone-country-line-layer-boundary';

function addLayer(map, layerDef) {
  map.addLayer(layerDef, RATINGS_CITY_LAYER_POINT_ID);
}

export default class CountryThreatZoneLayer extends MapMBTileOrgLayer {
  levels;
  mapType;
  locationTier;
  locationsFilter;

  constructor(locationTier) {
    super();
    this.locationTier = locationTier;
  }

  getInteractiveLayerIds() {
    return [THREAT_ZONE_COUNTRY_LAYER_ID];
  }

  getLayerIds() {
    return [THREAT_ZONE_COUNTRY_LAYER_ID, THREAT_ZONE_COUNTRY_LINE_LAYER_ID];
  }

  getTilesUrl() {
    return `${BASE_API_URL}/mbtiles/v1/mbtiles/threatzone/{z}/{x}/{y}?locationTier=${this.locationTier}&organizationId=${this.organizationId}`;
  }

  setTiles() {
    this.map.getSource(THREAT_ZONE_COUNTRY_SOURCE_BOUNDARY_ID).setTiles([this.getTilesUrl()]);
  }

  createSources() {
    if (this.map) {
      this.map.addSource(THREAT_ZONE_COUNTRY_SOURCE_BOUNDARY_ID, {
        'type': 'vector',
        'tiles': [this.getTilesUrl()],
        minzoom: 1,
        maxzoom: 16
      });
    }
  }

  createLayers() {
    if (this.map) {
      const opacity = [
        'step',
        ['zoom'],
        1, 11,
        0
      ];

      addLayer(this.map, {
        'id': THREAT_ZONE_COUNTRY_LAYER_ID, // Layer ID
        'type': 'fill',
        'source': THREAT_ZONE_COUNTRY_SOURCE_BOUNDARY_ID, // ID of the tile source created above
        "source-layer": 'country_threat_zone_tiles',
        'paint': {
          'fill-opacity': opacity
        }
      });

      addLayer(this.map, {
        'id': THREAT_ZONE_COUNTRY_LINE_LAYER_ID,
        'source': THREAT_ZONE_COUNTRY_SOURCE_BOUNDARY_ID,
        "source-layer": 'country_threat_zone_tiles',
        'type': 'line',
        'paint': {
          'line-opacity': opacity,
          'line-width': 1
        }
      });

      this.updateStyling();
    }
  }

  setRatingFilters(levels, mapType, locationsFilter) {
    if (this.levels !== levels || this.mapType !== mapType || this.locationsFilter !== locationsFilter) {
      this.levels = levels;
      this.mapType = mapType;
      this.locationsFilter = locationsFilter;

      this.updateStyling();
    }
  }

  updateStyling() {
    if (this.map && this.organizationId && this.levels && this.levels.length > 0) {

      const currentRatingProperty = ['get', 'rating'];

      const filters = [getLevelsThreatZoneFilter(currentRatingProperty, this.levels)];

      if (this.locationsFilter?.length > 0 && isNotWorldwideLocation(this.locationsFilter)) {
        filters.push(getLocationsFilter(this.locationsFilter));
      }

      this.map.setFilter(THREAT_ZONE_COUNTRY_LAYER_ID, ['all', ...filters]);
      this.map.setFilter(THREAT_ZONE_COUNTRY_LINE_LAYER_ID, ['all', ...filters]);

      this.map.setPaintProperty(THREAT_ZONE_COUNTRY_LAYER_ID, 'fill-pattern', [
        'step',
        ['to-number', currentRatingProperty, 0],
        '',
        1, 'diag_minimal_greenblue_v1',
        2, 'diag_low_green_v1',
        3, 'diag_moderate_yellow_v1',
        4, 'diag_high_orange_v1',
        5, 'diag_extreme_red_v1'
      ]);

      this.map.setPaintProperty(THREAT_ZONE_COUNTRY_LINE_LAYER_ID,
        'line-color', [
          'step',
          ['to-number', currentRatingProperty, 0],
          styles.ratingUnrated, 1, styles.ratingMinimal, 2, styles.ratingLow, 3, styles.ratingModerate, 4, styles.ratingHigh, 5, styles.ratingExtreme
        ]);
    }
  }
}
