import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {notificationActions} from '../../redux/modules/notification';

export default function useCreateNotification() {
  const dispatch = useDispatch();

  const showNotification = useCallback((description, key, title, icon, keepOpen) => {
    dispatch(notificationActions.addNotification(description, key, title, icon, keepOpen));
  }, [dispatch]);

  const showSuccessNotification = useCallback((description, key, title, icon, keepOpen) => {
    dispatch(notificationActions.showSuccessNotification(description, key, title, icon, keepOpen));
  }, [dispatch]);

  const showErrorNotification = useCallback((description, key, title, icon, keepOpen) => {
    dispatch(notificationActions.showErrorNotification(description, key, title, icon, keepOpen));
  }, [dispatch]);

  const showDownloadCompleteNotification = useCallback((description, title, buttonLabel, downloadCallback, key) => {
    dispatch(notificationActions.downloadCompleteNotification(description, title, buttonLabel, downloadCallback, key));
  }, [dispatch]);

  const removeNotification = useCallback((key) => {
    dispatch(notificationActions.removeNotification(key));
  }, [dispatch])

  const keepOpenSuccessNotification = useCallback((description, title, buttonLabel, downloadCallback, key) => {
    dispatch(notificationActions.keepOpenSuccessNotification(description, title, buttonLabel, downloadCallback, key));
  }, [dispatch]);

  return {
    showNotification,
    showSuccessNotification,
    showErrorNotification,
    showDownloadCompleteNotification,
    removeNotification,
    keepOpenSuccessNotification
  };
}
