import {area, convertArea} from '@turf/turf';
import _ from 'lodash';

import mapboxUtils from '../utils/mapbox-utils';

export const POLYGON_SELECTION_LAYER = 'polygon-manager-selection-layer';
export const POLYGON_BOUNDS_LAYER = 'polygon-manager-bounds-layer';

const MAX_POLYGON_AREA_KM_SQ = 500000000; // 500 million sq km little less than half the planet

export default class PolygonManager {

  /**
   * Constructor.
   *
   * onChangeCallback(error, polygon) will be triggered anytime a polygon is created, updated,
   * selected, or deleted. If the polygon fails validation, it is removed and
   * an error is passed.
   *
   * @param config {{
   *     onChange: function,
   *     onSelect: function,
   *     onError: function
   * }}
   */
  constructor(config) {
    this.drawMode = 'simple_select';
    this.defaultPolygon = null;

    this.onChange = _.get(config, 'onChange');
    this.onSelect = _.get(config, 'onSelect');
    this.onError = _.get(config, 'onError');
  }

  /**
   * Sets internal map reference and adds listeners
   *
   * @param map
   * @param drawTool
   */
  onAdd(map, drawTool) {

    this.map = map;
    this.draw = drawTool;

    if (this.map) {
      this.map.on('draw.create', this.onDrawCreate.bind(this));
      this.map.on('draw.update', this.onDrawUpdate.bind(this));
      this.map.on('draw.delete', this.onDrawDelete.bind(this));
      this.map.on('draw.modechange', this.onDrawModeChange.bind(this));
      this.map.on('draw.selectionchange', this.onDrawSelectionChange.bind(this));
    }

    this.setCurrentPolygon(this.defaultPolygon);
  }

  /**
   * Removes listeners and sets map and draw references to null
   */
  onRemove() {

    if (this.map) {
      this.map.off('draw.create', this.onDrawCreate);
      this.map.off('draw.update', this.onDrawUpdate);
      this.map.off('draw.delete', this.onDrawDelete);
      this.map.off('draw.modechange', this.onDrawModeChange);
      this.map.off('draw.selectionchange', this.onDrawSelectionChange);
    }

    this.map = null;
    this.draw = null;
  }

  /**
   * Triggered by draw.delete
   * triggerPolygonChange is called with null to signal deletion.
   *
   */
  onDrawDelete() {
    this.clearAll();
    this.triggerPolygonChange(undefined);
    this.triggerPolygonSelect(undefined);
  }

  /**
   * Triggered by draw.modechange
   * calls removeLastPolygon to ensure only one polygon on the map at once
   */
  onDrawModeChange(event) {
    this.removeLastPolygon();
    this.drawMode = event.mode;
  }

  /**
   * Triggered by draw.create
   * If the polygon passes validation, triggerPolygonChange is
   * called with event.features[0] or null if undefined.
   *
   * @param event
   */
  onDrawCreate(event) {
    const polygon = this.createPolygonFromDrawEvent(event);
    if (this.validatePolygon(polygon) && this.validateDrawMode()) {
      this.triggerPolygonChange(polygon);
    }
  }

  /**
   * Triggered by draw.update
   * If the polygon passes validation, triggerPolygonChange is
   * called with event.features[0] or null if undefined.
   *
   * @param event
   */
  onDrawUpdate(event) {
    const polygon = this.createPolygonFromDrawEvent(event);
    if (this.validatePolygon(polygon) && this.validateDrawMode()) {
      this.triggerPolygonChange(polygon);
    }
  }

  /**
   * Triggered by draw.selectionchange
   * If the polygon passes validation, triggerPolygonSelect is
   * called with event.features[0] or null if deselected.
   *
   * @param event
   */
  onDrawSelectionChange(event) {
    const polygon = this.createPolygonFromDrawEvent(event);
    if (this.validatePolygon(polygon) && this.validateDrawMode()) {
      this.triggerPolygonSelect(polygon);
    }
  }

  /**
   * Returns a polygon feature created from the first item in features
   * returned from a draw event and sets the bounds and layer id
   *
   * @param event
   * @returns {*}
   */
  createPolygonFromDrawEvent(event) {
    const polygon = _.get(event, 'features[0]');

    if (polygon) {
      const bounds = mapboxUtils.getBoundsFromFeature(polygon);

      return {
        ...polygon,
        bounds,
        layer: {
          id: POLYGON_SELECTION_LAYER
        }
      };
    }
  }

  /**
   * Return a polygon feature created from the current map bounds
   *
   * @returns {{bounds: *[][], geometry: {coordinates: *[][][], type: string}, type: string, layer: {id: string}}}
   */
  createPolygonFromMapBounds() {
    if (this.map) {
      const {_sw, _ne} = this.map.getBounds();

      return {
        type: 'Feature',
        layer: {
          id: POLYGON_BOUNDS_LAYER
        },
        bounds: [
          [_sw.lng, _sw.lat],
          [_ne.lng, _ne.lat]
        ],
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [_sw.lng, _sw.lat],
            [_sw.lng, _ne.lat],
            [_ne.lng, _ne.lat],
            [_ne.lng, _sw.lat],
            [_sw.lng, _sw.lat]
          ]]
        }
      };
    }
  }

  /**
   * Take current map bounds and convert it to a polygon.
   * After validation, trigger polygon select with this simple rectangle
   */
  selectPolygonFromMapBounds() {
    const polygon = this.createPolygonFromMapBounds();
    if (this.validatePolygon(polygon)) {
      this.triggerPolygonSelect(polygon);
    }
  }

  /**
   * Get all current polygon features on the map
   *
   * @returns {*|features|Function}
   */
  getCurrentPolygons() {
    return this.draw && this.draw.getAll().features;
  }

  /**
   * Find and remove the current polygon in index 0
   */
  removeLastPolygon() {
    const currentPolygons = this.getCurrentPolygons();
    if (this.draw && currentPolygons.length > 1) {
      const featureIdToDelete = currentPolygons[0].id;
      this.draw.delete(featureIdToDelete);
      this.triggerPolygonChange(null);
    }
  }

  /**
   * Since we only support drawing a single polygon for searching, this method
   * allows a safe way to set a single polygon feature geometry to the draw tools.
   *
   * If a geometry in polygonFeature is not passed, clearAll is called.
   *
   * @param polygonFeature
   */
  setCurrentPolygon(polygonFeature) {
    this.defaultPolygon = polygonFeature;
    if (this.draw) {
      if (polygonFeature) {
        this.draw.set({
          type: 'FeatureCollection',
          features: [polygonFeature]
        });
      }
      else {
        this.clearAll();
      }
    }
  }

  /**
   * Returns true if the draw tool mode is anything other than simple_select
   *
   * @returns {boolean}
   */
  isDrawing() {
    return this.drawMode !== 'simple_select';
  }

  /**
   * Set draw to an empty FeatureCollection
   */
  clearAll() {
    if (this.draw) {
      this.draw.set({
        type: 'FeatureCollection',
        features: []
      });
    }
  }

  /**
   * Trigger the polygon.error to fire from the map
   *
   * @param error
   * @returns {*|void}
   */
  triggerPolygonError(error) {

    this.onDrawDelete();

    if (this.onError) {
      this.onError(error);
    }
    if (this.map) {
      return this.map.fire('polygon.error', {error});
    }
  }

  /**
   * Trigger the polygon.change to fire from the map
   *
   * @param polygon
   * @returns {*|void}
   */
  triggerPolygonChange(polygon) {
    if (this.onChange) {
      this.onChange(polygon);
    }
    if (this.map) {
      return this.map.fire('polygon.change', {polygon});
    }
  }

  /**
   * Trigger the polygon.select to fire from the map
   *
   * @param polygon
   * @returns {*|void}
   */
  triggerPolygonSelect(polygon) {
    if (this.onSelect) {
      this.onSelect(polygon);
    }
    if (this.map) {
      return this.map.fire('polygon.select', {polygon});
    }
  }

  /**
   * Validates the square kilometers of provided polygon and return true
   * if the kilometer is withing the limit, false if not.
   *
   * Also, if the polygon is large than the limit, triggerPolygonError is
   * called passing the area of the new polygon and the max for reference.
   *
   * @param polygon
   * @returns {boolean}
   */
  validatePolygon(polygon) {
    const polygonArea = PolygonManager.getPolygonAreaSquareKilometers(polygon);
    const isTooLarge = polygonArea > MAX_POLYGON_AREA_KM_SQ;

    if (isTooLarge) {
      this.triggerPolygonError({
        type: 'tooLarge',
        area: polygonArea,
        max: MAX_POLYGON_AREA_KM_SQ
      });

      return false;
    }

    return true;
  }

  /**
   * Validates the draw mode is not draw_line_string or draw_polygon
   *
   * @returns {boolean}
   */
  validateDrawMode() {
    return (
      this.drawMode !== 'draw_line_string' &&
      this.drawMode !== 'draw_polygon' &&
      this.drawMode !== 'draw_point'
    );
  }

  /**
   * Static method that uses turf.js to provide the square kilometers of a polygon.
   * If the polygon is not defined, this function returns 0
   *
   * @param polygon
   * @returns {number}
   */
  static getPolygonAreaSquareKilometers(polygon) {
    const geometry = _.get(polygon, 'geometry');

    if (!geometry) {
      return 0;
    }

    const areaInMeters = area(geometry);

    return Math.round(convertArea(areaInMeters, 'meters', 'kilometers'));
  }
}
