import waRequest from './wa-request';

export const apiBase = '/categories/v1';

/**
 * Initiate authenticated POST ${apiBase}/categories/searches
 *
 * @returns {Promise}
 */
function getCategories(locale = 'en', includeSubcategories = false, tag = 'ALERTS') {
  const url = `${apiBase}/categories/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale
    },
    data: {
      tags: [tag],
      includeSubcategories
    }
  });
}

/**
 * Initiate authenticated POST ${apiBase}/categories/searches to get all categories user has access to
 *
 * @returns {Promise}
 */
function getAllCategories({params: {locale = 'en'}}) {
  const url = `${apiBase}/categories/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale
    },
    data: {
      includeSubcategories: true
    }
  });
}

export default {
  getCategories,
  getAllCategories
};

