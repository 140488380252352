import * as types from './types';
import _ from 'lodash';

import {parseApiResult} from './utils';

/**
 * Request categories.getCategories, parse results into all + parents categories tree and dict
 */
export const getAlertCategories = (locale, includeSubcategories = true) => {
  return (dispatch, _getState, {categories}) => {
    dispatch({type: types.ALERT_CATEGORIES_REQUEST});
    return categories.getCategories(locale, includeSubcategories)
      .then(results => {
        const {categoriesTree: alertCategoriesTree, topLevelCategories: alertParentCategoriesTree, dict} = parseApiResult(results);
        dispatch({
          type: types.ALERT_CATEGORIES_SUCCESS,
          payload: {alertCategoriesTree, alertParentCategoriesTree, dict},
        })
      })
      .catch(error => dispatch({
        type: types.ALERT_CATEGORIES_ERROR,
        error
      }));
  }
};

/**
 * Request categories.getCategories, parse results into all + parents categories tree and dict
 */
export const getArticlesCategories = (locale) => {
  return (dispatch, _getState, {categories}) => {
    dispatch({type: types.ARTICLES_CATEGORIES_REQUEST});
    return categories.getCategories(locale, true, 'ARTICLES')
      .then(results => {
        const {categoriesTree: alertCategoriesTree, topLevelCategories: alertParentCategoriesTree, dict} = parseApiResult(results);
        dispatch({
          type: types.ARTICLES_CATEGORIES_SUCCESS,
          payload: {alertCategoriesTree, alertParentCategoriesTree, dict},
        })
      })
      .catch(error => dispatch({
        type: types.ARTICLES_CATEGORIES_ERROR,
        error
      }));
  }
};

/**
 * Request categories.getCategories, parse results into all + parents categories tree and dict
 */
export const getIncidentCategories = (locale) => {
  return (dispatch, _getState, {categories}) => {
    dispatch({type: types.INCIDENT_CATEGORIES_REQUEST});
    return categories.getCategories(locale, true, 'INCIDENT_REPORTS')
      .then(results => {
        const {categoriesTree: alertCategoriesTree, topLevelCategories: alertParentCategoriesTree, dict} = parseApiResult(results);
        dispatch({
          type: types.INCIDENT_CATEGORIES_SUCCESS,
          payload: {alertCategoriesTree, alertParentCategoriesTree, dict},
        })
      })
      .catch(error => dispatch({
        type: types.INCIDENT_CATEGORIES_ERROR,
        error
      }));
  }
};

/**
 * Request categories.getRatingsCategories, parse results into all + parents categories tree and dict
 */
export const getRatingsCategories = (locale) => {
  return (dispatch, getState, {categories}) => {
    dispatch({type: types.RATINGS_CATEGORIES_REQUEST});

    const aggPermissions = _.get(getState(), 'session.aggregatedPermissionIds', []);
    const includeSubCategories = aggPermissions.includes('VIEW_SUB_CATEGORY_RATINGS');

    return categories.getCategories(locale, includeSubCategories, 'RATINGS')
      .then(results => {
        const {categoriesTree: alertCategoriesTree, dict} = parseApiResult(results);
        dispatch({
          type: types.RATINGS_CATEGORIES_SUCCESS,
          payload: {alertCategoriesTree, dict}
        })
      })
      .catch(error => dispatch({
        type: types.RATINGS_CATEGORIES_ERROR,
        error
      }));
  }
};

const syncCategories = (force = false) => {
  return (dispatch, getState, {categories}) => {
    const locale = getState()?.localization?.locale;

    if (force || getState()?.categories?.locale !== locale || _.isEmpty(getState()?.categories?.dict)) {
      dispatch({type: types.CATEGORIES_REQUEST});

      return categories.getAllCategories({params:{locale}})
        .then(results => {
          dispatch({
            type: types.CATEGORIES_SUCCESS,
            payload: {
              ...parseApiResult(results),
              locale
            }
          });
        })
        .catch(error => dispatch({
          type: types.CATEGORIES_ERROR,
          error
        }));
    }
  };
};

const _throttledSyncCategories = _.throttle((dispatch, force) => dispatch(syncCategories(force)), 10000);

export const getCategories = (force) => {
  return (dispatch) => {
    return _throttledSyncCategories(dispatch, force);
  };
};
