import PropTypes from 'prop-types';

import './BasePageHeaderSubtitle.css';

function BasePageHeaderSubtitle(props = {}) {
  const {subtitle} = props;

  return (
    <span className={'BasePageHeaderSubtitle'}>
      {subtitle}
    </span>
  );
}

BasePageHeaderSubtitle.propTypes = {
  subtitle: PropTypes.node
};

export default BasePageHeaderSubtitle;