import * as types from './types';
import _ from 'lodash';

const syncPersonTypes = (force = false) => {
  return (dispatch, getState, {persons}) => {
    const locale = getState()?.localization?.locale;

    if (force || getState()?.personTypes?.locale !== locale || _.isEmpty(getState()?.personTypes?.list)) {
      dispatch({type: types.PERSON_TYPES_REQUEST});

      return persons.getPersonTypes(locale)
        .then(results => dispatch({
          type: types.PERSON_TYPES_SUCCESS,
          payload: {
            data: results?.data?.persons,
            locale
          }
        }))
        .catch(error => dispatch({
          type: types.PERSON_TYPES_FAILURE,
          error
        }));
    }
  };
};

const _throttledSyncPersonTypes = _.throttle((dispatch, force) => dispatch(syncPersonTypes(force)), 10000);

export const getPersonTypes = (force) => {
  return (dispatch) => {
    return _throttledSyncPersonTypes(dispatch, force);
  };
};
