import * as types from './types';

const locationsReducer = (
  state = {
    isFetching: false,
    isFetchingLocationResources: false,
    locationResources: {},
    list: [],
    tree: [],
    dict: {},
    error: null,
    locale: undefined
  },
  action
) => {
  switch (action.type) {
    case types.LOCATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case types.LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.list,
        tree: action.payload.tree,
        dict: action.payload.dict,
        locale: action.payload.locale
      };
    case types.LOCATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        list: [],
        tree: [],
        dict: {},
        error: action.error,
        locale: undefined
      };
    case types.GET_LOCATION_RESOURCES_REQUEST:
      return {
        ...state,
        isFetchingLocationResources: true,
        locationResources: {}
      };
    case types.GET_LOCATION_RESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingLocationResources: false,
        locationResources: action.payload.data
      };
    case types.GET_LOCATION_RESOURCES_FAILURE:
      return {
        ...state,
        isFetchingLocationResources: false
      };
    default:
      return state;
  }
};

export default locationsReducer;
