import _ from 'lodash';

import * as organizationsUtils from '../../../utils/organization-utils';
import parsePagedResults from '../../utils/parsePagedResults';

/**
 * Parses the data object of a organization.getOrganizationChildren request.
 *
 * The result objects include id / parentId combinations that are used to
 * create a tree structure with children nested under parents. Once this
 * tree has been created, the node references are also returned in a
 * flattened list and a dictionary to reference by id.
 *
 * @param getOrganizationChildrenResult
 * @returns {{tree: *, list: *, dict}}
 */
export function parseApiResult(getOrganizationChildrenResult) {
  const pageable = parsePagedResults(getOrganizationChildrenResult);
  const organizationsTree = createOrganizationTree(getOrganizationChildrenResult, false);
  const organizationsTreeWithIds = createOrganizationTree(getOrganizationChildrenResult, true);
  const organizationsList = _.forEach(reduceTree(organizationsTree), org => {
    delete org.children;
  });
  const organizationsDict = _.keyBy(organizationsList, 'key');
  // Add descendants (flattened children tree) to each node
  _.forEach(organizationsList, d => d.descendants = reduceTree(d.children));

  return {
    tree: organizationsTree,
    treeWithIds: organizationsTreeWithIds,
    list: organizationsList,
    dict: organizationsDict,
    pageable: pageable
  };
}

/**
 * Transforms the results of the organizations api request into
 * a nested tree based on the id / parentId properties.
 * The objects are mapped to fit the format of the Antd tree select control
 * with properties {parentId, title, value, key} and sorts by title
 *
 * @param organizationResults
 * @param includeIdInTitle
 * @returns {*}
 */
export function createOrganizationTree(organizationResults, includeIdInTitle) {
  const nodes = {};
  const data = _.get(organizationResults, 'data.content');

  const ids = _.chain(data).map('id').uniq().value();
  const parents = _.chain(data).map('parentId').uniq().value();
  const orphans = _.difference(parents, ids);

  return _.chain(data)
    .map(d => {
      return {
        parentId: d.parentId,
        title: d.name + (includeIdInTitle ? ' (' + d.id + ')' : ''),
        value: d.id,
        key: d.id,
        audit: d.audit,
        types: d?.types,
        status: d?.status
      };
    })
    .filter(d => {
      nodes[d.key] = _.defaults(d, nodes[d.key], {children: []});
      nodes[d.parentId] = _.defaults(nodes[d.parentId], {children: []});
      nodes[d.parentId]['children'].push(d);

      return _.includes(orphans, d.parentId);
    })
    .value();
}

/**
 * The tree is an object that may contain some other (child) objects nested in an array. For instance, the organization hierarchy,
 * which contains the field "children" containing an array of children organization objects, is a tree.
 * This function calls "callback" on the parent node and every child node in the tree
 * @param tree an object that may contain some other (child) objects nested in an array
 * @param callback the callback. It receives the tree node as its only argument
 * @param childKey the name of the key which contains the children, "children" by default.
 */
export function loopOverTree(tree, callback, childKey = 'children') {
  if (tree !== undefined && tree !== null) {
    callback(tree);
    if (_.isArray(tree[childKey])) {
      tree[childKey].forEach(treeChild => {
        loopOverTree(treeChild, callback, childKey);
      });
    }
  }
}

/**
 * Given a tree (such as an org hierarchy) and some keyword string, return a (deep) cloned tree with all nodes that do
 * not match on the keywords filtered out.
 * If a node matches, all of its parents are also matched even if the parents do not match on the keywords themselves.
 *
 * The search does one full pass of the tree using a Depth-First recursion approach. The search also clones the tree.
 * It therefore requires O(n) time and O(n) space, where n is the number of nodes in the tree.
 *
 * Although we know the organization hierarchy should not surpass 1100 objects, so this runs in constant time
 *
 * @param tree the org hierarchy.
 * @param keywords {string} keyword to include in the search
 * @returns {object} The filtered org Hierarchy
 */
export function filterTree(tree, keywords) {
  if (_.isNil(keywords) || (_.isString(keywords) && keywords.trim().length === 0)) {
    // no keywords
    return tree;
  }
  else {
    const clonedTree = _.cloneDeep(tree);// we don't want to modify the original tree
    return _filterTree(clonedTree) ? clonedTree : {};
  }

  /**
   * Warning: The tree parameter is modified directly, pass a clone of the tree to avoid destroying the original tree
   * Filters the tree.
   * @returns {boolean} Returns false if there were no matches, true otherwise.
   * @private
   * @param node
   */
  function _filterTree(node) {
    if (!_.isNil(node)) {
      if (Array.isArray(node.children)) {
        for (let i = 0; i < node.children.length; i++) {
          const childNode = node.children[i];
          //on the way down
          _filterTree(childNode);// depth first search style recursion

          //The child should match if it matches on the keyword or it has an array of (already-)matching children
          const childMatches = doesNodeMatch(childNode) || !_.isEmpty(childNode.children);
          if (!childMatches) {
            node.children[i] = null;//mark this child node for removal
          }
        }
        _.pull(node.children, null);//remove all nulls (nodes marked for removal) from children

        if (node.children.length === 0) {
          //if no children left, delete the whole array so that no expand icon will be shown)
          node.children = null;
        }
      }

      //The function _filterTree returns this boolean check, where node is the tree ROOT:
      //Checks if the ROOT matches or has matching children. Not used during recursion.
      return doesNodeMatch(node) || !_.isEmpty(node.children);
    }
  }

  /**
   * Returns true if this node matches on the keywords (case-insensitive) , false otherwise
   * @param node
   * @returns {boolean}
   */
  function doesNodeMatch(node) {
    if (!_.isNil(node)) {
      const tokenizedKeywords = keywords.split(' ');
      return tokenizedKeywords.some( keyword =>  nodeNameMatchesOnKeyword(keyword) || nodeIdMatchesOnKeyword(keyword));
    }

    function nodeNameMatchesOnKeyword(keyword) {
      return stringContainsKeywordIgnoreCase(node.name, keyword);
    }

    function nodeIdMatchesOnKeyword(keyword) {
      return stringContainsKeywordIgnoreCase(node.id, keyword);
    }

    /**
     * Return true if string contains the keyword, false otherwise (case insensitive)
     * @param {string} string
     * @param {string} keyword
     * @returns {boolean}
     */
    function stringContainsKeywordIgnoreCase(string, keyword) {
      return string.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
    }
  }
}

export function linkParentOrgInChildren(hierarchy) {
  if (hierarchy && _.isArray(hierarchy.children)) {
    // parent
    hierarchy.title = hierarchy.name;
    hierarchy.value = hierarchy.id;

    hierarchy.children.forEach(childOrg => {
      childOrg.parent = hierarchy;
      childOrg.title = childOrg.name;
      childOrg.value = childOrg.id;
      linkParentOrgInChildren(childOrg);
    });
  }
}

/**
 * Here we set children arrays to undefined if they are empty. This is a workaround for the antd unresolved bug https://github.com/ant-design/ant-design/issues/18928
 * (otherwise antd will show the expand icon even for tree nodes with empty child arrays)
 * @param tree
 */
export function deleteEmptyChildrenArraysInHierarchy(tree) {
  loopOverTree(tree, (node) => {
    if (!_.isNil(tree) && _.isEmpty(_.get(node, 'children'))) { // node.children is empty
      delete node.children;
    }
  });
}

/**
 * WARNING: Modifies "tree" directly
 * Given a tree and a node in that tree, find the node and delete it
 * @param tree
 * @param node
 * @returns {*}
 */
export function deleteOrganization(tree, node) {

  if (_.isObject(tree) && _.isObject(node) && _.isString(node.id)) {
    if (Array.isArray(tree.children)) {
      for (let i = 0; i < tree.children.length; i++) {
        deleteOrganization(tree.children[i], node);

        if (tree.children[i].id === node.id) {
          _.pull(tree.children, tree.children[i]); //found the matchin node, remove it
        }

      }
    }
  }
  return tree;

}

/**
 * Returns an array of all the nodes in the locations tree flattened out.
 * Can accept a single tree object or a collection
 *
 * @param tree
 * @returns {*}
 */
export function reduceTree(tree) {
  return organizationsUtils.reduceTree(tree);
}

/**
 * Given a "tree" (typically the root of an organization hierarchy), return an array of all heirarchies which have children
 * @param tree
 * @returns {*}
 */
export function getAllExpandableHierarchies(tree) {
  return reduceTree(tree).filter(node =>
    Array.isArray(node.children) && node.children.length > 0 // include node if it has children
  );
}

/**
 * Given a "tree" (typically the root of an organization hierarchy) and a "node" (an organization) in that tree,
 * returns an array of all the nodes in the direct path between the given tree and the node, excluding the given node
 * @param tree
 * @param node
 * @returns {object[]} returns an array of all the nodes in the direct path between the given tree and the node
 */
export function getExpandableHierarchiesOnPathToNode(tree, node) {
  const hierarchiesOnPathToNode = [];

  //pre-conditions
  if (_.isObject(tree) && _.isObject(node) && _.isString(node.id)) {
    _getExpandableHierarchiesOnPathToNode(tree);
  }

  function _getExpandableHierarchiesOnPathToNode(tree) {
    if (!_.isNil(tree)) {
      const parentNode = tree;
      if (Array.isArray(tree.children)) {
        for (let i = 0; i < tree.children.length; i++) {
          const childNode = tree.children[i];
          //depth first search
          _getExpandableHierarchiesOnPathToNode(childNode);

          if (childNode.id === node.id || hierarchiesOnPathToNode.includes(childNode)) {
            hierarchiesOnPathToNode.unshift(parentNode);
          }
        }
      }
    }
  }

  return hierarchiesOnPathToNode;
}

/**
 * Features array in the response from org subscriptions doesn't include permissions
 * This grabs those permissions from /product-solutions and attaches them to their respective solution
 * @param {*} allSolutionsResponse
 * @param {*} orgSolutionsResponse
 */
export function assignPermissionsToOrgSolutions(allSolutionsResponse, orgSolutionsResponse) {
  const allSolutions = allSolutionsResponse.data;
  const orgSolutions = orgSolutionsResponse.data?.productSolutions;
  return _.chain(allSolutions)
    .map(solution => {
      const foundSolution = _.find(orgSolutions, {id: solution.id});
      if (!foundSolution || !foundSolution.enabled) {
        return null;
      }

      solution.features = _.chain(solution.features)
        .map(feature => {
          const foundFeature = _.find(foundSolution.features, {id: feature.id});
          if (!foundFeature || !foundFeature.enabled) {
            return null;
          }

          return feature;
        })
        .compact()
        .value();
      return solution;
    })
    .compact()
    .value();

}

/**
 * MODIFIES organizationHierarchy DIRECTLY
 * For every node in the organizationHierarchy tree:
 *  1. Create a property node.parent which links to the node's parent
 *  2. Delete node.children array if it is empty
 * @param organizationHierarchy
 */
export const processOrganizationHierarchy = (organizationHierarchy) => {
  linkParentOrgInChildren(organizationHierarchy);
  deleteEmptyChildrenArraysInHierarchy(organizationHierarchy);
  return organizationHierarchy;
};
/**
 * MODIFIES organizationHierarchy DIRECTLY
 * 1. Find and delete "node"
 * For every node in the organizationHierarchy tree:
 *  2. Create a property node.parent which links to the node's parent
 *  3. Delete node.children array if it is empty
 * @param rootOrgHierarchy the entire organization hierarchy starting from ROOT
 * @param node the node to delete
 * @returns the entire organization hierarchy minus the given "node" and all of its children
 */
export const processParentOrganizationHierarchy = (rootOrgHierarchy, node) => {
  deleteOrganization(rootOrgHierarchy, node);
  processOrganizationHierarchy(rootOrgHierarchy);
  return rootOrgHierarchy;
};

export const formatRolePayload = (payload) => {
  const name = _.get(payload, 'name');
  const roles = _.omit(payload, ['name', 'id']);
  const enabledPermissions = _.keys(_.pickBy(roles));
  return {
    name: name,

    // hardcoding empty string until we have requirements
    description: '',
    enabledPermissions: enabledPermissions
  };
};

/**
 * If ALL organizationIds are present in ONE client hierarchy - return that client's orgId; otherwise return undefined.
 * @param organizationIds {string[]} - list of organizationIds
 * @param orgHierarchy - state.session.organizationHierarchy (the hierarchy of organizations you have access to)
 * @return {string} single client id if ALL organizationIds are present in a SINGLE organization hierarchy; undefined otherwise.
 */
export function clientIdContainingAllOrgIds(organizationIds, orgHierarchy) {
  if (!_.isEmpty(organizationIds) && !_.isEmpty(orgHierarchy)) {
    //Array of all the client hierarchies
    const allClientHierarchies = getClients(orgHierarchy);
    //Each element is an array representing all the organizationIds in a particular client hierarchy
    const allFlattenedClientHierarchyIds = _.chain(allClientHierarchies)
      .map(reduceTree)
      .map(flattenedHierarchy => flattenedHierarchy.map(org => org.id))
      .value();
    return allFlattenedClientHierarchyIds.find(flattenedClientHierarchy => {
      //Are ALL organizationIds present in this ONE client hierarchy
      return organizationIds.every(organizationId => flattenedClientHierarchy.includes(organizationId));
    })?.[0];
  }
}

/**
 * Get an array of the top-level clients that you have access to
 * @param orgHierarchy
 */
export function getClients(orgHierarchy) {
  if(!_.isEmpty(orgHierarchy)){
    return orgHierarchy[0].children
  }
}

