import * as types from './types';
import _ from 'lodash';

import {checkIfAllLinksAreQuarantined, groupResourcesLinksByType} from '../organization-configurations/utils';

const syncSiteTypes = (force = false) => {
  return (dispatch, getState, {sites}) => {
    const locale = getState()?.localization?.locale;

    if (force || getState()?.sites?.locale !== locale || _.isEmpty(getState()?.sites?.list)) {
      dispatch({type: types.SITE_TYPES_REQUEST});

      return sites.getSiteTypes(locale)
        .then(results => dispatch({
          type: types.SITE_TYPES_SUCCESS,
          payload: {
            list: results?.data?.map((value) => {
              return {
                label: value.name,
                value: value.id
              }
            }),
            locale
          }
        }))
        .catch(error => dispatch({
          type: types.SITE_TYPES_FAILURE,
          error
        }));
    }
  };
};

const _throttledSyncSiteTypes = _.throttle((dispatch, force) => dispatch(syncSiteTypes(force)), 10000);

export const getSiteTypes = (force) => {
  return (dispatch) => {
    return _throttledSyncSiteTypes(dispatch, force);
  };
};

export const getSiteResources = (orgId, siteId) => {
  return (dispatch, _getState, {resources}) => {
    dispatch({type: types.GET_SITE_RESOURCES_REQUEST});

    return resources.getSiteResources(orgId, siteId)
      .then((result) => {
        const groupedLinks = groupResourcesLinksByType(result);
        const allLinksQuarantined = !_.isEmpty(groupedLinks?.links?.DOCUMENT) && checkIfAllLinksAreQuarantined(groupedLinks?.links?.DOCUMENT)
        if (allLinksQuarantined) {
          delete groupedLinks?.links?.DOCUMENT;
        }
        return dispatch({
          type: types.GET_SITE_RESOURCES_SUCCESS,
          payload: {
            data: groupedLinks
          }
        });
      })
      .catch(() => {
        dispatch({type: types.GET_SITE_RESOURCES_FAILURE});
      });
  };
};

