import _ from 'lodash';
import {removeEmptyStrings} from '../form-utils';
import {ORG_ADMIN_ROLE_NAME, RISK_MANAGER_ROLE_NAME} from '../../constants/users';

/**
 * @typedef {{firstName:string, lastName:string}} UserDetails
 */

/**
 * Returns `${user.firstName} ${user.lastName}`.
 * @param {UserDetails} user
 * @return {string}
 */
export function formUsername(user) {
  const firstName = _.get(user, 'firstName', '');
  const lastName = _.get(user, 'lastName', '');

  return `${firstName} ${lastName}`;
}

/**
 * Creating names WITHOUT the middle name
 * @param {UserDetails} user
 * @param {boolean} lastFirst Whether to show the last name first
 * @return {string}
 */
export function formName(user, lastFirst = true) {
  const lastName = _.get(user, 'lastName', '');
  const firstName = _.get(user, 'firstName', '');

  if (lastFirst) {
    return `${lastName}, ${firstName}`;
  }
  else {
    return `${firstName} ${lastName}`;
  }
}

/**
 * Creating names WITH the middle name
 * @param {UserDetails} user
 * @param {boolean} lastFirst Whether to show the last name first
 * @return {string}
 */
export function formFullName(user, lastFirst = true) {
  const lastName = _.get(user, 'lastName', '');
  const firstName = _.get(user, 'firstName', '');
  const middleName = _.get(user, 'middleName', '');
  if (lastFirst) {
    return middleName ? `${lastName}, ${firstName} ${middleName}` : `${lastName}, ${firstName}`;
  }
  else {
    return middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`;
  }
}

export function setUsersPersonName(user, lastFirst = true) {
  return {
    ...user,
    fullName: formFullName(user, lastFirst),
    // _.get() returns null for null values so we need to check for falsey values returned from _.get()
    middleName: _.get(user, 'middleName') || ''
  };
}

export function formatUpdateUserPayload(person) {
  return _.chain(removeEmptyStrings(person))
    .assign({username: person.primaryEmail})
    .omit('primaryEmail')
    .value();
}

/**
 * GET organizations/:orgId/users/:userId returns a flat list of users role assignments
 * this function rolls up the role assignments, builds a map where org ID is the key for all user's role assignments as well as a list witht just the values
 * @param {*} person user data response from users-ms
 * @returns object org permission map + list
 */
export function rollupOrganizationPermissions(person) {
  const organizationPermissionsMap = {};
  const roleAssignments = _.get(person, 'roleAssignments', []);
  roleAssignments.map(roleAssignment => {
    const {organizationRole} = roleAssignment;
    const {organization} = organizationRole;
    if (organizationPermissionsMap[organization.id]) {
      organizationPermissionsMap[organization.id].roleAssignments.push(roleAssignment);
    } else {
      organizationPermissionsMap[organization.id] = {
        ...organization,
        roleAssignments: [roleAssignment]
      };
    }
    return roleAssignment;
  });
  const list = Object.values(organizationPermissionsMap);

  return {map: organizationPermissionsMap, list};
}

function getRoleIdByRoleName(roles, roleName) {
  const role = roles?.find(role => role?.roleDefinition?.name === roleName);
  const roleId = role?.roleDefinition?.id;
  return roleId;
}

export function getRiskManagerRoleId(roles) {
  return getRoleIdByRoleName(roles, RISK_MANAGER_ROLE_NAME);
}

export function getOrgAdminRoleId(roles) {
  return getRoleIdByRoleName(roles, ORG_ADMIN_ROLE_NAME);
}