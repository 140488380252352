import {NavLink} from 'react-router-dom';
import {FormattedMessage, defineMessages} from 'react-intl';

import * as types from './types';
import {getSessionOrgId} from '../session/utils';
import {parseFilterParams} from '../persons/utils';
import {notificationActions} from '../notification';
import {parseMessageTemplates, showConfirmDiscardMessage} from './utils';

import {ROUTE_URLS, COMM_SUB_PATHS} from '../../../constants/routes';

const messages = defineMessages({
  sendMessageSuccess: {
    id: 'Redux.communicator.sendMessageSuccess',
    defaultMessage: 'Sending Message, for more details or to manage responses {clickLink}.'
  },
  clickLink: {
    id: 'Redux.communicator.clickLink',
    defaultMessage: 'click here'
  },
  sendMessageFailure: {
    id: 'Redux.communicator.sendMessageFailure',
    defaultMessage: 'Failed to send message'
  },
  createMessageDraftFailure: {
    id: 'Redux.communicator.createMessageDraftFailure',
    defaultMessage: 'Could not create message'
  },
  addRecipientFailure: {
    id: 'Redux.communicator.addRecipientFailure',
    defaultMessage: 'Unable to add recipient'
  },
  deleteRecipientFailure: {
    id: 'Redux.communicator.deleteRecipientFailure',
    defaultMessage: 'Unable to delete recipient'
  },
  getMessageFailure: {
    id: 'Redux.communicator.getMessageFailure',
    defaultMessage: 'Unable to get message'
  },
  getMessageRecipientsFailure: {
    id: 'Redux.communicator.getMessageRecipientsFailure',
    defaultMessage: 'Unable to get recipients'
  },
  deleteRecipientSuccess: {
    id: 'Redux.communicator.deleteRecipientSuccess',
    defaultMessage: 'Successfully removed recipient from draft'
  },
  addRecipientSuccess: {
    id: 'Redux.communicator.addRecipientSuccess',
    defaultMessage: 'Successfully added recipient to draft'
  }
});

/**
 * Returns a promise.
 * If there is no message in progress, the promise resolves immediately.
 * If there is a message in progress, we show a confirmation dialog which asks the user to confirm that they want to discard their active message:
 *     If the user presses "OK", we resolve the promise.
 *     If the user presses "Cancel" we reject the promise.
 *
 *     Therefore, a call to `await dispatch(checkForUnsavedMessage());` in another action herein will block that action until a choice is made.
 *     If the user presses cancel, we reject the promise which throws an error and prevents the rest of the function from executing. The existing
 *     message is therefore preserved.
 * @returns {function(*, *): Promise<undefined>}
 */
function checkForUnsavedMessage() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const composeMessageInProgress = getState().communicator.composeMessageInProgress;
      if (composeMessageInProgress) {
        showConfirmDiscardMessage({onOk: resolve, onCancel: reject});
      }
      else {
        resolve();
      }
    });
  };
}

/**
 *
 * @param {string[]} personIds - The array of persons Ids which are selected
 * @param {number} numPersons - The total number of persons available in this search
 * @param {boolean} allPersonsSelected - True when all persons are selected, false otherwise
 * @param {object} filter - the filter from person search
 * @return {function(...[*]=)}
 */
export const initiateComposeMessage = ({personIds, numPersons, allPersonsSelected, filter = {}}) => {
  return async (dispatch, getState) => {
    await dispatch(checkForUnsavedMessage());
    dispatch(endComposeMessage());
    dispatch({type: types.INITIATE_COMPOSE_MESSAGE, payload: {personIds, numPersons, allPersonsSelected}});

    filter = parseFilterParams(filter);

    if (!allPersonsSelected) {
      //If people are selected manually in the table from Person Search page, the filter should be
      //the selected personIds in the table and the selected organizationIds from the Advanced Search Org Selector
      const sessionOrgId = getSessionOrgId(getState());
      const organizationIds = filter.organizationIds ?? [sessionOrgId]; //defaults to active orgId if no org selected
      const includeSubOrganizations = filter.includeSubOrganizations ?? undefined;

      filter = {
        organizationIds: organizationIds,
        includeSubOrganizations: includeSubOrganizations,
        personIds: personIds
      };
    }
    dispatch(createDraftMessage(filter));
  };
};

export const initiateSinglePersonComposeMessage = (personId, orgId) => {
  return async (dispatch) => {
    await dispatch(checkForUnsavedMessage());
    dispatch(endComposeMessage());
    dispatch({type: types.INITIATE_COMPOSE_MESSAGE, payload: {personIds: [personId]}});
    dispatch(createDraftMessage({organizationIds: [orgId], personIds: [personId]}));
  };
};

export const initiateMapPersonsComposeMessage = (filter, personIds, personOrgId) => {
  return async (dispatch) => {
    await dispatch(checkForUnsavedMessage());
    dispatch(endComposeMessage());
    dispatch({type: types.INITIATE_COMPOSE_MESSAGE, payload: {personIds: personIds}});
    dispatch(createDraftMessage(filter, undefined, personOrgId));
  };
};

export const initiateAlertComposeMessage = (alertId, personIds, personFilter) => {
  return async (dispatch) => {
    await dispatch(checkForUnsavedMessage());
    dispatch(endComposeMessage());
    dispatch({type: types.INITIATE_COMPOSE_MESSAGE, payload: {personIds: personIds}});
    dispatch(createDraftMessage(personFilter, undefined, undefined, alertId));
  };
};

export const initiateSitesComposeMessage = (filter, organizationId, includeSubOrganizations) => {
  return async (dispatch) => {
    await dispatch(checkForUnsavedMessage());
    dispatch(endComposeMessage());
    dispatch({
      type: types.INITIATE_COMPOSE_MESSAGE,
      payload: {}
    });
    dispatch(createDraftMessageGeneric({sitesPersonFilter: filter}, organizationId, includeSubOrganizations));
  };
};

export const initiateAlertDetailsComposeMessage = (filter, sitesPersonFilter, alertId) => {
  return async (dispatch) => {
    await dispatch(checkForUnsavedMessage());
    dispatch(endComposeMessage());
    dispatch({
      type: types.INITIATE_COMPOSE_MESSAGE,
      payload: {}
    });
    dispatch(createDraftMessage(filter, null, null, alertId, sitesPersonFilter));
  };
};

export const initiateComposeMessageGeneric = (filter, organizationId) => {
  return async (dispatch) => {
    await dispatch(checkForUnsavedMessage());
    dispatch(endComposeMessage());
    dispatch({
      type: types.INITIATE_COMPOSE_MESSAGE,
      payload: {}
    });

    // The organizationId argument value will always be a top level client id;
    // however, a user may NOT have access to the top level client.
    // If we pass that client id for that user the POST /draft call will fail.
    // So we will get the orgId to pass from the personFilter object, which will only have the
    // ids of the orgs to which the user has access.
    const orgIdForCompose = filter?.personFilter?.organizationIds?.[0] || organizationId;

    dispatch(createDraftMessageGeneric({
      sitesPersonFilter: filter?.sitesPersonFilter,
      personFilter: filter?.personFilter,
      personGroupPersonFilter: filter?.personGroupPersonFilter
    }, orgIdForCompose));
  };
};

export const createDraftMessageGeneric = (filter, organizationId, includeSubOrganizations) => {
  return (dispatch, getState, {communicator}) => {
    dispatch({type: types.CREATE_DRAFT_MESSAGE_REQUEST});

    return communicator.createDraftMessage(
      {
        organizationId,
        sitesPersonFilter: filter?.sitesPersonFilter,
        filter: filter?.personFilter,
        personGroupPersonFilter: filter?.personGroupPersonFilter
      })
      .then((res) => {
        const {data} = res;

        const {author, id, initiationContext} = data;
        dispatch({
          payload: {
            author,
            id,
            initiationContext,
            organizationIds: [organizationId],
            includeSubOrganizations
          },
          type: types.CREATE_DRAFT_MESSAGE_SUCCESS
        });

        dispatch(getMessageRecipients(id));
      })
      .catch((err) => {
        dispatch({type: types.CREATE_DRAFT_MESSAGE_FAILURE});
        dispatch(endComposeMessage());
        dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.createMessageDraftFailure} />));
      });
  };
};

export const createDraftMessage = (filter, personId, personOrgId, alertId, sitesPersonFilter) => {
  return (dispatch, getState, {communicator}) => {
    dispatch({type: types.CREATE_DRAFT_MESSAGE_REQUEST});
    const organizationIds = filter?.organizationIds || sitesPersonFilter?.siteFilter?.organizationIds;
    const includeSubOrganizations = filter?.includeSubOrganizations;

    // org ID for sending message to individual person OR session org
    const organizationId =
     organizationIds?.length ? organizationIds[0] : // grab first organizationId if passed in the filter
       personOrgId ?? getSessionOrgId(getState());

    return communicator.createDraftMessage({organizationId, includeSubOrganizations, filter, personId, alertId, sitesPersonFilter})
      .then((res) => {
        const {data} = res;

        const {author, id, initiationContext, fromEmail} = data;
        dispatch({
          payload: {
            author,
            id,
            initiationContext,
            organizationIds: organizationIds || [organizationId],
            includeSubOrganizations: includeSubOrganizations,
            fromEmail
          },
          type: types.CREATE_DRAFT_MESSAGE_SUCCESS
        });

        dispatch(getMessageRecipients(id));
      })
      .catch((err) => {
        dispatch({type: types.CREATE_DRAFT_MESSAGE_FAILURE});
        dispatch(endComposeMessage());
        dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.createMessageDraftFailure} />));
      });
  };
};

export const getMessageRecipients = (messageId, {pageNumber, pageSize = 25} = {}) => {
  return (dispatch, _getState, {communicator}) => {
    dispatch({type: types.GET_MESSAGE_RECIPIENTS_REQUEST});

    return communicator.getMessageRecipients(messageId, pageNumber, pageSize)
      .then((res) => {
        const {content, pageable} = res.data;
        pageable.totalElements = res.data.totalElements;
        pageable.sort = res.data.sort;
        const formattedContent = content.map(person => {
          return {
            ...person,
            email: person.emailAddress
          }
        })
        dispatch({
          payload: {
            content: formattedContent,
            pageable: {
              ...pageable,
              pageNumber: pageNumber ?? 0,
              pageSize
            }
          },
          type: types.GET_MESSAGE_RECIPIENTS_SUCCESS
        });
      })
      .catch(() => {
        dispatch({type: types.GET_MESSAGE_RECIPIENTS_FAILURE});
        dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.getMessageRecipientsFailure} />));
      });
  };
};

export const getMessageTemplates = (locale) => {
  return (dispatch, _getState, {communicator}) => {
    dispatch({type: types.GET_MESSAGE_TEMPLATES_REQUEST});

    return communicator.getMessageTemplates(locale)
      .then((res) => {
        dispatch({
          payload: {
            // we want to return parsed templates that have expected content properties (name, subject and richText)
            messageTemplates: parseMessageTemplates(res.data)
          },
          type: types.GET_MESSAGE_TEMPLATES_SUCCESS
        });
      })
      .catch(() => {
        dispatch({type: types.GET_MESSAGE_TEMPLATES_FAILURE});
      });
  };
};

export const sendMessage = ({formats, conferenceBridge, statusConfirmationOptions, survey, messageLanguage}) => {
  return (dispatch, getState, {communicator}) => {
    dispatch({type: types.SEND_MESSAGE_REQUEST});
    return communicator.updateMessage({params: {formats, conferenceBridge, statusConfirmationOptions, survey}})
      .then((res) => {
        const messageId = res.data.id;
        return communicator.sendMessage({data: {messageLanguage}})
          .then(() => {
            dispatch({type: types.SEND_MESSAGE_SUCCESS});
            dispatch(endComposeMessage());
            dispatch(notificationActions.showSuccessNotification(
              <FormattedMessage {...messages.sendMessageSuccess} values={{
                clickLink: (
                  <NavLink to={`${ROUTE_URLS.COMMUNICATION}/${COMM_SUB_PATHS.MASS_NOTIFICATION}/${COMM_SUB_PATHS.SUMMARY}/${messageId}`}>
                    <FormattedMessage {...messages.clickLink}/>
                  </NavLink>
                )
              }}/>
            ));
          })
          .catch(() => {
            dispatch({type: types.SEND_MESSAGE_FAILURE});
            dispatch(endComposeMessage());
            dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.sendMessageFailure} />));
          });
      })
      .catch(() => {
        dispatch({type: types.SEND_MESSAGE_FAILURE});
        dispatch(endComposeMessage());
        dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.sendMessageFailure} />));
      });
  };
};

export const endComposeMessage = () => {
  return (dispatch, _getState) => {
    dispatch({type: types.END_COMPOSE_MESSAGE});
  };
};

export const addRecipient = (recipientId) => {
  return (dispatch, getState, {communicator}) => {
    const communicatorState = getState()?.communicator;
    const {pageable, messageId} = communicatorState;

    dispatch({type: types.ADD_RECIPIENT_REQUEST});
    return communicator.addRecipient(recipientId)
      .then(() => {
        dispatch({type: types.ADD_RECIPIENT_SUCCESS});
        dispatch(getMessageRecipients(messageId, pageable));
        dispatch(notificationActions.showSuccessNotification(<FormattedMessage {...messages.addRecipientSuccess} />));
      })
      .catch(() => {
        dispatch({type: types.ADD_RECIPIENT_FAILURE});
        dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.addRecipientFailure} />));
      });
  };
};

export const deleteRecipient = (recipientId) => {
  return (dispatch, getState, {communicator}) => {
    const communicatorState = getState()?.communicator;
    const {pageable, messageId} = communicatorState;

    dispatch({type: types.DELETE_RECIPIENT_REQUEST});
    return communicator.deleteRecipient(recipientId)
      .then(() => {
        dispatch({type: types.DELETE_RECIPIENT_SUCCESS});
        dispatch(getMessageRecipients(messageId, pageable));
        dispatch(notificationActions.showSuccessNotification(<FormattedMessage {...messages.deleteRecipientSuccess} />));
      })
      .catch((err) => {
        dispatch({type: types.DELETE_RECIPIENT_FAILURE});
        dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.deleteRecipientFailure} />));
      });
  };
};

export const getMessage = (messageId) => {
  return (dispatch, getState, {communicator}) => {
    dispatch({type: types.GET_MESSAGE_REQUEST});
    return communicator.getMessage(messageId)
      .then(() => {
        dispatch({type: types.GET_MESSAGE_SUCCESS});
      })
      .catch(() => {
        dispatch({type: types.GET_MESSAGE_FAILURE});
        dispatch(notificationActions.showErrorNotification(<FormattedMessage {...messages.getMessageFailure} />));
      });
  };
};

