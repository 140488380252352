import {SELF_REGISTRATION_TYPES} from '../../../constants/self-registration';
import _ from 'lodash';

export const STEPS = {
  INITIAL: 'INITIAL',

  PASSWORD_CREDS: 'PASSWORD_CREDS',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_EMAIL_SENT: 'RESET_PASSWORD_EMAIL_SENT',
  RESET_PASSWORD_SUBMIT: 'RESET_PASSWORD_SUBMIT',

  REGISTRATION_PROFILE: 'REGISTRATION_PROFILE',
  REGISTRATION_MEMBER_ID_PROFILE: 'REGISTRATION_MEMBER_ID_PROFILE',
  REGISTRATION_NON_MEMBER_ID_PROFILE: 'REGISTRATION_NON_MEMBER_ID_PROFILE',

  REGISTRATION_COMPLETE: 'REGISTRATION_COMPLETE',
  REGISTRATION_DELAYED: 'REGISTRATION_DELAYED',
  ACCOUNT_ACTIVATION: 'ACCOUNT_ACTIVATION',
  TEMPORARY_PASSWORD_EXPIRED: 'TEMPORARY_PASSWORD_EXPIRED',
  AUTO_AUTH: 'AUTO_AUTH',
  RESEND_INVITE: 'RESEND_INVITE',
  REGISTRATION_LINK: 'REGISTRATION_LINK',
  FSSO_LOGIN: 'FSSO_LOGIN'
};

export function checkFSSO(username, getState, {selfRegistration, auth}, redirect) {
  const emailDomain = username.split('@')[1];
  return selfRegistration.selfRegPoliciesExists({type: SELF_REGISTRATION_TYPES.FSSO, emailDomain})
    .then(results => {
      if (results?.data?.providerName) {
        if(redirect){
          const redirectUrl = _.get(getState(), ['login', 'redirectUrl']);

          auth.federatedSignIn(results?.data?.providerName, redirectUrl);
        }

        return SELF_REGISTRATION_TYPES.FSSO;
      }
    });
}

/**
 * Checks for and return the self registration policy exist for given userName -> domain
 * If policy has providerName call auth.federatedSignIn else returns data
 * @param username
 * @param getState
 * @param selfRegistration
 * @param auth
 * @returns {Promise<unknown>}
 */
export function checkForEmailRegistrationPolicies(username, getState, {selfRegistration, auth}) {
  const emailDomain = username.split('@')[1];

  // Have to make two calls because backend does not support not sending type atm, causes us to check both
  return Promise.allSettled([
    selfRegistration.selfRegPoliciesExists({type: SELF_REGISTRATION_TYPES.FSSO, emailDomain}),
    selfRegistration.selfRegPoliciesExists({type: SELF_REGISTRATION_TYPES.VALID_EMAIL_DOMAIN, emailDomain})
  ])
    .then(([fssoPolicyResponse, emailDoaminPolicyResponse]) => {
      if(fssoPolicyResponse?.value?.data?.providerName){
        const redirectUrl = _.get(getState(), ['login', 'redirectUrl']);

        auth.federatedSignIn(fssoPolicyResponse?.value?.data?.providerName, redirectUrl);

        return SELF_REGISTRATION_TYPES.FSSO;
      }

      return emailDoaminPolicyResponse?.value?.data
    })
}

export function checkEmailDomainPolicy(username, {selfRegistration}) {
  const emailDomain = username.split('@')[1];
  return selfRegistration.selfRegPoliciesExists({type: SELF_REGISTRATION_TYPES.VALID_EMAIL_DOMAIN, emailDomain})
    .then(results => {
      if (results?.data?.exists) {
        return SELF_REGISTRATION_TYPES.VALID_EMAIL_DOMAIN;
      }
    });
}

export function checkMemberId(memberId, clientUrlString, {selfRegistration}) {
  if (_.isEmpty(memberId)) {
    return Promise.resolve();
  }

  return selfRegistration.selfRegPoliciesExists({type: SELF_REGISTRATION_TYPES.MEMBER_ID, memberId, clientUrlString})
    .then(results => {
      if (results?.data?.exists) {
        return SELF_REGISTRATION_TYPES.MEMBER_ID;
      }
    });
}

export function getSelfRegPolicyType(username, memberId, clientUrlString, getState, services) {
  // Priority order
  const promises = [
    checkFSSO(username, getState, services, true),
    checkEmailDomainPolicy(username, services),
    checkMemberId(memberId, clientUrlString, services)
  ];

  return Promise.all(promises)
    .then((values) => {
      return _.chain(values).compact().first().value(); // first defined self reg type
    });
}
