import {INTEL_SUB_PATHS, ROUTE_URLS} from '../../../constants/routes';

export const MIN_RATING = 1;
export const MAX_RATING = 5;

export function getClientRatingsPage(orgId) {
  return `${ROUTE_URLS.INTEL}/${INTEL_SUB_PATHS.LOCATION}/${orgId}/${INTEL_SUB_PATHS.EDIT_RATING}`;
}

export function getEditClientRatingsPage(orgId, locationId) {
  return `${getClientRatingsPage(orgId)}/${locationId}`;
}

export function convertTheatZoneDetailToFeatureProperties(details, locale) {
  return {
    id: details.id,
    threatZoneId: details.id,
    rating: details.rating,
    locationId: details.location?.id,
    [`threatZoneName_${locale}`]: details.name,
    locationName: details.location?.name,
    locationParentName: details.location?.parentName,
    locationCountryName: details.location?.countryName,
    locationTier: details.location?.locationTier
  };
}