import {LOGO_SCOPES} from '../../../../../components/Organizations/ClientLogoField/constants';
import {RESTRICTED_APP_KEYWORD} from '../../../../../constants/routes';

export const patternAntdExcludeSpecialChars = /^[a-zA-Z\d-_]+$/;
export const patternAlphanumericWithSpace = /^[a-zA-Z\d\s]+$/;
//Matches any string except 'app' (case insensitive)
export const patternAntdExcludeApp = new RegExp('^(?!'+RESTRICTED_APP_KEYWORD+'$)', 'i');
export const mapAttributeRestrictions = (restrictions) => {
  let mappedRestrictions = {};

  restrictions?.forEach(restriction =>  mappedRestrictions[restriction.restrictionType] = restriction.values);

  return mappedRestrictions;
}

export const getWebLogoUrlFromResponse = (response) => {
  const logos = response?.data?.logos;
  return logos?.find((logo) => logo?.scopes?.includes(LOGO_SCOPES.WEB_APP))?.logoUrl;
}