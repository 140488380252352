import moment from 'moment';
import enAntdProvider from 'antd/lib/locale-provider/en_US';

import {createIntl, createIntlCache} from 'react-intl';

import enMessages from '../translations/en.json';
import {getMomentLocale} from '../redux/modules/localization/utils';
import {LOCALES} from '../constants/locale';
import {horizonLocalStorage} from '../utils/local-storage';

export const LOCAL_STORAGE_KEY = 'horizon-locale';
export const DEFAULT_LANGUAGE = LOCALES.EN;

/**
 * This class manages all localization file loading and access.
 *
 * It contains some utility methods to detect and store user language
 * as well as methods to async load message and local files for the
 * supported locales.
 *
 * Corresponding translation files can be found in /translations
 */
class Localization {

  constructor() {
    this.cache = createIntlCache();

    // Set english locale defaults synchronously
    this.setCachedLocaleData({
      locale: DEFAULT_LANGUAGE,
      messages: enMessages,
      antd: enAntdProvider
    });
  }

  /**
   *
   * @returns {boolean}
   */
  requireIntlPolyfill = () => {
    return !window.Intl.PluralRules;
  };

  /**
   *
   * @param value
   * @returns {*}
   */
  isIntlMessageObject = (value) => {
    return !!value && !!value.id && !!value.defaultMessage;
  };

  /**
   * This method allows javascript translation of react-intl message objects
   * without the need to be in the react context or a component.
   *
   * Use this sparingly as it can result in additional coupling.
   *
   * @param message
   * @param data
   * @returns {string}
   */
  formatMessage = (message, data) => {
    return this.intl.formatMessage(message, data);
  };

  /**
   * Define user's language. Different browsers have the user locale defined
   * on different fields on the `navigator` object, so we make sure to account
   * for these different by checking all of them
   *
   * @returns {string}
   */
  getSystemLanguage = () => {
    const navigator = window.navigator;

    const globalLanguage = (navigator.languages && navigator.languages[0]) || navigator.language || DEFAULT_LANGUAGE;

    // Handle traditional chinese separately- check for zh-tw, zh-hk, or zh-Hant
    if (globalLanguage.match(/zh[_-](hant|hk|tw)/i)) {
      return LOCALES.ZH_HANT;
    }
    else if (globalLanguage.match(/zh[_-](hans|cn)/i)) {
      return LOCALES.ZH_HANS;
    }

    // Strip country and return base language only
    return globalLanguage.toLowerCase().split(/[_-]+/)[0];
  };

  /**
   * Returns the language value at LOCAL_STORAGE_KEY
   *
   * @returns {string}
   */
  getLocalStorageLanguage = () => {
    return horizonLocalStorage.getItem(LOCAL_STORAGE_KEY);
  };

  /**
   * Sets the language value at LOCAL_STORAGE_KEY
   *
   * @param language
   */
  setLocalStorageLanguage = (language) => {
    return horizonLocalStorage.setItem(LOCAL_STORAGE_KEY, language);
  };

  /**
   * Set the locale, messages, antd, and intl class values
   * and set the moment.locale value.
   *
   * @param localeData
   * @returns {{
   *   antd: *,
   *   messages: *,
   *   locale: *,
   *   intl: IntlShape
   * }}
   */
  setCachedLocaleData = (localeData) => {

    // Set the moment locale
    moment.locale(getMomentLocale(localeData.locale));

    // Create an intl cache to drive translations
    const intl = createIntl({
      locale: localeData.locale,
      messages: localeData.messages
    }, this.cache);

    // Update class references
    this.locale = localeData.locale;
    this.language = localeData.language;
    this.messages = localeData.messages;
    this.antd = localeData.antd;
    this.intl = intl;

    return {
      locale: this.locale,
      language: this.language,
      messages: this.messages,
      antd: this.antd,
      intl: this.intl
    };
  };

  /**
   *
   * @param language
   * @returns {Promise<{antd: *, messages: *, locale: *, intl: IntlShape}>}
   */
  updateLocaleDataAsync = async (language) => {

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/polyfill');
      await import('@formatjs/intl-relativetimeformat/polyfill');
    }

    const localeData = await this.importLocaleDataByLanguage(language);

    return this.setCachedLocaleData(localeData);
  };

  /**
   * Asynchronously import and resolve the local info associated with the appropriate language.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importLocaleDataByLanguage = async (language) => {
    await import('react-moment');
    switch (language) {
      case LOCALES.JA:
        return this.importJapaneseLocale();
      case LOCALES.IT:
        return this.importItalianLocale();
      case LOCALES.FR:
        return this.importFrenchLocale();
      case LOCALES.ES:
        return this.importSpanishLocale();
      case LOCALES.DE:
        return this.importGermanLocale();
      case LOCALES.PT:
        return this.importPortugueseLocale();
      case LOCALES.ZH_HANS:
        return this.importChineseLocale();
      case LOCALES.ZH_HANT:
        return this.importChineseTraditionalLocale();
      case LOCALES.NL:
        return this.importDutchLocale();
      case LOCALES.PL:
        return this.importPolishLocale();
      case LOCALES.HI:
        return this.importHindiLocale();
      case LOCALES.RU:
        return this.importRussianLocale();
      case LOCALES.HE:
        return this.importHebrewLocale();
      case LOCALES.EN:
      default :
        return this.importEnglishLocale();
    }
  };

  /**
   * Asynchronously import English en_US formats.
   *
   * Technically these are defaults and should not
   * need to be imported, but here for consistency and to future proof.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importEnglishLocale = async () => {
    const locale = LOCALES.EN;
    const messages = await import('../translations/en.json');
    const antd = await import('antd/lib/locale-provider/en_US');

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/en');
      await import('@formatjs/intl-relativetimeformat/locale-data/en');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Spanish es_ES formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importSpanishLocale = async () => {
    const locale = LOCALES.ES;
    const messages = await import('../translations/es.json');
    const antd = await import('antd/lib/locale-provider/es_ES');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/es');
      await import('@formatjs/intl-relativetimeformat/locale-data/es');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Italian it_IT formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importItalianLocale = async () => {
    const locale = LOCALES.IT;
    const messages = await import('../translations/it.json');
    const antd = await import('antd/lib/locale-provider/it_IT');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/it');
      await import('@formatjs/intl-relativetimeformat/locale-data/it');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import French fr_FR formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importFrenchLocale = async () => {
    const locale = LOCALES.FR;
    const messages = await import('../translations/fr.json');
    const antd = await import('antd/lib/locale-provider/fr_FR');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/fr');
      await import('@formatjs/intl-relativetimeformat/locale-data/fr');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Japanese ja_JP formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importJapaneseLocale = async () => {
    const locale = LOCALES.JA;
    const messages = await import('../translations/ja.json');
    const antd = await import('antd/lib/locale-provider/ja_JP');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/ja');
      await import('@formatjs/intl-relativetimeformat/locale-data/ja');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import German de_DE formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importGermanLocale = async () => {
    const locale = LOCALES.DE;
    const messages = await import('../translations/de.json');
    const antd = await import('antd/lib/locale-provider/de_DE');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/de');
      await import('@formatjs/intl-relativetimeformat/locale-data/de');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Portuguese pt_BR formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importPortugueseLocale = async () => {
    const locale = LOCALES.PT;
    const messages = await import('../translations/pt.json');
    const antd = await import('antd/lib/locale-provider/pt_BR');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/pt');
      await import('@formatjs/intl-relativetimeformat/locale-data/pt');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Chinese zh_CN formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importChineseLocale = async () => {
    const locale = LOCALES.ZH_HANS;
    const messages = await import('../translations/zh-hans.json');
    const antd = await import('antd/lib/locale-provider/zh_CN');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/zh');
      await import('@formatjs/intl-relativetimeformat/locale-data/zh-Hans');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Chinese Traditional zh_HK formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importChineseTraditionalLocale = async () => {
    const locale = LOCALES.ZH_HANT;
    const messages = await import('../translations/zh-hant.json');
    const antd = await import('antd/lib/locale-provider/zh_TW');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/zh');
      await import('@formatjs/intl-relativetimeformat/locale-data/zh-Hant');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Dutch nl_NL formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importDutchLocale = async () => {
    const locale = LOCALES.NL;
    const messages = await import('../translations/nl.json');
    const antd = await import('antd/lib/locale-provider/nl_NL');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/nl');
      await import('@formatjs/intl-relativetimeformat/locale-data/nl');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Polish pl_PL formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importPolishLocale = async () => {
    const locale = LOCALES.PL;
    const messages = await import('../translations/pl.json');
    const antd = await import('antd/lib/locale-provider/pl_PL');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/pl');
      await import('@formatjs/intl-relativetimeformat/locale-data/pl');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Hindi hi_IN formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importHindiLocale = async () => {
    const locale = LOCALES.HI;
    const messages = await import('../translations/hi.json');
    const antd = await import('antd/lib/locale-provider/hi_IN');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/hi');
      await import('@formatjs/intl-relativetimeformat/locale-data/hi');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

  /**
   * Asynchronously import Russian ru_RU formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importRussianLocale = async () => {
    const locale = LOCALES.RU;
    const messages = await import('../translations/ru.json');
    const antd = await import('antd/lib/locale-provider/ru_RU');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/ru');
      await import('@formatjs/intl-relativetimeformat/locale-data/ru');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };
  
  /**
   * Asynchronously import Hebrew he_HE formats.
   *
   * @returns {Promise<{antd: *, data: *, messages: *, locale: string}>}
   */
  importHebrewLocale = async () => {
    const locale = LOCALES.HE;
    const messages = await import('../translations/he.json');
    const antd = await import('antd/lib/locale-provider/he_IL');
    const momentLocale = getMomentLocale(locale);
    await import(`moment/locale/${momentLocale}`);

    if (this.requireIntlPolyfill()) {
      await import('@formatjs/intl-pluralrules/locale-data/he');
      await import('@formatjs/intl-relativetimeformat/locale-data/he');
    }

    return {
      locale: locale,
      language: locale,
      messages: messages.default,
      antd: antd.default
    };
  };

}

// Export singleton instance of class
export default new Localization();
