import {LOCAL_STORAGE_KEY, TEST_ENVIRONMENTS} from '../../../../constants/features';

/**
 * If isEnabled is true, return true.
 * If isEnabled is falsy, only returns true in TEST_ENVIRONMENTS
 * Use case:
 *  featureToggle() ? <span>show in test env</span> : <span>show in prod/live</span>
 */
export function featureToggle(isEnabled, alwaysShowInEnvironments = TEST_ENVIRONMENTS) {
  //This localStorage is never set programmatically, so there is no need to use horizonLocalStorage here.
  const overrideEnable = localStorage.getItem(LOCAL_STORAGE_KEY);
  return (isEnabled || (alwaysShowInEnvironments.includes(process.env.REACT_APP_ENVIRONMENT) && !overrideEnable));
}
