import {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import {defineMessages, FormattedMessage} from 'react-intl';
import {Button, Space} from 'antd';

import NavigationBlocker from '../../Common/NavigationBlocker/NavigationBlocker';

import './FormFooter.css';

const messages = defineMessages({
  submitText: {
    id: 'FormInlineFooter.submitText',
    defaultMessage: 'Save'
  },
  cancelText: {
    id: 'FormInlineFooter.cancelText',
    defaultMessage: 'Cancel'
  }
});

/**
 * Form Inline Footer component
 *
 * To be used when as footer component on form pages or part of cards actions
 * includes acceptance and cancel buttons in addition to optional extra button
 */
export default class FormFooter extends Component {
  static propTypes = {
    extra: PropTypes.element,
    loading: PropTypes.bool,
    submitText: PropTypes.node,
    cancelText: PropTypes.node,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    formRef: PropTypes.object,
    form: PropTypes.object,
    className: PropTypes.string,
    disableNavigationCheck: PropTypes.bool,
    disableSubmit: PropTypes.bool,
    discardModalTitle: PropTypes.node,
    discardModalContent: PropTypes.node
  };

  static defaultProps = {
    submitText: <FormattedMessage {...messages.submitText} />,
    cancelText: <FormattedMessage {...messages.cancelText} />
  };

  processingSubmit = false;

  getFormReference = () => {
    const {form, formRef} = this.props;
    return form ? form : _.get(formRef, ['current']);
  };

  handleSubmit = () => {
    const formReference = this.getFormReference();
    let validatePromise = _.invoke(formReference, ['validateFields']);
    if (validatePromise) {
      validatePromise
        .then(data => {
          this.processingSubmit = true;
          this.props?.onSubmit?.(data)
            .finally(() => {
              this.processingSubmit = false;
            });
        })
        .catch(errorInfo => {
          const firstErrorField = _.chain(errorInfo)
            .get('errorFields')
            .first()
            .get('name')
            .first()
            .value();

          _.invoke(formReference, ['scrollToField'], firstErrorField, {behavior: 'smooth'});

          console.error(errorInfo);
        });
    }
  };

  preventNavigation = () => {
    return !this.props.disableNavigationCheck && !this.processingSubmit && this.getFormReference()?.isFieldsTouched();
  };

  onNavigation = () => {
    this.getFormReference()?.resetFields();
  };

  render() {
    return (
      <div className={classNames('FormFooter', this.props.className)}>

        <NavigationBlocker
          when={this.preventNavigation}
          onNavigation={this.onNavigation}
          title={this.props.discardModalTitle}
          message={this.props.discardModalContent}
        />

        <div>
          {this.props.extra && (
            <div className="pull-left">
              {this.props.extra}
            </div>
          )}

          <Space className="pull-right">
            <Button onClick={this.props.onCancel}>
              {this.props.cancelText}
            </Button>

            <Button
              htmlType="button"
              type="primary"
              disabled={this.props.disableSubmit}
              onClick={this.handleSubmit}
              loading={this.props.loading}
            >
              {this.props.submitText}
            </Button>
          </Space>

          <div className="clearfix"/>
        </div>

      </div>
    );
  }
}
