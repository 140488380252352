import * as types from './types';

import {v4 as uuid} from 'uuid'
import _ from 'lodash';

import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  error: {
    id: 'Redux.notification.error',
    defaultMessage: 'ERROR'
  },
  success: {
    id: 'Redux.notification.success',
    defaultMessage: 'Success'
  },
  newFeature: {
    id: 'Redux.notification.newFeature',
    defaultMessage: 'New Feature'
  },
  downloadFileError: {
    id: 'Redux.notification.downloadFileError',
    defaultMessage: 'There was a problem downloading this file'
  },
  downloadReportError: {
    id: 'Redux.notification.downloadReportError',
    defaultMessage: 'There was a problem generating this report'
  },
  createMessageError: {
    id: 'Redux.notification.createMessageError',
    defaultMessage: 'There was a problem creating this message'
  },
  noDataToDownload: {
    id: 'Redux.notification.noDataToDownload',
    defaultMessage: 'No data to download'
  },
  exceedsSizeLimit: {
    id: 'Redux.notification.exceedsSizeLimit',
    defaultMessage: 'Data exceeds report size limit. Please reduce requested date range and re-run report.'
  },
});

/**
 * notification object is to be defined as follows
 * {
      type: string enum 'success', 'info', 'warning', 'error', if it is no provided will default to 'warning'
      message: string , aka title
      description: string
      key: optional, if not provided a uuid will be generated,
      createdDate: optional, if not provided one will generated Date.now()
 * }
 *
 * @param notification
 * @returns {Function}
 */
export const addNotification = (notification) => {
  return (dispatch, getState) => {

    if (notification.key) {
      const currentNotifications = _.get(getState(), 'notification.notifications');
      if (_.find(currentNotifications, _.matchesProperty('key', notification.key))) {
        dispatch(removeNotification(notification.key));
      }
    }

    const payload = {
      type: notification.type || 'warning',
      icon: notification.icon,
      message: notification.message?.id ? (<FormattedMessage {...notification.message}/>) : notification.message,
      description: notification.description?.id ?
        (<FormattedMessage {...notification.description}/>) :
        notification.description,
      key: notification.key || uuid(),
      createdDate: notification.createdDate || Date.now(),
      duration: notification.keepOpen ? 0 : 4.5,
      buttonLabel: notification.buttonLabel
    };

    if (!_.isUndefined(notification.downloadCallback)) {
      payload.downloadCallback = notification.downloadCallback;
    }

    if (!_.isUndefined(notification.onClose)) {
      payload.onClose = notification.onClose;
    }

    dispatch({
      type: types.ADD_NOTIFICATION,
      payload
    });
  };
};

/**
 * Add a notification with type success and default success title (message)
 * and provided description (content).
 *
 * If not provided, the key is a generic common key that
 * will allow only one success notification added at a time. Provide your own key
 * if you want greater control over the removal of notification added with this method.
 *
 *
 * @param description
 * @param key
 * @param title
 * @param icon
 * @param keepOpen
 * @returns {(function(*, *): void)|*}
 */
export const showSuccessNotification = (description, key = 'generic-success', title = <FormattedMessage {...messages.success}/>, icon, keepOpen) => {
  const notification = {
    key,
    type: 'success',
    message: title,
    description,
    icon,
    keepOpen
  };

  return addNotification(notification);
};

/**
 * Calls showSuccessNotification with keepOpen set to true
 */
export const keepOpenSuccessNotification = (description, key = 'generic-success', title = <FormattedMessage {...messages.success}/>, icon) => {
  return showSuccessNotification(description, key, title, icon, true);
};

/**
 * Creates a specific type of notification to support download workflow
 * Supports a notification with a single button - button label and callback function are passed
 */
export const downloadCompleteNotification = (description, title = <FormattedMessage {...messages.success}/>, buttonLabel, downloadCallback, key = 'generic-download') => {
  const notification = {
    key,
    type: 'success',
    message: title,
    description,
    buttonLabel,
    downloadCallback,
    keepOpen: true
  };

  return addNotification(notification);
};

/**
 * Add a notification with type error and default error title (message)
 * and provided description (content).
 *
 * If not provided, the key is a generic common key that
 * will allow only one error notification added at a time. Provide your own key
 * if you want greater control over the removal of notification added with this method.
 *
 * @param description
 * @param key
 * @param title
 * @returns {Function}
 */
export const showErrorNotification = (description, key = 'generic-error', title = <FormattedMessage {...messages.error}/>) => {
  const notification = {
    key: key,
    type: 'error',
    message: title,
    description: description
  };

  return addNotification(notification);
};

/**
 * Create info notification for new feature with provided description
 * Notification will remain open until user explicitly closes it
 *
 * @param description
 * @param onCloseCallback
 * @param key
 * @returns {Function}
 */

export const showFeatureNotification = (description, onCloseCallback, key = 'generic-feature') => {
  const notification = {
    key: key,
    type: 'info',
    message: <FormattedMessage {...messages.newFeature}/>,
    description: description,
    keepOpen: true,
    onClose: onCloseCallback
  };

  return addNotification(notification);
};

/**
 * Calls showErrorNotification with generic download file error text
 */
export const showDownloadFileError = () => {
  return showErrorNotification(<FormattedMessage {...messages.downloadFileError}/>);
};

/**
 * Calls showErrorNotification with generic download report error text
 */
export const showDownloadReportError = () => {
  return showErrorNotification(<FormattedMessage {...messages.downloadReportError}/>);
};

/**
 * Calls showErrorNotification with generic create message error text
 */
export const showCreateMessageError = () => {
  return showErrorNotification(<FormattedMessage {...messages.createMessageError}/>);
};

/**
 * Calls showErrorNotification with generic size limit error text
 */
export const reportExceedsSizeError = () => {
  return showErrorNotification(<FormattedMessage {...messages.exceedsSizeLimit}/>);
};

/**
 * Create REMOVE_NOTIFICATION event with key im payload
 *
 * @param key
 * @returns {{type: string, payload: {key: *}}}
 */
export const removeNotification = key => ({
  type: types.REMOVE_NOTIFICATION,
  payload: {key}
});

/**
 * Create REMOVE_ALL_NOTIFICATIONS event
 *
 * @returns {{type: string}}
 */
export const removeAllNotifications = () => ({
  type: types.REMOVE_ALL_NOTIFICATIONS
});
