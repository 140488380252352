import _ from 'lodash';

export function isEmptyString(str) {
  return _.isString(str) && _.isEmpty(str);
}
/**
 * Returns a copy of 'obj' with all entries containing empty string values removed
 * @param obj - any object
 * @param {object} settings
 * @param {boolean} settings.recursive - Removes empty strings from all nested objects as well.
 * @return {object}
 */
export function removeEmptyStrings(obj, settings = {recursive: false}) {
  return _.mapValues(obj, (value) => {
    if (settings.recursive) {
      if (_.isPlainObject(value)) {
        return removeEmptyStrings(value, settings);
      }
      else if (_.isArray(value)) {
        return value.map((arrayEntry) => removeEmptyStrings(arrayEntry, settings));
      }
    }

    if (isEmptyString(value)) {
      return undefined;
    }
    else {
      return value;
    }
  });
}

export function createFieldToWatchFunction(fieldPath) {
  return (prevValues, curValues) => {
    return _.get(prevValues, fieldPath) !== _.get(curValues, fieldPath);
  };
}
