import MapLayer from '../MapLayer';

export const ALERT_DETAIL_SOURCE_ID = 'alerts-detail-source';

// LAYER IDs Match and provided by GIS team and must match
export const ALERT_DETAIL_POINTS_LAYER_ID = 'alerts-points';
export const ALERT_DETAIL_LINES_LAYER_ID = 'Alert-lines';
export const ALERT_DETAIL_POLYGON_OUTLINE_LAYER_ID = 'Polygon-outline';
export const ALERT_DETAIL_CRITICAL_POLYGON_LAYER_ID = 'Critical-polygon';
export const ALERT_DETAIL_WARNING_POLYGON_LAYER_ID = 'Warning-polygon';
export const ALERT_DETAIL_CAUTION_POLYGON_LAYER_ID = 'Caution-polygon';
export const ALERT_DETAIL_INFORMATIONAL_POLYGON_LAYER_ID = 'Informational-polygon';

export const ALERT_DETAIL_POLYGONS_LAYER_ID = 'alert_polygon';
export const NON_MAP_AFFECTED_PEOPLE_LAYER_ID = 'affected_people';
export const NON_MAP_AFFECTED_PEOPLE_AT_SITES_LAYER_ID = 'affected_people_at_sites';
export const NON_MAP_AFFECTED_SITES_LAYER_ID = 'affected_sites';
const POLYGON_LAYER_SEVERITY_LIST = [
  {id: ALERT_DETAIL_CRITICAL_POLYGON_LAYER_ID, severity: '3'},
  {id: ALERT_DETAIL_WARNING_POLYGON_LAYER_ID, severity: '2'},
  {id: ALERT_DETAIL_CAUTION_POLYGON_LAYER_ID, severity: '1'},
  {id: ALERT_DETAIL_INFORMATIONAL_POLYGON_LAYER_ID, severity: '0'}
];

function replaceLayer(map, layerDef) {
  const oldLayers = map.getStyle().layers;
  const layerIndex = oldLayers.findIndex(l => l.id === layerDef.id);
  const exists = layerIndex !== -1;
  const before = exists && oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
  if (exists) {
    map.removeLayer(layerDef.id);
  }
  map.addLayer(layerDef, before);
}

/**
 * Creates a data source for the /intel/v3/alerts/ details mapdata
 * results and 3 layers layer symbols, polygon, line
 *
 */
export default class AlertDetailLayer extends MapLayer {

  constructor(interactive = true) {
    super();

    this.dataSource = false;
    this.interactive = interactive;
  }

  getInteractiveLayerIds() {
    if (this.interactive) {
      return this.getLayerIds();
    }

    return [];
  }

  getLayerIds() {
    return [
      ALERT_DETAIL_POLYGONS_LAYER_ID,
      ALERT_DETAIL_CRITICAL_POLYGON_LAYER_ID,
      ALERT_DETAIL_WARNING_POLYGON_LAYER_ID,
      ALERT_DETAIL_CAUTION_POLYGON_LAYER_ID,
      ALERT_DETAIL_INFORMATIONAL_POLYGON_LAYER_ID,

      ALERT_DETAIL_LINES_LAYER_ID,
      ALERT_DETAIL_POINTS_LAYER_ID
    ];
  }

  createSources() {
    if (this.map) {
      this.map.addSource(ALERT_DETAIL_SOURCE_ID, {
        type: 'geojson',
        data: this.dataSource
      });
    }
  }

  createLayers() {
    if (this.map) {
      POLYGON_LAYER_SEVERITY_LIST.forEach(layer => {
        const oldPolygonLayerStyle = this.map.getStyle().layers.find(item => item.id === layer.id);
        replaceLayer(this.map,
          {
            'id': `${layer.id}`,
            'type': 'fill',
            'source': ALERT_DETAIL_SOURCE_ID,
            'paint': oldPolygonLayerStyle.paint,
            'filter': ['all',
              ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']],
              ['==', ['get', 'severity'], layer.severity]
            ]
          });
      });

      const polygonOutline = this.map.getStyle().layers.find(item => item.id === ALERT_DETAIL_POLYGON_OUTLINE_LAYER_ID);
      replaceLayer(this.map, {
        'id': ALERT_DETAIL_POLYGON_OUTLINE_LAYER_ID,
        'type': 'line',
        'filter': ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']],
        'source': ALERT_DETAIL_SOURCE_ID,
        'paint': polygonOutline.paint ?? {'line-width': 1}
      });

      const line = this.map.getStyle().layers.find(item => item.id === ALERT_DETAIL_LINES_LAYER_ID);
      replaceLayer(this.map, {
        'id': ALERT_DETAIL_LINES_LAYER_ID,
        'type': 'line',
        'filter': ['any', ['==', ['geometry-type'], 'LineString'], ['==', ['geometry-type'], 'MultiLineString']],

        'source': ALERT_DETAIL_SOURCE_ID,
        'paint': line.paint
      });

      this.map.addLayer({
        id: ALERT_DETAIL_POINTS_LAYER_ID,
        filter: ['==', '$type', 'Point'],
        type: 'symbol',
        layout: {
          'icon-image': '{feature-symbol}',
          'icon-allow-overlap': true,
          'icon-size': 0.7
        },
        'source': ALERT_DETAIL_SOURCE_ID
      });

    }
  }

  /**
   * Set the source data for all layers.
   *
   * @param dataSource
   */
  setDataSource(dataSource) {
    if (this.dataSource !== dataSource) {

      // Save data reference for comparison and use onAdd
      this.dataSource = dataSource || false;

      if (this.map) {
        this.map.getSource(ALERT_DETAIL_SOURCE_ID)?.setData(
          this.dataSource || {'type': 'FeatureCollection', 'features': []});
      }
    }
  }

}
