import waRequest from './wa-request';

import {AUTO_STATUS_CONFIRM_TYPE} from '../../hooks/services/auto-status-confirmation/constants';

export const apiBase = '/auto-status-confirmation/v1';

function getScheduledTravelerCheckin({pathParams: {organizationId}}) {
  const url = `${apiBase}/organizations/${organizationId}/configurations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {type: AUTO_STATUS_CONFIRM_TYPE.TRAVELER}
  });
}

function getOneScheduledTravelerCheckin({pathParams: {organizationId, configId}}) {
  const url = `${apiBase}/organizations/${organizationId}/configurations/${configId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {type: AUTO_STATUS_CONFIRM_TYPE.TRAVELER}
  });
}


function createScheduledTravelerCheckin({pathParams: {organizationId}, data}) {
  const url = `${apiBase}/organizations/${organizationId}/configurations`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: data
  });
}

function updateScheduledTravelerCheckin({pathParams: {organizationId, configId}, data}) {
  const url = `${apiBase}/organizations/${organizationId}/configurations/${configId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: data
  });
}

function deleteScheduledTravelerCheckin({pathParams: {organizationId, configId}}) {
  const url = `${apiBase}/organizations/${organizationId}/configurations/${configId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete',
  });
}

export default {
  createScheduledTravelerCheckin,
  updateScheduledTravelerCheckin,
  deleteScheduledTravelerCheckin,
  getScheduledTravelerCheckin,
  getOneScheduledTravelerCheckin
};