import moment from 'moment'

/**
 * File user to house common custom validators. To keep consistent in usage
 * each item should be create in the form of function and return an antd
 * rule object
 *
 */

import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  minLength: {
    id: 'FormItemValidators.minLength',
    defaultMessage: 'Must be at least {minLength} characters'
  },
  maxLength: {
    id: 'FormItemValidators.maxLength',
    defaultMessage: 'Must be at most {maxLength} characters'
  },
  whitespace: {
    id: 'FormItemValidators.whitespace',
    defaultMessage: 'Input cannot be only spaces'
  },
  invalidStartDate: {
    id: 'FormItemValidators.invalidStartDate',
    defaultMessage: 'Start date cannot be in the past'
  },
});

export function minLength(minLength, trigger = ['onChange']) {
  return {
    min: minLength,
    message: <FormattedMessage {...messages.minLength} values={{minLength}}/>,
    validateTrigger: trigger
  };
}

export function maxLength(maxLength, trigger = ['onChange'], maxLengthCustomValidationMessage) {
  return {
    max: maxLength,
    message: maxLengthCustomValidationMessage 
      ? <FormattedMessage {...maxLengthCustomValidationMessage} values={{maxLength}} /> 
      : <FormattedMessage {...messages.maxLength} values={{maxLength}} />,
    validateTrigger: trigger
  };
}

// for daterange picker component validates that start date can't be in the past
export const dateRangePickerValidStartDate = () => {
  const currentDate = moment.utc().startOf('day');

  return ({
    validator(_, value) {
      if (value) {
        const [startDate] = value;
        if (startDate < currentDate) {
          return Promise.reject(<FormattedMessage {...messages.invalidStartDate} />);
        }
      }
      return Promise.resolve();
    }
  });
};

export function whiteSpace() {
  return {
    whitespace: true,
    message: <FormattedMessage {...messages.whitespace}/>
  };
}

export default {
  minLength,
  maxLength,
  whiteSpace
};
