import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {Card} from 'antd';

import {surveyResponseMessages} from '../utils';

import './SurveyResponseCard.css';


export default function SurveyResponseCard(props = {}) {
  
  const {title, comments, responseOptionText} = props;
  
  return (
    <Card
      className ="SurveyResponseCard"
      title={title}
    >
      {responseOptionText && <p>{responseOptionText}</p>}
      {comments && (
        <>
          <p className='comments-label'>
            <FormattedMessage {...surveyResponseMessages.comments} />
          </p>
          <p>{comments}</p>
        </>
      )}
    </Card>
  ); 
}

SurveyResponseCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  responseOptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  comments: PropTypes.string
};