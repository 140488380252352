import {EXPOSURES_FILTER_STORAGE_KEY_BASE} from '../constants/alert-filters';
import {horizonLocalStorage} from './local-storage';

const currentPersonaKey = 'current-persona-';

export function getStorageCurrentPersona(userId) {
  const encodedUserId = btoa(userId);
  const encodedValue = horizonLocalStorage.getItem(currentPersonaKey + encodedUserId);
  if (encodedValue) {
    return atob(encodedValue);
  } else {
    return encodedValue;
  }
}

export function setStorageCurrentPersona(value, userId) {
  const encodedUserId = btoa(userId);
  const encodedValue = btoa(value);
  horizonLocalStorage.setItem(currentPersonaKey + encodedUserId, encodedValue);
  horizonLocalStorage.removeItem(EXPOSURES_FILTER_STORAGE_KEY_BASE + userId);
}
