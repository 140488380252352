import MapMBTileOrgLayer from '../MapMBTileOrgLayer';

import {BASE_API_URL} from '../../../../../services/api/base-utils';
import {getRatingKey} from '../../../../../utils/rating-filters';
import {getLevelsFilter, getLocationsFilter, isNotWorldwideLocation} from '../+shared/utils';

import svgUnrated from '../../../../../assets/icon-city-view-unrated.svg';
import svgMinimal from '../../../../../assets/icon-city-view-minimal.svg';
import svgLow from '../../../../../assets/icon-city-view-low.svg';
import svgModerate from '../../../../../assets/icon-city-view-moderate.svg';
import svgHigh from '../../../../../assets/icon-city-view-high.svg';
import svgExtreme from '../../../../../assets/icon-city-view-extreme.svg';

import styles from '../../../../../containers/App/colors.module.css';

const RATINGS_CITY_SOURCE_POINT_ID = 'ratings-city-source-point';
const RATINGS_CITY_SOURCE_BOUNDARY_ID = 'ratings-city-source-boundary';
export const RATINGS_CITY_LAYER_POINT_ID = 'ratings-city-layer-point';
export const RATINGS_CITY_LAYER_BOUNDARY_ID = 'ratings-city-layer-boundary';

function loadImage(map, svg, name) {
  let img = new Image(20,20);
  img.onload = ()=> map.addImage(name, img);
  img.src = svg;
}

function addPointLayer(map, layerDef) {
  map.addLayer(layerDef, 'regional_zones');
}

function addBoundaryLayer(map, layerDef) {
  map.addLayer(layerDef, RATINGS_CITY_LAYER_POINT_ID);
}

export default class CityRatingsLayer extends MapMBTileOrgLayer {
  ratingId;
  levels;
  mapType;
  locationsFilter;

  getInteractiveLayerIds() {
    return this.getLayerIds();
  }

  getLayerIds() {
    return [RATINGS_CITY_LAYER_POINT_ID, RATINGS_CITY_LAYER_BOUNDARY_ID];
  }

  getTilesUrl(type) {
    return  `${BASE_API_URL}/mbtiles/v1/mbtiles/rating/{z}/{x}/{y}?tileType=${type}&locationTier=City&organizationId=${this.organizationId}`;
  }

  setTiles() {
    this.map.getSource(RATINGS_CITY_SOURCE_POINT_ID).setTiles([this.getTilesUrl('Point')]);
    this.map.getSource(RATINGS_CITY_SOURCE_BOUNDARY_ID).setTiles([this.getTilesUrl('Boundary')]);
  }

  createSources() {
    if (this.map) {
      this.map.addSource(RATINGS_CITY_SOURCE_POINT_ID, {
        'type': 'vector',
        'tiles': [this.getTilesUrl('Point')],
        minzoom: 1,
        maxzoom: 16
      });

      this.map.addSource(RATINGS_CITY_SOURCE_BOUNDARY_ID, {
        'type': 'vector',
        'tiles': [this.getTilesUrl('Boundary')],
        minzoom: 6,
        maxzoom: 16
      });
    }
  }

  createLayers() {
    if (this.map) {
      loadImage(this.map, svgUnrated, 'city-point-unrated');
      loadImage(this.map, svgMinimal, 'city-point-minimal');
      loadImage(this.map, svgLow, 'city-point-low');
      loadImage(this.map, svgModerate, 'city-point-moderate');
      loadImage(this.map, svgHigh, 'city-point-high');
      loadImage(this.map, svgExtreme, 'city-point-extreme');

      addPointLayer(this.map, {
        'id': RATINGS_CITY_LAYER_POINT_ID, // Layer ID
        'type': 'symbol',
        'source': RATINGS_CITY_SOURCE_POINT_ID, // ID of the tile source created above
        "source-layer": 'city_point_tiles',
        "maxzoom": 8,
        "layout": {
          'icon-allow-overlap': true
        }
      });


      addBoundaryLayer(this.map, {
        'id': RATINGS_CITY_LAYER_BOUNDARY_ID, // Layer ID
        'type': 'fill',
        'source': RATINGS_CITY_SOURCE_BOUNDARY_ID, // ID of the tile source created above
        "source-layer": 'city_boundary_tiles',
        'paint': {
          'fill-opacity':
          [
            'step',
            ['zoom'],
            // 0 if zoom is less then 7
            0, 7,
            // 1 if zoom is less then 16
            0.5, 16,
            // 0 if zoom is 8 (or greater)
            0
          ]
        }
      });

      this.updateStyling();
    }
  }

  setRatingFilters(ratingId, levels, mapType, locationsFilter) {
    if (this.ratingId !== ratingId || this.levels !== levels || this.mapType !== mapType || this.locationsFilter !== locationsFilter) {
      this.ratingId = ratingId;
      this.levels = levels;
      this.mapType = mapType;
      this.locationsFilter = locationsFilter;

      this.updateStyling();
    }
  }

  updateStyling() {
    if (this.map && this.organizationId && this.levels && this.levels.length > 0) {

      const currentRatingProperty = ['get', getRatingKey(this.ratingId)];

      const filters = [getLevelsFilter(currentRatingProperty, this.levels)];

      if (this.locationsFilter?.length > 0 && isNotWorldwideLocation(this.locationsFilter)) {
        filters.push(getLocationsFilter(this.locationsFilter));
      }

      this.map.setFilter(RATINGS_CITY_LAYER_POINT_ID, ['all', ...filters]);
      this.map.setFilter(RATINGS_CITY_LAYER_BOUNDARY_ID, ['all', ...filters]);

      this.map.setLayoutProperty(RATINGS_CITY_LAYER_POINT_ID, 'icon-image', [
        'step',
        ['to-number', currentRatingProperty, 0],
        // ratingUnrated if less then 1
        'city-point-unrated', 1,
        // ratingMinimal if less then 1.5
        'city-point-minimal', 1.5,
        // ratingLow if less then 2.5
        'city-point-low', 2.5,
        // ratingModerate if less then 3.5
        'city-point-moderate', 3.5,
        // ratingHigh if less then 4.5
        'city-point-high', 4.5,
        // ratingExtreme if more than 4.5
        'city-point-extreme'
      ]);

      this.map.setPaintProperty(RATINGS_CITY_LAYER_BOUNDARY_ID, 'fill-color', [
        'step',
        ['to-number', currentRatingProperty, 0],
        // ratingUnrated if less then 1
        styles.ratingUnrated, 1,
        // ratingMinimal if less then 1.5
        styles.ratingMinimal, 1.5,
        // ratingLow if less then 2.5
        styles.ratingLow, 2.5,
        // ratingModerate if less then 3.5
        styles.ratingModerate, 3.5,
        // ratingHigh if less then 4.5
        styles.ratingHigh, 4.5,
        // ratingExtreme if more than 4.5
        styles.ratingExtreme
      ]);
    }
  }
}
