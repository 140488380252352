import waRequest from './wa-request';

export const apiBase = '/briefs/v2';

/**
 * Initiate authenticated GET ${apiBase}/briefs/${locationId}
 *
 * @param locationId
 * @param briefType
 * @param organizationId
 * @param locale
 * @returns {Promise}
 */
function getLocationBrief({
  pathParams: {locationId}, 
  params: {briefType, organizationId, locale}
}) {
  const url = `${apiBase}/briefs/${locationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: {
      briefType,
      locale,
      organizationId
    }
  });
}

export default {
  getLocationBrief
};
