export const DEFAULT_PARENT_ID              = "00000000-0000-0000-0000-000000000000";
export const DEFAULT_PARENT_KEY             = "parent";
export const DEFAULT_PARENTID_KEY           = "parentId";
export const DEFAULT_HOTLINE_KEY            = "hotline";
export const DEFAULT_NAME_KEY               = "name";
export const DEFAULT_LEGACYID_KEY           = "legacyId";
export const DEFAULT_ID_KEY                 = "id";
export const DEFAULT_CHILDREN_KEY           = "children";

export const DEFAULT_AUDIT_KEY              = "audit";
export const DEFAULT_LAST_UPDATED_AT_KEY    = "lastUpdatedAt";
export const DEFAULT_CREATED_BY_KEY         = "createdBy";
export const DEFAULT_LAST_NAME_KEY          = "lastName";
export const DEFAULT_FIRST_NAME_KEY         = "firstName";
export const DEFAULT_MIDDLE_NAME_KEY        = "middleName";
export const DEFAULT_CLIENT_URL_STRING_KEY  = "clientUrlString";
export const DATA_RESIDENCY_KEY             = "dataResidency";
export const STATUS_KEY                     = "status";
export const TYPE_KEY                       = "types"

export const ROLE_TYPES = {
  STANDARD_INTERNAL: 'STANDARD_INTERNAL',
  STANDARD_CLIENT: 'STANDARD_CLIENT',
  CUSTOM: 'CUSTOM'
}

export const ACCESS_ANALYST_FIELDS = {
  TOTAL_REQUESTS_AVAILABLE: 'totalRequestsAvailable',
  FREQUENCY_LIMIT_PERIOD: 'frequencyInterval',
  FREQUENCY_LIMIT: 'frequencyLimit',
  OVERALL_RISK_RATING_RESTRICTIONS: 'overallRiskRatingRestrictions'
}

export const FREQUENCY_OPTIONS = {
  MONTH: 'MONTH',
  YEAR: 'YEAR'
}

export const RISK_RATING_RESTRICTION_VALUES = {
  NO_RESTRICTION: 'NO_RESTRICTION',
  TWO_OR_HIGHER:  'TWO_OR_HIGHER',
  THREE_OR_HIGHER: 'THREE_OR_HIGHER',
  FOUR_OR_HIGHER: 'FOUR_OR_HIGHER',
  FIVE_OR_HIGHER: 'FIVE_OR_HIGHER'
};

export const WELCOME_MESSAGE_FIELDS = {
  LOCALE: 'locale',
  MESSAGE:  'message'
};

export const DATA_RESIDENCY_OPTIONS = {
  US: 'US',
  EU:  'EU'
};

export const STATUS_OPTIONS = {
  ACTIVE: "ACTIVE",
  IMPLEMENTATION: "IMPLEMENTATION"
}
