export const GET_CONFIGURATION_REQUEST= 'organization/GET_CONFIGURATION_REQUEST';
export const GET_CONFIGURATION_SUCCESS = 'organization/GET_CONFIGURATION_SUCCESS';
export const GET_CONFIGURATION_FAILURE = 'organization/GET_CONFIGURATION_FAILURE';

export const ADD_CONFIGURATION_REQUEST= 'organization/ADD_CONFIGURATION_REQUEST';
export const ADD_CONFIGURATION_SUCCESS = 'organization/ADD_CONFIGURATION_SUCCESS';
export const ADD_CONFIGURATION_FAILURE = 'organization/ADD_CONFIGURATION_FAILURE';

export const UPDATE_CONFIGURATION_REQUEST= 'organization/UPDATE_CONFIGURATION_REQUEST';
export const UPDATE_CONFIGURATION_SUCCESS = 'organization/UPDATE_CONFIGURATION_SUCCESS';
export const UPDATE_CONFIGURATION_FAILURE = 'organization/UPDATE_CONFIGURATION_FAILURE';

export const GET_CONFIGURATION_ITEM_REQUEST= 'organization/GET_CONFIGURATION_ITEM_REQUEST';
export const GET_CONFIGURATION_ITEM_SUCCESS = 'organization/GET_CONFIGURATION_ITEM_SUCCESS';
export const GET_CONFIGURATION_ITEM_FAILURE = 'organization/GET_CONFIGURATION_ITEM_FAILURE';

export const GET_MOBILE_CONFIGURATIONS_REQUEST= 'organization/GET_MOBILE_CONFIGURATIONS_REQUEST';
export const GET_MOBILE_CONFIGURATIONS_SUCCESS = 'organization/GET_MOBILE_CONFIGURATIONS_SUCCESS';
export const GET_MOBILE_CONFIGURATIONS_FAILURE = 'organization/GET_MOBILE_CONFIGURATIONS_FAILURE';

export const ADD_CONFIGURATION_ITEM_REQUEST= 'organization/ADD_CONFIGURATION_ITEM_REQUEST';
export const ADD_CONFIGURATION_ITEM_SUCCESS = 'organization/ADD_CONFIGURATION_ITEM_SUCCESS';
export const ADD_CONFIGURATION_ITEM_FAILURE = 'organization/ADD_CONFIGURATION_ITEM_FAILURE';

export const UPDATE_CONFIGURATION_ITEM_REQUEST= 'organization/UPDATE_CONFIGURATION_ITEM_REQUEST';
export const UPDATE_CONFIGURATION_ITEM_SUCCESS = 'organization/UPDATE_CONFIGURATION_ITEM_SUCCESS';
export const UPDATE_CONFIGURATION_ITEM_FAILURE = 'organization/UPDATE_CONFIGURATION_ITEM_FAILURE';

export const DELETE_CONFIGURATION_ITEM_REQUEST= 'organization/DELETE_CONFIGURATION_ITEM_REQUEST';
export const DELETE_CONFIGURATION_ITEM_SUCCESS = 'organization/DELETE_CONFIGURATION_ITEM_SUCCESS';
export const DELETE_CONFIGURATION_ITEM_FAILURE = 'organization/DELETE_CONFIGURATION_ITEM_FAILURE';

export const DELETE_CONFIGURATION_REQUEST= 'organization/DELETE_CONFIGURATION_REQUEST';
export const DELETE_CONFIGURATION_SUCCESS = 'organization/DELETE_CONFIGURATION_SUCCESS';
export const DELETE_CONFIGURATION_FAILURE = 'organization/DELETE_CONFIGURATION_FAILURE';

export const CLEAR_CONFIGURATION_ITEM = 'organization/CLEAR_CONFIGURATION_ITEM';

export const GET_RESOURCES_REQUEST= 'organization/GET_RESOURCES_REQUEST';
export const GET_RESOURCES_SUCCESS = 'organization/GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILURE = 'organization/GET_RESOURCES_FAILURE';
