import waRequest from './wa-request';

export const baseApi = '/itinerary/v1';

/**
 * Initiate authenticated GET ${baseApi}/itineraries
 *
 * @returns {Promise}
 */
function getTrips({personId, searchWindows, includeDeleted, filterString, statuses}, {page = 0, size = 25, sort}, locale) {

  const url = `${baseApi}/itineraries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    params: {
      personId,
      searchWindows,
      includeDeleted,
      filterString,
      statuses,
      page,
      size,
      sort,
      locale
    },
    data: {
      personId,
      searchWindows
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/itineraries/{itineraryId}
 *
 * @param {string} itineraryId The itinerary id
 * @param {string} locale
 * @returns {Promise}
 */
function getItineraryById(itineraryId, locale) {
  const url = `${baseApi}/itineraries/${itineraryId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {locale}
  });
}

function createTrip(trip) {
  const url = `${baseApi}/itineraries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: trip
  });
}

function updateTrip(trip) {
  const url = `${baseApi}/itineraries/${trip.id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: trip
  });
}

function patchTrip(tripId, payload) {
  const url = `${baseApi}/itineraries/${tripId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data: payload
  });
}

function deleteTrip(tripId) {
  const url = `${baseApi}/itineraries/${tripId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 * Initiate authenticated GET ${baseApi}/bulkUploads/example to get bulk upload example template file
 *
 * @returns {Promise}
 */
function getBulkUploadExample(values) {
  const url = `${baseApi}/bulkUploads/example`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: values,
    responseType: 'blob'
  });
}

/**
 * Initiate authenticated POST Bulk Upload file to ${baseApi}/bulkUploads
 *
 * @param {FormData} data File converted to FormData
 * @returns {Promise}
 */
function uploadFile(data) {
  const url = `${baseApi}/bulkUploads`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  });

}

/**
 * Initiate authenticated GET Bulk Upload errors for file to ${baseApi}/bulkUploads/{id}/errors
 *
 * @param {string} id The bulk upload id
 * @returns {Promise}
 */
function getFileErrors({pathParams: {fileId}}) {
  const url = `${baseApi}/bulkUploads/${fileId}/errors`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });

}

/**
 * Initiate authenticated GET Bulk Upload file details to ${baseApi}/bulkUploads/{id}
 *
 * @param {string} id The bulk upload id
 * @param {function} successCallback A function called that returns true when the retries should stop
 * @returns {Promise}
 */
function getFileDetails(id, successCallback) {
  const url = `${baseApi}/bulkUploads/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback,
  5000
  );
}

function getBulkUploadsList({params: {size = 25, page = 0, organizationId, sort='createdAt,desc'}, signal}) {
  const url = `${baseApi}/bulkUploads`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      size,
      page,
      organizationId,
      sort
    }
  });
}

/**
 * Get trip itineraries using config pattern (for hook)
 *
 * @param {Object} config
 * @param {Object} config.params
 * @param {string} config.params.personId Id of the person on the itinerary
 * @param {Array.<string>} config.params.searchWindows The search windows or timeframes itineraries must be within, One or more of PAST, ACTIVE, or FUTURE
 * @param {boolean} [config.params.includeDeleted=false] Whether to include deleted itineraries in the results
 * @param {Object=} config.params.filterString Terms separated by whitespaces to be used across trip name and record locator to filter the results.
 * @param {number=} config.params.statuses The trip statuses that itineraries must have. One or more of CANCELLED, or BOOKED
 * @param {number=} config.params.page Zero-based page index (0..N)
 * @param {number=} config.params.size The size of the page to be returned
 * @param {Array.<string>=} config.params.sort [property.(asc|desc)] Default sort order is ascending.
 * @param {AbortSignal} config.signal AbortController signal
 *
 * */
function getTripsConfigPattern(config) {

  const {
    params: {
      personId,
      searchWindows,
      filterString,
      statuses,
      includeDeleted,
      page,
      size,
      sort,
      fromDate,
      toDate
    },
    signal
  } = config;

  const url = `${baseApi}/itineraries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    params: {
      personId,
      searchWindows,
      filterString,
      statuses,
      includeDeleted,
      page,
      size,
      sort,
      fromDate,
      toDate
    },
    signal
  });
}

function getTripCustomFields({params: {organizationId, locale}}) {
  const url = `${baseApi}/customFieldConfigs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      organizationId,
      locale
    }
  });
}

function createTripCustomFields({
  data: {
    organizationId,
    fields
  },
}){
  const url = `${baseApi}/customFieldConfigs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      organizationId,
      fields
    }
  });
}

function updateTripCustomFields({
  pathParams: {id},
  data: {
    organizationId,
    fields
  },
}) {
  const url = `${baseApi}/customFieldConfigs/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {
      organizationId,
      fields
    },
  });
}

export default {
  getTrips,
  getItineraryById,
  createTrip,
  updateTrip,
  deleteTrip,
  getBulkUploadExample,
  uploadFile,
  getFileErrors,
  getFileDetails,
  getBulkUploadsList,
  patchTrip,
  getTripsConfigPattern,
  getTripCustomFields,
  createTripCustomFields,
  updateTripCustomFields
};


