import _ from 'lodash';

import LAYERS from "../../../../components/Common/Mapbox/layers/layerIds";

export function parseSitePolygonFeatureResult(response, filterParams) {
  const data = _.get(response, ['data'], {});

  if (!data || data?.siteCount === 0) {
    return [];
  }

  return {
    layer: {
      id: LAYERS.SITE_LAYER_ID
    },
    properties: {
      pointCount: data.siteCount,
      occupantCount: data.occupantCount,
      id: data.siteId,
      occupantId: data.occupantId,
      occupantSiteId: data.occupantSiteId
    },
    sitesFilterParams: filterParams,
    filterParams,
    isPolygonSelection: true
  };
}