import waRequest from './wa-request';

export const apiBase = '/communication-history/v1';

/**
 *
 * @typedef {{organizationId: string, locale: string, page: number, size: integer, sort: string}} PageableCommunicationParams
 *
 * @typedef {{organizationId: string, locale: string}} CommunicationParams
 *
 * @typedef {{type: string, organizationId: string, locale: string, sort: string}} CommunicationReportParams
 *
 * @typedef {{
    channels: string,
    dateEnd: string (datetime),
    dateStart: string (datetime),
    direction: string
    organizationId: string,
    recipientLike: string,
    senderLike: string,
    subjectLike: string,
    types: string,
    relatedIntel: bool,
    view: string //"ACCOUNT" or "ALERT"
  }} CommunicationApiFilter
 */

/**
 * Initiate authenticated POST ${apiBase}/communications/searches
 * @param {PageableCommunicationParams} urlParams
 * @param {CommunicationApiFilter} filter
 * @returns {Promise}
 */
function getListOfCommunications(urlParams, filter, signal) {
  const url = `${apiBase}/communications/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    signal
  });
}

function getListOfCommunicationsWrapper({
  params: urlParams,
  data: filter,
  signal
}) {
  return getListOfCommunications(urlParams, filter, signal)
}

/**
 * Initiate authenticated POST ${apiBase}/communications/reports
 * @param {CommunicationReportParams} urlParams
 * @param {CommunicationApiFilter} filter
 * @returns {Promise}
 */
function getReportOfListOfCommunications(urlParams, filter) {
  const url = `${apiBase}/communications/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    responseType: 'blob'
  });
}

/**
 * Initiate authenticated GET ${apiBase}/communications/{id}
 * @param {string} communicationId
 * @param {CommunicationParams} urlParams
 * @returns {Promise}
 */
function getCommunication(communicationId, urlParams) {
  const url = `${apiBase}/communications/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

function getCommunicationConfigPattern({pathParams: communicationId, params: {orgId, locale, relatedIntel=false, signal}}) {
  const url = `${apiBase}/communications/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      organizationId: orgId,
      locale,
      relatedIntel
    }
  });
}

/**
 * Initiate authenticated GET ${apiBase}/communications/{id}/reports
 * @param {string} communicationId
 * @param {CommunicationReportParams} urlParams
 * @returns {Promise}
 */
function getReportOfCommunication(communicationId, urlParams) {

  const url = `${apiBase}/communications/${communicationId}/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams,
    responseType: 'blob'
  });
}

function getCommunicationBody({pathParams: communicationId, params: {orgId, locale, signal}}) {
  const url = `${apiBase}/communications/${communicationId}/body`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      organizationId: orgId,
      locale
    }
  });
}

/**
 * Initiate authenticated GET ${apiBase}/communications/{id}/recipients
 * @param {string} communicationId
 * @param {PageableCommunicationParams} urlParams
 * @returns {Promise}
 */
function getRecipientsOfCommunication(communicationId, urlParams) {

  const url = `${apiBase}/communications/${communicationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

/**
 * Initiate authenticated GET ${apiBase}/communications/sites/{siteId}/intel/{intelId}/recipients
 * @param {string} communicationId
 * @param {PageableCommunicationParams} urlParams
 * @returns {Promise}
 */
function getCommunicationsAlertHistoryRecipients({
  signal,
  params: {
    organizationId
  },
  pathParams: {
    siteId,
    intelId
  }
}) {
  const url = `${apiBase}/communications/sites/${siteId}/intel/${intelId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      organizationId
    }
  });
}


/**
 * Initiate authenticated POST ${apiBase}/check-in-notifications/searches
 * @param {PageableCommunicationParams} urlParams
 * @param {CommunicationApiFilter} filter
 * @returns {Promise}
 */
function getListOfMobileCheckins(urlParams, filter) {

  const url = `${apiBase}/check-in-notifications/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams
  });
}

/**
 * Initiate authenticated POST ${apiBase}/check-in-notifications/reports
 * @param {CommunicationReportParams} urlParams
 * @param {CommunicationApiFilter} filter
 * @returns {Promise}
 */
function getReportOfListOfMobileCheckins(urlParams, filter) {
  const url = `${apiBase}/check-in-notifications/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    responseType: 'blob'
  });
}

/**
 * Initiate authenticated GET ${apiBase}/check-in-notifications/{id}
 * @param {string} communicationId
 * @param {CommunicationParams} urlParams
 * @returns {Promise}
 */
function getMobileCheckin(communicationId, urlParams) {

  const url = `${apiBase}/check-in-notifications/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

/**
 * Initiate authenticated GET ${apiBase}/check-in-notifications/{id}/reports
 * @param {string} communicationId
 * @param {CommunicationReportParams} urlParams
 * @returns {Promise}
 */
function getReportOfMobileCheckin(communicationId, urlParams) {

  const url = `${apiBase}/check-in-notifications/${communicationId}/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams,
    responseType: 'blob'
  });
}

/**
 * Initiate authenticated GET ${apiBase}/check-in-notifications/{id}/recipients
 * @param {string} communicationId
 * @param {PageableCommunicationParams} urlParams
 * @returns {Promise}
 */
function getRecipientsOfMobileCheckin(communicationId, urlParams) {

  const url = `${apiBase}/check-in-notifications/${communicationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

function getRollupSearches(urlParams, filter) {
  const url = `${apiBase}/rollups/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams
  });
}

function getReportOfRollupSearches(urlParams, filter) {
  const url = `${apiBase}/rollups/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    responseType: 'blob'
  });
}

function getRollupMessageDetails(communicationId, urlParams) {
  const url = `${apiBase}/rollups/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

function getRollupMessageRecipients(communicationId, urlParams) {
  const url = `${apiBase}/rollups/${communicationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

function getRollupMessageReport(communicationId, urlParams) {

  const url = `${apiBase}/rollups/${communicationId}/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams,
    responseType: 'blob'
  });
}

function getCommunicationSubTypes({view, locale}) {

  const url = `${apiBase}/communications/options`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      view
    }
  });
}

export default {
  getListOfCommunications,
  getListOfCommunicationsWrapper,
  getReportOfListOfCommunications,
  getCommunication,
  getReportOfCommunication,
  getRecipientsOfCommunication,
  getCommunicationsAlertHistoryRecipients,

  getListOfMobileCheckins,
  getReportOfListOfMobileCheckins,
  getMobileCheckin,
  getReportOfMobileCheckin,
  getRecipientsOfMobileCheckin,

  getRollupSearches,
  getReportOfRollupSearches,
  getRollupMessageDetails,
  getRollupMessageRecipients,
  getRollupMessageReport,
  getCommunicationBody,
  getCommunicationConfigPattern,

  getCommunicationSubTypes
};
