import {useEffect, useState} from 'react';
import logo from '../../../assets/entry-exit-logo.png';

const ENTRY_EXIT_IFRAME_URL = process.env.REACT_APP_ENTRY_EXIT_IFRAME_URL ||
  'https://www.timaticweb2.com/integration/external?ref=b12598b10f91b6fc99912adbeb7ec13d';

export default function EntryExit() {
  const [scrollbarWidth, setScrollbarWidth] = useState(17);

  useEffect(() => {
    const node = document.getElementById('EntryExit');
    if (node) {
      setScrollbarWidth(node.offsetWidth - node.clientWidth);
    }
  }, [setScrollbarWidth]);

  return (
    <div id="EntryExit" style={{width: "100%", height: '100vh', overflow: 'auto'}}>
      <div style={{background: 'white', position: 'absolute', height: '60px', width: `calc(100% - ${scrollbarWidth}px)`, padding: '5px 5px 5px 25px', borderBottom: '1px solid #F0F0F0'}}>
        <img style={{height: '100%'}} src={logo} alt="Crisis24 Horizon Logo"/>
      </div>
      <iframe src={ENTRY_EXIT_IFRAME_URL} title='timaticweb2' frameBorder="0" style={{width:'100%', height: '980px', paddingTop: '35px'}} />
      <div style={{color: 'black', padding: '0 25px'}}>
        Please note that this content is only available in English.
      </div>
      <div style={{color: 'black', padding: '10px 25px 40px'}}>
        The information in this document is provided “as-is” and is subject to change at any time. This document may also contain proprietary and confidential information that includes but is not limited to personally identifiable information (all such data collectively, the “Information”). Use of the Information is at your own risk. Crisis24 accepts no liability or responsibility for any errors or omissions in the Information arising out of partial or faulty transmission. This service may contain language translations performed by machine or artificial intelligence, and as a result, may contain translation imperfections or errors. Crisis24 disclaims all warranties related to any such translations, express or implied, including any warranties of accuracy, reliability, and any implied warranties of merchantability and fitness for a particular purpose. Crisis24 owns copyrights in the transmitted Information, and it may not be reproduced or redistributed in any form without the express written consent of Crisis24. If you have obtained this document in error, please delete it immediately.
      </div>
    </div>
  )
}