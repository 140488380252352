export const SELF_REGISTRATION_TYPES = {
  VALID_EMAIL_DOMAIN: 'VALID_EMAIL_DOMAIN',
  MEMBER_ID: 'MEMBER_ID',
  FSSO: 'FSSO'
};

export const MEMBER_ID_TYPE = {
  STATIC: 'memberId',
  DYNAMIC: 'memberIdPattern'
};

export const FSSO_SUB_TYPE = {
  PROVIDER_NAME: 'PROVIDER_NAME',
  EMAIL_DOMAIN: 'EMAIL_DOMAIN'
};