import _ from 'lodash';

import * as worldMapUtils from '../utils';
import {WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE} from '../../../../constants/person-filters';

import {
  VIEW_CRISIS_SIGNAL_LOCATIONS,
  VIEW_EXPATRIATIONS,
  VIEW_PERSONS,
  VIEW_PERSONS_GEOCODED_ADDRESS,
  VIEW_PERSONS_MOBILE_LOCATION,
  VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS,
  VIEW_TRIPS
} from '../../../../constants/permissions';
import {
  ADDRESSES_LOCATIONS_RADIO,
  AIRLINE_ID,
  AIRPORT_ID,
  ALL,
  FLIGHT_NUMBER_LIKE,
  INCLUDE_SUB_ORGS,
  ML_ALL,
  OCCUPANT,
  PERSON_GROUP,
  PERSON_MOBILE_LOCATIONS_RADIO,
  PERSON_ORGS,
  POC,
  RAILWAY,
  TRAIN_NUMBER
} from '../../../../components/Person/PersonOptionsSelection/form-constants';
import {LAYERS} from '../../../../components/Common/Mapbox/layers/layerIds';
import {constants} from '../../persons/utils';
import {DEFAULT_PARENT_ID} from '../../../../components/Organizations/constants';

const imageCDN = `${process.env.REACT_APP_CDN_URL}/images/world-map`;

export const EMPTY_PERSON_CLUSTER_ID = '___EMPTY___PERSON___CLUSTER___';

export const PERSON_IMAGE_SRC = {
  CRISIS_SIGNAL: `${imageCDN}/marker_crisis.svg`,
  SAFETY_CHECK_IN: `${imageCDN}/marker_checkin.svg`,
  MOBILE_BACKGROUND_SHARE: `${imageCDN}/marker_person.svg`,
  TRAIN: `${imageCDN}/icon_pin_rail.svg`,
  PLANE: `${imageCDN}/icon_pin_flight_white.svg`,
  HOTEL: `${imageCDN}/icon_pin_hotel_white.svg`,
  CAR: `${imageCDN}/icon_pin_car.svg`,
  ADDRESSES: `${imageCDN}/icon_pin_address_white.svg`,
  EXPATRIATIONS: `${imageCDN}/icon_pin_expatriation.svg`
};

export const PERSON_LOCATION_TYPES_EXCLUDED_VALUES = {
  MAP_PEOPLE: 'MAP_PEOPLE',
  MAP_MOBILE_LOCATIONS: 'MAP_MOBILE_LOCATIONS',
  MAP_TRIPS: 'MAP_TRIPS'
};

export const PERSON_MOBILE_LOCATIONS_VALUES = {
  CRISIS_SIGNAL: 'CRISIS_SIGNAL',
  SAFETY_CHECK_IN: 'SAFETY_CHECK_IN',
  MOBILE_BACKGROUND_SHARE: 'MOBILE_BACKGROUND_SHARE'
};

export const PERSON_TRIPS_VALUES = {
  TRIP_AIR: 'TRIP_AIR',
  TRIP_HOTEL: 'TRIP_HOTEL',
  TRIP_RAIL: 'TRIP_RAIL',
  TRIP_CAR: 'TRIP_CAR'
};

export const PERSON_SITE_VALUES = {
  OCCUPANT: OCCUPANT,
  POC: POC
};

/**
 *  The required permissions for all of the VIEW_PERSONS types
 *  was changed to VIEW_PERSONS in this Jira issue:
 *  https://gardaworld.atlassian.net/browse/HORIZONWEB-2850
 *  */
export const PERSON_MAPPING = [
  {
    permissions: [VIEW_PERSONS],
    value: PERSON_MOBILE_LOCATIONS_VALUES.CRISIS_SIGNAL
  },
  {
    permissions: [VIEW_PERSONS],
    value: PERSON_MOBILE_LOCATIONS_VALUES.SAFETY_CHECK_IN
  },
  {
    permissions: [VIEW_PERSONS],
    value: PERSON_MOBILE_LOCATIONS_VALUES.MOBILE_BACKGROUND_SHARE
  },
  // trips
  {
    permissions: [VIEW_PERSONS],
    value: PERSON_TRIPS_VALUES.TRIP_AIR
  },
  {
    permissions: [VIEW_PERSONS],
    value: PERSON_TRIPS_VALUES.TRIP_HOTEL
  },
  {
    permissions: [VIEW_PERSONS],
    value: PERSON_TRIPS_VALUES.TRIP_RAIL
  },
  {
    permissions: [VIEW_PERSONS],
    value: PERSON_TRIPS_VALUES.TRIP_CAR
  }
];

export function getCurrentOrgs(state) {
  return getMapSelectedPeopleOrgs(state);
}

export function getMapSelectedPeopleOrgs(state) {
  const personOrgsFilter = _.get(state, ['worldMap', WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, PERSON_ORGS], []);
  const fullPermissions = _.get(state, ['session', 'fullPermissions'], []);
  const defaultOrgsFilter = _.chain(fullPermissions)
    .pickBy(value => value?.permissions?.[VIEW_PERSONS])
    .keys()
    .value();
  return personOrgsFilter?.length > 0 ? personOrgsFilter : defaultOrgsFilter;
}

export function getMapSelectedPeopleOrgsPerFilter(personOrgsFilter, fullPermissions) {
  if (personOrgsFilter?.length > 0) {
    return personOrgsFilter;
  }

  const defaultOrgsFilter = _.chain(fullPermissions)
    .pickBy(value => value?.permissions?.[VIEW_PERSONS])
    .keys()
    .value();
  if (defaultOrgsFilter?.includes(DEFAULT_PARENT_ID)) {
    return [DEFAULT_PARENT_ID];
  }
  return defaultOrgsFilter;
}

export function parsePersonPolygonFeatureResult(response, filterParams) {
  const idCount = _.get(response, ['data', 'totalElements'], 0);
  const singlePersonId = _.get(response, ['data', 'content', 0, 'id']);

  if (idCount > 0) {
    return {
      layer: {
        id: LAYERS.PERSON_LAYER_ID
      },
      properties: {
        id: idCount === 1 && singlePersonId,
        idCount
      },
      personFilterParams: filterParams,
      filterParams,
      isPolygonSelection: true
    };
  }

  return []; //empty set for no results
}

export function filterPersonViaPermissions(hasPermission = {}, personTypes) {
  return PERSON_MAPPING.reduce((accumulator, item) => {
    const acceptedPermissions = item.permissions
      .filter((permission) => {
        return hasPermission[permission];
      });
    if (personTypes?.includes?.(item.value) && acceptedPermissions.length === item.permissions.length) {
      accumulator.push(item.value);
    }
    return accumulator;
  }, []);
}

/**
 * Returns true if PERSON_LAYER_ID
 *
 * @param feature
 * @returns {*}
 */
export function isPersonFeature(feature) {
  const layerId = _.get(feature, 'layer.id');
  return [
    LAYERS.PERSON_LAYER_ID,
    LAYERS.CRISIS_SIGNAL_LAYER_ID,
    LAYERS.PERSON_DETAIL_LAYER_ID,
    LAYERS.NON_MAP_AFFECTED_PEOPLE_LAYER_ID,
    LAYERS.NON_MAP_AFFECTED_PEOPLE_AT_SITES_LAYER_ID
  ].includes(layerId);
}

export function isSitesFeature(feature) {
  const layerId = _.get(feature, 'layer.id');
  return layerId === LAYERS.NON_MAP_AFFECTED_SITES_LAYER_ID || layerId === LAYERS.SITE_LAYER_ID;
}

export function getLocalStorageMapPeople(state, worldMapLocalStorage) {
  let localStorageMapPeople = _.get(worldMapLocalStorage, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE]);
  const verifiedPeopleOrgs = worldMapUtils.filterOrgsPerAvailablePersonas(state, localStorageMapPeople?.[PERSON_ORGS]);
  const hasPersonPermission = worldMapUtils.getAggregatedPermissionIdsObject(state);
  const hasViewPerson = hasPersonPermission?.[VIEW_PERSONS];
  const hasAnyOfPersonSubPermissions = _.some([
    VIEW_SAFETY_CHECKIN_SIGNAL_LOCATIONS,
    VIEW_CRISIS_SIGNAL_LOCATIONS,
    VIEW_PERSONS_MOBILE_LOCATION,
    VIEW_TRIPS,
    VIEW_PERSONS_GEOCODED_ADDRESS,
    VIEW_EXPATRIATIONS
  ], permission => hasPersonPermission?.[permission]);
  const showPerson = hasViewPerson && hasAnyOfPersonSubPermissions;
  localStorageMapPeople = {
    ...localStorageMapPeople,
    [PERSON_GROUP]: showPerson ? localStorageMapPeople?.[PERSON_GROUP] : [],
    [PERSON_MOBILE_LOCATIONS_RADIO]: showPerson ? localStorageMapPeople?.[PERSON_MOBILE_LOCATIONS_RADIO] : ML_ALL,
    [ADDRESSES_LOCATIONS_RADIO]: showPerson ? localStorageMapPeople?.[ADDRESSES_LOCATIONS_RADIO] : ALL,
    [PERSON_ORGS]: verifiedPeopleOrgs,
    [INCLUDE_SUB_ORGS]: localStorageMapPeople?.[INCLUDE_SUB_ORGS]
  };
  return {
    showPerson,
    localStorageMapPeople
  };
}

export function getPersonAdvanceSearchParams(worldMapState) {
  const airline = _.get(worldMapState, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, AIRLINE_ID]);
  const airport = _.get(worldMapState, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, AIRPORT_ID]);
  const flightNumberLike = _.get(worldMapState, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, FLIGHT_NUMBER_LIKE]);
  const addressLabels = _.get(worldMapState, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, constants.ADDRESS_LABELS]);
  const addressAccuracies = _.get(worldMapState, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, constants.ADDRESS_ACCURACY]);
  const railwayNameLike = _.get(worldMapState, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, RAILWAY]);
  const trainNumberLike = _.get(worldMapState, [WORLD_MAP_FILTER_PERSON_STORAGE_KEY_BASE, TRAIN_NUMBER]);

  return {
    airlineId: airline?.id,
    airportId: airport?.id,
    flightNumberLike: flightNumberLike === "" ? undefined : flightNumberLike,
    addressLabels: addressLabels?.length > 0 ? addressLabels?.map(i => i?.value) : undefined,
    addressAccuracies: addressAccuracies?.length > 0 ? addressAccuracies?.map(i => i?.value) : undefined,
    railwayNameLike: railwayNameLike === "" ? undefined : railwayNameLike,
    trainNumberLike: trainNumberLike === "" ? undefined : trainNumberLike
  };
}
