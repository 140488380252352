import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';

import {Card, Radio} from 'antd';

import localization from '../../../services/localization';
import {surveyResponseMessages} from '../utils';

import InfoLabel from '../../Common/InfoLabel/InfoLabel';
import TextAreaInput from '../../Common/FormItems/TextAreaInput';
import RequiredFieldStar from '../../Common/BaseFormItem/RequiredFieldStar/RequiredFieldStar';
import BaseFormItem from '../../Common/BaseFormItem/BaseFormItem';


import './SurveyResponseFormCard.css';

const messages = defineMessages({
  textPlaceholder: {
    id: 'SurveyResponseFormCard.textPlaceholder',
    defaultMessage: 'Enter comments'
  }
});

export default function SurveyResponseFormCard(props) {

  const {survey, required, info, field} = props;

  const options = survey?.responseOptions?.map((responseOption) => {
    return (
      <Radio key={responseOption.number} value={responseOption.number}>{responseOption.text}</Radio>
    );
  });

  const requiredIndicator = required ? <RequiredFieldStar/> : null;
  
  const title = (
    <InfoLabel
      label={(<span>{requiredIndicator}{survey?.text}</span>)}
      info={info}/>
  );

  const fieldName = {
    radio: required ?
      [field, 'confirmedStatus'] :
      [field, survey.number - 1, 'responseOptionNumber'],
    comments: required ? 
      [field, 'comments'] :
      [field, survey.number - 1, 'comments']
  };

  return (
    <Card className='SurveyResponseFormCard' title={title}>
      {survey?.responseOptions?.length > 0 && (
        <BaseFormItem
          field={fieldName.radio}
          required={required}
        >
          <Radio.Group className='radio-group-vertical'>
            {options}
          </Radio.Group>
        </BaseFormItem>
      )}
      <TextAreaInput
        label={localization.formatMessage(surveyResponseMessages.comments)}
        field={fieldName.comments}
        rows={4}
        maxLength={200}
        placeholder={localization.formatMessage(messages.textPlaceholder)}
      />
    </Card>
  );
}

SurveyResponseFormCard.propTypes = {
  survey: PropTypes.object,
  required: PropTypes.bool,
  info: PropTypes.node,
  field: PropTypes.string
};