export const FILE_TYPE = {
  CSV: 'csv',
  XLSX: 'xlsx',
  PDF: 'pdf',
  XLS: 'xls',
  PGP: 'pgp',
  TXT: 'txt'
};

export const EXPOSURES_FILE_TYPE = {
  CSV: 'CSV',
  XLSX: 'XLSX'
};

export const FILE_TYPE_EXTENDED = {
  ...FILE_TYPE,
  PPT: 'ppt',
  PPTX: 'pptx',
  DOC: 'doc',
  DOCX: 'docx'
};

export const IMAGE_FILE_TYPE = {
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg'
}
