// available only in localhost dev machine
// show browser native alert with react error as Warning

import {horizonLocalStorage} from './utils/local-storage';

//MUST have Crisis24 Horizon Functional Storage enabled in Usercentrics to use this ReactWarningTracker
export default function ReactWarningTracker() {

  const rwtKey = horizonLocalStorage.getItem('rwt');

  const isRwtKey = !!rwtKey && rwtKey.toLowerCase() !== 'false';

  if (process.env.REACT_APP_ENVIRONMENT.includes('LOCAL') && isRwtKey) {
    var oldLog = console.error;
    console.error = function (message) {
      // DO MESSAGE HERE.
      oldLog.apply(console, arguments);
      if (message.includes('Warning:')) {
        alert('!!!REACT TECH DEBT DETECTED!!! please fix !!! : ' + message);
      }
    };
  }
}
