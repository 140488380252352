import {defineMessages} from 'react-intl';
import {VIEW_CAUTION_ALERTS, VIEW_CRITICAL_ALERTS, VIEW_INFORMATIONAL_ALERTS, VIEW_WARNING_ALERTS} from './permissions';

export const INFORMATIONAL_ALERT_KEY = '0';
export const CAUTION_ALERT_KEY = '1';
export const WARNING_ALERT_KEY = '2';
export const CRITICAL_ALERT_KEY = '3';

export const ALERT_SEVERITY_KEY = {
  INFORMATIONAL: INFORMATIONAL_ALERT_KEY,
  CAUTION: CAUTION_ALERT_KEY,
  WARNING: WARNING_ALERT_KEY,
  CRITICAL: CRITICAL_ALERT_KEY
};

export const ALERT_SEVERITY = {
  INFORMATIONAL: 'INFORMATIONAL',
  CAUTION: 'CAUTION',
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL'
};

export const INCIDENT_IMPACT_LEVEL = {
  MINIMAL: 'MINIMAL',
  LOW: 'LOW',
  MODERATE: 'MODERATE',
  HIGH: 'HIGH',
  SEVERE: 'SEVERE'
};

export const MINIMAL_INCIDENT_KEY = 'I1';
export const LOW_INCIDENT_KEY = 'I2';
export const MODERATE_INCIDENT_KEY = 'I3';
export const HIGH_INCIDENT_KEY = 'I4';
export const SEVERE_INCIDENT_KEY = 'I5';

export const INCIDENT_IMPACT_LEVEL_KEY = {
  [INCIDENT_IMPACT_LEVEL.MINIMAL]: MINIMAL_INCIDENT_KEY,
  [INCIDENT_IMPACT_LEVEL.LOW]: LOW_INCIDENT_KEY,
  [INCIDENT_IMPACT_LEVEL.MODERATE]: MODERATE_INCIDENT_KEY,
  [INCIDENT_IMPACT_LEVEL.HIGH]: HIGH_INCIDENT_KEY,
  [INCIDENT_IMPACT_LEVEL.SEVERE]: SEVERE_INCIDENT_KEY
};

export const DEFAULT_ALERT_SEVERITIES = [
  ALERT_SEVERITY.INFORMATIONAL,
  ALERT_SEVERITY.WARNING,
  ALERT_SEVERITY.CRITICAL,
  ALERT_SEVERITY.CAUTION
];

export const ALERT_SEVERITY_FROM_KEY = {
  [INFORMATIONAL_ALERT_KEY]: ALERT_SEVERITY.INFORMATIONAL,
  [CAUTION_ALERT_KEY]: ALERT_SEVERITY.CAUTION,
  [WARNING_ALERT_KEY]: ALERT_SEVERITY.WARNING,
  [CRITICAL_ALERT_KEY]: ALERT_SEVERITY.CRITICAL
};

export const messages = defineMessages({
  informational: {
    id: 'AlertSeverity.informational',
    defaultMessage: 'Informational'
  },
  caution: {
    id: 'AlertSeverity.caution',
    defaultMessage: 'Caution'
  },
  warning: {
    id: 'AlertSeverity.warning',
    defaultMessage: 'Warning'
  },
  critical: {
    id: 'AlertSeverity.critical',
    defaultMessage: 'Critical'
  }
});

export const ALERT_SEVERITIES = {
  INFORMATIONAL: {
    id: 'INFORMATIONAL',
    severityValue: 'Informational',
    severityKey: '0',
    message: messages.informational,
    permission: VIEW_INFORMATIONAL_ALERTS
  },
  CAUTION: {
    id: 'CAUTION',
    severityValue: 'Caution',
    severityKey: '1',
    message: messages.caution,
    permission: VIEW_CAUTION_ALERTS
  },
  WARNING: {
    id: 'WARNING',
    severityValue: 'Warning',
    severityKey: '2',
    message: messages.warning,
    permission: VIEW_WARNING_ALERTS
  },
  CRITICAL: {
    id: 'CRITICAL',
    severityValue: 'Critical',
    severityKey: '3',
    message: messages.critical,
    permission: VIEW_CRITICAL_ALERTS
  }
};

export const EXPOSURES_ALERT_SEVERITIES = {
  INFORMATIONAL: {
    id: ALERT_SEVERITY.INFORMATIONAL,
    severityValue: ALERT_SEVERITY.INFORMATIONAL,
    severityKey: '0',
    message: messages.informational,
    permission: VIEW_INFORMATIONAL_ALERTS
  },
  CAUTION: {
    id: ALERT_SEVERITY.CAUTION,
    severityValue: ALERT_SEVERITY.CAUTION,
    severityKey: '1',
    message: messages.caution,
    permission: VIEW_CAUTION_ALERTS
  },
  WARNING: {
    id: ALERT_SEVERITY.WARNING,
    severityValue: ALERT_SEVERITY.WARNING,
    severityKey: '2',
    message: messages.warning,
    permission: VIEW_WARNING_ALERTS
  },
  CRITICAL: {
    id: ALERT_SEVERITY.CRITICAL,
    severityValue: ALERT_SEVERITY.CRITICAL,
    severityKey: '3',
    message: messages.critical,
    permission: VIEW_CRITICAL_ALERTS
  }
};

export const INCIDENT_IMPACT_LEVEL_TO_SEVERITY = {
  [INCIDENT_IMPACT_LEVEL.LOW]: INFORMATIONAL_ALERT_KEY,
  [INCIDENT_IMPACT_LEVEL.MODERATE]: CAUTION_ALERT_KEY,
  [INCIDENT_IMPACT_LEVEL.HIGH]: WARNING_ALERT_KEY,
  [INCIDENT_IMPACT_LEVEL.SEVERE]: CRITICAL_ALERT_KEY
};
