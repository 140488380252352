export const TEST_ENVIRONMENTS = ['QA', 'QA-LOCAL', 'DEV', 'DEV-LOCAL'];
export const LIVE_ENVIRONMENTS = ['STAGE', 'PROD-LOCAL', 'PROD'];

export const FEATURES = {
  TRM_475: true,
  ELEARN_15: true,
  PRM_498: true,
  PRM_408: true,
  MGNM_597: true,
  MGNM_398: true,
  MGNM_681: true,
  MOBILE_268: true,
  INTH_273: true,
  ELEARN_33: true,
  XSRF: true,
  PRM_438: true,
  MGNM_755: true,
  MGNM_530: true,
  MOBILE_410: true,
  TRM_68: true,
  ELEARN_34: true,
  MAPI_107: true,
  MAPI_128: true,
  TRM_511: true,
  INTH_780: true,
  HORIZONWEB_6740: false,
  INTH_486: true,
  MOBILE_344: true,
  MOBILE_391: true,
  PRM_585: true,
  TBA_18: true,
  TRM_605: true,
  MOBILE_339: true,
  MOBILE_476: true,
  MOBILE_477: true,
  INTH_843: true,
  TBA_17: true,
  ELEARN_71: true,
  INTH_850: true,
  INTH_954: true,
  MGNM_678: true,
  PRM_649: true,
  ELEARN_51: true,
  PRM_487: true,
  PRM_660: true,
  PRM_664: false,
  PRM_633: true,
  PRM_662: true,
  INTH_166: true,
  MGNM_747: true,
  DIG_748: true,
  MASSNOTIF_736: true,
  MASSNOTIF_765: true,
  MGNMSTS_225: true,
  TBA_74: false,
  MAPI_207: true,
  MOBILE_506: true,
  PRM_553: true,
  MGNM_825: true,
  PRM_618: true,
  TBA_199: true,
  TBA_86: true,
  PRM_508: true,
  MGNMSTS_383: true,
  INTR_15: false,
  MASSNOTIF_784: true,
  TBA_152: false,
  MGNM_683: true,
  MAPI_223: false,
  TBA_198: true,
  TBA_225: false,
  MGNMSTS_384: false,
  PRM_733: false,
  MOBILE_529: false,
  MOBILE_345: false,
  MOBILE_535: false,
  MGNM_802: true
};

export const LOCAL_STORAGE_KEY = 'FEATURE_TOGGLE_ENABLE_OVERRIDE';
