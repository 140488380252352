import * as types from './types';

/**
 * Request reports.getAvailableReports
 *
 * @returns {function(*, *, {reports: *}): Promise<unknown>}
 */
export const requestAllReports = () => {
  return (dispatch, getState, {reports}) => {
    dispatch({type: types.GET_REPORTS_REQUEST});

    const locale = getState()?.localization?.locale;

    return reports.getReports(locale)
      .then(({data}) => {
        return dispatch({type: types.GET_REPORTS_SUCCESS, payload: data});
      })
      .catch(error => {
        return dispatch({type: types.GET_REPORTS_FAILURE, payload: error});
      });
  };
};
