import waRequest from './wa-request';

export const apiBase = '/exposures/v1';

/**
 * Initiate authenticated GET ${apiBase}/exposures/intel
 *
 * @param filter
 * @param locale
 * @param page
 * @param size
 * @param sort
 * @param includeExposures
 * @returns {Promise}
 */
function getAlerts(filter = {}, locale = 'en', page = 0, size = 25,sort = ['publishedDate,desc'], includeExposures = true) {
  const url = `${apiBase}/exposures/intel`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale,
      page,
      size,
      sort
    },
    data: {
      ...filter,
      includeExposures
    }
  });
}

/**
 * @param {string} alertId
 * @param {string[]} organizationIds
 * @param {string} locale
 * @return {Promise}
 */
function getAlertSummary(alertId, organizationIds, locale, includeSubOrganizations) {
  const url = `${apiBase}/exposures/intel/alerts/${alertId}/summaries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      alertId,
      organizationIds,
      locale,
      includeSubOrganizations
    }
  });
}

function getAlertSummaryConfigPattern({pathParams: {alertId}, params: {organizationIds, locale, includeSubOrganizations}}) {
  return getAlertSummary(alertId, organizationIds, locale, includeSubOrganizations)
}

function getIncidentSummary({pathParams: {incidentId}, params: {organizationIds, includeSubOrganizations, locale}}) {
  const url = `${apiBase}/exposures/intel/incidents/${incidentId}/summaries`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      incidentId,
      organizationIds,
      includeSubOrganizations,
      locale
    }
  });
}

/**
 *
 * @param alertId
 * @param organizationIds
 * @param locale
 * @param customDuration
 * @param customDuration.startDate
 * @param customDuration.endDate
 * @returns {Promise}
 */
function getCustomDuration(alertId, organizationIds, locale, {startDate, endDate}) {
  const url = `${apiBase}/exposures/intel/alerts/${alertId}/custom`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      alertId,
      organizationIds,
      locale,
      startDate,
      endDate
    }
  });
}

function initiateAlertsExport(exposureType, alertId, organizationIds, filterParams, includeSubOrganizations) {
  const url = `${apiBase}/exposures/intel/alerts/${alertId}/export`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      exposureType,
      includeSubOrganizations,
      ...filterParams
    }
  });
}

/**
 * Request all pages of alerts based on the filter, will loop through pagination to get all alerts
 *
 * @param filter
 * @param locale
 * @param sort
 * @returns {Promise<{data: *}>}
 */
function getAllAlerts(filter = {}, locale = 'en', sort = ['publishedDate,desc']) {
  const url = `${apiBase}/exposures/intel`;

  return waRequest.requestAllPages({
    url: url,
    method: 'post',
    params: {
      locale,
      size: 1000,
      sort
    },
    data: filter
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

// beginDate, modifiedDate, and publishedDate
function exportAlerts({
  params: {locale, exportType, sort='beginDate,desc'},
  signal,
  data: {
    intelTypes,
    activeWithinStart,
    activeWithinEnd,
    alertSeverities,
    incidentImpactLevels,
    categoryIds,
    locationIds,
    organizationIds,
    requirePersonExposures,
    requireSiteExposures,
    includeExpired,
    searchTerms,
    searchMode,
    intelKeywordIds
  }
}) {
  const url = `${apiBase}/exposures/intel/download`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      activeWithinStart,
      activeWithinEnd ,
      intelTypes,
      alertSeverities ,
      incidentImpactLevels,
      categoryIds,
      locationIds,
      includeExpired,
      organizationIds,
      requirePersonExposures,
      requireSiteExposures,
      searchTerms,
      searchMode,
      intelKeywordIds
    },
    params: {
      locale,
      exportType,
      sort
    },
    signal,
    responseType: 'blob'
  })
    .catch(err => {
      console.error('err:', err)
      throw new Error(err)
    });
}

function exportAffectedSites({
  params: {
    exportFormat,
    includeNames,
    includeIds,
    includeSiteType,
    includeVulnerabilityRadius,
    includeOrganization,
    includeCoordinates,
    includeAddress,
    includeEmployeeCount,
    includePOCs,
    includeNotes,
    organizationIds,
    includeSubOrganizations,
    exposureType,
    versionNumber,
    createUrl,
    alertId,
    includeDistanceFromExposureArea
  },
  signal
}) {

  const url = `${apiBase}/exposures/intel/alerts/${alertId}/export-sites`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      includeSubOrganizations,
      exposureType,
      versionNumber,
      createUrl,
      exportFormat,
      includeNames,
      includeIds,
      includeSiteType,
      includeVulnerabilityRadius,
      includeOrganization,
      includeCoordinates,
      includeAddress,
      includeEmployeeCount,
      includePOCs,
      includeNotes,
      includeDistanceFromExposureArea
    },
    signal
  });
}

function exportIncidentAffectedSites({
  params: {
    exportFormat,
    includeNames,
    includeIds,
    includeSiteType,
    includeVulnerabilityRadius,
    includeOrganization,
    includeCoordinates,
    includeAddress,
    includeEmployeeCount,
    includePOCs,
    includeNotes,
    organizationIds,
    exposureType,
    versionNumber,
    createUrl,
    incidentId,
    includeDistanceFromExposureArea
  },
  signal
}) {

  const url = `${apiBase}/exposures/intel/incidents/${incidentId}/export-sites`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      exposureType,
      versionNumber,
      createUrl,
      exportFormat,
      includeNames,
      includeIds,
      includeSiteType,
      includeVulnerabilityRadius,
      includeOrganization,
      includeCoordinates,
      includeAddress,
      includeEmployeeCount,
      includePOCs,
      includeNotes,
      includeDistanceFromExposureArea
    },
    signal
  })
}

function exportAlertAffectedOccupants({
  params: {
    organizationIds,
    includePOCs,
    createUrl,
    alertId,
    locale,
    sort,
    accountInfo,
    includeOrganizations,
    addresses,
    emails,
    emergencyContacts,
    names,
    phones,
    optionalFields,
    mobileLocations,
    includePersonTypes,
    includeSites
  },
  signal
}) {

  const url = `${apiBase}/exposures/intel/alerts/${alertId}/export-occupants`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      includePOCs,
      createUrl,
      locale,
      sort,
      accountInfo,
      includeOrganizations,
      addresses,
      emails,
      emergencyContacts,
      names,
      phones,
      optionalFields,
      mobileLocations,
      includePersonTypes,
      includeSites
    },
    signal
  });
}

function exportIncidentAffectedOccupants({
  params: {
    organizationIds,
    incidentId,
    includePOCs,
    createUrl,
    locale,
    sort,
    accountInfo,
    includeOrganizations,
    addresses,
    emails,
    emergencyContacts,
    names,
    phones,
    optionalFields,
    mobileLocations,
    includePersonTypes,
    includeSites
  },
  signal
}) {

  const url = `${apiBase}/exposures/intel/incidents/${incidentId}/export-occupants`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationIds,
      includePOCs,
      createUrl,
      locale,
      sort,
      accountInfo,
      includeOrganizations,
      addresses,
      emails,
      emergencyContacts,
      names,
      phones,
      optionalFields,
      mobileLocations,
      includePersonTypes,
      includeSites
    },
    signal
  })
}

function getAlertsConfigPattern({params}) {
  const {filter, ...rest} = params;
  const {locale, sort, size, page, includeExposures} = rest;
  return getAlerts(
    filter,
    locale,
    page,
    size,
    sort,
    includeExposures
  )
}

function getAllIntelExposures({params: {locale, sort = ['publishedDate,desc']}, data, signal}) {
  const url = `${apiBase}/exposures/intel`;

  return waRequest.requestAllPages({
    url: url,
    method: 'post',
    params: {
      locale,
      size: 1000,
      sort
    },
    signal,
    data
  })
    .then(pagedResults => {
      return waRequest.extractAllPagesResult(pagedResults, 'data.content');
    });
}

function getSiteExposures({
  pathParams: {siteId},
  params: {
    locale,
    page,
    size,
    sort = 'beginDate,desc'
  },
  data,
  signal
}) {
  const url = `${apiBase}/exposures/intel/sites/${siteId}/history`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale,
      page,
      size,
      sort
    },
    data,
    signal
  });
}

function exportSiteExposures({
  pathParams: {siteId},
  params: {
    locale,
    sort = 'beginDate,desc'
  },
  data
}) {
  const url = `${apiBase}/exposures/intel/sites/${siteId}/history/download`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    responseType: 'blob',
    params: {
      locale,
      sort
    },
    data
  });
}

export default {
  getAlerts,
  getAlertSummary,
  getIncidentSummary,
  initiateAlertsExport,
  getCustomDuration,
  getAllAlerts,
  exportAlerts,
  getAllIntelExposures,
  exportAffectedSites,
  exportIncidentAffectedSites,
  getSiteExposures,
  exportSiteExposures,
  exportAlertAffectedOccupants,
  exportIncidentAffectedOccupants,
  getAlertSummaryConfigPattern,
  getAlertsConfigPattern
};

