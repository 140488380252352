import {defineMessages, FormattedMessage} from 'react-intl';
import {WarningOutlined} from '@ant-design/icons';

import LocalizedModal from '../LocalizedModal/LocalizedModal';

const messages = defineMessages({
  okText: {
    id: 'CloseConfirmationModal.okText',
    defaultMessage: 'OK'
  },
  cancelText: {
    id: 'CloseConfirmationModal.cancelText',
    defaultMessage: 'Cancel'
  },
  title: {
    id: 'CloseConfirmationModal.title',
    defaultMessage: 'Discard Changes'
  },
  content: {
    id: 'CloseConfirmationModal.content',
    defaultMessage: 'Your changes have not been saved and will be discarded.'
  }
});

export default function CloseConfirmationModal(
  {
    title,
    content,
    maskClosable = true,
    showCancel = true,
    className = 'CloseConfirmationModal',
    icon = <WarningOutlined className="danger"/>,
    okType = 'danger primary',
    okText = <FormattedMessage {...messages.okText}/>
  },
  okCallback = () => {},
  cancelCallback = () => {}
) {
  LocalizedModal.confirm({
    className,
    icon: icon,
    maskClosable: maskClosable,
    title: title || <FormattedMessage {...messages.title}/>,
    content: content || <FormattedMessage {...messages.content}/>,
    okType: okType,
    okText: okText,
    cancelButtonProps: !showCancel ? {style: {display: 'none'}} : undefined,
    cancelText: <FormattedMessage {...messages.cancelText}/>,
    onOk() {
      okCallback();
    },
    onCancel() {
      cancelCallback();
    }
  });
}
