import MapLayer from './MapLayer';

export default class  MapMBTileOrgLayer extends MapLayer {
  organizationId;

  initializeLayer(){
    this.createSources();
    this.loadImages();
    this.createLayers();
    this.initializeLayers();
  }
    
  onAdd(map) {
    this.map = map;

    if (this.organizationId) {
      this.initializeLayer();
    }
  }

  refreshSource(newOrgId) {
    const missingOrgId = !this.organizationId;
    this.organizationId = newOrgId;

    if (this.map) {
      // if organizationID was missing org id it needs to create th layers/source and add to map
      if (missingOrgId) {
        this.initializeLayer();
      }
      else {
        this.setTiles();
      }
    }
  }

  /**
   * This method must be overridden for MapMBTileOrgLayer to function appropriately.
   * Should return a string of the tile url
   *
   * @returns {string}
   */
  getTilesUrl() {
    // empty method for class extension
  }

  /**
   * This method must be overridden for MapMBTileOrgLayer to function appropriately.
   */
  setTiles() {
    // empty method for class extension
  }
}
