import * as types from './types';

const notificationReducer = (
  state = {
    notifications: []
  },
  action
) => {
  switch (action.type) {

    case types.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };

    case types.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.payload.key)
      };

    case types.REMOVE_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: []
      };

    default:
      return state;
  }
};

export default notificationReducer;
