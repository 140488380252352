import * as types from './types';

const reducer = (
  state = {
    dict: {},
    isGetFilterLoading: false
  },
  action
) => {
  switch (action.type) {
    case types.INITIALIZE_PREFERENCES_DICT:
      return {
        ...state,
        dict: action.payload,
      };
    case types.UPDATE_PREFERENCES_DICT:
      return {
        ...state,
        dict: {
          ...state.dict,
          ...action.payload
        },
      };
  
    default:
      return state;
  }
};

export default reducer;
