import waHttp from './wa-http';

export const apiBase = '/contact-us/v1';
const baseURL = process.env.REACT_APP_GATEWAY_URL;

/**
 * Initiate authenticated POST `${apiBase}/contact-us`
 * @param {{
    title?: string,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string,
    query?: string,
    clientUrlString: string,
    recaptchaToken: string
  }} contactUsFields

 * @returns {Promise}
 */
function contactUsRequest(contactUsFields) {
  const url = `${apiBase}/contact-us`;

  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'post',
    data: contactUsFields
  });
}

export default {
  contactUsRequest
}
