import * as types from './types';
import {LOGOUT_REQUEST} from '../session/types';

const reducer = (
  state = {
    isFetching: false,
    list: [],
    error: null,
    locale: null
  },
  action
) => {
  switch (action.type) {
    case types.PERSON_MATCHING_PATTERNS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case types.PERSON_MATCHING_PATTERNS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        locale: action.payload.locale
      };

    case types.PERSON_MATCHING_PATTERNS_FAILURE:
      return {
        ...state,
        isFetching: false,
        list: [],
        error: action.error
      };

    case LOGOUT_REQUEST: // used to clear
      return {
        ...state,
        isFetching: false,
        list: [],
        error: null,
        locale: null
      };

    default:
      return state;
  }
};

export default reducer;
